import React, { forwardRef } from "react";
import { useState } from "react";
import moment from "moment";
import { ClickAwayListener } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import Flex from "../_styles/Flex";
import { ReactComponent as CalendarIcon } from "../../assets/calendar-outline.svg";
import { ReactComponent as RemoveRecoIcon } from "../../assets/close-outline.svg";
import { isArray } from "lodash";

const RadioBtn = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 50%;
  border-color: ${(props) => props.borderColor};
  background-color: ${(props) => props.bg};
  padding: 1% !important;
  background-clip: content-box;
  margin-right: 7px;
  cursor: pointer;
`;

const RadioLabel = styled.label`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 0px;
`;

const RadioContainer = styled.div`
  border: 1px solid #c3c3c3;
  width: 230px;
  display: flex;
  flex-direction: column;
  background: white;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 12px;
  border-radius: 4px;
`;

const InitialSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43.5px;
  padding: 9.5px 12px;
  width: 230px;
  background: white;
  border: 1px solid #dfe0eb;
  cursor: pointer;
  border-radius: 5px;
`;
const SelectDropdownTitle = styled.div`
  margin-bottom: 4px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 9.2px;
  line-height: 17px;
  color: #000000;
  opacity: 0.6;
  text-transform: uppercase;
  position:absolute;
  top:-20px;
`;
const SelectContainer = styled.div`
  position:relative;
  width:230px;
`;

const DatePickerContainer = styled(Flex)`
  min-width: 230px;
  margin-right: 6px;
  & > div > div > div {
    height: 43.5px;
    min-width: 230px;
  }
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 8px;
  font-size: 18px;
  width: max-content;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;

const DateContainer = styled(Flex)`
  margin-right: 15px;
  font-size: 12px;
`;

const DateClearButton = styled.div`
  cursor: pointer;
  display: flex;
`;
const RecoOptions = [
  {
    value: "last7days",
    label: "Last 7 days",
    start: new Date(moment().subtract(7, "days")),
    end: new Date(moment()),
  },
  {
    value: "last30days",
    label: "Last 30 days",
    start: new Date(moment().subtract(30, "days")),
    end: new Date(moment()),
  },
  {
    value: "last3months",
    label: "Last 3 months",
    start: new Date(moment().subtract(3, "months")),
    end: new Date(moment()),
  },
  {
    value: "last6months",
    label: "Last 6 months",
    start: new Date(moment().subtract(6, "months")),
    end: new Date(moment()),
  },
  {
    value: "last12months",
    label: "Last 12 months",
    start: new Date(moment().subtract(12, "months")),
    end: new Date(moment()),
  },
  { value: "custom", label: "Custom date range", start: null, end: null },
];

const RecoRangeSelector = ({ selectedValues, setSelectedValues,setCurrPageNumber}) => {
  const onChange = (dates) => {
    setSelectedValues((prev) => ({
      ...prev,
      date: dates,
      triggerUpdate: true,
    }));
  };

  const onInitialRangeDatesChange = (dates) => {
    if (isArray(dates)) {
      const [start, end] = dates;
      setSelectedValues((prev) => ({
        ...prev,
        initialRangeStartDate: start,
        initialRangeEndDate: end,
        initialRangeDatesFilter: {
          startDate: start ? moment(start).format("YYYY-MM-DD") : "",
          endDate: end ? moment(end).format("YYYY-MM-DD") : "",
        },
        triggerUpdate: true,
      }));
      setCurrPageNumber(1);
    }
  };
  const resetRangeDateFilter = () => {
    setSelectedValues((prev) => ({
      ...prev,
      date: "",
      initialRangeStartDate: "",
      initialRangeEndDate: "",
      initialRangeDatesFilter: {
        startDate: "",
        endDate: "",
      },
    }));
    setCurrPageNumber(1);
  };

  const DatePickerInputField = React.forwardRef((props, ref) => {
    const { onClick, date } = props;
    const formatDate = (date, time) => {
      return `${moment(date).format("MMM, DD, YYYY")}`;
    };
    return (
      <DatePickerButton
        onClick={onClick}
        style={{ padding: "8px 24px", fontSize: "12px", width: "230px" }}
      >
        <DateContainer style={{ fontSize: 12 }}>
          {selectedValues?.date ? `${formatDate(date)}` : "Select Reco Date"}
        </DateContainer>
        {!selectedValues?.date && <CalendarIcon />}
        {selectedValues?.date && (
          <DateClearButton
            onClick={() => {
              resetRangeDateFilter();
              setSelectedValues((prev) => ({
                ...prev,
                selectedOption: null,
                selectedConfiguration: null,
                selectedSubOptionReco: null,
                recoRangeDateView: true,
                triggerUpdate: true,
              }));
            }}
          >
            <RemoveRecoIcon />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });

  const DatePickerInputRange = forwardRef(
    ({ onClick, startDate, endDate }, ref) => {
      const formatDate = (date) => {
        return date ? moment(date).format("MMM, DD, YYYY") : "No Date";
      };

      return (
        <DatePickerButton ref={ref}>
          <DateContainer>
            {!startDate && !endDate
              ? "Select Reco Date Range"
              : `${formatDate(startDate)} - ${formatDate(endDate)}`}
          </DateContainer>

          {!selectedValues?.initialRangeStartDate &&
            !selectedValues?.initialRangeEndDate && <CalendarIcon />}
          {(selectedValues?.initialRangeStartDate ||
            selectedValues?.initialRangeEndDate) && (
            <DateClearButton
              onClick={() => {
                resetRangeDateFilter();
                setSelectedValues((prev) => ({
                  ...prev,
                  selectedOption: null,
                  selectedConfiguration: null,
                  selectedSubOptionReco: null,
                  recoRangeDateView: true,
                  triggerUpdate: true,
                }));
              }}
            >
              <RemoveRecoIcon />
            </DateClearButton>
          )}
        </DatePickerButton>
      );
    }
  );

  return (
    <SelectContainer style={{position:'relative'}}>
      <SelectDropdownTitle>
      RECO DUE DATE RANGE
    </SelectDropdownTitle>

      {selectedValues?.recoRangeDateView &&
      !selectedValues?.date &&
      !selectedValues?.initialRangeStartDate &&
      !selectedValues?.initialRangeEndDate ? (
        <InitialSelect
          onClick={() =>
            setSelectedValues((prev) => ({
              ...prev,
              recoRangeDateView: false,
            }))
          }
        >
          <span>Select...</span>
          <CalendarIcon />
        </InitialSelect>
      ) : selectedValues?.date ? (
        <DatePickerContainer>
          <DatePicker
            popperPlacement={"auto"}
            onChange={onChange}
            dateFormat="MM/dd/yyyy"
            selected={selectedValues?.date}
            customInput={<DatePickerInputField date={selectedValues?.date} />}
          />
        </DatePickerContainer>
      ) : selectedValues?.initialRangeStartDate &&
        selectedValues?.initialRangeEndDate ? (
        <DatePickerContainer>
          <DatePicker
            popperPlacement={"auto"}
            selected={selectedValues?.initialRangeStartDate}
            startDate={selectedValues?.initialRangeStartDate}
            endDate={selectedValues?.initialRangeEndDate}
            customInput={
              <DatePickerInputRange
                startDate={selectedValues?.initialRangeStartDate}
                endDate={selectedValues?.initialRangeEndDate}
              />
            }
            shouldCloseOnSelect={false}
            selectsRange
            dateFormat={"dd/MM/yyyy"}
            popperModifiers={{
              offset: {
                enabled: true,
                offset: "5px, 10px",
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                boundariesElement: "viewport",
              },
            }}
          />
        </DatePickerContainer>
      ) : (
        <ClickAwayListener
          onClickAway={() => {
            setSelectedValues((prev) => ({
              ...prev,
              selectedConfiguration: null,
              selectedOption: null,
              recoRangeDateView: true,
              selectedSubOptionReco: null,
              triggerUpdate: false,
            }));
          }}
        >
          <div style={{position:'absolute', zIndex:2}}>
            <RadioContainer>
              <RadioLabel>
                <RadioBtn
                  type="radio"
                  value="range start"
                  borderColor={
                    selectedValues?.selectedOption === "range start"
                      ? "#00AEEF"
                      : "#D4D4D4"
                  }
                  bg={
                    selectedValues?.selectedOption === "range start"
                      ? "#00AEEF"
                      : "#FFF"
                  }
                  checked={selectedValues?.selectedOption === "range start"}
                  onChange={(e) => {
                    resetRangeDateFilter();
                    setSelectedValues((prev) => ({
                      ...prev,
                      selectedOption: "range start",
                      selectedConfiguration: null,
                      selectedSubOptionReco: null,
                      triggerUpdate: false,
                    }));
                  }}
                />
                Min Due Date
              </RadioLabel>
              {selectedValues?.selectedOption === "range start" && (
                <div
                  style={{
                    marginLeft: "34px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                  }}
                >
                  <RadioLabel>
                    <RadioBtn
                      type="radio"
                      value="lte"
                      checked={selectedValues?.selectedConfiguration === "lte"}
                      onChange={() => {
                        resetRangeDateFilter();
                        setSelectedValues((prev) => ({
                          ...prev,
                          selectedConfiguration: "lte",
                          triggerUpdate: false,
                        }));
                      }}
                      borderColor={
                        selectedValues?.selectedConfiguration === "lte"
                          ? "#00AEEF"
                          : "#D4D4D4"
                      }
                      bg={
                        selectedValues?.selectedConfiguration === "lte"
                          ? "#00AEEF"
                          : "#FFF"
                      }
                    />
                    Before
                  </RadioLabel>
                  <RadioLabel>
                    <RadioBtn
                      type="radio"
                      value="gte"
                      checked={selectedValues?.selectedConfiguration === "gte"}
                      onChange={() => {
                        resetRangeDateFilter();
                        setSelectedValues((prev) => ({
                          ...prev,
                          selectedConfiguration: "gte",
                          triggerUpdate: false,
                        }));
                      }}
                      borderColor={
                        selectedValues?.selectedConfiguration === "gte"
                          ? "#00AEEF"
                          : "#D4D4D4"
                      }
                      bg={
                        selectedValues?.selectedConfiguration === "gte"
                          ? "#00AEEF"
                          : "#FFF"
                      }
                    />
                    After
                  </RadioLabel>
                  <RadioLabel>
                    <RadioBtn
                      type="radio"
                      value="gte and lte"
                      checked={
                        selectedValues?.selectedConfiguration === "gte and lte"
                      }
                      onChange={() => {
                        resetRangeDateFilter();
                        setSelectedValues((prev) => ({
                          ...prev,
                          selectedConfiguration: "gte and lte",
                          triggerUpdate: false,
                        }));
                      }}
                      borderColor={
                        selectedValues?.selectedConfiguration === "gte and lte"
                          ? "#00AEEF"
                          : "#D4D4D4"
                      }
                      bg={
                        selectedValues?.selectedConfiguration === "gte and lte"
                          ? "#00AEEF"
                          : "#FFF"
                      }
                    />
                    Between
                  </RadioLabel>
                  {selectedValues?.selectedConfiguration === "gte and lte" && (
                    <div
                      style={{
                        marginLeft: "23px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "6px",
                      }}
                    >
                      <RadioLabel>
                        <RadioBtn
                          type="radio"
                          value="custom"
                          checked={
                            selectedValues?.selectedSubOptionReco === "custom"
                          }
                          onChange={() =>
                            setSelectedValues((prev) => ({
                              ...prev,
                              selectedSubOptionReco: "custom",
                              triggerUpdate: false,
                            }))
                          }
                          borderColor={
                            selectedValues?.selectedSubOptionReco === "custom"
                              ? "#00AEEF"
                              : "#D4D4D4"
                          }
                          bg={
                            selectedValues?.selectedSubOptionReco === "custom"
                              ? "#00AEEF"
                              : "#FFF"
                          }
                        />
                        Custom Range
                      </RadioLabel>
                      <RadioLabel>
                        <RadioBtn
                          type="radio"
                          value="last7days"
                          checked={
                            selectedValues?.selectedSubOptionReco ===
                            "last7days"
                          }
                          onChange={() => {
                            let obj = RecoOptions.find(
                              (itm) => itm.value === "last7days"
                            );
                            setSelectedValues((prev) => ({
                              ...prev,
                              selectedSubOptionReco: "last7days",
                              initialRangeStartDate: obj.start,
                              initialRangeEndDate: obj.end,
                              initialRangeDatesFilter: {
                                startDate: obj.start
                                  ? moment(obj.start).format("YYYY-MM-DD")
                                  : "",
                                endDate: obj.end
                                  ? moment(obj.end).format("YYYY-MM-DD")
                                  : "",
                              },
                              triggerUpdate: true,
                            }));
                          }}
                          borderColor={
                            selectedValues?.selectedSubOptionReco ===
                            "last7days"
                              ? "#00AEEF"
                              : "#D4D4D4"
                          }
                          bg={
                            selectedValues?.selectedSubOptionReco ===
                            "last7days"
                              ? "#00AEEF"
                              : "#FFF"
                          }
                        />
                        Last 7 Days
                      </RadioLabel>
                      <RadioLabel>
                        <RadioBtn
                          type="radio"
                          value="last30days"
                          checked={
                            selectedValues?.selectedSubOptionReco ===
                            "last30days"
                          }
                          onChange={() => {
                            let obj = RecoOptions.find(
                              (itm) => itm.value === "last30days"
                            );
                            setSelectedValues((prev) => ({
                              ...prev,
                              selectedSubOptionReco: "last30days",
                              initialRangeStartDate: obj.start,
                              initialRangeEndDate: obj.end,
                              initialRangeDatesFilter: {
                                startDate: obj.start
                                  ? moment(obj.start).format("YYYY-MM-DD")
                                  : "",
                                endDate: obj.end
                                  ? moment(obj.end).format("YYYY-MM-DD")
                                  : "",
                              },
                              triggerUpdate: true,
                            }));
                          }}
                          borderColor={
                            selectedValues?.selectedSubOptionReco ===
                            "last30days"
                              ? "#00AEEF"
                              : "#D4D4D4"
                          }
                          bg={
                            selectedValues?.selectedSubOptionReco ===
                            "last30days"
                              ? "#00AEEF"
                              : "#FFF"
                          }
                        />
                        Last 30 Days
                      </RadioLabel>
                      <RadioLabel>
                        <RadioBtn
                          type="radio"
                          value="last6months"
                          checked={
                            selectedValues?.selectedSubOptionReco ===
                            "last6months"
                          }
                          onChange={() => {
                            let obj = RecoOptions.find(
                              (itm) => itm.value === "last6months"
                            );
                            setSelectedValues((prev) => ({
                              ...prev,
                              selectedSubOptionReco: "last6months",
                              initialRangeStartDate: obj.start,
                              initialRangeEndDate: obj.end,
                              initialRangeDatesFilter: {
                                startDate: obj.start
                                  ? moment(obj.start).format("YYYY-MM-DD")
                                  : "",
                                endDate: obj.end
                                  ? moment(obj.end).format("YYYY-MM-DD")
                                  : "",
                              },
                              triggerUpdate: true,
                            }));
                          }}
                          borderColor={
                            selectedValues?.selectedSubOptionReco ===
                            "last6months"
                              ? "#00AEEF"
                              : "#D4D4D4"
                          }
                          bg={
                            selectedValues?.selectedSubOptionReco ===
                            "last6months"
                              ? "#00AEEF"
                              : "#FFF"
                          }
                        />
                        Last 6 Months
                      </RadioLabel>
                      <RadioLabel>
                        <RadioBtn
                          type="radio"
                          value="last12months"
                          checked={
                            selectedValues?.selectedSubOptionReco ===
                            "last12months"
                          }
                          onChange={() => {
                            let obj = RecoOptions.find(
                              (itm) => itm.value === "last12months"
                            );
                            setSelectedValues((prev) => ({
                              ...prev,
                              selectedSubOptionReco: "last12months",
                              initialRangeStartDate: obj.start,
                              initialRangeEndDate: obj.end,
                              initialRangeDatesFilter: {
                                startDate: obj.start
                                  ? moment(obj.start).format("YYYY-MM-DD")
                                  : "",
                                endDate: obj.end
                                  ? moment(obj.end).format("YYYY-MM-DD")
                                  : "",
                              },
                              triggerUpdate: true,
                            }));
                          }}
                          borderColor={
                            selectedValues?.selectedSubOptionReco ===
                            "last12months"
                              ? "#00AEEF"
                              : "#D4D4D4"
                          }
                          bg={
                            selectedValues?.selectedSubOptionReco ===
                            "last12months"
                              ? "#00AEEF"
                              : "#FFF"
                          }
                        />
                        Last 12 Months
                      </RadioLabel>
                    </div>
                  )}
                </div>
              )}
              <RadioLabel>
                <RadioBtn
                  type="radio"
                  value="range end"
                  borderColor={
                    selectedValues?.selectedOption === "range end"
                      ? "#00AEEF"
                      : "#D4D4D4"
                  }
                  bg={
                    selectedValues?.selectedOption === "range end"
                      ? "#00AEEF"
                      : "#FFF"
                  }
                  checked={selectedValues?.selectedOption === "range end"}
                  onChange={(e) => {
                    resetRangeDateFilter();
                    setSelectedValues((prev) => ({
                      ...prev,
                      selectedOption: "range end",
                      selectedConfiguration: null,
                      selectedSubOptionReco: null,
                      triggerUpdate: false,
                    }));
                  }}
                />
                Max Due Date
              </RadioLabel>
              {selectedValues?.selectedOption === "range end" && (
                <div
                  style={{
                    marginLeft: "34px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                  }}
                >
                  <RadioLabel>
                    <RadioBtn
                      type="radio"
                      value="lte"
                      checked={selectedValues?.selectedConfiguration === "lte"}
                      onChange={() => {
                        resetRangeDateFilter();
                        setSelectedValues((prev) => ({
                          ...prev,
                          selectedConfiguration: "lte",
                          triggerUpdate: false,
                        }));
                      }}
                      borderColor={
                        selectedValues?.selectedConfiguration === "lte"
                          ? "#00AEEF"
                          : "#D4D4D4"
                      }
                      bg={
                        selectedValues?.selectedConfiguration === "lte"
                          ? "#00AEEF"
                          : "#FFF"
                      }
                    />
                    Before
                  </RadioLabel>
                  <RadioLabel>
                    <RadioBtn
                      type="radio"
                      value="gte"
                      checked={selectedValues?.selectedConfiguration === "gte"}
                      onChange={() => {
                        resetRangeDateFilter();
                        setSelectedValues((prev) => ({
                          ...prev,
                          selectedConfiguration: "gte",
                          triggerUpdate: false,
                        }));
                      }}
                      borderColor={
                        selectedValues?.selectedConfiguration === "gte"
                          ? "#00AEEF"
                          : "#D4D4D4"
                      }
                      bg={
                        selectedValues?.selectedConfiguration === "gte"
                          ? "#00AEEF"
                          : "#FFF"
                      }
                    />
                    After
                  </RadioLabel>
                  <RadioLabel>
                    <RadioBtn
                      type="radio"
                      value="gte and lte"
                      checked={
                        selectedValues?.selectedConfiguration === "gte and lte"
                      }
                      onChange={() => {
                        resetRangeDateFilter();
                        setSelectedValues((prev) => ({
                          ...prev,
                          selectedConfiguration: "gte and lte",
                          triggerUpdate: false,
                        }));
                      }}
                      borderColor={
                        selectedValues?.selectedConfiguration === "gte and lte"
                          ? "#00AEEF"
                          : "#D4D4D4"
                      }
                      bg={
                        selectedValues?.selectedConfiguration === "gte and lte"
                          ? "#00AEEF"
                          : "#FFF"
                      }
                    />
                    Between
                  </RadioLabel>

                  {selectedValues?.selectedConfiguration === "gte and lte" && (
                    <div
                      style={{
                        marginLeft: "23px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "6px",
                      }}
                    >
                      <RadioLabel>
                        <RadioBtn
                          type="radio"
                          value="custom"
                          checked={
                            selectedValues?.selectedSubOptionReco === "custom"
                          }
                          onChange={() =>
                            setSelectedValues((prev) => ({
                              ...prev,
                              selectedSubOptionReco: "custom",
                              triggerUpdate: false,
                            }))
                          }
                          borderColor={
                            selectedValues?.selectedSubOptionReco === "custom"
                              ? "#00AEEF"
                              : "#D4D4D4"
                          }
                          bg={
                            selectedValues?.selectedSubOptionReco === "custom"
                              ? "#00AEEF"
                              : "#FFF"
                          }
                        />
                        Custom Range
                      </RadioLabel>
                      <RadioLabel>
                        <RadioBtn
                          type="radio"
                          value="last7days"
                          checked={
                            selectedValues?.selectedSubOptionReco ===
                            "last7days"
                          }
                          onChange={() => {
                            let obj = RecoOptions.find(
                              (itm) => itm.value === "last7days"
                            );
                            setSelectedValues((prev) => ({
                              ...prev,
                              selectedSubOptionReco: "last7days",
                              initialRangeStartDate: obj.start,
                              initialRangeEndDate: obj.end,
                              initialRangeDatesFilter: {
                                startDate: obj.start
                                  ? moment(obj.start).format("YYYY-MM-DD")
                                  : "",
                                endDate: obj.end
                                  ? moment(obj.end).format("YYYY-MM-DD")
                                  : "",
                              },
                              triggerUpdate: true,
                            }));
                          }}
                          borderColor={
                            selectedValues?.selectedSubOptionReco ===
                            "last7days"
                              ? "#00AEEF"
                              : "#D4D4D4"
                          }
                          bg={
                            selectedValues?.selectedSubOptionReco ===
                            "last7days"
                              ? "#00AEEF"
                              : "#FFF"
                          }
                        />
                        Last 7 Days
                      </RadioLabel>
                      <RadioLabel>
                        <RadioBtn
                          type="radio"
                          value="last30days"
                          checked={
                            selectedValues?.selectedSubOptionReco ===
                            "last30days"
                          }
                          onChange={() => {
                            let obj = RecoOptions.find(
                              (itm) => itm.value === "last30days"
                            );
                            setSelectedValues((prev) => ({
                              ...prev,
                              selectedSubOptionReco: "last30days",
                              initialRangeStartDate: obj.start,
                              initialRangeEndDate: obj.end,
                              initialRangeDatesFilter: {
                                startDate: obj.start
                                  ? moment(obj.start).format("YYYY-MM-DD")
                                  : "",
                                endDate: obj.end
                                  ? moment(obj.end).format("YYYY-MM-DD")
                                  : "",
                              },
                              triggerUpdate: true,
                            }));
                          }}
                          borderColor={
                            selectedValues?.selectedSubOptionReco ===
                            "last30days"
                              ? "#00AEEF"
                              : "#D4D4D4"
                          }
                          bg={
                            selectedValues?.selectedSubOptionReco ===
                            "last30days"
                              ? "#00AEEF"
                              : "#FFF"
                          }
                        />
                        Last 30 Days
                      </RadioLabel>
                      <RadioLabel>
                        <RadioBtn
                          type="radio"
                          value="last6months"
                          checked={
                            selectedValues?.selectedSubOptionReco ===
                            "last6months"
                          }
                          onChange={() => {
                            let obj = RecoOptions.find(
                              (itm) => itm.value === "last6months"
                            );
                            setSelectedValues((prev) => ({
                              ...prev,
                              selectedSubOptionReco: "last6months",
                              initialRangeStartDate: obj.start,
                              initialRangeEndDate: obj.end,
                              initialRangeDatesFilter: {
                                startDate: obj.start
                                  ? moment(obj.start).format("YYYY-MM-DD")
                                  : "",
                                endDate: obj.end
                                  ? moment(obj.end).format("YYYY-MM-DD")
                                  : "",
                              },
                              triggerUpdate: true,
                            }));
                          }}
                          borderColor={
                            selectedValues?.selectedSubOptionReco ===
                            "last6months"
                              ? "#00AEEF"
                              : "#D4D4D4"
                          }
                          bg={
                            selectedValues?.selectedSubOptionReco ===
                            "last6months"
                              ? "#00AEEF"
                              : "#FFF"
                          }
                        />
                        Last 6 Months
                      </RadioLabel>
                      <RadioLabel>
                        <RadioBtn
                          type="radio"
                          value="last12months"
                          checked={
                            selectedValues?.selectedSubOptionReco ===
                            "last12months"
                          }
                          onChange={() => {
                            let obj = RecoOptions.find(
                              (itm) => itm.value === "last12months"
                            );
                            setSelectedValues((prev) => ({
                              ...prev,
                              selectedSubOptionReco: "last12months",
                              initialRangeStartDate: obj.start,
                              initialRangeEndDate: obj.end,
                              initialRangeDatesFilter: {
                                startDate: obj.start
                                  ? moment(obj.start).format("YYYY-MM-DD")
                                  : "",
                                endDate: obj.end
                                  ? moment(obj.end).format("YYYY-MM-DD")
                                  : "",
                              },
                              triggerUpdate: true,
                            }));
                          }}
                          borderColor={
                            selectedValues?.selectedSubOptionReco ===
                            "last12months"
                              ? "#00AEEF"
                              : "#D4D4D4"
                          }
                          bg={
                            selectedValues?.selectedSubOptionReco ===
                            "last12months"
                              ? "#00AEEF"
                              : "#FFF"
                          }
                        />
                        Last 12 Months
                      </RadioLabel>
                    </div>
                  )}
                </div>
              )}
            </RadioContainer>
            {!selectedValues?.initialRangeStartDate &&
            (selectedValues?.selectedConfiguration === "gte" ||
              selectedValues?.selectedConfiguration === "lte") ? (
              <DatePicker
                selected={selectedValues?.initialRangeStartDate}
                onChange={onChange}
                inline
              />
            ) : null}
            {selectedValues?.selectedConfiguration === "gte and lte" &&
              selectedValues?.selectedSubOptionReco === "custom" && (
                <DatePicker
                  selected={selectedValues?.initialRangeStartDate}
                  onChange={onInitialRangeDatesChange}
                  startDate={selectedValues?.initialRangeStartDate}
                  endDate={selectedValues?.initialRangeEndDate}
                  selectsRange
                  inline
                  shouldCloseOnSelect
                />
              )}
          </div>
        </ClickAwayListener>
      )}
    </SelectContainer>
  );
};

export default RecoRangeSelector;
