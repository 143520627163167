import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Nav, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import filterOutline from "../../../assets/IO-icons/filter-outline.svg";
import close from "../../../assets/cancel-outline-chip.svg";

import {
  SET_DOCTOR_SEARCH_VALUE,
  SET_RADIOLOGIST_SEARCH_VALUE,
  showAlert,
} from "../../../actions/radminUploads";
import {
  getDoctorsListAction,
  getRadiologistList,
  setDoctorForPatientsAction,
  getExportReportAction,
} from "../../../actions/radminUploads";
import {
  RefferedPatientsIcon,
  ImagingFollowUpsIcon,
  StarBlueIcon,
  TimerBlueIcon,
  CheckBoxBlueIcon,
  LikeBlueIcon,
} from "./Icons";

import TopDoctorCard from "./TopDoctorCard";
import TopLeaderBoardCard from "./TopLeaderboardCard";
import DoubleDataCell from "../PatientList/LastScanCell";
import Box from "../../../components/_styles/Box";
import Flex from "../../../components/_styles/Flex";
import Table, {
  AvatarContainer,
  UserAvatar,
  UserEmptyAvatar,
} from "../../../components/Table/Table";
import EnableFeature from "../../../components/EnableFeature/EnableFeature";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import LoadMoreButton from "../../../components/LoadMoreButton";
import { ReactComponent as Search } from "../../../assets/search.svg";
import { ReactComponent as EmptyStateImg } from "../../../assets/empty-state-search.svg";
import { ReactComponent as ChevronRightIcon } from "../../../assets/chevron_right.svg";
import { Card } from "../../../styles";
import moment from "moment";
import { camelCase } from "lodash";
import { useLocation } from "react-router";
import { ClipboardIcon } from "../../../components/Dashboard/Icons";
import { Filters } from "./Filters";
import ExamDateSelector from "../../../components/ExamDateSelector/ExamDateSelector";
import RecoRangeSelector from "../../../components/RecoRangeSelector/RecoRangeSelector";
import { Link } from "react-router-dom";


const StyledLinkRedirectRecos = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  text-decoration:underline;
  color:white;
`;

const Image = styled.img`
  display: block;
  width: 20px;
`;

const FilterButton = styled(Flex)`
  width: 41px;
  justify-content: space-around;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #00aeef;
  background: #fff;
  cursor: pointer;
  border: 1px solid #dfe0eb;
  border-radius: 4px;
`;

const Badge = styled(Flex)`
  width: fit-content;
  min-width: 54px;
  padding: 0 5px;
  height: 24px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 100px;
  color: white;
  background: ${(props) => props.bcolor};
`;

const StyledChevron = styled(ChevronRightIcon)`
  width: 26px;
  height: 26px;
  cursor: pointer;
  & > path {
    fill: #9fa2b4;
  }
  &:hover > path {
    fill: #3f4857;
    transition: all 0.3s ease;
  }
`;

const StyledLink = styled(Nav.Link)`
  padding: 0;
`;

const HeaderContainer = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-right: 0;
  }
`;

const SwitchContainer = styled.div`
  padding: 4px;
  display: flex;
  background: #e6e9f1;
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0c2945;
  width: max-content;
`;

const SwitchButton = styled.button`
  padding: 7px 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? "#0C2945" : "#E6E9F1")};
  color: ${(props) => (props.active ? "#F9FAFC" : "#0C2945")};
  border: 0;
  outline: 0;
  border-radius: 6px;
  font-weight: 600;
`;

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
`;
export const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  margin-right: 10px;
`;
const FormContainer = styled(Flex)`
  justify-content: space-between;
`;
const InputContainer = styled.div`
  width: 450px;
  // margin-right: 50px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    width: 350px;
  }
  @media (max-width: 957px) {
    margin-top: 5px;
  }
`;

const CustomPlaceholder = styled.span`
  position: absolute;
  margin-left: 100px;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  color: #000000;
  @media (max-width: 768px) {
    margin-left: 60px;
  }
`;

const Input = styled.input`
  width: 100%;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 5px;
  height: 43.5px;
  text-align: center;
  font: normal 16px "Open Sans", sans-serif;
`;

const ClearButton = styled.button`
  position: absolute;
  margin-left: 410px;
  border-radius: 5%;
  z-index: 1;
  border: none;
  cursor: pointer;
  color: #000000;
  background-color: transparent;
  transform: translateX(2px);
  @media (max-width: 768px) {
    margin-left: -30px;
  }
`;

const SearchIcon = styled.i`
  display: inline-block;
  position: absolute;
  margin-left: 50px;
  color: green;
  min-width: 45px;
  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;

const RefPhysicianName = styled.span``;

const TableItemNum = styled.span`
  margin-right: 30px;
  font-weight: 600;
  font-size: 16px;
  color: #9fa2b4;
`;

const ColumnNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExportContainer = styled.button`
  border: 0px;
  float: right;
  margin-top: -10px;
  background-color: #00aeef;
  border-radius: 5px;
`;

const ExportButton = styled.button`
  padding: 12px 10px;
  background-color: #00aeef;
  font-family: Roboto Condensed;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  border: 0;
  float: right;
  min-width: max-content;
  background-color: #00aeef;
  color: #ffffff;
  width: 208px;
  @media (max-width: 947px) {
    margin-top: 5px;
  }
`;

const radiologistColumns = [
  {
    key: "name",
    name: "Radiologist Name",
    displayCell: (cellValue, row, idx) => (
      <UserDataBlock name={cellValue} num={idx + 1} index={idx} row={row} />
    ),
  },
  {
    key: "num_reports",
    name: "# of Reports",
    displayCell: (cellValue) => (
      <ItemWrapper>
        <IconContainer>
          <ClipboardIcon />
        </IconContainer>
        <DoubleDataCell mainInfo={cellValue} />
      </ItemWrapper>
    ),
  },
  {
    key: "numberOfRecos",
    name: "# of imaging Recos",
    displayCell: (cellValue) => (
      <ItemWrapper>
        <IconContainer>
          <LikeBlueIcon />
        </IconContainer>
        <DoubleDataCell mainInfo={cellValue} />
      </ItemWrapper>
    ),
  },
  {
    key: "closedRecos",
    name: "# of imaging Recos Closed",
    displayCell: (cellValue) => (
      <ItemWrapper>
        <IconContainer>
          <CheckBoxBlueIcon />
        </IconContainer>
        <DoubleDataCell mainInfo={cellValue} />
      </ItemWrapper>
    ),
  },
  {
    key: "numberOfDetailedRecos",
    name: "# of imaging Recos (with Time.Modality.Anatomy)",
    displayCell: (cellValue, row) => (
      <ItemWrapper>
        <IconContainer>
          <TimerBlueIcon />
        </IconContainer>{" "}
        <DoubleDataCell mainInfo={cellValue} />
      </ItemWrapper>
    ),
  },
  {
    key: "highQuality",
    name: "High Quality Recomendations (%)",
    displayCell: (cellValue, row) => (
      <ItemWrapper>
        <IconContainer>
          <StarBlueIcon />
        </IconContainer>{" "}
        <DoubleDataCell mainInfo={cellValue} />
      </ItemWrapper>
    ),
  },
];

export const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 8px;
  font-size: 18px;
  width: max-content;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;

const RecoBadge = ({
  patientsCount,
  overduePatientsCount,
  bcolor,
  redirectLink,
  history,
}) => (
  <Badge
    percents={(overduePatientsCount / patientsCount) * 100}
    bcolor={bcolor}
  >
    <StyledLinkRedirectRecos
    to={redirectLink}
    >
      {overduePatientsCount}
    </StyledLinkRedirectRecos>
  </Badge>
);

const UserDataBlock = ({ row, name, avatar, num, index }) => {
  const initial = row.initials;
  return (
    <UserInfoContainer>
      <TableItemNum>{num}</TableItemNum>
      <AvatarContainer>
        {avatar ? (
          <UserAvatar src={avatar} />
        ) : (
          <UserEmptyAvatar>{initial}</UserEmptyAvatar>
        )}
      </AvatarContainer>
      <RefPhysicianName>{name}</RefPhysicianName>
    </UserInfoContainer>
  );
};

export const ColumnName = ({ title }) => {
  return (
    <ColumnNameWrapper>
      <span>{title}</span>
    </ColumnNameWrapper>
  );
};

export const DatePickerContainer = styled(Flex)`
  min-width: 208px;
  margin-right: 6px;
  & > div > div > div {
    height: 38px;
    min-width: 208px;
  }
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

export const DateClearButton = styled.div`
  cursor: pointer;
  display: flex;
`;

export const DateContainer = styled(Flex)`
  margin-right: 15px;
  font-size: 12px;
`;

const SelectedItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  min-width: fit-content;
  height: 32px;
  margin-top: 2px;
  padding: 4px 10px 4px 10px;
  background: #eeeeee;
  border-radius: 25px;
  @media (max-width: 768px) {
    width: 120px;
  }
`;

const SelectedItem = styled.div`
  margin: 7px 3px 6px 8px;
  min-width: 50px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color: #a1a1a1;
`;

const CloseSign = styled.div`
  width: 7px;
  height: 7px;
  color: #00aeef;
  margin-right: 7px;
  opacity: 1;
  &:hover {
    cursor: pointer;
  }
`;

const Header = styled.h1`
  font-size: 36px;
  color: #0c2945;
  font-weight: 700;
  font-family: Roboto Condensed;
  margin-bottom: 0px;
`;

const TableHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLinkRedirect = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  color: #00aeef;
  text-decoration:underline;
`;



const DoctorList = ({ history }) => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);

  const doctors = useSelector((state) => state.radAdmin.doctors.data);
  const doctorsCount = useSelector(
    (state) => state.radAdmin.doctors.totalItems
  );

  const totalItems = useSelector(
    (state) => state.radAdmin.doctors.hasMoreItems
  );
  const isLoadingDoctors = useSelector(
    (state) => state.radAdmin.doctors.isLoading
  );
  const radiologist = useSelector((state) => state.radAdmin.radiologist.data);
  const isLoadingRadiologist = useSelector(
    (state) => state.radAdmin.radiologist.isLoading
  );
  const radiologistCount = useSelector(
    (state) => state.radAdmin.radiologist.totalItems
  );

  const totalItemsRad = useSelector(
    (state) => state.radAdmin.radiologist.hasMoreItems
  );

  const topDocs = doctors && doctors.slice(0, 3);
  const topRad = radiologist && radiologist.slice(0, 3);
  const itemsPortionSize = 10;
  const dispatch = useDispatch();

  const [currPageNumber, setCurrPageNumber] = useState(1);

  const [filteredSort, setFilteredSort] = useState(null);
  const [isActiveFirst, setIsActiveFirst] = useState(
    history.location.pathname.split("/")[3] === "referringDocs"
  );
  const [displayItemsCount, setDisplayItemsCount] = useState(itemsPortionSize);
  const [displayItemsCountRad, setDisplayItemsCountRad] =
    useState(itemsPortionSize);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);

  const getFilters = () => {
    let faxSentFilter =
      queryParams
        .getAll("fax_sent")
        ?.map((itm) => ({ value: itm, label: itm, category: "fax_sent" })) ||
      [];
    return {
      fax_sent: faxSentFilter,
    };
  };

  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(getFilters());
  const getSearchValue = () => {
    let initialSearchValue = "";
    if (queryParams.has("search")) {
      initialSearchValue = queryParams.get("search");
    }
    return initialSearchValue;
  };
  const [searchValue, setSearchValue] = useState(getSearchValue());
  const [showPlaceholder, setShowPlaceholder] = useState(
    searchValue ? false : true
  );
  const [showPlaceholderRad, setShowPlaceholderRad] = useState(
    searchValue ? false : true
  );
  const [exporting, setExporting] = useState(false);
  const inputRef = useRef(null);
  const formik = useFormik({
    initialValues: {
      searchValue: searchValue || "",
      searchValueRad: searchValue || "",
    },
  });

  const handleSearchSubmit = (e) =>{
    e.preventDefault();
    const searchTerm = isActiveFirst ? formik.values.searchValue : formik.values.searchValueRad;
    window.analytics.track(isActiveFirst ? "Doc-List-Search-Submit" : "Radiologist-List-Search-Submit", {
      search: searchTerm,
    });
    setCurrPageNumber(1);
    setSearchValue(searchTerm);
  }

  const getInitialSelectedValues = () => {
    let initialDate = "";
    let initialSelectedOption = "";
    let initialSelectedConfiguration = "";
    let initialRangeStartDate = "";
    let initialRangeEndDate = "";
    let initialRangeDatesFilter = { startDate: "", endDate: "" };
    let initialExamStartDate = "";
    let initialExamEndDate = "";
    let initialExamDatesFilter = { startDate: "", endDate: "" };

    if (queryParams.has("rangeStartGte") && queryParams.has("rangeStartLte")) {
      initialSelectedOption = "range start";
      initialSelectedConfiguration = "gte and lte";
      initialRangeStartDate = new Date(queryParams.get("rangeStartGte"));
      initialRangeEndDate = new Date(queryParams.get("rangeStartLte"));
      initialRangeDatesFilter = {
        startDate: new Date(queryParams.get("rangeStartGte")),
        endDate: new Date(queryParams.get("rangeStartLte")),
      };
    } else if (queryParams.has("rangeStartGte")) {
      initialSelectedOption = "range start";
      initialSelectedConfiguration = "gte";
      initialDate = new Date(queryParams.get("rangeStartGte"));
      initialRangeStartDate = new Date(queryParams.get("rangeStartGte"));
    } else if (queryParams.has("rangeStartLte")) {
      initialSelectedOption = "range start";
      initialSelectedConfiguration = "lte";
      initialDate = new Date(queryParams.get("rangeStartLte"));
      initialRangeEndDate = new Date(queryParams.get("rangeStartLte"));
    }

    if (queryParams.has("rangeEndGte") && queryParams.has("rangeEndLte")) {
      initialSelectedOption = "range end";
      initialSelectedConfiguration = "gte and lte";
      initialRangeStartDate = new Date(queryParams.get("rangeEndGte"));
      initialRangeEndDate = new Date(queryParams.get("rangeEndLte"));
      initialRangeDatesFilter = {
        startDate: new Date(queryParams.get("rangeEndGte")),
        endDate: new Date(queryParams.get("rangeEndLte")),
      };
    } else if (queryParams.has("rangeEndGte")) {
      initialSelectedOption = "range end";
      initialSelectedConfiguration = "gte";
      initialDate = new Date(queryParams.get("rangeEndGte"));
      initialRangeStartDate = new Date(queryParams.get("rangeEndGte"));
    } else if (queryParams.has("rangeEndLte")) {
      initialSelectedOption = "range end";
      initialSelectedConfiguration = "lte";
      initialDate = new Date(queryParams.get("rangeEndLte"));
      initialRangeEndDate = new Date(queryParams.get("rangeEndLte"));
    }

    if (queryParams.has("examStart") && queryParams.has("examEnd")) {
      initialExamStartDate = new Date(queryParams.get("examStart"));
      initialExamEndDate = new Date(queryParams.get("examEnd"));
      initialExamDatesFilter = {
        startDate: new Date(queryParams.get("examStart")),
        endDate: new Date(queryParams.get("examEnd")),
      };
    }

    return {
      initialExamStartDate,
      initialExamEndDate,
      initialExamDatesFilter,
      date: initialDate,
      selectedConfiguration: initialSelectedConfiguration,
      selectedOption: initialSelectedOption,
      initialRangeStartDate,
      initialRangeEndDate,
      initialRangeDatesFilter,
      selectedSubOptionExamDate: "",
      selectedSubOptionReco: "",
      recoRangeDateView: true,
      examDateView: true,
      triggerUpdate: true,
    };
  };

  const [selectedValues, setSelectedValues] = useState(
    getInitialSelectedValues()
  );

  useEffect(() => {
    const fetchData = () => {
      const optionKey = camelCase(selectedValues.selectedOption);
      const formattedDate = selectedValues.date
        ? moment(selectedValues.date).format("YYYY-MM-DD")
        : "";

      const updatedParams = {};
      const selectedFilterValues = Object.values(selectedFilters);

      updatedParams.search = searchValue;

      if (selectedFilterValues.length > 0) {
        updatedParams.fax_sent = "";
        selectedFilterValues.forEach((item) => {
          if (item) {
            let newItem = item?.forEach((child) => {
              updatedParams[child.category] = child.value;
            });
          }
        });
      }

      if (selectedValues.selectedConfiguration === "gte") {
        updatedParams[`${optionKey}Gte`] = formattedDate;
        updatedParams[`${optionKey}Lte`] = "";
        updatedParams.rangeStartLte = "";
        updatedParams.rangeEndLte = "";
      } else if (selectedValues.selectedConfiguration === "lte") {
        updatedParams[`${optionKey}Lte`] = formattedDate;
        updatedParams[`${optionKey}Gte`] = "";
        updatedParams.rangeStartGte = "";
        updatedParams.rangeEndGte = "";
      } else if (selectedValues.selectedConfiguration === "gte and lte") {
        if (
          selectedValues.initialRangeDatesFilter.startDate &&
          selectedValues.initialRangeDatesFilter.endDate
        ) {
          updatedParams[`${optionKey}Gte`] = moment(
            selectedValues.initialRangeDatesFilter.startDate
          ).format("YYYY-MM-DD");
          updatedParams[`${optionKey}Lte`] = moment(
            selectedValues.initialRangeDatesFilter.endDate
          ).format("YYYY-MM-DD");
        }
      }

      if (
        selectedValues.initialExamDatesFilter?.startDate &&
        selectedValues.initialExamDatesFilter?.endDate
      ) {
        updatedParams.examStart = moment(
          selectedValues.initialExamDatesFilter.startDate
        ).format("YYYY-MM-DD");
        updatedParams.examEnd = moment(
          selectedValues.initialExamDatesFilter.endDate
        ).format("YYYY-MM-DD");
      } else {
        updatedParams.examStart = "";
        updatedParams.examEnd = "";
      }

      if (!selectedValues.selectedConfiguration) {
        updatedParams[`${optionKey}Gte`] = "";
        updatedParams[`${optionKey}Lte`] = "";
        updatedParams.rangeStartGte = "";
        updatedParams.rangeStartLte = "";
        updatedParams.rangeEndGte = "";
        updatedParams.rangeEndLte = "";
      }

      updateURLParams(updatedParams);

      const actionPayload = [
        currPageNumber,
        filteredSort,
        searchValue,
        selectedValues?.selectedOption,
        selectedValues?.selectedConfiguration,
        selectedValues?.date,
        selectedValues?.initialRangeDatesFilter,
        selectedValues?.initialExamDatesFilter,
        selectedFilters,
      ];

      if (isActiveFirst) {
        dispatch(getDoctorsListAction(...actionPayload));
      } else {
        dispatch(getRadiologistList(...actionPayload));
      }
    };

    if (selectedValues.triggerUpdate) {
      fetchData();
    }
  }, [
    isActiveFirst,
    currPageNumber,
    selectedValues?.selectedOption,
    selectedValues?.selectedConfiguration,
    selectedValues?.date,
    selectedValues?.initialRangeDatesFilter,
    selectedValues?.initialExamDatesFilter,
    selectedValues.triggerUpdate,
    selectedFilters,
    searchValue,
  ]);

  const updateURLParams = (paramsToUpdate) => {
    const params = new URLSearchParams(location.search);

    Object.keys(paramsToUpdate).forEach((key) => {
      if (paramsToUpdate[key]) {
        params.set(key, paramsToUpdate[key]);
      } else {
        params.delete(key);
      }
    });

    history.replace({ search: params.toString() });
  };

  const resetRangeDateFilter = () => {
    setSelectedValues((prev) => ({
      ...prev,
      date: "",
      initialRangeStartDate: "",
      initialRangeEndDate: "",
      initialRangeDatesFilter: {
        startDate: "",
        endDate: "",
      },
    }));
    setCurrPageNumber(1);
  };

  const resetExamDateFilter = () => {
    setSelectedValues((prev) => ({
      ...prev,
      initialExamStartDate: "",
      initialExamEndDate: "",
      initialExamDatesFilter: {
        startDate: "",
        endDate: "",
      },
    }));
    setCurrPageNumber(1);
  };

  const columns = [
    {
      key: "name",
      name: <ColumnName title="Referring Doctor Name" />,
      displayCell: (cellValue, row, idx) => (
        <UserDataBlock name={cellValue} num={idx + 1} row={row} />
      ),
    },
    {
      key: "referredPatients",
      name: <ColumnName title="Referred Patients" />,
      displayCell: (cellValue, row) => (
        <ItemWrapper>
          <IconContainer>
            <RefferedPatientsIcon />
          </IconContainer>
          <StyledLinkRedirect
            to={`/auth/radAdmin/referringDocs/${row.id}/patients`}
          >
            {cellValue}
          </StyledLinkRedirect>
        </ItemWrapper>
      ),
    },
    {
      key: "imagingRecos",
      name: <ColumnName title="Reports With Imaging Follow-Ups" />,
      displayCell: (cellValue, row) => (
        <ItemWrapper>
          <IconContainer>
            <ImagingFollowUpsIcon />
          </IconContainer>
          <StyledLinkRedirect
          to={ `/auth/radAdmin/followUpList?status=Overdue&referrers=${row.id}`}
          >
            {cellValue}
          </StyledLinkRedirect>
        </ItemWrapper>
      ),
    },
    {
      key: "adherentPatients",
      name: <ColumnName title="Adherent Recos" />,
      displayCell: (cellValue, row) => (
        <RecoBadge
          patientsCount={row.referredPatients}
          overduePatientsCount={cellValue}
          bcolor={"#64C67C"}
          redirectLink={`/auth/radAdmin/followUpList?status=Adherent&referrers=${row.id}`}
          history={history}
        />
      ),
    },
    {
      key: "comingDuePatients",
      name: <ColumnName title="Coming Due Recos" />,
      displayCell: (cellValue, row) => (
        <RecoBadge
          patientsCount={row.referredPatients}
          overduePatientsCount={cellValue}
          bcolor={"#FEC400"}
          redirectLink={`/auth/radAdmin/followUpList?status=Coming Due&referrers=${row.id}`}
          history={history}
        />
      ),
    },
    {
      key: "overduePatients",
      name: <ColumnName title="Overdue Recos" />,
      displayCell: (cellValue, row) => (
        <RecoBadge
          patientsCount={row.referredPatients}
          overduePatientsCount={cellValue}
          bcolor={"#F12B2C"}
          redirectLink={`/auth/radAdmin/followUpList?status=Overdue&referrers=${row.id}`}
          history={history}
        />
      ),
    },
  ];
  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const exportPermission = rolePermissions
    ?.find((itm) => itm.name === "Export")
    ?.permissions.find((itm) => itm.codename === "add_export")?.is_authorized;

  useEffect(() => {
    setIsLoading(false);
  }, [doctors, radiologist]);

  const onShowPlaceholder = () => {
    if (!inputRef.current.value) {
      setShowPlaceholder(true);
    }
  };

  const onHidePlaceholder = () => {
    if (isActiveFirst) {
      setShowPlaceholder(false);
      return;
    }
    setShowPlaceholderRad(false);
  };

  const onResetForm = () => {
    if (isActiveFirst) {
      formik.setFieldValue("searchValue", "");
      dispatch({ type: SET_DOCTOR_SEARCH_VALUE, payload: "" });

      setShowPlaceholder(true);
      setSearchValue();
      setCurrPageNumber(1);

    } else {
      formik.setFieldValue("searchValueRad", "");
      dispatch({ type: SET_RADIOLOGIST_SEARCH_VALUE, payload: "" });

      setShowPlaceholderRad(true);
      setSearchValue();
      setCurrPageNumber(1);

    }
  };

  const onLoadMore = () => {
    setIsLoading(true);
    setCurrPageNumber((prev) => prev + 1);

    if (isActiveFirst) {
      setDisplayItemsCount((prevCount) => prevCount + itemsPortionSize);
    } else {
      setDisplayItemsCountRad((prevCount) => prevCount + itemsPortionSize);
    }
  };

  const goToPatientList = (item) => {
    dispatch(setDoctorForPatientsAction(item));
    history.push(`/auth/radAdmin/referringDocs/${item.id}/patients`);
    window.analytics.track("Patient-List-Navigation-Click");
  };

  const renderButtonsColumn = (row) => {
    return (
      <StyledLink onClick={() => goToPatientList(row)}>
        <StyledChevron />
      </StyledLink>
    );
  };

  const checkboxHandler = (data) => {
    if (selectedIds.includes(data.id)) {
      const filteredItems = selectedIds.filter((item) => {
        return item !== data.id;
      });
      setSelectedIds([...filteredItems]);
    }
    if (!selectedIds.includes(data.id)) {
      selectedIds.push(data.id);
      setSelectedIds([...selectedIds]);
    }
  };

  const onExportPhysician = () => {
    if ((permission && exportPermission) || !permission) {
      setExporting(true);
      dispatch(
        getExportReportAction(null, null, { selectedPhysicians: selectedIds })
      ).then((res) => {
        setSelectedIds([]);
        setExporting(false);
      });
    } else {
      dispatch(
        showAlert(
          "danger",
          "Error",
          `You don't have permission to export physicians`
        )
      );
    }
  };

  const onExportRadiologist = () => {
    if ((permission && exportPermission) || !permission) {
      setExporting(true);
      dispatch(
        getExportReportAction(null, null, { radiologist: selectedIds })
      ).then((res) => {
        setSelectedIds([]);
        setExporting(false);
      });
    } else {
      dispatch(
        showAlert(
          "danger",
          "Error",
          `You don't have permission to export radiologists`
        )
      );
    }
  };

  const closeSelected = (category, selectedItem) => {
    const filters = selectedFilters[category].filter((item) => {
      return item.value !== selectedItem.value;
    });
    let newFilters = {
      ...selectedFilters,
      [category]: filters.length > 0 ? filters : [],
    };
    setSelectedFilters(newFilters);
    setCurrPageNumber(1);
  };

  const getChips = () => {
    return Object.values(selectedFilters).map((item, index) => {
      if (!item || !Array.isArray(item)) return null;
      return item.map((i, idx) => (
        <SelectedItemContainer key={index + idx}>
          <SelectedItem>
            {" "}
            {i?.category
              ?.replace(/_/g, " ")
              ?.replace(/-/g, " ")
              ?.replace(/\b\w/g, function (char) {
                return char.toUpperCase();
              })}{" "}
            :{" "}
            {i?.label?.replace(/\b\w/g, function (char) {
              return char.toUpperCase();
            })}
          </SelectedItem>
          <CloseSign onClick={() => closeSelected(i.category, i)}>
            <Image src={close} alt={"close sign"} />
          </CloseSign>
        </SelectedItemContainer>
      ));
    });
  };

  function hasNonEmptyArray(items) {
    return items.some(
      (subArray) => Array.isArray(subArray) && subArray.length > 0
    );
  }


  return (
    <Box p={30}>
      <HeaderContainer>
        <div style={{ display: "flex", gap: "20px" }}>
          <SwitchContainer>
            <SwitchButton
              active={isActiveFirst}
              onClick={() => {
                if(!isActiveFirst){
                  resetExamDateFilter();
                  resetRangeDateFilter();
                  setSelectedValues((prev) => ({
                    initialExamStartDate: "",
                    initialExamEndDate: "",
                    initialExamDatesFilter: "",
                    date: "",
                    selectedConfiguration: "",
                    selectedOption: "",
                    initialRangeStartDate: "",
                    initialRangeEndDate: "",
                    initialRangeDatesFilter: "",
                    selectedSubOptionExamDate: "",
                    selectedSubOptionReco: "",
                    recoRangeDateView: true,
                    examDateView: true,
                    triggerUpdate: true,
                  }));
                  onResetForm();
                  setCurrPageNumber(1);
                  history.push(`/auth/radAdmin/referringDocs/`);
  
                  setIsActiveFirst(true);
                  window.analytics.track("Referring-Doctors-Button-Click");
                }
              
              }}
            >
              Referring Doctors
            </SwitchButton>
            <SwitchButton
              active={!isActiveFirst}
              onClick={() => {
                if(isActiveFirst){
                  resetExamDateFilter();
                  resetRangeDateFilter();
                  setSelectedValues((prev) => ({
                    initialExamStartDate: "",
                    initialExamEndDate: "",
                    initialExamDatesFilter: "",
                    date: "",
                    selectedConfiguration: "",
                    selectedOption: "",
                    initialRangeStartDate: "",
                    initialRangeEndDate: "",
                    initialRangeDatesFilter: "",
                    selectedSubOptionExamDate: "",
                    selectedSubOptionReco: "",
                    recoRangeDateView: true,
                    examDateView: true,
                    triggerUpdate: true,
                  }));
                  setCurrPageNumber(1);
                  setSelectedFilters({});
                  onResetForm();
  
                  history.push(`/auth/radAdmin/radiologistLeaderboard/`);
                  setIsActiveFirst(false);
                  window.analytics.track("Radiologist-Leaderboard-Button-Click");
                
              }}
              }
            >
              Radiologist Leaderboard
            </SwitchButton>
          </SwitchContainer>

          <ExamDateSelector
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            setCurrPageNumber={setCurrPageNumber}
          />
          <RecoRangeSelector
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            setCurrPageNumber={setCurrPageNumber}
          />
          {isActiveFirst && (
            <FilterButton onClick={() => setShowFilters(true)}>
              <Image src={filterOutline} alt={"settings icon"} />
            </FilterButton>
          )}
        </div>

        {showFilters && (
          <Filters
            showModal={showFilters}
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilters}
            onClose={() => setShowFilters(false)}
            handleSubmit={() => {}}
            setCurrPageNumber={setCurrPageNumber}
          />
        )}
        {isActiveFirst ? (
          <FormContainer>
            <form onSubmit={handleSearchSubmit}>
              <InputContainer>
                {showPlaceholder && (
                  <>
                    <SearchIcon>
                      <Search />
                    </SearchIcon>
                    <CustomPlaceholder onClick={onHidePlaceholder}>
                      {"Search by Name, Unique ID, Email Address"}
                    </CustomPlaceholder>
                  </>
                )}
                <Input
                  type="text"
                  id={"searchValue"}
                  name={"searchValue"}
                  onFocus={onHidePlaceholder}
                  onBlur={onShowPlaceholder}
                  onChange={formik.handleChange}
                  value={formik.values.searchValue}
                  ref={inputRef}
                />
                {!showPlaceholder && formik.values.searchValue && (
                  <ClearButton type={"reset"} onClick={onResetForm}>
                    X
                  </ClearButton>
                )}
              </InputContainer>
            </form>
          </FormContainer>
        ) : (
          <FormContainer>
            <form onSubmit={handleSearchSubmit}>
              <InputContainer>
                {showPlaceholderRad && (
                  <>
                    <SearchIcon>
                      <Search />
                    </SearchIcon>
                    <CustomPlaceholder onClick={onHidePlaceholder}>
                      {isActiveFirst
                        ? "Search by Name, Unique ID, Email Address"
                        : "Search by Name"}
                    </CustomPlaceholder>
                  </>
                )}
                <Input
                  type="text"
                  id={"searchValueRad"}
                  name={"searchValueRad"}
                  onFocus={onHidePlaceholder}
                  onBlur={onShowPlaceholder}
                  onChange={formik.handleChange}
                  value={formik.values.searchValueRad}
                  ref={inputRef}
                />
                {!showPlaceholderRad && formik.values.searchValueRad && (
                  <ClearButton type={"reset"} onClick={onResetForm}>
                    X
                  </ClearButton>
                )}
              </InputContainer>
            </form>
          </FormContainer>
        )}
      </HeaderContainer>
      {isActiveFirst && hasNonEmptyArray(getChips()) && (
        <Row
          style={{
            justifyContent: "flex-end",
            marginBottom: "20px",
            gap: "6px",
            flexWrap: "wrap",
            marginLeft: 0,
            marginRight: 0,
          }}
        >
          {getChips()}
        </Row>
      )}
      <Row>
        {topDocs && isActiveFirst
          ? topDocs.map((item) => (
              <Col sm={12} lg={12} xl={4} key={JSON.stringify(item)}>
                <TopDoctorCard info={item} goToPatientList={goToPatientList} />
              </Col>
            ))
          : topRad &&
            !isActiveFirst &&
            topRad.map((item, index) => (
              <Col sm={12} lg={12} xl={4} key={index}>
                <TopLeaderBoardCard
                  info={item}
                  goToPatientList={goToPatientList}
                  position={index === 0 ? "1st" : index === 1 ? "2nd" : "3rd"}
                />
              </Col>
            ))}
      </Row>
      {isActiveFirst && !isLoadingDoctors ? (
        <TableHeaderRow>
          <Header>Referrers: {doctorsCount}</Header>
          <ExportContainer>
            <ExportButton
              disabled={exporting}
              type={"button"}
              onClick={onExportPhysician}
            >
              {exporting ? "Exporting..." : "Export Selected Physicians"}
            </ExportButton>
          </ExportContainer>
        </TableHeaderRow>
      ) : !isActiveFirst && !isLoadingRadiologist ? (
        <TableHeaderRow>
          <Header>Radiologists: {radiologistCount}</Header>
          <ExportContainer>
            <ExportButton
              disabled={exporting}
              type={"button"}
              onClick={onExportRadiologist}
            >
              {exporting ? "Exporting..." : "Export Selected Radiologist"}
            </ExportButton>
          </ExportContainer>
        </TableHeaderRow>
      ) : null}
      <Card>
        <Table
          rows={
            !isActiveFirst
              ? radiologist && radiologist.slice(0, displayItemsCountRad)
              : doctors && doctors.slice(0, displayItemsCount)
          }
          isActiveFirst={isActiveFirst}
          columns={
            isActiveFirst
              ? [
                  {
                    key: "",
                    name: "",
                    displayCell: (cellValue, rowData) => (
                      <DoubleDataCell
                        mainInfo={"checkbox"}
                        checkboxHandler={() => checkboxHandler(rowData)}
                        checked={selectedIds?.includes(rowData?.id)}
                      />
                    ),
                  },
                ].concat(columns)
              : [
                  {
                    key: "",
                    name: "",
                    displayCell: (cellValue, rowData) => (
                      <DoubleDataCell
                        mainInfo={"checkbox"}
                        checkboxHandler={() => checkboxHandler(rowData)}
                        checked={selectedIds?.includes(rowData?.id)}
                      />
                    ),
                  },
                ].concat(radiologistColumns)
          }
          initialPageNumber={null}
          isLoadingRows={
            isActiveFirst ? isLoadingDoctors : isLoadingRadiologist
          }
          shouldGetRowActions={false}
          renderButtonsColumn={renderButtonsColumn}
          checkboxHandler={checkboxHandler}
          noDataRender={() => (
            <EmptyState
              title={"No Results"}
              img={() => <EmptyStateImg />}
              buttonInfo={{
                link: "/auth/radAdmin/Import",
                text: "Import Data",
              }}
            >
              Sorry, there is no data yet <br /> Please import a csv to get
              started
            </EmptyState>
          )}
        />

        {((doctors && doctors.length !== 0) ||
          (radiologist && radiologist.length !== 0)) &&
          !(isLoadingDoctors && currPageNumber === 1) && (
            <LoadMoreButton
              title="Load More"
              handleClick={onLoadMore}
              isLoading={
                isActiveFirst
                  ? isLoadingDoctors || isLoading
                  : isLoadingRadiologist || isLoading
              }
              isDisabled={
                isActiveFirst
                  ? isLoadingDoctors || !totalItems
                  : isLoadingRadiologist || !totalItemsRad
              }
            />
          )}
      </Card>
    </Box>
  );
};

export default DoctorList;

export const DocListWithFeature = (props) => {
  return (
    <EnableFeature featureName={"ref-docs-list-view"} {...props}>
      <DoctorList />
    </EnableFeature>
  );
};
