import React, { useMemo, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ChevronRightIcon } from '../../../../assets/chevron_right.svg';
import Flex from '../../../../components/_styles/Flex';
import DoubleDataCell from '../LastScanCell';
import HistoryTimeline from './HistoryTimeline';
import { generateColor } from '../../../../utils/utils';

const TableRow = styled.div`
  display: flex;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    left: 19px;
    top: 65px;
    height: 100%;
    width: 2px;
    background: #c2ccd9;
  }
`;

const TableRowItem = styled.div`
  flex: ${props => (props.flex ? props.flex : 1)};
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  cursor: ${props => props.nocursor? "" : "pointer"}
`;

const PatientCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #c2ccd9;
  border-radius: 8px;
  margin: 0 0 10px 0;
  &:hover {
    border: 1px solid #00AEEF;
    transition: all 0.3s ease;
  }
`;

const PatientCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 20px;
  height: 92px;
`;

const PatientCardBody = styled.div`
  display: flex;
  align-items: center;
  border-top: 2px solid #dfe0eb;
  height: 215px;
`;

const StyledChevron = styled(ChevronRightIcon)`
  transform: rotate(${props => props.expanded});
  width: 26px;
  height: 26px;
  cursor: pointer;
  & > path {
    fill: #9FA2B4;
  }
  &:hover > path {
    fill: #3F4857;
    transition: all 0.3s ease;
  }
`;

export const AvatarContainer = styled(Flex)`
  margin: 25px 21px 0 0;
`;

export const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
`;

export const UserEmptyAvatar = styled(Flex)`
  background-color: ${props => props.bgcolor};
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: #666b86;
  border: 2px solid #ffffff;
  border-radius: 50%;
`;

export const UserIcon = ({ name, avatar,bgColor }) => {
  const splitName = name.split(' ');
  const initials = (
    (splitName[0] ? splitName[0].charAt(0) : '') + (splitName[1] ? splitName[1].charAt(0) : '')
  ).toUpperCase();
  return (
    <Flex>
      <AvatarContainer>
        {avatar ? <UserAvatar src={avatar} /> : <UserEmptyAvatar bgcolor={bgColor}>{initials}</UserEmptyAvatar>}
      </AvatarContainer>
    </Flex>
  );
};

const PatientInfoCard = ({ patientItem, physicianData }) => {
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);
  const moveNext = () => {
    history.push(`/auth/radAdmin/followUpList/patients/patientDetails/${patientItem?.id}`)
  }
  const patientName = patientItem.first_name + ' ' + patientItem.last_name
  const patientMrn = patientItem.mrn
  const patientTotalReports = patientItem.total_reports
  const patientSelfReports = patientItem.self_reports
  const patientReports = patientItem.reports
  const latestPatientReport = patientReports.at(-1);
  const bgColor = useMemo(()=>generateColor(),[]);

  return (
    <TableRow>
      <UserIcon name={patientName} bgColor={bgColor} />
      <PatientCard>
        <PatientCardHeader >
          <TableRowItem flex={2} onClick={moveNext}>
            <DoubleDataCell
              textAlign="left"
              mainInfo={patientName}
              additionalInfo={patientMrn}
            />
          </TableRowItem>
          <TableRowItem onClick={moveNext}>
            <DoubleDataCell
              mainInfo={latestPatientReport?.modality || '-'}
              additionalInfo={`on ${moment(latestPatientReport?.initial_exam_date).format('MM.DD.YYYY')}`}
            />
          </TableRowItem>
          <TableRowItem onClick={moveNext}>
            <DoubleDataCell mainInfo={latestPatientReport?.accession_number} />
          </TableRowItem>
          <TableRowItem onClick={moveNext}>
            <DoubleDataCell mainInfo={patientTotalReports} />
          </TableRowItem>
          <TableRowItem onClick={moveNext}>
            <DoubleDataCell mainInfo={patientSelfReports} />
          </TableRowItem>
          <TableRowItem flex={0.5} nocursor>
            <StyledChevron expanded={isExpanded ? '90deg' : '0deg'} onClick={() => setIsExpanded(!isExpanded)} />
          </TableRowItem>
        </PatientCardHeader>
        {isExpanded && (
            <PatientCardBody>
              <HistoryTimeline
                reports={patientReports}
                physician={physicianData}
              />
            </PatientCardBody>
        )}
      </PatientCard>
    </TableRow>
  );
};

export default PatientInfoCard;
