import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Box from '../../../components/_styles/Box';
import { Card, PageTitle } from '../../../styles';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/arrow_left.svg';
import {
  getPatientsListAction,
  resetPatientsDataAction,
} from '../../../actions/radminUploads';
import Flex from '../../../components/_styles/Flex';
import PatientTable from './PatientTable/PatientTable';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import LoadMoreButton from '../../../components/LoadMoreButton';
import { ReactComponent as EmptyStateImg } from '../../../assets/empty-state-search.svg';

const DoctorName = styled.div`
  margin-left: 10px;
`;

const GoPreviousPageContainer = styled(Flex)`
  width: fit-content;
  align-items: center;
  color: #0c2945 !important;
  &:hover {
    cursor: pointer;
  }
`;

const ImportPatientsBtn = styled(Flex)`
  width: 177px;
  background: #ffc342;
  border-radius: 5px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }
`;

const PageTitleContainer = styled(Flex)`
  justify-content: space-between;
`;

const PatientList = ({ history }) => {
  const patients = useSelector(state => state.radAdmin.patients);
  const { doctorId } = useParams();
  const dispatch = useDispatch();
  const itemsPortionSize = 10;
  const [displayItemsCount, setDisplayItemsCount] = useState(itemsPortionSize);
  const [pageNumber, setPageNumber] = useState(1);
  const totalItems = patients.totalItems;


  useEffect(() => {
    if (pageNumber) {
      dispatch(getPatientsListAction(pageNumber, doctorId));
    }
  }, [pageNumber,doctorId]);


  const moveToDoctors = () => {
    history.push('/auth/radAdmin/referringDocs');
    window.analytics.track('Patient-List-Referrer-Back-Click');
  };
  
  const moveToImportPatients = () => {
    history.push('/auth/radAdmin/Import');
    window.analytics.track('Patient-List-Import-Patients-Click');
  };

  const onLoadMore = () => {
    setDisplayItemsCount(prevCount => prevCount + itemsPortionSize);
    setPageNumber(prevNumber => prevNumber + 1);
  };

  return (
    <Box p={30}>
      <PageTitle>
        <PageTitleContainer>
          <GoPreviousPageContainer onClick={moveToDoctors}>
            <ArrowLeftIcon />
            <DoctorName>{patients?.data?.physician?.name}: {totalItems}</DoctorName>
          </GoPreviousPageContainer>
          <ImportPatientsBtn onClick={moveToImportPatients}>Import Patients</ImportPatientsBtn>
        </PageTitleContainer>
      </PageTitle>
      <Card>
        <PatientTable
          patientsData={patients.data?.patients && patients.data.patients.slice(0, displayItemsCount)}
          isLoading={pageNumber === 1 && patients.isLoading}
          physicianData={patients?.data?.physician}
          resetDataAction={resetPatientsDataAction}
          noDataRender={() => (
            <EmptyState title={'No Results'} img={() => <EmptyStateImg />}>
              Sorry, there are no results
            </EmptyState>
          )}
        />
        {patients?.data?.patients && patients?.data?.patients.length !== 0 && (
          <LoadMoreButton 
          title="Load more"
          handleClick={onLoadMore} 
          isDisabled={totalItems < displayItemsCount}    
          isLoading={patients?.isLoading} />
        )}
      </Card>
    </Box>
  );
};

export default PatientList;
