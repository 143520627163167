import {
  SHOW_ALERT,
  HIDE_ALERT,
  RESET_STATE,
  SET_PROGRESS_BAR_VALUE,
  GET_DOCTORS_LIST_REQUEST,
  GET_DOCTORS_LIST_FAILURE,
  GET_DOCTORS_LIST_SUCCESS,
  GET_DOCTORS_LIST_DROPDOWN_SUCCESS,
  GET_DOCTORS_LIST_DROPDOWN_REQUEST,
  GET_DOCTORS_LIST_DROPDOWN_FAILURE,
  GET_PATIENTS_LIST_REQUEST,
  GET_PATIENTS_LIST_FAILURE,
  GET_PATIENTS_LIST_SUCCESS,
  RESET_PATIENTS_DATA,
  GET_DOCTOR_REQUEST,
  GET_DOCTOR_SUCCESS,
  GET_DOCTOR_FAILURE,
  SET_DOCTOR_SEARCH_VALUE,
  SET_DOCTOR_FOR_PATIENTS,
  GET_PREVIOUS_UPLOADS_LIST_REQUEST,
  GET_PREVIOUS_UPLOADS_LIST_SUCCESS,
  GET_PREVIOUS_UPLOADS_LIST_FAILURE,
  GET_IMAGING_ADHERENCE_REQUEST,
  GET_IMAGING_ADHERENCE_SUCCESS,
  GET_IMAGING_ADHERENCE_FAILURE,
  GET_RECOMMENDATION_TIMEFRAME_REQUEST,
  GET_RECOMMENDATION_TIMEFRAME_SUCCESS,
  GET_RECOMMENDATION_TIMEFRAME_FAILURE,
  GET_MODALITIES_REQUEST,
  GET_MODALITIES_SUCCESS,
  GET_MODALITIES_FAILURE,
  GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_REQUEST,
  GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_SUCCESS,
  GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_FAILURE,
  GET_ADHERENCE_FUNNEL_REQUEST,
  GET_ADHERENCE_FUNNEL_SUCCESS,
  GET_ADHERENCE_FUNNEL_FAILURE,
  GET_FOLLOW_UP_LIST_REQUEST,
  GET_FOLLOW_UP_LIST_FAILURE,
  GET_FOLLOW_UP_LIST_SUCCESS,
  UPDATE_FOLLOW_UP_LIST_REQUEST,
  UPDATE_FOLLOW_UP_LIST_SUCCESS,
  DELETE_FOLLOW_UP_LIST_SUCCESS,
  UPDATE_FOLLOW_UP_LIST_FAILURE,
  GET_RECO_HISTORY_REQUEST,
  GET_RECO_HISTORY_FAILURE,
  GET_RECO_HISTORY_SUCCESS,
  GET_EXPORT_REPORT_REQUEST,
  GET_EXPORT_REPORT_FAILURE,
  GET_EXPORT_REPORT_SUCCESS,
  GET_RECO_CONFIG_REQUEST,
  GET_RECO_CONFIG_SUCCESS,
  GET_RECO_CONFIG_FAILURE,
  UPDATE_RECO_CONFIG_REQUEST,
  UPDATE_RECO_CONFIG_SUCCESS,
  UPDATE_RECO_CONFIG_FAILURE,
  GET_RECENT_INCOMING_MESSAGES_REQUEST,
  GET_RECENT_INCOMING_MESSAGES_FAILURE,
  GET_RECENT_INCOMING_MESSAGES_SUCCESS,
  GET_RECENT_INCOMING_MESSAGES_UPDATE_SUCCESS,
  SET_USERS_MESSAGES_INFO,
  GET_CHAT_MESSAGES_REQUEST,
  GET_CHAT_MESSAGES_FAILURE,
  GET_CHAT_MESSAGES_SUCCESS,
  GET_MORE_CHAT_MESSAGES_REQUEST,
  GET_MORE_CHAT_MESSAGES_FAILURE,
  GET_MORE_CHAT_MESSAGES_SUCCESS,
  CREATE_OUTGOING_MESSAGE_REQUEST,
  CREATE_OUTGOING_MESSAGE_FAILURE,
  CREATE_OUTGOING_MESSAGE_SUCCESS,
  GET_TWILIO_CONFIG_REQUEST,
  GET_TWILIO_CONFIG_FAILURE,
  GET_TWILIO_CONFIG_SUCCESS,
  GET_FINDINGS_BOI_REQUEST,
  GET_FINDINGS_BOI_FAILURE,
  GET_FINDINGS_BOI_SUCCESS,
  UPDATE_TWILIO_CONFIG_REQUEST,
  UPDATE_TWILIO_CONFIG_FAILURE,
  UPDATE_TWILIO_CONFIG_SUCCESS,
  GET_SMS_RESPONSE_REQUEST,
  GET_SMS_RESPONSE_FAILURE,
  GET_SMS_RESPONSE_SUCCESS,
  UPDATE_SMS_RESPONSE_REQUEST,
  UPDATE_SMS_RESPONSE_FAILURE,
  UPDATE_SMS_RESPONSE_SUCCESS,
  SET_USERS_PHONE_NUMBERS,
  GET_RADIOLOGIST_LIST_REQUEST,
  GET_RADIOLOGIST_LIST_FAILURE,
  GET_RADIOLOGIST_LIST_SUCCESS,
  CHANGE_ACTION_STATUS_REQUEST,
  CHANGE_ACTION_STATUS_FAILURE,
  CHANGE_ACTION_STATUS_SUCCESS,
  GET_TOTAL_STATUSES_REQUEST,
  GET_TOTAL_STATUSES_FAILURE,
  GET_TOTAL_STATUSES_SUCCESS,
  GET_RECO_MODALITIES_REQUEST,
  FETCH_CAMPAIGN_STEPS,
  DELETE_CAMPAIGN_STEP,
  CREATE_CAMPAIGN_STEP,
  EDIT_CAMPAIGN_STEP,
  GET_RECO_MODALITIES_FAILURE,
  GET_RECO_MODALITIES_SUCCESS,
  GET_RECO_ANATOMIES_REQUEST,
  GET_RECO_ANATOMIES_FAILURE,
  GET_RECO_ANATOMIES_SUCCESS,
  GET_RECO_ANATOMIES_AND_MODALITIES_REQUEST,
  GET_RECO_ANATOMIES_AND_MODALITIES_FAILURE,
  GET_RECO_ANATOMIES_AND_MODALITIES_SUCCESS,
  GET_PATIENT_HISTORY_REQUEST,
  GET_PATIENT_HISTORY_FAILURE,
  GET_PATIENT_HISTORY_SUCCESS,
  GET_MODALITY_LIST_REQUEST,
  GET_MODALITY_LIST_FAILURE,
  GET_MODALITY_LIST_SUCCESS,
  GET_ANATOMY_LIST_REQUEST,
  GET_ANATOMY_LIST_FAILURE,
  GET_ANATOMY_LIST_SUCCESS,
  GET_TIMEFRAME_STATUS_LIST_REQUEST,
  GET_TIMEFRAME_STATUS_LIST_FAILURE,
  GET_TIMEFRAME_STATUS_LIST_SUCCESS,
  GET_PATIENT_REPORTS_REQUEST,
  GET_PATIENT_REPORTS_FAILURE,
  GET_PATIENT_REPORTS_SUCCESS,
  GET_RADIOLOGIST_LEADERBOARD_SUCCESS,
  GET_RADIOLOGIST_LEADERBOARD_REQUEST,
  GET_RADIOLOGIST_LEADERBOARD_REQUEST_FAILURE,
  SET_RADIOLOGIST_SEARCH_VALUE,
  GET_NOTES_REQUEST,
  GET_NOTES_SUCCESS,
  GET_NOTES_ANALYTICS_REQUEST,
  GET_NOTES_ANALYTICS_SUCCESS,
  GET_NOTES_ANALYTICS_FAILURE,
  GET_NOTES_FAILURE,
  CREATE_NOTE_REQUEST,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAILURE,
  UPDATE_NOTE_REQUEST,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAILURE,
  GET_TEMPLATES_REQUEST,
  SET_TEMPLATE_ID_TO_PREVIEW,
  SET_TEMPLATE_TO_PREVIEW,
  SET_FAX_TEMPLATE_ID_TO_PREVIEW,
  SET_FAX_TEMPLATE_TO_PREVIEW,
  GET_TEMPLATES_SUCCESS,
  // GET_TEMPLATES_FAILURE,
  GET_FAX_TEMPLATES_REQUEST,
  GET_FAX_TEMPLATES_SUCCESS,
  GET_LETTER_TEMPLATES_REQUEST,
  GET_LETTER_TEMPLATES_SUCCESS,
  GET_LETTER_TEMPLATES_FAILURE,
  CREATE_OUTGOING_MESSAGE_REQUEST_FROM_DIALOG,
  CREATE_OUTGOING_MESSAGE_SUCCESS_FROM_DIALOG,
  CREATE_OUTGOING_MESSAGE_FAILURE_FROM_DIALOG,
  GET_OUTGOING_MESSAGES_REQUEST,
  GET_OUTGOING_MESSAGES_SUCCESS,
  GET_OUTGOING_MESSAGES_FAILURE,
  GET_OUTGOING_EMAIL_REQUEST,
  GET_OUTGOING_EMAIL_SUCCESS,
  GET_OUTGOING_EMAIL_FAILURE,
  
  GET_OUTGOING_FAXES_REQUEST,
  GET_OUTGOING_FAXES_SUCCESS,
  GET_OUTGOING_FAXES_FAILURE,
  GET_SEARCH_REPORT_TEXT_REQUEST,
  GET_SEARCH_REPORT_TEXT_SUCCESS,
  GET_SEARCH_REPORT_TEXT_FAILURE,

  GET_ADHERENCE_OF_TODAY_REQUEST,
  GET_ADHERENCE_OF_TODAY_SUCCESS,
  GET_ADHERENCE_OF_TODAY_FAILURE,
  GET_ADHERENCE_OF_DATE_REQUEST,
  GET_ADHERENCE_OF_DATE_SUCCESS,
  GET_ADHERENCE_OF_DATE_FAILURE,
  GET_ADHERENCE_OVER_RATE_REQUEST,
  GET_ADHERENCE_OVER_RATE_SUCCESS,
  GET_ADHERENCE_OVER_RATE_FAILURE,
  GET_REVENUE_CHARGES_REQUEST,
  GET_REVENUE_CHARGES_SUCCESS,
  GET_REVENUE_CHARGES_FAILURE,
  GET_TOTAL_REVENUE_CHARGES_AVERAGE_SUCCESS,
  GET_TOTAL_REVENUE_CHARGES_REQUEST,
  GET_TOTAL_REVENUE_CHARGES_SUCCESS,
  GET_TOTAL_REVENUE_CHARGES_FAILURE,
  GET_ATTRIBUTE_REVENUE_CHARGES_REQUEST,
  GET_ATTRIBUTE_REVENUE_CHARGES_SUCCESS,
  GET_ATTRIBUTE_REVENUE_CHARGES_FAILURE,
  GET_FOLLOWUP_LIST_FROM_MESSAGES_REQUEST,
  GET_FOLLOWUP_LIST_FROM_MESSAGES_SUCCESS,
  GET_COHORTS_REQUEST,
  GET_COHORTS_SUCCESS,
  GET_COHORTS_FAILURE,
  GET_RECO_COHORTS_REQUEST,
  GET_RECO_COHORTS_SUCCESS,
  GET_RECO_COHORTS_FAILURE,
  GET_COHORTS_RECOMMENDATIONS_REQUEST,
  GET_COHORTS_RECOMMENDATIONS_SUCCESS,
  GET_COHORTS_RECOMMENDATIONS_FAILURE,
  GET_RECOMMENDATIONS_IN_COHORT_REQUEST,
  GET_RECOMMENDATIONS_IN_COHORT_SUCCESS,
  GET_RECOMMENDATIONS_IN_COHORT_FAILURE,
  GET_REJECTED_RECOMMENDATIONS_REQUEST,
  GET_REJECTED_RECOMMENDATIONS_SUCCESS,
  GET_REJECTED_RECOMMENDATIONS_FAILURE,
  GET_TASKS_IN_COHORT_REQUEST,
  GET_TASKS_IN_COHORT_SUCCESS,
  GET_TASKS_IN_COHORT_FAILURE,
  GET_COHORT_WITH_ID_REQUEST,
  GET_COHORT_WITH_ID_SUCCESS,
  GET_COHORT_WITH_ID_FAILURE,
  GET_RECO_COHORT_WITH_ID_REQUEST,
  GET_RECO_COHORT_WITH_ID_SUCCESS,
  GET_RECO_COHORT_WITH_ID_FAILURE,
  RESET_RECO_COHORT_WITH_ID_REQUEST,
  CREATE_COHORT_SUCCESS,
  GET_DIMENTION_REQUEST,
  GET_DIMENTION_SUCCESS,
  GET_DIMENTION_FAILURE,
  GET_ALL_DIMENTIONS_REQUEST,
  GET_ALL_DIMENTIONS_SUCCESS,
  GET_ALL_DIMENTIONS_FAILURE,
  GET_PATIENT_IN_COHORT_REQUEST,
  GET_PATIENT_IN_COHORT_SUCCESS,
  GET_PATIENT_IN_COHORT_FAILURE,
  GET_DIMENSIONS_TO_EDIT_REQUEST,
  GET_DIMENSIONS_TO_EDIT_SUCCESS,
  GET_DIMENSIONS_TO_EDIT_FAILURE,
  GET_COHORT_FIXED_VALUES_REQUEST,
  GET_COHORT_FIXED_VALUES_SUCCESS,
  GET_COHORT_FIXED_VALUES_FAILURE,
  GET_RECO_COHORT_FIXED_VALUES_REQUEST,
  GET_RECO_COHORT_FIXED_VALUES_SUCCESS,
  GET_RECO_COHORT_FIXED_VALUES_FAILURE,
  SET_DIMENSIONS_TO_INITIAL,
  GET_CAMPAIGNS_REQUEST,
  GET_CAMPAIGNS_REQUEST_SUCCESS,
  GET_CAMPAIGNS_REQUEST_FAILURE,
  DELETE_CAMPAIGNS_REQUEST,
  DELETE_CAMPAIGNS_REQUEST_SUCCESS,
  GET_PATIENT_BY_ID_REQUEST,
  GET_PATIENT_BY_ID_SUCCESS,
  GET_PATIENT_BY_ID_FAILURE,
  GET_PATIENT_LIST_VIEW_REQUEST,
  GET_PATIENT_LIST_VIEW_SUCCESS,
  GET_PATIENT_LIST_VIEW_FAILURE,
  GET_APPOINTMENT_BY_PATIENT_ID_REQUEST,
  GET_APPOINTMENT_BY_PATIENT_ID_SUCCESS,
  GET_APPOINTMENT_BY_PATIENT_ID_FAILURE,
  GET_REPORT_BY_PATIENT_ID_REQUEST,
  GET_REPORT_BY_PATIENT_ID_SUCCESS,
  GET_REPORT_BY_PATIENT_ID_FAILURE,
  GET_RECO_BY_PATIENT_ID_REQUEST,
  GET_RECO_BY_PATIENT_ID_SUCCESS,
  GET_RECO_BY_PATIENT_ID_FAILURE,
  UPDATE_RECO_BY_PATIENT_ID_REQUEST,
  UPDATE_RECO_BY_PATIENT_ID_SUCCESS,
  UPDATE_RECO_BY_PATIENT_ID_FAILURE,
  UPDATE_PATIENT_BY_PATIENT_ID_SUCCESS,
  UPDATE_REPORTS_BY_PATIENT_ID_SUCCESS,
  UPDATE_RECO_BY_RECOMMENDATION_TASK_REQUEST,
  UPDATE_RECO_BY_RECOMMENDATION_TASK_SUCCESS,
  UPDATE_RECO_BY_RECOMMENDATION_TASK_FAILURE,
  UPDATE_REJECTED_RECOMMENDATION_REQUEST,
  UPDATE_REJECTED_RECOMMENDATION_SUCCESS,
  UPDATE_REJECTED_RECOMMENDATION_FAILURE,
  UPDATE_TASK_BY_RECOMMENDATION_TASK_REQUEST,
  UPDATE_TASK_BY_RECOMMENDATION_TASK_SUCCESS,
  UPDATE_TASK_BY_RECOMMENDATION_TASK_FAILURE,
  UPDATE_PATIENT_BY_ID_REQUEST,
  UPDATE_PATIENT_BY_ID_SUCCESS,
  UPDATE_PATIENT_BY_ID_FAILURE,
  GET_MESSAGES_BY_PATIENT_PHONE_REQUEST,
  GET_MESSAGES_BY_PATIENT_PHONE_SUCCESS,
  GET_MESSAGES_BY_PATIENT_PHONE_FAILURE,
  GET_EMAILS_BY_PATIENT_ID_REQUEST,
  GET_EMAILS_BY_PATIENT_ID_SUCCESS,
  GET_EMAILS_BY_PATIENT_ID_FAILURE,
  GET_COHORTS_BY_PATIENT_ID_REQUEST,
  GET_COHORTS_BY_PATIENT_ID_SUCCESS,
  GET_COHORTS_BY_PATIENT_ID_FAILURE,
  CREATE_CAMPAIGNS_REQUEST,
  CREATE_CAMPAIGNS_REQUEST_SUCCESS,
  RESET_CAMPAIGN,
  CREATE_CAMPAIGNS_REQUEST_FAILURE,
  GET_CAMPAIGNS_ATTRIBUTE_CHOICES_REQUEST,
  GET_CAMPAIGNS_ATTRIBUTE_CHOICES_SUCCESS,
  GET_CAMPAIGNS_ATTRIBUTE_CHOICES_FAILURE,
  GET_DROPDOWN_RADIOLOGISTS_REQUEST,
  GET_DROPDOWN_RADIOLOGISTS_SUCCESS,
  GET_DROPDOWN_RADIOLOGISTS_FAILURE,
  GET_DROPDOWN_REFERRERS_REQUEST,
  GET_DROPDOWN_REFERRERS_SUCCESS,
  GET_DROPDOWN_REFERRERS_FAILURE,
  GET_COHORTS_DROPDOWN_REQUEST,
  GET_COHORTS_DROPDOWN_SUCCESS,
  GET_COHORTS_DROPDOWN_FAILURE,
  SET_ADDITIONAL_SEGMENT_CONDITIONS,
  SET_SEGMENT_CONDITION,
  SET_TIME_TRIGGER,
  RESET_TIME_TRIGGER,
  EDIT_SAVED_FILTER_RECOMMENDATIONS_SUCCESS,
  DELETE_SAVED_FILTER_RECOMMENDATIONS_SUCCESS,
  GET_SAVED_FILTER_RECOMMENDATIONS_SUCCESS,
  CREATE_SAVED_FILTER_RECOMMNEDATIONS_SUCCESS, 
  RESET_SAVED_FILTER_RECOMMENDATIONS_SUCCESS,
  GET_ALL_NOTES_REQUEST,
  GET_ALL_NOTES_SUCCESS,
  GET_ALL_NOTES_FAILURE,
  GET_PRESCRIPTIONS_BY_PATIENT_ID_SUCCESS,
  GET_PRESCRIPTIONS_BY_PATIENT_ID_FAILURE,
  GET_PRESCRIPTIONS_BY_PATIENT_ID_REQUEST,
  GET_KEY_METRICS_REQUEST,
  GET_KEY_METRICS_SUCCESS,
  GET_KEY_METRICS_FAILURE,
  GET_MARKETING_ATTRIBUTION_REQUEST,
  GET_MARKETING_ATTRIBUTION_SUCCESS,
  GET_MARKETING_ATTRIBUTION_FAILURE,
  GET_PROVIDERS_LIST_REQUEST,
  GET_PROVIDERS_LIST_SUCCESS,
  GET_PROVIDERS_LIST_FAILURE,
  UPDATE_SEARCH_REPORT_BY_RECOMMENDATION_SUCCESS,
  GET_COMMUNICATION_STAGE_OPTIONS_REQUEST,
  GET_COMMUNICATION_STAGE_OPTIONS_SUCCESS,
  GET_COMMUNICATION_STAGE_OPTIONS_FAILURE,
  UPDATE_COMMUNICATION_STAGE_OPTIONS_SUCCESS,
  GET_OPTIONS_LIST_REQUEST,
  GET_OPTIONS_LIST_SUCCESS,
  GET_OPTIONS_LIST_FAILURE,
  UPDATE_OPTIONS_LIST_SUCCESS,
  GET_ATTRIBUTES_LIST_REQUEST,
  GET_ATTRIBUTES_LIST_SUCCESS,
  GET_ATTRIBUTES_LIST_FAILURE,
  GET_LOCATIONS_OPTIONS_LIST_REQUEST,
  GET_LOCATIONS_OPTIONS_LIST_SUCCESS,
  GET_LOCATIONS_OPTIONS_LIST_FAILURE,
  GET_RADIOLOGISTS_OPTIONS_LIST_REQUEST,
  GET_RADIOLOGISTS_OPTIONS_LIST_SUCCESS,
  GET_RADIOLOGISTS_OPTIONS_LIST_FAILURE,
  GET_USERS_OPTIONS_LIST_REQUEST,
  GET_USERS_OPTIONS_LIST_SUCCESS,
  GET_USERS_OPTIONS_LIST_FAILURE,

} from '../actions/radminUploads';

import {
  GET_RADMIN_EXPORT_REQUEST,
  GET_RADMIN_EXPORT_SUCCESS,
  GET_RADMIN_EXPORT_FAILURE,
  GET_MESSAGE_EXPORT_REQUEST,
  GET_MESSAGE_EXPORT_SUCCESS,
  GET_MESSAGE_EXPORT_FAILURE,
  GET_NOTES_EXPORT_REQUEST,
  GET_NOTES_EXPORT_SUCCESS,
  GET_NOTES_EXPORT_FAILURE,
  GET_EMAILS_EXPORT_REQUEST,
  GET_EMAILS_EXPORT_SUCCESS,
  GET_EMAILS_EXPORT_FAILURE,
  GET_FAXES_EXPORT_REQUEST,
  GET_FAXES_EXPORT_SUCCESS,
  GET_FAXES_EXPORT_FAILURE
} from '../actions/radminExports';


import {
  GET_ACTIVITY_LOGS_REQUEST,
  GET_ACTIVITY_LOGS_SUCCESS,
  GET_ACTIVITY_LOGS_FAILURE,
} from '../actions/radminActivityData';

import {
  GET_CHANGELOG_REQUEST,
  GET_CHANGELOG_SUCCESS,
  GET_CHANGELOG_FAILED,
} from '../actions/radminChangelog';

import {
  GET_OUTBOUND_INTEGRATIONS_REQUEST,
  GET_OUTBOUND_INTEGRATIONS_SUCCESS,
  GET_OUTBOUND_INTEGRATIONS_FAILURE,
} from '../actions/radminOutboundIntegrations';



import {
  GET_INVITED_USERS_REQUEST,
  GET_INVITED_USERS_SUCCESS,
  GET_INVITED_USERS_FAILURE,
  GET_ROLES_PERMISSIONS_REQUEST,
  GET_ROLES_PERMISSIONS_SUCCESS,
  GET_ROLES_PERMISSIONS_FAILURE
} from '../actions/radminSettings';

import {
  GET_USER_SSO_TOKEN_FAILED,
  GET_USER_SSO_TOKEN_SUCCESS
} from '../actions/radminSupport'

import { truncateSync } from 'fs';
import { GET_API_TOKEN_GENERATION_FAILURE, GET_API_TOKEN_GENERATION_REQUEST, GET_API_TOKEN_GENERATION_SUCCESS } from '../actions/radminApiTokenGeneration';

const initialData = {
  data: null,
  totalItems: null,
  pageNumber: null,
  provider: null,
  isLoading: false,
  isError: null,
  searchValue: null,
};

const initial = {
  data: [],
  isLoading: false,
  isError: null,
  totalItems: null,
};

const initiaRadiologistlData = {
  data: null,
  totalItems: null,
  pageNumber: null,
  provider: null,
  isLoading: false,
  isError: null,
  searchValue: null,
};

const initialTemplatesData = {
  data: [],
  isLoading: false,
  isError: null,
  totalItems: null,
  templateIdToPreview: null,
  templateToPreview: null,
};

const initialTimeTriggerForm = {
  additionalSegmentConditions: [],
  segment: {
    condition: 'Is Not',
    cohortTitle: '',
    cohortId: null,
    showAddSegmentBtn: false,
  },
  timeTrigger: {
    dateAttribute: '',
    isClickedDateAttribute: false,
    offset: 'on',
    isClickedOffset: false,
    daysOffset: '',
    frequency: '',
    isClickedDaysOffset: false,
  },
}

export const initialState = {
  doctors: initialData,
  doctorsOptions:initialData,
  radiologist: initiaRadiologistlData,
  templates: initialTemplatesData,
  faxTemplates: initialTemplatesData,
  letterTemplates: initialTemplatesData,
  importProgressBarValue: null,
  cohortsRecommendations: {
    ...initial,
    dimension: {
      data: null,
      isLoading: false,
    },
    cohortWithId: {
      data: null,
      isLoading: false,
      isError: null,
    },
    dimensionsToEdit: initial,
    availableDimensions: initial,
    patients: initial,
    fixedValues: initial,
    communicationOptions: {
      ...initial,
      data: null
    }
  },
  rejectedRecommendations: {
    ...initial,
  },
  cohorts: {
    ...initial,
    dimension: {
      data: null,
      isLoading: false,
    },
    cohortWithId: {
      data: null,
      isLoading: false,
      isError: null,
    },
    dimensionsToEdit: initial,
    availableDimensions: initial,
    patients: initial,
    fixedValues: initial,
  },
  campaigns: {
    ...initial,
    isDeletingCampaign: false,
    createdCampaign: null,
    attributeChoices: initial,
  },
  notes: {
    data: null,
    isLoading: false,
    isError: null,
    totalItems: 0,
    analytics: {
      data: [],
      isLoading: false,
      isError: null,
    },
  },
  allNotes: {
    data: null,
    isLoading: false,
    isError: null,
    totalItems: 0,
  },
  doctorForPatients: {
    data: initialData,
    isLoading: false,
    isError: null,
  },
  imagingAdherence: {
    data: null,
    isLoading: false,
    isError: null,
    modalityOptions: {
      data: null,
      isLoading: false,
      isError: null,
    },
  },
  recommendationTimeframe: {
    data: null,
    isLoading: false,
    isError: null,
    startDate:'',
    endDate:'',
  },
  modalities: {
    data: null,
    isLoading: false,
    isError: null,
  },
  recoModalities: {
    data: null,
    isLoading: false,
    isError: null,
  },
  recoAnatomies: {
    data: null,
    isLoading: false,
    isError: null,
  },
  recoAnatomiesAndModalities: {
    data: null,
    isLoading: false,
    isError: null,
  },
  funnelAdherence: {
    data: null,
    isLoading: false,
    isError: null,
  },
  keyMetrics: {
    data: null,
    isLoading: false,
    isError: null,
  },
  marketingAttribution: {
    data: null,
    isLoading: false,
    isError: null,
  },
  patients: initialData,
  patientDetails: {
    ...initialData,
    appointments: initial,
    reports: initial,
    recommendations: initial,
    messages: initialData,
    prescriptions: initial,
    emails: initialData,
    cohorts:initial
  },
  patientsList: {
    data: [],
    hasMoreItems: null,
    pageNumber: null,
    isError: null,
  },
  searchReport: {
    data: null,
    totalItems: null,
    pageNumber: null,
    isLoading: false,
    isError: null,
  },
  previousUploads: initialData,
  activityLogs: {
    data: null,
    totalItems: null,
    pageNumber: null,
    isLoading: false,
    isError: null,
  },
  changeLogs: {
    data: null,
    isLoading: false,
    isError: null,
  },
  outboundIntegrations: {
    data: null,
    totalItems: null,
    pageNumber: null,
    isLoading: false,
    isError: null,
  },
  apiTokenGeneration: {
    data: null,
    totalItems: null,
    pageNumber: null,
    isLoading: false,
    isError: null,
  },
  exportHistory: initialData,
  invitedUsers:initialData,
  rolePermissions:initialData,
  exportMessages: {
    data: null,
    totalItems: null,
    pageNumber: null,
    isLoading: false,
    isError: null,
  },
  exportNotes: {
    data: null,
    totalItems: null,
    pageNumber: null,
    isLoading: false,
    isError: null,
  },
  exportEmails: {
    data: null,
    totalItems: null,
    pageNumber: null,
    isLoading: false,
    isError: null,
  },
  exportFaxes: {
    data: null,
    totalItems: null,
    pageNumber: null,
    isLoading: false,
    isError: null,
  },
  ssoToken: {
    token: null,
    isError:null
  },
  recoConfig: {
    data: null,
    isError: null,
    isLoading: false,
  },
  exportReport: {
    ...initialData,
  },
  followUpList: {
    ...initialData,
    patientDetails: {
      ...initialData,
    },
    totalStatuses: {
      data: null,
      isLoading: false,
      isError: null,
    },
    adherenceOfToday: {
      data: [],
      isLoading: false,
      isError: null,
    },
    adherenceOfDate: { data: [], totalItems: null, loading:false },
    adherenceOverRate: { data: [], startdate: '', endDate: '', loading:false },
    revenueCharges: { data: [], startdate: '', endDate: '', loading:false },
    totalRevenueCharges:{data:{}, loading:true},
    totalRevenueChargesAverage:{data:{}},
    totalAttributeRevenueCharges:{data:{}, loading:false},
    recoHistory: {
      data: null,
      isLoading: false,
      isError: null,
    },
    radiologists: {
      ...initialData,
    },
    dropdownRadiologists: {
      data: null,
      isLoading: false,
      isError: null,
    },
    dropdownReferrers: {
      data: null,
      isLoading: false,
      isError: null,
    },
    dropdownCohorts: {
      data: null,
      isLoading: false,
      isError: null,
    },
    actionStatus: {
      isLoading: false,
      isError: null,
    },
    modalities: {
      ...initialData,
    },
    anatomies: {
      ...initialData,
    },
    timeframeStatuses: {
      ...initialData,
    },
    findingsBoi: {
      data: null,
      isLoading: false,
      isError: null,
    },
  },
  followUpListSavedFilters :{
    data : []
  },
  messages: {
    recentMessages: {
      ...initialData,
    },
    chatMessages: {
      ...initialData,
    },
    outgoingMessages: {
      data: [],
      totalItems: null,
      isLoading: false,
      isError: null,
    },
    analytics: {
      counts: [],
      data: [],
      isLoading: false,
    },
    outgoingMessageFromDialog: {
      isLoading: false,
      isError: null,
    },
    usersInfo: {
      data: null,
    },
    phoneNumbers: {
      data: null,
    },
    patientHistory: {
      ...initialData,
    },
    twilioConfig: {
      data: null,
      isLoading: false,
      isError: null,
    },
    smsResponse: {
      data: null,
      isLoading: false,
      isError: null,
    },
    followUpList: {
      data: [],
      isLoading: false,
    },
  },
  emails: {
    recentMessages: {
      ...initialData,
    },
    outgoingMessages: {
      data: [],
      totalItems: null,
      isLoading: false,
      isError: null,
    },
    analytics: {
      counts: [],
      data: [],
      isLoading: false,
    },
    outgoingMessageFromDialog: {
      isLoading: false,
      isError: null,
    },
    usersInfo: {
      data: null,
    },
    phoneNumbers: {
      data: null,
    },
    patientHistory: {
      ...initialData,
    },
    twilioConfig: {
      data: null,
      isLoading: false,
      isError: null,
    },
    smsResponse: {
      data: null,
      isLoading: false,
      isError: null,
    },
    followUpList: {
      data: [],
      isLoading: false,
    },
  },
  
  faxes: {
    outgoingMessages: {
      data: [],
      totalItems: null,
      isLoading: false,
      isError: null,
    },
    analytics: {
      counts: [],
      data: [],
      isLoading: false,
    },
   },
  alert: {
    type: '',
    headline: '',
    children: '',
    timeout: 0,
    active: false,
  },
  timeTriggerForm: {
    ...initialTimeTriggerForm
  },
  providersList: {
    data: null,
    isError: null,
    isLoading: false,
  },
  optionsList: {
    data: null,
    isError: null,
    isLoading: false,
  },
  attributesList: {
    data: null,
    isError: null,
    isLoading: false,
  },
  locationsOptionsList: {
    data: null,
    isError: null,
    isLoading: false,
  },
  radiologistsOptionsList: {
    data: null,
    isError: null,
    isLoading: false,
  },
  usersOptionsList: {
    data: null,
    isError: null,
    isLoading: false,
  }
};
 
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SAVED_FILTER_RECOMMNEDATIONS_SUCCESS:
      return {
        ...state,
        followUpListSavedFilters:{
          data: []
        }
      }
      case GET_SAVED_FILTER_RECOMMENDATIONS_SUCCESS:
        return {
          ...state,
          followUpListSavedFilters:{
            data: [  ...state.followUpListSavedFilters.data ,...action.payload]
          }
        }
        case RESET_SAVED_FILTER_RECOMMENDATIONS_SUCCESS:
          return {
            ...state,
          followUpListSavedFilters:{
            data: [...action.payload]
          }
          }

      case DELETE_SAVED_FILTER_RECOMMENDATIONS_SUCCESS:
        return {
          ...state,
          followUpListSavedFilters:{
            data: [...action.payload]
          }
        }
        case EDIT_SAVED_FILTER_RECOMMENDATIONS_SUCCESS:
          return {
            ...state,
            followUpListSavedFilters:{
              data: [...action.payload]
            }
          }
    case SHOW_ALERT:
      return {
        ...state,
        alert: {
          ...action.payload,
        },
      };
    case HIDE_ALERT:
      return {
        ...state,
        alert: {
          ...initialState.alert,
        },
      };
    case UPDATE_NOTE_REQUEST:
      return {
        ...state,
        notes: {
          ...state.notes,
          isLoading: true,
        },
      };
    case UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          data: action.payload,
          isLoading: false,
        },
      };
    case UPDATE_NOTE_FAILURE:
      return {
        ...state,
        notes: {
          ...state.notes,
          isError: action.payload,
          isLoading: false,
        },
      };
    case CREATE_NOTE_REQUEST:
      return {
        ...state,
        notes: {
          ...state.notes,
          isLoading: true,
        },
      };
    case CREATE_NOTE_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          data: action.payload,
          isLoading: false,
          totalItems: state.notes.totalItems + 1,
        },
      };
    case CREATE_NOTE_FAILURE:
      return {
        ...state,
        notes: {
          ...state.notes,
          isError: action.payload,
          isLoading: false,
        },
      };
    case GET_NOTES_REQUEST:
      return {
        ...state,
        notes: {
          ...state.notes,
          isLoading: true,
        },
      };

    case GET_NOTES_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          data:
            action.payload.page > 1
              ? [...state.notes.data, ...action.payload.data]
              : action.payload.data,
          totalItems: action.payload.totalItems,
          isLoading: false,
        },
      };
    case GET_NOTES_FAILURE:
      return {
        ...state,
        notes: {
          ...state.notes,
          isLoading: false,
          isError: action.payload,
        },
      };

      case GET_ALL_NOTES_REQUEST:
        return {
          ...state,
          allNotes: {
            ...state.notes,
            isLoading: true,
          },
        };
  
      case GET_ALL_NOTES_SUCCESS:
        return {
          ...state,
          allNotes: {
            ...state.allNotes,
            data: action.payload.data,
            totalItems: action.payload.totalItems,
            isLoading: false,
          },
        };
      case GET_ALL_NOTES_FAILURE:
        return {
          ...state,
          allNotes: {
            ...state.allNotes,
            isLoading: false,
            isError: action.payload,
          },
        };
 
        case GET_USER_SSO_TOKEN_SUCCESS:
          return {
            ...state,
            ssoToken:{
              ...state.ssoToken,
              token:action.payload
            }
          }
          case GET_USER_SSO_TOKEN_FAILED:
            return {
              ...state,
              ssoToken:{
                ...state.ssoToken,
                isError:action.payload
              }
            }

    case GET_NOTES_ANALYTICS_REQUEST:
      return {
        ...state,
        notes: {
          ...state.notes,
          analytics: {
            ...state.notes.analytics,
            isLoading: true,
          },
        },
      };
    case GET_NOTES_ANALYTICS_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          analytics: {
            ...state.notes.analytics,
            data: action.payload,
            isLoading: false,
          },
        },
      };
    case GET_NOTES_ANALYTICS_FAILURE:
      return {
        ...state,
        notes: {
          ...state.notes,
          analytics: {
            ...state.notes.analytics,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_COHORTS_REQUEST:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          isLoading: true,
        },
      };

    case GET_COHORTS_SUCCESS:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          data:
            action.payload.page > 1
              ? [...state.cohorts.data, ...action.payload.data]
              : action.payload.data,
          totalItems: action.payload.totalItems,
          isLoading: false,
        },
      };
    case GET_COHORTS_FAILURE:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          isLoading: false,
          isError: action.payload,
        },
      };

      case GET_RECO_COHORTS_REQUEST:
        return {
          ...state,
          cohortsRecommendations: {
            ...state.cohortsRecommendations,
            isLoading: true,
          },
        };
  
      case GET_RECO_COHORTS_SUCCESS:
        return {
          ...state,
          cohortsRecommendations: {
            ...state.cohortsRecommendations,
            data:
              action.payload.page > 1
                ? [...state.cohortsRecommendations.data, ...action.payload.data]
                : action.payload.data,
            totalItems: action.payload.totalItems,
            isLoading: false,
          },
        };
      case GET_RECO_COHORTS_FAILURE:
        return {
          ...state,
          cohortsRecommendations: {
            ...state.cohortsRecommendations,
            isLoading: false,
            isError: action.payload,
          },
        };

      case GET_COHORTS_RECOMMENDATIONS_REQUEST:
        return {
          ...state,
          cohortsRecommendations: {
            ...state.cohortsRecommendations,
            isLoading: true,
          },
        };
  
      case GET_COHORTS_RECOMMENDATIONS_SUCCESS:
        return {
          ...state,
          cohortsRecommendations: {
            ...state.cohortsRecommendations,
            data:
              action.payload.page > 1
                ? [...state.cohortsRecommendations.data, ...action.payload.data]
                : action.payload.data,
            totalItems: action.payload.totalItems,
            isLoading: false,
          },
        };
      case GET_COHORTS_RECOMMENDATIONS_FAILURE:
        return {
          ...state,
          cohortsRecommendations: {
            ...state.cohortsRecommendations,
            isLoading: false,
            isError: action.payload,
          },
        };

      case GET_RECOMMENDATIONS_IN_COHORT_REQUEST:
            return {
              ...state,
              cohortsRecommendations: {
                ...state.cohortsRecommendations,
                recommendations: {
                  ...state.cohortsRecommendations.recommendations,
                  isLoading: true,
                  data: action.payload === 1 ? [] : state.cohortsRecommendations.recommendations.data,
                  pageNumber:action.payload

                },
              },
            };
          case GET_RECOMMENDATIONS_IN_COHORT_SUCCESS:
            return {
              ...state,
              cohortsRecommendations: {
                ...state.cohortsRecommendations,
                recommendations: {
                  ...state.cohortsRecommendations.recommendations,
                  isLoading: false,
                  data:
                    action.payload.page > 1
                      ? [...state.cohortsRecommendations.recommendations.data, ...action.payload.data]
                      : action.payload.data,
                  totalItems: action.payload.totalItems,
                  pageNumber:action.payload.page
                },
              },
            };
          case GET_RECOMMENDATIONS_IN_COHORT_FAILURE:
            return {
              ...state,
              cohortsRecommendations: {
                ...state.cohortsRecommendations,
                recommendations: {
                  ...state.cohortsRecommendations.recommendations,
                  isLoading: false,
                  isError: action.payload,
                  pageNumber:action.payload.page

                },
              },
            };


            case GET_REJECTED_RECOMMENDATIONS_REQUEST:
              return {
                ...state,
                rejectedRecommendations: {
                  ...state.rejectedRecommendations,
                  recommendations: {
                    ...state.rejectedRecommendations.recommendations,
                    isLoading: true,
                    data: action.payload === 1 ? [] : state.rejectedRecommendations.recommendations.data,
                    pageNumber:action.payload
  
                  },
                },
              };
            case GET_REJECTED_RECOMMENDATIONS_SUCCESS:
              return {
                ...state,
                rejectedRecommendations: {
                  ...state.rejectedRecommendations,
                  recommendations: {
                    ...state.rejectedRecommendations.recommendations,
                    isLoading: false,
                    data:
                      action.payload.page > 1
                        ? [...state.rejectedRecommendations.recommendations.data, ...action.payload.data]
                        : action.payload.data,
                    totalItems: action.payload.totalItems,
                    pageNumber:action.payload.page
                  },
                },
              };
            case GET_REJECTED_RECOMMENDATIONS_FAILURE:
              return {
                ...state,
                rejectedRecommendations: {
                  ...state.rejectedRecommendations,
                  recommendations: {
                    ...state.rejectedRecommendations.recommendations,
                    isLoading: false,
                    isError: action.payload,
                    pageNumber:action.payload.page
  
                  },
                },
              };

        
            case GET_TASKS_IN_COHORT_REQUEST:
              return {
                ...state,
                cohortsRecommendations: {
                  ...state.cohortsRecommendations,
                  tasks: {
                    ...state.cohortsRecommendations.tasks,
                    isLoading: true,
                    data: action.payload === 1 ? [] : state.cohortsRecommendations.tasks.data,
                    pageNumber:action.payload

                  },
                },
              };
            case GET_TASKS_IN_COHORT_SUCCESS:
              return {
                ...state,
                cohortsRecommendations: {
                  ...state.cohortsRecommendations,
                  tasks: {
                    ...state.cohortsRecommendations.tasks,
                    isLoading: false,
                    data:
                      action.payload.page > 1
                        ? [...state.cohortsRecommendations.tasks.data, ...action.payload.data]
                        : action.payload.data,
                    totalItems: action.payload.totalItems,
                    pageNumber:action.payload.page

                  },
                },
              };
            case GET_TASKS_IN_COHORT_FAILURE:
              return {
                ...state,
                cohortsRecommendations: {
                  ...state.cohortsRecommendations,
                  tasks: {
                    ...state.cohortsRecommendations.tasks,
                    isLoading: false,
                    isError: action.payload,
                    pageNumber:action.payload.page

                  },
                },
              };

    case GET_CAMPAIGNS_REQUEST:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: true,
        },
      };
    case GET_CAMPAIGNS_REQUEST_SUCCESS:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          data:
            action.payload.page > 1
              ? [...state.campaigns.data, ...action.payload.data]
              : action.payload.data,
          totalItems: action.payload.totalItems,
          isLoading: false,
        },
      };
    case DELETE_CAMPAIGNS_REQUEST:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isDeletingCampaign: true,
        },
      };
    case DELETE_CAMPAIGNS_REQUEST_SUCCESS:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          data: action.payload,
          isDeletingCampaign: false,
          totalItems: state.campaigns.totalItems - 1,
        },
      };
    case DELETE_CAMPAIGN_STEP:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: false,
          createdCampaign: {
            ...state.campaigns.createdCampaign,
            steps: action.payload,
            stepCount: state.campaigns.createdCampaign.stepCount - 1,
          },
        },
      };
    case FETCH_CAMPAIGN_STEPS:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: false,
          createdCampaign: {
            ...state.campaigns.createdCampaign,
            steps: action.payload.steps,
            stepCount: action.payload.stepCount,
          },
        },
      };
    case EDIT_CAMPAIGN_STEP:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: false,
          createdCampaign: {
            ...state.campaigns.createdCampaign,
            steps: action.payload.steps,
          },
        },
      };
    case CREATE_CAMPAIGN_STEP:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: false,
          createdCampaign: {
            ...state.campaigns.createdCampaign,
            steps: action.payload.steps,
            stepCount: state.campaigns.createdCampaign.stepCount + 1,
          },
        },
      };
    case RESET_CAMPAIGN:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          createdCampaign: null,
        },
      };
    case CREATE_CAMPAIGNS_REQUEST:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: true,
        },
      };
    case CREATE_CAMPAIGNS_REQUEST_SUCCESS:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: false,
          createdCampaign: {
            ...action.payload,
            steps: [],
          },
        },
      };
    case CREATE_CAMPAIGNS_REQUEST_FAILURE:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: false,
        },
      };
    case GET_CAMPAIGNS_ATTRIBUTE_CHOICES_REQUEST:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          attributeChoices: {
            ...state.campaigns.attributeChoices,
            isLoading: true,
          },
        },
      };
    case GET_CAMPAIGNS_ATTRIBUTE_CHOICES_SUCCESS:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          attributeChoices: {
            ...state.campaigns.attributeChoices,
            isLoading: false,
            data: action.payload,
          },
        },
      };
    case GET_CAMPAIGNS_ATTRIBUTE_CHOICES_FAILURE:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          attributeChoices: {
            ...state.campaigns.attributeChoices,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_CAMPAIGNS_REQUEST_FAILURE:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_COHORT_WITH_ID_REQUEST:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          cohortWithId: {
            ...state.cohorts.cohortWithId,
            isLoading: true,
          },
        },
      };
    case GET_COHORT_WITH_ID_SUCCESS:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          cohortWithId: {
            ...state.cohorts.cohortWithId,
            isLoading: false,
            data: action.payload,
          },
        },
      };
    case GET_COHORT_WITH_ID_FAILURE:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          cohortWithId: {
            ...state.cohorts.cohortWithId,
            isLoading: false,
            isError: action.payload,
          },
        },
      };

      case RESET_RECO_COHORT_WITH_ID_REQUEST:
        return {
          ...state,
          cohortsRecommendations: {
            ...state.cohortsRecommendations,
            cohortWithId: {
              ...state.cohortsRecommendations.cohortWithId,
              isLoading: false,
              data:null,
              isError:null
            },
          },
        };

      case GET_RECO_COHORT_WITH_ID_REQUEST:
        return {
          ...state,
          cohortsRecommendations: {
            ...state.cohortsRecommendations,
            cohortWithId: {
              ...state.cohortsRecommendations.cohortWithId,
              isLoading: true,
            },
          },
        };
      case GET_RECO_COHORT_WITH_ID_SUCCESS:
        return {
          ...state,
          cohortsRecommendations: {
            ...state.cohortsRecommendations,
            cohortWithId: {
              ...state.cohortsRecommendations.cohortWithId,
              isLoading: false,
              data: action.payload,
            },
          },
        };
      case GET_RECO_COHORT_WITH_ID_FAILURE:
        return {
          ...state,
          cohortsRecommendations: {
            ...state.cohortsRecommendations,
            cohortWithId: {
              ...state.cohortsRecommendations.cohortWithId,
              isLoading: false,
              isError: action.payload,
            },
          },
        };
    case CREATE_COHORT_SUCCESS:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          isLoading: false,
        },
      };
    case GET_DIMENTION_REQUEST:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          dimension: {
            ...state.cohorts.dimension,
            isLoading: true,
            data: null,
          },
        },
      };
    case GET_DIMENTION_SUCCESS:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          dimension: {
            ...state.cohorts.dimension,
            isLoading: false,
            data: action.payload.data,
          },
        },
      };
    case GET_DIMENTION_FAILURE:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          dimension: {
            ...state.cohorts.dimension,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_ALL_DIMENTIONS_REQUEST:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          availableDimensions: {
            ...state.cohorts.availableDimensions,
            isLoading: true,
            data: [],
          },
        },
      };
    case GET_ALL_DIMENTIONS_SUCCESS:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          availableDimensions: {
            ...state.cohorts.availableDimensions,
            isLoading: false,
            data: action.payload.data,
          },
        },
      };
    case GET_ALL_DIMENTIONS_FAILURE:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          availableDimensions: {
            ...state.cohorts.availableDimensions,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_DIMENSIONS_TO_EDIT_REQUEST:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          dimensionsToEdit: {
            ...state.cohorts.dimensionsToEdit,
            isLoading: true,
            data: [],
          },
        },
      };
    case GET_DIMENSIONS_TO_EDIT_SUCCESS:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          dimensionsToEdit: {
            ...state.cohorts.dimensionsToEdit,
            isLoading: false,
            data: action.payload,
          },
        },
      };
    case GET_DIMENSIONS_TO_EDIT_FAILURE:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          dimensionsToEdit: {
            ...state.cohorts.dimensionsToEdit,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_COHORT_FIXED_VALUES_REQUEST:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          fixedValues: {
            ...state.cohorts.fixedValues,
            isLoading: true,
          },
        },
      };
    case GET_COHORT_FIXED_VALUES_SUCCESS:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          fixedValues: {
            ...state.cohorts.fixedValues,
            isLoading: false,
            data: [...state.cohorts.fixedValues.data, action.payload],
          },
        },
      };
    case GET_COHORT_FIXED_VALUES_FAILURE:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          fixedValues: {
            ...state.cohorts.fixedValues,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
      case GET_RECO_COHORT_FIXED_VALUES_REQUEST:
        return {
          ...state,
          cohortsRecommendations: {
            ...state.cohortsRecommendations,
            fixedValues: {
              ...state.cohortsRecommendations.fixedValues,
              isLoading: true,
            },
          },
        };
      case GET_RECO_COHORT_FIXED_VALUES_SUCCESS:
        return {
          ...state,
          cohortsRecommendations: {
            ...state.cohortsRecommendations,
            fixedValues: {
              ...state.cohortsRecommendations.fixedValues,
              isLoading: false,
              data: [...state.cohortsRecommendations.fixedValues.data, action.payload],
            },
          },
        };
      case GET_RECO_COHORT_FIXED_VALUES_FAILURE:
        return {
          ...state,
          cohortsRecommendations: {
            ...state.cohortsRecommendations,
            fixedValues: {
              ...state.cohortsRecommendations.fixedValues,
              isLoading: false,
              isError: action.payload,
            },
          },
        };
    case SET_DIMENSIONS_TO_INITIAL:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          dimensionsToEdit: initial,
        },
      };
    case GET_PATIENT_IN_COHORT_REQUEST:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          patients: {
            ...state.cohorts.patients,
            isLoading: true,
            data: action.payload === 1 ? [] : state.cohorts.patients.data,
          },
        },
      };
    case GET_PATIENT_IN_COHORT_SUCCESS:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          patients: {
            ...state.cohorts.patients,
            isLoading: false,
            data:
              action.payload.page > 1
                ? [...state.cohorts.patients.data, ...action.payload.data]
                : action.payload.data,
            totalItems: action.payload.totalItems,
          },
        },
      };
    case GET_PATIENT_IN_COHORT_FAILURE:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          patients: {
            ...state.cohorts.patients,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_PATIENT_BY_ID_REQUEST:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          isLoading: true,
        },
      };
    case GET_PATIENT_BY_ID_SUCCESS:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_PATIENT_BY_ID_FAILURE:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_APPOINTMENT_BY_PATIENT_ID_REQUEST:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          appointments: {
            ...state.patientDetails.appointments,
            isLoading: true,
          },
        },
      };
    case GET_APPOINTMENT_BY_PATIENT_ID_SUCCESS:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          appointments: {
            ...state.patientDetails.appointments,
            isLoading: false,
            totalItems: action.totalItems,
            data: action.page > 1
            ? [
                ...state.patientDetails.appointments.data,
                ...action.payload,
              ]
            : action.payload,
          },
        },
      };
    case GET_APPOINTMENT_BY_PATIENT_ID_FAILURE:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          appointments: {
            ...state.patientDetails.appointments,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_REPORT_BY_PATIENT_ID_REQUEST:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          reports: {
            ...state.patientDetails.reports,
            isLoading: true,
          },
        },
      };
    case GET_REPORT_BY_PATIENT_ID_SUCCESS:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          reports: {
            ...state.patientDetails.reports,
            isLoading: false,
            totalItems: action.totalItems,
            data: action.page > 1
            ? [
                ...state.patientDetails.reports.data,
                ...action.payload,
              ]
            : action.payload,
          },
        },
      };
      case GET_PRESCRIPTIONS_BY_PATIENT_ID_REQUEST:
        return {
          ...state,
          patientDetails: {
            ...state.patientDetails,
              prescriptions: {
                ...state.patientDetails.prescriptions,
                isLoading: true,
              },
            
          },
        };
      case GET_PRESCRIPTIONS_BY_PATIENT_ID_SUCCESS:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
            prescriptions: {
              ...state.patientDetails.prescriptions,
              data:
                 action.payload.data,
              totalItems: action.payload.totalItems,
              isLoading: false,
            },
          
        },
      };

    case GET_PRESCRIPTIONS_BY_PATIENT_ID_FAILURE:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
            prescriptions: {
              ...state.patientDetails.prescriptions,
              isLoading: false,
              isError: action.payload,
            },
          
        },
      };
    case GET_REPORT_BY_PATIENT_ID_FAILURE:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          reports: {
            ...state.patientDetails.reports,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_RECO_BY_PATIENT_ID_REQUEST:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          recommendations: {
            ...state.patientDetails.recommendations,
            isLoading: true,
          },
        },
      };
    case GET_RECO_BY_PATIENT_ID_SUCCESS:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          recommendations: {
            ...state.patientDetails.recommendations,
            isLoading: false,
            totalItems: action.totalItems,
            data: action.page > 1
            ? [
                ...state.patientDetails.reports.data,
                ...action.payload,
              ]
            : action.payload,
          },
       },
      };
    case GET_RECO_BY_PATIENT_ID_FAILURE:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          recommendations: {
            ...state.patientDetails.recommendations,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    
      case UPDATE_RECO_BY_PATIENT_ID_REQUEST:
        return {
          ...state,
          patientDetails: {
            ...state.patientDetails,
            recommendations: {
              ...state.patientDetails.recommendations,
              isLoading: true,
            },
          },
        };
      case UPDATE_RECO_BY_PATIENT_ID_SUCCESS:
        return {
          ...state,
          patientDetails: {
            ...state.patientDetails,
            recommendations: {
              ...state.patientDetails.recommendations,
              isLoading: false,
              data: action.payload,
            },
         },
        };
      case UPDATE_RECO_BY_PATIENT_ID_FAILURE:
        return {
          ...state,
          patientDetails: {
            ...state.patientDetails,
            recommendations: {
              ...state.patientDetails.recommendations,
              isLoading: false,
              isError: action.payload,
            },
          },
        };

        case UPDATE_REPORTS_BY_PATIENT_ID_SUCCESS:
          return {
            ...state,
            patientDetails: {
              ...state.patientDetails,
              reports: {
                ...state.patientDetails.reports,
                isLoading: false,
                data: action.payload,
              },
           },
          };

        case UPDATE_PATIENT_BY_PATIENT_ID_SUCCESS:
          return {
            ...state,
            patientDetails: {
              ...state.patientDetails,
              data: action.payload,
              isLoading: false,
           },
          };


        case UPDATE_RECO_BY_RECOMMENDATION_TASK_REQUEST:
          return {
            ...state,
            cohortsRecommendations: {
              ...state.cohortsRecommendations,
              recommendations: {
                ...state.cohortsRecommendations.recommendations,
                isLoading: true,
              },
            },
          };
        case UPDATE_RECO_BY_RECOMMENDATION_TASK_SUCCESS:
          return {
            ...state,
            cohortsRecommendations: {
              ...state.cohortsRecommendations,
              recommendations: {
                ...state.cohortsRecommendations.recommendations,
                isLoading: false,
                data: action.payload,
              },
           },
          };
        case UPDATE_RECO_BY_RECOMMENDATION_TASK_FAILURE:
          return {
            ...state,
            cohortsRecommendations: {
              ...state.cohortsRecommendations,
              recommendations: {
                ...state.cohortsRecommendations.recommendations,
                isLoading: false,
                isError: action.payload,
              },
            },
          };
  
          case UPDATE_REJECTED_RECOMMENDATION_REQUEST:
            return {
              ...state,
              rejectedRecommendations: {
                ...state.rejectedRecommendations,
                recommendations: {
                  ...state.rejectedRecommendations.recommendations,
                  isLoading: true,
                },
              },
            };
          case UPDATE_REJECTED_RECOMMENDATION_SUCCESS:
            return {
              ...state,
              rejectedRecommendations: {
                ...state.rejectedRecommendations,
                recommendations: {
                  ...state.rejectedRecommendations.recommendations,
                  isLoading: false,
                  data: action.payload,
                },
             },
            };
          case UPDATE_REJECTED_RECOMMENDATION_FAILURE:
            return {
              ...state,
              rejectedRecommendations: {
                ...state.rejectedRecommendations,
                recommendations: {
                  ...state.rejectedRecommendations.recommendations,
                  isLoading: false,
                  isError: action.payload,
                },
              },
            };

          case UPDATE_TASK_BY_RECOMMENDATION_TASK_REQUEST:
            return {
              ...state,
              cohortsRecommendations: {
                ...state.cohortsRecommendations,
                tasks: {
                  ...state.cohortsRecommendations.tasks,
                  isLoading: true,
                },
              },
            };
          case UPDATE_TASK_BY_RECOMMENDATION_TASK_SUCCESS:
            return {
              ...state,
              cohortsRecommendations: {
                ...state.cohortsRecommendations,
                tasks: {
                  ...state.cohortsRecommendations.tasks,
                  isLoading: false,
                  data: action.payload.data,
                  totalItems:action.payload.totalItems
                },
             },
            };
          case UPDATE_TASK_BY_RECOMMENDATION_TASK_FAILURE:
            return {
              ...state,
              cohortsRecommendations: {
                ...state.cohortsRecommendations,
                tasks: {
                  ...state.cohortsRecommendations.tasks,
                  isLoading: false,
                  isError: action.payload,
                },
              },
            };
    

        case UPDATE_PATIENT_BY_ID_REQUEST:
          return {
            ...state,
            patientDetails: {
              ...state.patientDetails,
              isLoading: true,
            },
          };
        case UPDATE_PATIENT_BY_ID_SUCCESS:
          return {
            ...state,
            patientDetails: {
              ...state.patientDetails,
              isLoading: false,
              data: action.payload,
           },
          };
        case UPDATE_PATIENT_BY_ID_FAILURE:
          return {
            ...state,
            patientDetails: {
              ...state.patientDetails,
              isLoading: false,
              isError: action.payload,
            },
          };
      case GET_MESSAGES_BY_PATIENT_PHONE_REQUEST:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          messages: {
            ...state.patientDetails.messages,
            isLoading: true,
          },
        },
      };
    case GET_MESSAGES_BY_PATIENT_PHONE_SUCCESS:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          messages: {
            ...state.patientDetails.messages,
            isLoading: false,
            data:
              action.payload.page > 1
                ? [
                    ...state.patientDetails.messages.data,
                    ...action.payload.data,
                  ]
                : action.payload.data,
            totalItems: action.payload.totalItems,
          },
        },
      };
    case GET_MESSAGES_BY_PATIENT_PHONE_FAILURE:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          messages: {
            ...state.patientDetails.messages,
            isLoading: false,
            isError: action.payload,
          },
        },
      };

      case GET_EMAILS_BY_PATIENT_ID_REQUEST:
        return {
          ...state,
          patientDetails: {
            ...state.patientDetails,
            emails: {
              ...state.patientDetails.emails,
              isLoading: true,
            },
          },
        };
      case GET_EMAILS_BY_PATIENT_ID_SUCCESS:
        return {
          ...state,
          patientDetails: {
            ...state.patientDetails,
            emails: {
              ...state.patientDetails.emails,
              isLoading: false,
              data:
                action.payload.page > 1
                  ? [
                      ...state.patientDetails.emails.data,
                      ...action.payload.data,
                    ]
                  : action.payload.data,
              totalItems: action.payload.totalItems,
            },
          },
        };
        case GET_EMAILS_BY_PATIENT_ID_FAILURE:
          return {
            ...state,
            patientDetails: {
              ...state.patientDetails,
              emails: {
                ...state.patientDetails.emails,
                isLoading: false,
                isError: action.payload,
              },
            },
          };

          case GET_COHORTS_BY_PATIENT_ID_REQUEST:
            return {
              ...state,
              patientDetails: {
                ...state.patientDetails,
                cohorts: {
                  ...state.patientDetails.cohorts,
                  isLoading: true,
                },
              },
            };
          case GET_COHORTS_BY_PATIENT_ID_SUCCESS:
            return {
              ...state,
              patientDetails: {
                ...state.patientDetails,
                cohorts: {
                  ...state.patientDetails.cohorts,
                  isLoading: false,
                  data:
                    action.payload.page > 1
                      ? [
                          ...state.patientDetails.cohorts.data,
                          ...action.payload.data,
                        ]
                      : action.payload.data,
                  totalItems: action.payload.totalItems,
                },
              },
            };
            case GET_COHORTS_BY_PATIENT_ID_FAILURE:
              return {
                ...state,
                patientDetails: {
                  ...state.patientDetails,
                  cohorts: {
                    ...state.patientDetails.cohorts,
                    isLoading: false,
                    isError: action.payload,
                  },
                },
              };

    case SET_TEMPLATE_ID_TO_PREVIEW:
      return {
        ...state,
        templates: {
          ...state.templates,
          templateIdToPreview: action.payload,
        },
      };

    case SET_TEMPLATE_TO_PREVIEW:
      return {
        ...state,
        templates: {
          ...state.templates,
          templateToPreview: action.payload,
          isLoading: false,
        },
      };

      case SET_FAX_TEMPLATE_ID_TO_PREVIEW:
        return {
          ...state,
          faxTemplates: {
            ...state.faxTemplates,
            templateIdToPreview: action.payload,
          },
        };
  
      case SET_FAX_TEMPLATE_TO_PREVIEW:
        return {
          ...state,
          faxTemplates: {
            ...state.faxTemplates,
            templateToPreview: action.payload,
            isLoading: false,
          },
        };

    case GET_TEMPLATES_REQUEST:
      return {
        ...state,
        templates: {
          ...state.templates,
          isLoading: true,
        },
      };
    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: {
          ...state.templates,
          isLoading: false,
          data:
            action.payload.pageNo >= 2
              ? [...state.templates.data, ...action.payload.data]
              : action.payload.data,
          totalItems: action.payload.totalItems,
        },
      };
    case GET_TEMPLATES_REQUEST:
      return {
        ...state,
        templates: {
          ...state.templates,
          isLoading: false,
          isError: action.payload,
        },
      };

      case GET_FAX_TEMPLATES_SUCCESS:
        return {
          ...state,
          faxTemplates: {
            ...state.faxTemplates,
            isLoading: false,
            data:
              action.payload.pageNo >= 2
                ? [...state.faxTemplates.data, ...action.payload.data]
                : action.payload.data,
            totalItems: action.payload.totalItems,
          },
        };
      case GET_FAX_TEMPLATES_REQUEST:
        return {
          ...state,
          faxTemplates: {
            ...state.faxTemplates,
            isLoading: false,
            isError: action.payload,
          },
        };

        case GET_LETTER_TEMPLATES_SUCCESS:
          return {
            ...state,
            letterTemplates: {
              ...state.letterTemplates,
              isLoading: false,
              data:
                action.payload.pageNo >= 2
                  ? [...state.letterTemplates.data, ...action.payload.data]
                  : action.payload.data,
              totalItems: action.payload.totalItems,
            },
          };
        case GET_LETTER_TEMPLATES_REQUEST:
          return {
            ...state,
            letterTemplates: {
              ...state.letterTemplates,
              isLoading: true,
              isError: action.payload,
            },
          };
          case GET_LETTER_TEMPLATES_FAILURE:
            return {
              ...state,
              letterTemplates: {
                ...state.letterTemplates,
                isLoading: false,
                isError: action.payload,
              },
            };

            case SET_PROGRESS_BAR_VALUE:
              return {
                  ...state,
                  importProgressBarValue: action.payload
              };
  
    case GET_DOCTORS_LIST_REQUEST:
      return {
        ...state,
        doctors: {
          ...state.doctors,
          data: state.doctors.data,
          pageNumber: action.payload,
          isLoading: action.payload === 1 ? true : false,
          isError: null,
        },
      };
      
      case GET_DOCTORS_LIST_DROPDOWN_REQUEST:
        return {
          ...state,
          doctorsOptions: {
            ...state.doctorsOptions,
            data: state.doctorsOptions.data,
            pageNumber: action.payload,
            isLoading: action.payload === 1 ? true : false,
            isError: null,
          },
        };
    case GET_IMAGING_ADHERENCE_REQUEST:
      return {
        ...state,
        imagingAdherence: {
          ...state.imagingAdherence,
          data: null,
          isLoading: true,
          isError: null,
        },
      };
    case GET_DOCTORS_LIST_FAILURE:
      return {
        ...state,
        doctors: {
          data: null,
          hasMoreItems: null,
          pageNumber: null,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_DOCTORS_LIST_SUCCESS:
      return {
        ...state,
        doctors: {
          ...state.doctors,
          data:
            state.doctors.data && action.payload.pageNumber !== 1
              ? state.doctors.data.concat(action.payload.items)
              : action.payload.items,
          hasMoreItems: action.payload.hasMoreItems,
          totalItems:action.payload.count,
          isLoading: false,
          isError: null,
        },
      };

      case GET_DOCTORS_LIST_DROPDOWN_FAILURE:
      return {
        ...state,
        doctorsOptions: {
          data: null,
          hasMoreItems: null,
          pageNumber: null,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_DOCTORS_LIST_DROPDOWN_SUCCESS:
      return {
        ...state,
        doctorsOptions: {
          ...state.doctorsOptions,
          data:
            state.doctorsOptions.data && action.payload.pageNumber !== 1
              ? state.doctorsOptions.data.concat(action.payload.items)
              : action.payload.items,
          hasMoreItems: action.payload.hasMoreItems,
          isLoading: false,
          isError: null,
        },
      };
    case GET_PATIENTS_LIST_REQUEST:
      return {
        ...state,
        patients: {
          ...state.patients,
          pageNumber: action.payload.pageNumber,
          provider: action.payload.provider,
          isLoading: true,
          isError: null,
        },
      };
    case GET_PATIENTS_LIST_FAILURE:
      return {
        ...state,
        patients: {
          data: null,
          totalItems: null,
          pageNumber: null,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_PATIENTS_LIST_SUCCESS:
      return {
        ...state,
        patients: {
          ...state.patients,
          data:
            action.payload.pageNumber >= 2
              ? {
                 patients:[...state.patients.data.patients ,...action.payload.items.patients],
                 physician:{...action.payload.items.physician}
                }
              : action.payload.items,
          totalItems: action.payload.totalItems,
          isLoading: false,
          isError: null,
        },
      };
    case GET_PATIENT_REPORTS_REQUEST:
      return {
        ...state,
        patients: {
          ...state.patients,
          isError: null,
        },
      };
    case GET_PATIENT_REPORTS_FAILURE:
      return {
        ...state,
        patients: {
          ...state.patients,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_PATIENT_REPORTS_SUCCESS: {
      const reportsIndexForUpdate = state.patients.data.findIndex(
        ({ patient }) => patient.id === action.payload.results[0].patient
      );
      const itemForUpdate = state.patients.data.find(
        ({ patient }) => patient.id === action.payload.results[0].patient
      );
      const newReports = {
        ...action.payload,
        results: itemForUpdate.reports.results.concat(action.payload.results),
      };
      const updatedItem = {
        ...itemForUpdate,
        reports: {
          ...newReports,
        },
      };
      return {
        ...state,
        patients: {
          ...state.patients,
          data: state.patients.data
            .slice(0, reportsIndexForUpdate)
            .concat(
              updatedItem,
              state.patients.data.slice(reportsIndexForUpdate + 1)
            ),
          isLoading: false,
          isError: null,
        },
      };
    }
    case GET_DOCTOR_REQUEST:
      return {
        ...state,
        doctorForPatients: {
          data: null,
          isLoading: true,
          isError: null,
        },
      };
    case GET_DOCTOR_SUCCESS:
      return {
        ...state,
        doctorForPatients: {
          data: action.payload,
          isLoading: false,
          isError: null,
        },
      };
    case GET_DOCTOR_FAILURE:
      return {
        ...state,
        doctorForPatients: {
          data: null,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_PATIENT_LIST_VIEW_REQUEST:
      return {
        ...state,
        patientsList: {
          ...state.patientsList,
          isError: null,
          isLoading: true,
        },
      };
    case GET_PATIENT_LIST_VIEW_SUCCESS:
      return {
        ...state,
        patientsList: {
          ...state.patientsList,
          data:
            action.payload.pageNumber >= 2
              ? [...state.patientsList.data, ...action.payload.items]
              : action.payload.items,
          totalCount: action.payload.totalCount,
          pageNumber: action.payload.pageNumber,
          isLoading: false,
          isError: null,
        },
      };
    case GET_PATIENT_LIST_VIEW_FAILURE:
      return {
        ...state,
        patientsList: {
          data: null,
          isLoading: false,
          isError: action.payload,
        },
      };
    case SET_DOCTOR_SEARCH_VALUE:
      return {
        ...state,
        doctors: {
          ...state.doctors,
          searchValue: action.payload,
        },
      };
    case SET_DOCTOR_FOR_PATIENTS:
      return {
        ...state,
        doctorForPatients: {
          data: action.payload,
          isLoading: false,
          isError: null,
        },
      };
    case GET_IMAGING_ADHERENCE_SUCCESS:
      return {
        ...state,
        imagingAdherence: {
          ...state.imagingAdherence,
          data: action.payload,
          isLoading: false,
          isError: null,
        },
      };
      case GET_RECOMMENDATION_TIMEFRAME_REQUEST:
        return {
          ...state,
          recommendationTimeframe: {
            ...state.recommendationTimeframe,
            data: null,
            isLoading: true,
            isError: null,
          },
        };
      case GET_RECOMMENDATION_TIMEFRAME_SUCCESS:
        return {
          ...state,
          recommendationTimeframe: {
            ...state.recommendationTimeframe,
            data: action.payload,
            isLoading: false,
            isError: null,
            startDate:action.startDate,
            endDate: action.endDate
          },
        };

        case GET_RECOMMENDATION_TIMEFRAME_FAILURE:
          return {
            ...state,
            recommendationTimeframe: {
              ...state.recommendationTimeframe,
              data: null,
              isLoading: false,
              isError: action.payload,
            },
          };
    
    case RESET_PATIENTS_DATA:
      return {
        ...state,
        patients: initialData,
      };
    case GET_PREVIOUS_UPLOADS_LIST_REQUEST:
      return {
        ...state,
        previousUploads: {
          ...state.previousUploads,
          data: null,
          pageNumber: action.payload,
          isLoading: true,
          isError: null,
        },
      };
    case GET_PREVIOUS_UPLOADS_LIST_FAILURE:
      return {
        ...state,
        previousUploads: {
          data: null,
          totalItems: null,
          pageNumber: null,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_PREVIOUS_UPLOADS_LIST_SUCCESS:
      return {
        ...state,
        previousUploads: {
          ...state.previousUploads,
          data: action.payload.items,
          totalItems:
            state.previousUploads.pageNumber === 1
              ? action.payload.totalItems
              : state.previousUploads.totalItems,
          isLoading: false,
          isError: null,
        },
      };
    case GET_RADMIN_EXPORT_REQUEST:
      return {
        ...state,
        exportHistory: {
          ...state.exportHistory,
          pageNumber: null,
          isLoading: true,
          isError: null,
        },
      };
    case GET_RADMIN_EXPORT_FAILURE:
      return {
        ...state,
        exportHistory: {
          data: null,
          totalItems: null,
          pageNumber: null,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_RADMIN_EXPORT_SUCCESS:
      return {
        ...state,
        exportHistory: {
          ...state.exportHistory,
          data:
            action.payload.pageNumber >= 2
              ? [...state.exportHistory.data, ...action.payload.items]
              : action.payload.items,
          totalItems: action.payload.totalItems,
          pageNumber: action.payload.pageNumber,
          isLoading: false,
          isError: null,
        },
      };
    case GET_MESSAGE_EXPORT_REQUEST:
      return {
        ...state,
        exportMessages: {
          ...state.exportMessages,
          pageNumber: null,
          totalItems: null,
          isLoading: true,
          isError: null,
        },
      };
    case GET_MESSAGE_EXPORT_FAILURE:
      return {
        ...state,
        exportMessages: {
          data: null,
          totalItems: null,
          pageNumber: null,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_MESSAGE_EXPORT_SUCCESS:
      return {
        ...state,
        exportMessages: {
          ...state.exportMessages,
          data:
            action.payload.pageNumber >= 2
              ? [...state.exportMessages.data, ...action.payload.items]
              : action.payload.items,
          totalItems: action.payload.totalItems,
          pageNumber: action.payload.pageNumber,
          isLoading: false,
          isError: null,
        },
      };

      case GET_NOTES_EXPORT_REQUEST:
        return {
          ...state,
          exportNotes: {
            ...state.exportNotes,
            pageNumber: null,
            totalItems: null,
            isLoading: true,
            isError: null,
          },
        };
      case GET_NOTES_EXPORT_FAILURE:
        return {
          ...state,
          exportNotes: {
            data: null,
            totalItems: null,
            pageNumber: null,
            isLoading: false,
            isError: action.payload,
          },
        };
      case GET_NOTES_EXPORT_SUCCESS:
        return {
          ...state,
          exportNotes: {
            ...state.exportNotes,
            data:
              action.payload.pageNumber >= 2
                ? [...state.exportNotes.data, ...action.payload.items]
                : action.payload.items,
            totalItems: action.payload.totalItems,
            pageNumber: action.payload.pageNumber,
            isLoading: false,
            isError: null,
          },
        };


        case GET_EMAILS_EXPORT_REQUEST:
          return {
            ...state,
            exportEmails: {
              ...state.exportEmails,
              pageNumber: null,
              totalItems: null,
              isLoading: true,
              isError: null,
            },
          };
        case GET_EMAILS_EXPORT_FAILURE:
          return {
            ...state,
            exportEmails: {
              data: null,
              totalItems: null,
              pageNumber: null,
              isLoading: false,
              isError: action.payload,
            },
          };
        case GET_EMAILS_EXPORT_SUCCESS:
          return {
            ...state,
            exportEmails: {
              ...state.exportEmails,
              data:
                action.payload.pageNumber >= 2
                  ? [...state.exportEmails.data, ...action.payload.items]
                  : action.payload.items,
              totalItems: action.payload.totalItems,
              pageNumber: action.payload.pageNumber,
              isLoading: false,
              isError: null,
            },
          };

          case GET_FAXES_EXPORT_REQUEST:
            return {
              ...state,
              exportFaxes: {
                ...state.exportFaxes,
                pageNumber: null,
                totalItems: null,
                isLoading: true,
                isError: null,
              },
            };
          case GET_FAXES_EXPORT_FAILURE:
            return {
              ...state,
              exportFaxes: {
                data: null,
                totalItems: null,
                pageNumber: null,
                isLoading: false,
                isError: action.payload,
              },
            };
          case GET_FAXES_EXPORT_SUCCESS:
            return {
              ...state,
              exportFaxes: {
                ...state.exportFaxes,
                data:
                  action.payload.pageNumber >= 2
                    ? [...state.exportFaxes.data, ...action.payload.items]
                    : action.payload.items,
                totalItems: action.payload.totalItems,
                pageNumber: action.payload.pageNumber,
                isLoading: false,
                isError: null,
              },
            };
  

    case UPDATE_RECO_CONFIG_REQUEST:
      return {
        ...state,
        recoConfig: {
          ...state.recoConfig,
          isLoading: true,
          isError: null,
        },
      };
    case UPDATE_RECO_CONFIG_FAILURE:
      return {
        ...state,
        recoConfig: {
          ...state.recoConfig,
          isLoading: false,
          isError: null,
        },
      };
    case UPDATE_RECO_CONFIG_SUCCESS:
      return {
        ...state,
        recoConfig: {
          ...state.recoConfig,
          isError: null,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_RECO_CONFIG_REQUEST:
      return {
        ...state,
        recoConfig: {
          isLoading: true,
          isError: null,
        },
      };
    case GET_RECO_CONFIG_FAILURE:
      return {
        ...state,
        recoConfig: {
          isLoading: false,
          isError: null,
        },
      };
    case GET_RECO_CONFIG_SUCCESS:
      return {
        ...state,
        recoConfig: {
          isLoading: false,
          isError: null,
          data: action.payload,
        },
      };
    case GET_IMAGING_ADHERENCE_FAILURE:
      return {
        ...state,
        imagingAdherence: {
          ...state.imagingAdherence,
          data: null,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_FOLLOW_UP_LIST_REQUEST:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          pageNumber: null,
          isLoading: true,
          isError: null,
        },
      };

    case GET_FOLLOW_UP_LIST_FAILURE:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          data: null,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_FOLLOW_UP_LIST_SUCCESS:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          data:
            action.payload.pageNumber >= 2
              ? [...state.followUpList.data, ...action.payload.items]
              : action.payload.items,
          totalItems: action.payload.totalItems,
          isLoading: false,
          isError: null,
          pageNumber: action.payload.pageNumber,
        },
      };

    case UPDATE_FOLLOW_UP_LIST_REQUEST:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          isLoading: true,
        },
      };
    case UPDATE_FOLLOW_UP_LIST_FAILURE:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          isLoading: false,
          isError: action.payload,
        },
      };
    case UPDATE_FOLLOW_UP_LIST_SUCCESS:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          isLoading: false,
          data: action.payload,
        },
      };
    case DELETE_FOLLOW_UP_LIST_SUCCESS:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          data: action.payload,
          isLoading: false,
        },
      };
      case GET_ADHERENCE_OF_TODAY_REQUEST:
        return {
          ...state,
          followUpList: {
            ...state.followUpList,
            adherenceOfToday: {
             ...state.followUpList.adherenceOfToday,
              isLoading: true
            },
          },
        };
    case GET_ADHERENCE_OF_TODAY_SUCCESS:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          adherenceOfToday: {
            data: action.payload.data,
            isLoading: false,
            isError: false,
          },
        },
      };
    case GET_ADHERENCE_OF_TODAY_FAILURE:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          adherenceOfToday: {
            data: [],
            isLoading: false,
            isError: action.payload,
          },
        },
      };
      case GET_ADHERENCE_OF_DATE_REQUEST:
        return {
          ...state,
          followUpList: {
            ...state.followUpList,
            adherenceOfDate: {
             ...state.followUpList.adherenceOfDate,
              loading: true
            },
          },
        };
    case GET_ADHERENCE_OF_DATE_SUCCESS:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          adherenceOfDate: {
            data: action.payload.data,
            totalItems: action.payload.totalItems,
            loading: false
          },
        },
      };
      case GET_ADHERENCE_OF_DATE_FAILURE:
        return {
          ...state,
          followUpList: {
            ...state.followUpList,
            adherenceOfDate: {
              ...state.followUpList.adherenceOfDate,
              loading: false
            },
          },
        };
      case GET_ADHERENCE_OVER_RATE_REQUEST:
        return {
          ...state,
          followUpList: {
            ...state.followUpList,
            adherenceOverRate: {
             ...state.followUpList.adherenceOverRate,
             loading:true
            },
          },
        };
    case GET_ADHERENCE_OVER_RATE_SUCCESS:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          adherenceOverRate: {
            data: action.payload.data,
            startDate: action.payload.startDate,
            endDate: action.payload.endDate,
            loading:false
          },
        },
      };
      case GET_ADHERENCE_OVER_RATE_FAILURE:
        return {
          ...state,
          followUpList: {
            ...state.followUpList,
            adherenceOverRate: {
              ...state.followUpList.adherenceOverRate,
              loading:false
            },
          },
        };
      case GET_REVENUE_CHARGES_REQUEST:
        return {
          ...state,
          followUpList: {
            ...state.followUpList,
            revenueCharges: {
              ...state.followUpList.revenueCharges,
              loading:true
            },
          },
        };
      case GET_REVENUE_CHARGES_SUCCESS:
        return {
          ...state,
          followUpList: {
            ...state.followUpList,
            revenueCharges: {
              data: action.payload.data,
              startDate: action.payload.startDate,
              endDate: action.payload.endDate,
              loading:false
            },
          },
        };
        case GET_REVENUE_CHARGES_FAILURE:
        return {
          ...state,
          followUpList: {
            ...state.followUpList,
            revenueCharges: {
             ...state.followUpList.revenueCharges,
              loading:false
            },
          },
        };
        case GET_TOTAL_REVENUE_CHARGES_REQUEST:
          return {
            ...state,
            followUpList: {
              ...state.followUpList,
              totalRevenueCharges: {
                ...state.followUpList.totalRevenueCharges,
                loading:true
              },
            },
          };
        case GET_TOTAL_REVENUE_CHARGES_SUCCESS:
          return {
            ...state,
            followUpList: {
              ...state.followUpList,
              totalRevenueCharges: {
                data: action.payload.data,
                loading:false
              },
            },
          };
          case GET_TOTAL_REVENUE_CHARGES_FAILURE:
          return {
            ...state,
            followUpList: {
              ...state.followUpList,
              totalRevenueCharges: {
                ...state.followUpList.totalRevenueCharges,
                loading:false
              },
            },
          };
          case GET_TOTAL_REVENUE_CHARGES_AVERAGE_SUCCESS:
            return {
              ...state,
              followUpList: {
                ...state.followUpList,
                totalRevenueChargesAverage: {
                  data: action.payload.data,
                },
              },
            };
            case GET_ATTRIBUTE_REVENUE_CHARGES_REQUEST:
              return {
                ...state,
                followUpList: {
                  ...state.followUpList,
                  totalAttributeRevenueCharges: {
                    ...state.followUpList.totalAttributeRevenueCharges,
                    loading: true
                  },
                },
              };
            case GET_ATTRIBUTE_REVENUE_CHARGES_SUCCESS:
              return {
                ...state,
                followUpList: {
                  ...state.followUpList,
                  totalAttributeRevenueCharges: {
                    data: action.payload.data,
                    loading:false
                  },
                },
              };
              case GET_ATTRIBUTE_REVENUE_CHARGES_FAILURE:
                return {
                  ...state,
                  followUpList: {
                    ...state.followUpList,
                    totalAttributeRevenueCharges: {
                   ...state.followUpList.totalAttributeRevenueCharges,
                   loading:false
                    },
                  },
                };
    case GET_TOTAL_STATUSES_REQUEST:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          totalStatuses: {
            data: state.followUpList.totalStatuses.data || null,
            isLoading: true,
            isError: false,
          },
        },
      };
    case GET_TOTAL_STATUSES_FAILURE:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          totalStatuses: {
            data: null,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_TOTAL_STATUSES_SUCCESS:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          totalStatuses: {
            data: action.payload,
            isLoading: false,
            isError: null,
          },
        },
      };
    case GET_RECO_HISTORY_REQUEST:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          recoHistory: {
            data: null,
            isLoading: true,
            isError: false,
          },
        },
      };
    case GET_RECO_HISTORY_FAILURE:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          recoHistory: {
            data: null,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_RECO_HISTORY_SUCCESS:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          recoHistory: {
            data: action.payload,
            isLoading: false,
            isError: null,
          },
        },
      };
    case GET_RADIOLOGIST_LEADERBOARD_SUCCESS:
      return {
        ...state,
        radiologist: {
          ...state.radiologist,
          data:
            state.radiologist.data && action.payload.pageNumber !== 1
              ? state.radiologist.data.concat(action.payload.items)
              : action.payload.items,
          hasMoreItems: action.payload.hasMoreItems,
          totalItems:action.payload.count,
          isLoading: false,
          isError: null,
        },
      };
    case GET_RADIOLOGIST_LEADERBOARD_REQUEST:
      return {
        ...state,
        radiologist: {
          ...state.radiologist,
          isLoading: action.payload === 1 ? true : false,
          isError: null,
          pageNumber: action.payload,
        },
      };
    case GET_RADIOLOGIST_LEADERBOARD_REQUEST_FAILURE:
      return {
        ...state,
        radiologist: {
          ...state.radiologist,
          data: null,
          isLoading: false,
          isError: action.payload,
        },
      };
    case SET_RADIOLOGIST_SEARCH_VALUE:
      return {
        ...state,
        radiologist: {
          ...state.radiologist,
          searchValue: action.payload,
        },
      };
    case GET_RADIOLOGIST_LIST_REQUEST:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          radiologists: {
            data: null,
            isLoading: true,
            isError: null,
          },
        },
      };
    case GET_RADIOLOGIST_LIST_SUCCESS:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          radiologists: {
            ...state.followUpList.radiologists,
            data: action.payload,
            isLoading: false,
            isError: null,
          },
        },
      };
    case GET_RADIOLOGIST_LIST_FAILURE:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          radiologists: {
            ...state.followUpList.radiologists,
            data: null,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_DROPDOWN_RADIOLOGISTS_SUCCESS:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          dropdownRadiologists: {
            ...state.followUpList.dropdownRadiologists,
            data: action.payload,
            isLoading: false,
            isError: null,
          },
        },
      };
    case GET_RADIOLOGIST_LIST_FAILURE:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          dropdownRadiologists: {
            ...state.followUpList.dropdownRadiologists,
            data: null,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_DROPDOWN_REFERRERS_REQUEST:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          dropdownReferrers: {
            ...state.followUpList.dropdownReferrers,
            data: action.payload,
            isLoading: false,
            isError: null,
          },
        },
      };
    case GET_DROPDOWN_REFERRERS_SUCCESS:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          dropdownReferrers: {
            ...state.followUpList.dropdownReferrers,
            data:  state.followUpList.dropdownReferrers.data?.length>0 ?[...state.followUpList.dropdownReferrers.data,...action.payload] : action.payload,
            isLoading: false,
            isError: action.payload,
          },
        },
      };

      case GET_COHORTS_DROPDOWN_REQUEST:
        return {
          ...state,
          followUpList: {
            ...state.followUpList,
            dropdownCohorts: {
              ...state.followUpList.dropdownCohorts,
              data: action.payload,
              isLoading: false,
              isError: null,
            },
          },
        };
      case GET_COHORTS_DROPDOWN_SUCCESS:
        return {
          ...state,
          followUpList: {
            ...state.followUpList,
            dropdownCohorts: {
              ...state.followUpList.dropdownCohorts,
              data:  state.followUpList.dropdownCohorts.data?.length>0 ?[...state.followUpList.dropdownCohorts.data,...action.payload] : action.payload,
              isLoading: false,
              isError: action.payload,
            },
          },
        };

        case GET_COHORTS_DROPDOWN_FAILURE:
          return {
            ...state,
            followUpList: {
              ...state.followUpList,
                dropdownCohorts: {
                  ...state.followUpList.dropdownCohorts,
                  isLoading: false,
                  isError: action.payload,
                },
              
            },
          };

    case GET_MODALITY_LIST_REQUEST:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          modalities: {
            data: null,
            isLoading: true,
            isError: null,
          },
        },
      };
    case GET_MODALITY_LIST_SUCCESS:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          modalities: {
            ...state.followUpList.modalities,
            data: action.payload,
            isLoading: false,
            isError: null,
          },
        },
      };
    case GET_MODALITY_LIST_FAILURE:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          modalities: {
            ...state.followUpList.modalities,
            data: null,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_ANATOMY_LIST_REQUEST:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          anatomies: {
            data: null,
            isLoading: true,
            isError: null,
          },
        },
      };
    case GET_ANATOMY_LIST_SUCCESS:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          anatomies: {
            ...state.followUpList.anatomies,
            data: action.payload,
            isLoading: false,
            isError: null,
          },
        },
      };
    case GET_ANATOMY_LIST_FAILURE:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          anatomies: {
            ...state.followUpList.anatomies,
            data: null,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_TIMEFRAME_STATUS_LIST_REQUEST:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          timeframeStatuses: {
            data: null,
            isLoading: true,
            isError: null,
          },
        },
      };
    case GET_TIMEFRAME_STATUS_LIST_SUCCESS:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          timeframeStatuses: {
            ...state.followUpList.timeframeStatuses,
            data: action.payload,
            isLoading: false,
            isError: null,
          },
        },
      };
    case GET_TIMEFRAME_STATUS_LIST_FAILURE:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          timeframeStatuses: {
            ...state.followUpList.timeframeStatuses,
            data: null,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case CHANGE_ACTION_STATUS_REQUEST:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          actionStatus: {
            ...state.followUpList.actionStatus,
            isLoading: true,
          },
        },
      };
    case CHANGE_ACTION_STATUS_FAILURE:
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          actionStatus: {
            isError: action.payload,
            isLoading: false,
          },
        },
      };
    case CHANGE_ACTION_STATUS_SUCCESS:
      const idx = state.followUpList.data.findIndex(
        ({ id }) => id === action.payload.id
      );
      return {
        ...state,
        followUpList: {
          ...state.followUpList,
          data: state.followUpList.data
            .slice(0, idx)
            .concat(state.followUpList.data.slice(idx + 1)),
        },
      };
    case GET_MODALITIES_REQUEST:
      return {
        ...state,
        modalities: {
          data: null,
          isLoading: true,
          isError: null,
        },
      };
    case GET_MODALITIES_FAILURE:
      return {
        ...state,
        modalities: {
          data: null,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_MODALITIES_SUCCESS:
      return {
        ...state,
        modalities: {
          data: action.payload,
          isLoading: false,
          isError: null,
        },
      };
    case GET_RECO_MODALITIES_REQUEST:
      return {
        ...state,
        recoModalities: {
          data: null,
          isLoading: true,
          isError: null,
        },
      };
    case GET_RECO_MODALITIES_FAILURE:
      return {
        ...state,
        recoModalities: {
          data: null,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_RECO_MODALITIES_SUCCESS:
      return {
        ...state,
        recoModalities: {
          data: action.payload,
          isLoading: false,
          isError: null,
        },
      };
    case GET_RECO_ANATOMIES_REQUEST:
      return {
        ...state,
        recoAnatomies: {
          data: null,
          isLoading: true,
          isError: null,
        },
      };
    case GET_RECO_ANATOMIES_FAILURE:
      return {
        ...state,
        recoAnatomies: {
          data: null,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_RECO_ANATOMIES_SUCCESS:
      return {
        ...state,
        recoAnatomies: {
          data: action.payload,
          isLoading: false,
          isError: null,
        },
      };

      case GET_RECO_ANATOMIES_AND_MODALITIES_REQUEST:
        return {
          ...state,
          recoAnatomiesAndModalities: {
            data: null,
            isLoading: true,
            isError: null,
          },
        };
      case GET_RECO_ANATOMIES_AND_MODALITIES_FAILURE:
        return {
          ...state,
          recoAnatomiesAndModalities: {
            data: null,
            isLoading: false,
            isError: action.payload,
          },
        };
      case GET_RECO_ANATOMIES_AND_MODALITIES_SUCCESS:
        return {
          ...state,
          recoAnatomiesAndModalities: {
            data: action.payload,
            isLoading: false,
            isError: null,
          },
        };

    case GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_REQUEST:
      return {
        ...state,
        imagingAdherence: {
          ...state.imagingAdherence,
          modalityOptions: {
            data: null,
            isLoading: true,
            isError: false,
          },
        },
      };
    case GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_FAILURE:
      return {
        ...state,
        imagingAdherence: {
          ...state.imagingAdherence,
          modalityOptions: {
            data: null,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_IMAGING_ADHERENCE_MODALITY_OPTIONS_SUCCESS:
      return {
        ...state,
        imagingAdherence: {
          ...state.imagingAdherence,
          modalityOptions: {
            data: action.payload,
            isLoading: false,
            isError: null,
          },
        },
      };
    case GET_ADHERENCE_FUNNEL_REQUEST:
      return {
        ...state,
        funnelAdherence: {
          data: null,
          isLoading: true,
          isError: null,
        },
      };
    case GET_ADHERENCE_FUNNEL_SUCCESS:
      return {
        ...state,
        funnelAdherence: {
          data: action.payload,
          isLoading: false,
          isError: null,
        },
      };
    case GET_ADHERENCE_FUNNEL_FAILURE:
      return {
        ...state,
        funnelAdherence: {
          data: null,
          isLoading: false,
          isError: true,
        },
      };
    case GET_EXPORT_REPORT_REQUEST:
      return {
        ...state,
        exportReport: {
          ...state.exportReport,
          data: null,
          isLoading: true,
          isError: null,
        },
      };
    case GET_EXPORT_REPORT_SUCCESS:
      return {
        ...state,
        exportReport: {
          ...state.exportReport,
          data: action.payload.csv,
          isLoading: false,
          isError: null,
        },
      };
    case GET_EXPORT_REPORT_FAILURE:
      return {
        ...state,
        exportReport: {
          ...state.exportReport,
          data: null,
          totalItems: null,
          pageNumber: null,
          isLoading: false,
          isError: action.payload,
        },
      };
    case GET_PATIENT_HISTORY_REQUEST:
      return {
        ...state,
        messages: {
          ...state.messages,
          patientHistory: {
            data: state.messages.patientHistory?.data || null,
            isLoading: true,
            isError: null,
          },
        },
      };
    case GET_PATIENT_HISTORY_SUCCESS: {
      const isSamePatient =
        state.messages.patientHistory.data?.id === action.payload.id;
      const newData = !isSamePatient
        ? action.payload
        : {
            ...action.payload,
            data: [
              ...state.messages.patientHistory?.data?.data,
              ...action.payload.data,
            ],
          };
      return {
        ...state,
        messages: {
          ...state.messages,
          patientHistory: {
            data: newData,
            isLoading: false,
            isError: null,
          },
        },
      };
    }
    case GET_PATIENT_HISTORY_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          patientHistory: {
            data: null,
            isLoading: false,
            isError: action.payload,
          },
        },
      };

    case GET_RECENT_INCOMING_MESSAGES_REQUEST:
      return {
        ...state,
        messages: {
          ...state.messages,
          recentMessages: {
            ...state.messages.recentMessages,
            data: action.payload.page ===1 ? null :  state.messages.recentMessages.data,
            isLoading: true,
            isError: null,
            totalItems:action.payload.page ===1 ? null: state.messages.recentMessages.totalItems
          },
          chatMessages:{
            ...state.messages.chatMessages,
           data: action.payload.page ===1 ? null :  state.messages.chatMessages.data,
           totalItems:action.payload.page ===1?null : state.messages.chatMessages.totalItems
          }
        },
      };
    case GET_RECENT_INCOMING_MESSAGES_SUCCESS: {
      return {
        ...state,
        messages: {
          ...state.messages,
          recentMessages: {
            data:  action.payload.page >1
              ? state?.messages?.recentMessages?.data?.concat(action.payload.data.results)
              : action.payload.data.results,
            isLoading: false,
            isError: null,
            totalItems: action.payload.data.count
          },
        },
      };
    }
    case GET_RECENT_INCOMING_MESSAGES_UPDATE_SUCCESS: {
      return {
        ...state,
        messages: {
          ...state.messages,
          recentMessages: {
            data: action.payload.data,
            totalItems: action.payload.totalItems,
            isLoading: false,
            isError: null,
          },
        },
      };
    }
    case GET_FOLLOWUP_LIST_FROM_MESSAGES_SUCCESS: {
      return {
        ...state,
        messages: {
          ...state.messages,
          followUpList: {
            data: action.payload,
            isLoading: false,
          },
        },
      };
    }
    case GET_FOLLOWUP_LIST_FROM_MESSAGES_REQUEST: {
      return {
        ...state,
        messages: {
          ...state.messages,
          followUpList: {
            ...state.messages.followUpList,
            isLoading: true,
          },
        },
      };
    }
    case GET_RECENT_INCOMING_MESSAGES_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          recentMessages: {
            data: null,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_OUTGOING_MESSAGES_REQUEST:
      return {
        ...state,
        messages: {
          ...state.messages,
          outgoingMessages: {
            ...state.messages.outgoingMessages,
            isLoading: true,
          },
          analytics:{
            ...state.messages.analytics,
            isLoading: true
          }
        },
      };
    case GET_OUTGOING_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          outgoingMessages: {
            data:
              action.payload.page >= 2
                ? [
                    ...state.messages.outgoingMessages.data,
                    ...action.payload.data,
                  ]
                : action.payload.data,
            isLoading: false,
            totalItems: action.payload.totalItems,
            isError: null,
          },
          analytics: {
            ...state.messages.analytics,
            counts: action.payload.analyticsCounts,
            data: action.payload.analyticsData,
            isLoading: false,
          },
        },
      };
    case GET_OUTGOING_MESSAGES_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          outgoingMessages: {
            ...state.messages.outgoingMessages,
            isLoading: false,
            isError: action.payload,
          },
          analytics:{
            ...state.messages.analytics,
            isLoading: false,
          }
        },
      };
      case GET_OUTGOING_EMAIL_REQUEST:
        return {
          ...state,
          emails: {
            ...state.emails,
            outgoingMessages: {
              ...state.emails.outgoingMessages,
              isLoading: true,
            },
            analytics:{
              ...state.emails.analytics,
              isLoading:true
            }
          },
        };
      case GET_OUTGOING_EMAIL_SUCCESS:
        return {
          ...state,
          emails: {
            ...state.emails,
            outgoingMessages: {
              data:
                action.payload.page >= 2
                  ? [
                      ...state.emails.outgoingMessages.data,
                      ...action.payload.data,
                    ]
                  : action.payload.data,
              isLoading: false,
              totalItems: action.payload.totalItems,
              isError: null,
            },
            analytics: {
              ...state.emails.analytics,
              counts: action.payload.analyticsCounts,
              data: action.payload.analyticsData,
              isLoading: false,
            },
          },
        };

        case GET_OUTGOING_EMAIL_FAILURE:
          return {
            ...state,
            emails: {
              ...state.emails,
              outgoingMessages: {
                ...state.emails.outgoingMessages,
                isLoading: false,
                isError: action.payload,
              },
              analytics:{
                ...state.emails.analytics,
                isLoading:false
              }
            },
          };
   
          
      case GET_OUTGOING_FAXES_REQUEST:
        return {
          ...state,
          faxes: {
            ...state.faxes,
            outgoingMessages: {
              ...state.faxes.outgoingMessages,
              isLoading: true,
            },
            analytics:{
              ...state.faxes.analytics,
              isLoading:true
            }
          },
        };
      case GET_OUTGOING_FAXES_SUCCESS:
        return {
          ...state,
          faxes: {
            ...state.faxes,
            outgoingMessages: {
              data:
                action.payload.page >= 2
                  ? [
                      ...state.faxes.outgoingMessages.data,
                      ...action.payload.data,
                    ]
                  : action.payload.data,
              isLoading: false,
              totalItems: action.payload.totalItems,
              isError: null,
            },
            analytics: {
              ...state.faxes.analytics,
              counts: action.payload.analyticsCounts,
              data: action.payload.analyticsData,
              isLoading: false,
            },
          },
        };

        case GET_OUTGOING_FAXES_FAILURE:
          return {
            ...state,
            faxes: {
              ...state.faxes,
              outgoingMessages: {
                ...state.faxes.outgoingMessages,
                isLoading: false,
                isError: action.payload,
              },
              analytics:{
                ...state.faxes.analytics,
                isLoading:false
              }
            },
          };
   

      case SET_USERS_MESSAGES_INFO: {
      const isNotEmptyAndNew =
        state.messages?.usersInfo?.data &&
        !state.messages?.usersInfo?.data.some(
          ({ id }) => action.payload[0]?.id === id
        );
      return {
        ...state,
        messages: {
          ...state.messages,
          usersInfo: {
            data: isNotEmptyAndNew
              ? state.messages.usersInfo.data.concat(action.payload)
              : action.payload,
          },
        },
      };
    }
    case GET_CHAT_MESSAGES_REQUEST:
      return {
        ...state,
        messages: {
          ...state.messages,
          chatMessages: {
            ...state.messages.chatMessages,
            data:  action.payload.page ===1? null :    state.messages.chatMessages.data,
            isLoading: true,
            totalItems: action.payload.page ===1? null: state.messages.chatMessages.totalItems
          },
        },
      };
    case GET_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          chatMessages: {
            ...state.messages.chatMessages,
            data:  action.payload.page >1
            ? state?.messages?.chatMessages?.data.concat( action.payload.data.results)
            : action.payload.data.results,
            isLoading: false,
            isError: null,
            totalItems: action.payload.data.count
          },
        },
      };
    case GET_CHAT_MESSAGES_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          messages: {
            chatMessages: {
              ...state.messages.chatMessages,
              isLoading: false,
              isError: action.payload,
            },
          },
        },
      };
   
    case GET_MORE_CHAT_MESSAGES_REQUEST:
      return {
        ...state,
        messages: {
          ...state.messages,
          recentMessages: {
            ...state.messages.recentMessages,
            isLoading: true,
          },
        },
      };
    case GET_MORE_CHAT_MESSAGES_SUCCESS:
      const threadIndex = state.messages.recentMessages.data?.findIndex(
        (item) =>
          item.patient_phone_number === action.payload.patientPhoneNumber
      );

      const recentMsg = state.messages.recentMessages.data.map(itm=>itm.recentMsg);

      const threadWithNewMessages = {
        ...action.payload.data,
        patient_phone_number: action.payload.patientPhoneNumber,
        results: [
          ...action.payload.data.results,
          ...state.messages.recentMessages.data[threadIndex].results,
        ],
        recentMsg: recentMsg[threadIndex]
      };
      const newRecentMessages = [...state.messages.recentMessages.data];
      newRecentMessages.splice(threadIndex, 1, threadWithNewMessages);
      return {
        ...state,
        messages: {
          ...state.messages,
          recentMessages: {
            ...state.messages.recentMessages,
            data: newRecentMessages,
            isLoading: false,
            isError: null,
          },
        },
      };
    case GET_MORE_CHAT_MESSAGES_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          messages: {
            recentMessages: {
              ...state.messages.recentMessages,
              isLoading: false,
              isError: action.payload,
            },
          },
        },
      };
    case SET_USERS_PHONE_NUMBERS:
      return {
        ...state,
        messages: {
          ...state.messages,
          phoneNumbers: {
            data: action.payload,
          },
        },
      };
    case CREATE_OUTGOING_MESSAGE_REQUEST:
      return {
        ...state,
        messages: {
          ...state.messages,
          recentMessages: {
            ...state.messages.recentMessages,
            isLoading: true,
          },
        },
      };
    case CREATE_OUTGOING_MESSAGE_SUCCESS: {
      return {
        ...state,
        messages: {
          ...state.messages,
          chatMessages: {
            totalItems: state.messages.chatMessages.totalItems + 1 ,
            data: action.payload.onLastPage? [action.payload.message, ...state.messages.chatMessages.data] :  [ action.payload.message, ...state.messages.chatMessages.data.slice(0,9) ],
            isLoading: false,
            isError: null,
          },
        },
      };
    }
    case CREATE_OUTGOING_MESSAGE_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          recentMessages: {
           ...state.messages.recentMessages,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case CREATE_OUTGOING_MESSAGE_REQUEST_FROM_DIALOG:
      return {
        ...state,
        messages: {
          ...state.messages,
          outgoingMessageFromDialog: {
            ...state.messages.outgoingMessageFromDialog,
            isLoading: true,
          },
        },
      };
    case CREATE_OUTGOING_MESSAGE_SUCCESS_FROM_DIALOG:
      return {
        ...state,
        messages: {
          ...state.messages,
          outgoingMessageFromDialog: {
            ...state.messages.outgoingMessageFromDialog,
            isLoading: false,
          },
        },
      };
    case CREATE_OUTGOING_MESSAGE_FAILURE_FROM_DIALOG:
      return {
        ...state,
        messages: {
          ...state.messages,
          outgoingMessageFromDialog: {
            ...state.messages.outgoingMessageFromDialog,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case GET_TWILIO_CONFIG_REQUEST:
      return {
        ...state,
        messages: {
          ...state.messages,
          twilioConfig: {
            data: null,
            isLoading: true,
            isError: null,
          },
        },
      };
    case GET_TWILIO_CONFIG_SUCCESS: {
      return {
        ...state,
        messages: {
          ...state.messages,
          twilioConfig: {
            data: action.payload,
            isLoading: false,
            isError: null,
          },
        },
      };
    }
    case GET_TWILIO_CONFIG_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          twilioConfig: {
            data: null,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
      case GET_FINDINGS_BOI_REQUEST:
        return {
          ...state,
          followUpList: {
            ...state.followUpList,
            findingsBoi: {
              data: null,
              isLoading: true,
              isError: null,
            },
          },
        };
      case GET_FINDINGS_BOI_SUCCESS: {
        return {
          ...state,
          followUpList: {
            ...state.followUpList,
            findingsBoi: {
              data: action.payload,
              isLoading: false,
              isError: null,
            },
          },
        };
      }
      case GET_FINDINGS_BOI_FAILURE:
        return {
          ...state,
          followUpList: {
            ...state.followUpList,
            findingsBoi: {
              data: null,
              isLoading: false,
              isError: action.payload,
            },
          },
        };
    case UPDATE_TWILIO_CONFIG_REQUEST:
      return {
        ...state,
        messages: {
          ...state.messages,
          twilioConfig: {
            data: action.payload,
            isLoading: false,
            isError: null,
          },
        },
      };
    case UPDATE_TWILIO_CONFIG_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          twilioConfig: {
            data: null,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case UPDATE_TWILIO_CONFIG_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          twilioConfig: {
            data: action.payload,
            isLoading: false,
            isError: null,
          },
        },
      };
    case GET_SMS_RESPONSE_REQUEST:
      return {
        ...state,
        messages: {
          ...state.messages,
          smsResponse: {
            data: null,
            isLoading: true,
            isError: null,
          },
        },
      };
    case GET_SMS_RESPONSE_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          smsResponse: {
            data: action.payload,
            isLoading: false,
            isError: null,
          },
        },
      };
    case GET_SMS_RESPONSE_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          smsResponse: {
            data: null,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case UPDATE_SMS_RESPONSE_REQUEST:
      return {
        ...state,
        messages: {
          ...state.messages,
          smsResponse: {
            data: action.payload,
            isLoading: true,
            isError: null,
          },
        },
      };
    case UPDATE_SMS_RESPONSE_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          smsResponse: {
            data: null,
            isLoading: false,
            isError: action.payload,
          },
        },
      };
    case UPDATE_SMS_RESPONSE_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          smsResponse: {
            data: action.payload,
            isLoading: false,
            isError: null,
          },
        },
      };
    case RESET_STATE:
      return {
        ...initialState,
      };
    case SET_ADDITIONAL_SEGMENT_CONDITIONS:
      return {
        ...state,
        timeTriggerForm: {
          ...state.timeTriggerForm,
          additionalSegmentConditions: action.payload
        }
      }
    case SET_SEGMENT_CONDITION:
      return {
        ...state,
        timeTriggerForm: {
          ...state.timeTriggerForm,
          segment: action.payload
        }
      }
    case SET_TIME_TRIGGER:
      return {
        ...state,
        timeTriggerForm: {
          ...state.timeTriggerForm,
          timeTrigger: action.payload
        }
      }
    case RESET_TIME_TRIGGER:
      return {
        ...state,
        timeTriggerForm: {
          ...initialTimeTriggerForm
        }
      }
    case GET_INVITED_USERS_REQUEST:
      return {
      ...state,
      invitedUsers: {
      ...state.invitedUsers,
      pageNumber: null,
      isLoading: true,
      isError: null,
      },
      };
    case GET_INVITED_USERS_FAILURE:
      return {
      ...state,
      invitedUsers: {
      data: null,
      totalItems: null,
      pageNumber: null,
      isLoading: false,
      isError: action.payload,
     },
     };
    case GET_INVITED_USERS_SUCCESS:
     return {
      ...state,
      invitedUsers: {
      ...state.invitedUsers,
      data:
        action.payload.pageNumber >= 2
          ? [...state.invitedUsers.data, ...action.payload.items]
          : action.payload.items,
      totalItems: action.payload.totalItems,
      pageNumber: action.payload.pageNumber,
      isLoading: false,
      isError: null,
    },
  }
    case GET_ROLES_PERMISSIONS_REQUEST:
      return {
      ...state,
      rolePermissions: {
      ...state.rolePermissions,
      pageNumber: null,
      isLoading: true,
      isError: null,
      },
      };
    case GET_ROLES_PERMISSIONS_FAILURE:
      return {
      ...state,
      rolePermissions: {
      data: null,
      totalItems: null,
      pageNumber: null,
      isLoading: false,
      isError: action.payload,
     },
     };
    case GET_ROLES_PERMISSIONS_SUCCESS:
     return {
      ...state,
      rolePermissions: {
      ...state.rolePermissions,
      data:action.payload.items,
      totalItems: action.payload.totalItems,
      isLoading: false,
      isError: null,
    },
    };

      case GET_SEARCH_REPORT_TEXT_REQUEST:
        return {
          ...state,
          searchReport: {
            ...state.searchReport,
            pageNumber: null,
            isLoading: true,
            isError: null,
          },
        };
      case GET_SEARCH_REPORT_TEXT_FAILURE:
        return {
          ...state,
          searchReport: {
            data: null,
            totalItems: null,
            pageNumber: null,
            isLoading: false,
            isError: action.payload,
          },
        };
      case GET_SEARCH_REPORT_TEXT_SUCCESS:
        return {
          ...state,
          searchReport: {
            ...state.searchReport,
            data:
              action.payload.pageNumber >= 2
                ? [...state.searchReport.data, ...action.payload.items]
                : action.payload.items,
            totalItems: action.payload.totalItems,
            pageNumber: action.payload.pageNumber,
            isLoading: false,
            isError: null,
          },
        };
        case GET_ACTIVITY_LOGS_REQUEST:
          return {
            ...state,
            activityLogs: {
              ...state.activityLogs,
              pageNumber: null,
              isLoading: true,
              isError: null,
            },
          };
        case GET_ACTIVITY_LOGS_FAILURE:
          return {
            ...state,
            activityLogs: {
              data: null,
              totalItems: null,
              pageNumber: null,
              isLoading: false,
              isError: action.payload,
            },
          };
        case GET_ACTIVITY_LOGS_SUCCESS:
          return {
            ...state,
            activityLogs: {
              ...state.activityLogs,
              data:
                action.payload.pageNumber >= 2
                  ? [...state.activityLogs.data, ...action.payload.items]
                  : action.payload.items,
              totalItems: action.payload.totalItems,
              pageNumber: action.payload.pageNumber,
              isLoading: false,
              isError: null,
            },
          };
          case GET_OUTBOUND_INTEGRATIONS_REQUEST:
            return {
              ...state,
              outboundIntegrations: {
                ...state.outboundIntegrations,
                pageNumber: null,
                isLoading: true,
                isError: null,
              },
            };
          case GET_OUTBOUND_INTEGRATIONS_FAILURE:
            return {
              ...state,
              outboundIntegrations: {
                data: null,
                totalItems: null,
                pageNumber: null,
                isLoading: false,
                isError: action.payload,
              },
            };
          case GET_OUTBOUND_INTEGRATIONS_SUCCESS:
            return {
              ...state,
              outboundIntegrations: {
                ...state.outboundIntegrations,
                data:
                  action.payload.pageNumber >= 2
                    ? [...state.outboundIntegrations.data, ...action.payload.items]
                    : action.payload.items,
                totalItems: action.payload.totalItems,
                pageNumber: action.payload.pageNumber,
                isLoading: false,
                isError: null,
              },
            };
    case GET_KEY_METRICS_REQUEST:
        return {
        ...state,
        keyMetrics: {
          data: null,
          isLoading: true,
          isError: null,
        },
      };
    case GET_KEY_METRICS_SUCCESS:
      return {
        ...state,
        keyMetrics: {
          data: action.payload,
          isLoading: false,
          isError: null,
        },
      };
    case GET_KEY_METRICS_FAILURE:
      return {
        ...state,
        keyMetrics: {
          data: null,
          isLoading: false,
          isError: true,
        },
      }
      case GET_API_TOKEN_GENERATION_REQUEST:
        return {
          ...state,
          apiTokenGeneration: {
            ...state.apiTokenGeneration,
            pageNumber: null,
            isLoading: true,
            isError: null,
          },
        };
      case GET_API_TOKEN_GENERATION_FAILURE:
        return {
          ...state,
          apiTokenGeneration: {
            data: null,
            totalItems: null,
            pageNumber: null,
            isLoading: false,
            isError: action.payload,
          },
        };
      case GET_API_TOKEN_GENERATION_SUCCESS:
        return {
          ...state,
          apiTokenGeneration: {
            ...state.apiTokenGeneration,
            data:
              action.payload.items,
            totalItems: action.payload.totalItems,
            pageNumber: action.payload.pageNumber,
            isLoading: false,
            isError: null,
          },
        };
        case GET_CHANGELOG_REQUEST:
          return {
            ...state,
            changeLogs: {
              ...state.changeLogs,
              isLoading: true,
              isError: null,
              data:null
            },
          };
        case GET_CHANGELOG_FAILED:
          return {
            ...state,
            changeLogs: {
              data: null,
              isLoading: false,
              isError: action.payload,
            },
          };
        case GET_CHANGELOG_SUCCESS:
          return {
            ...state,
            changeLogs: {
              ...state.changeLogs,
              data:action.payload.items,
              isLoading: false,
              isError: null,
            },
          };
          case GET_MARKETING_ATTRIBUTION_REQUEST:
            return {
            ...state,
            marketingAttribution: {
              data: null,
              isLoading: true,
              isError: null,
            },
          };
        case GET_MARKETING_ATTRIBUTION_SUCCESS:
          return {
            ...state,
            marketingAttribution: {
              data: action.payload,
              isLoading: false,
              isError: null,
            },
          };
        case GET_MARKETING_ATTRIBUTION_FAILURE:
          return {
            ...state,
            marketingAttribution: {
              data: null,
              isLoading: false,
              isError: true,
            },
          }
          case GET_PROVIDERS_LIST_REQUEST:
            return {
            ...state,
            providersList: {
              data: null,
              isLoading: true,
              isError: null,
            },
          };
        case GET_PROVIDERS_LIST_SUCCESS:
          return {
            ...state,
            providersList: {
              data: action.payload,
              isLoading: false,
              isError: null,
            },
          };
        case GET_PROVIDERS_LIST_FAILURE:
          return {
            ...state,
            providersList: {
              data: null,
              isLoading: false,
              isError: true,
            },
          }
          case UPDATE_SEARCH_REPORT_BY_RECOMMENDATION_SUCCESS:
            return {
              ...state,
              searchReport: {
                ...state.searchReport,
                data: action.payload.data,
             },
            };
            case GET_COMMUNICATION_STAGE_OPTIONS_REQUEST:
              return {
              ...state,
              cohortsRecommendations: {
                ...state.cohortsRecommendations,
                communicationOptions:{
                data: null,
                isLoading: true,
                isError: null,
                }
              },
            };
          case GET_COMMUNICATION_STAGE_OPTIONS_SUCCESS:
            return {
              ...state,
              cohortsRecommendations: {
                ...state.cohortsRecommendations,
                communicationOptions:{
                data: action.payload,
                isLoading: false,
                isError: null,
                }
              },
            };
          case GET_COMMUNICATION_STAGE_OPTIONS_FAILURE:
            return {
              ...state,
              cohortsRecommendations: {
                ...state.cohortsRecommendations,
                communicationOptions:{
                data: null,
                isLoading: false,
                isError: true,
                }
              },
            }
            case UPDATE_COMMUNICATION_STAGE_OPTIONS_SUCCESS:
              return {
                ...state,
                cohortsRecommendations: {
                  ...state.cohortsRecommendations,
                  communicationOptions:{
                  data: action.payload,
                  isLoading: false,
                  isError: null,
                  }
                },
              };
              
            case GET_OPTIONS_LIST_REQUEST:
              return {
              ...state,
              optionsList: {
                data: null,
                isLoading: true,
                isError: null,
              },
            };
          case GET_OPTIONS_LIST_SUCCESS:
            return {
              ...state,
              optionsList: {
                data: action.payload,
                isLoading: false,
                isError: null,
              },
            };
          case GET_OPTIONS_LIST_FAILURE:
            return {
              ...state,
              optionsList: {
                data: null,
                isLoading: false,
                isError: true,
              },
            }
            case UPDATE_OPTIONS_LIST_SUCCESS:
              return {
                ...state,
                optionsList: {
                  data: {
                    results: action.payload
                  },
                  isLoading: false,
                  isError: null,
                },
              };
            case GET_ATTRIBUTES_LIST_REQUEST:
              return {
              ...state,
              attributesList: {
                data: null,
                isLoading: true,
                isError: null,
              },
            };
          case GET_ATTRIBUTES_LIST_SUCCESS:
            return {
              ...state,
              attributesList: {
                data: action.payload,
                isLoading: false,
                isError: null,
              },
            };
          case GET_ATTRIBUTES_LIST_FAILURE:
            return {
              ...state,
              attributesList: {
                data: null,
                isLoading: false,
                isError: true,
              },
            }
            case GET_LOCATIONS_OPTIONS_LIST_REQUEST:
              return {
              ...state,
               locationsOptionsList: {
                data: null,
                isLoading: true,
                isError: null,
              },
            };
          case GET_LOCATIONS_OPTIONS_LIST_SUCCESS:
            return {
              ...state,
              locationsOptionsList: {
                data: action.payload,
                isLoading: false,
                isError: null,
              },
            };
          case GET_LOCATIONS_OPTIONS_LIST_FAILURE:
            return {
              ...state,
              locationsOptionsList: {
                data: null,
                isLoading: false,
                isError: true,
              },
            }
            case GET_RADIOLOGISTS_OPTIONS_LIST_REQUEST:
              return {
              ...state,
                radiologistsOptionsList: {
                data: null,
                isLoading: true,
                isError: null,
              },
            };
          case GET_RADIOLOGISTS_OPTIONS_LIST_SUCCESS:
            return {
              ...state,
              radiologistsOptionsList: {
                data: action.payload,
                isLoading: false,
                isError: null,
              },
            };
          case GET_RADIOLOGISTS_OPTIONS_LIST_FAILURE:
            return {
              ...state,
              radiologistsOptionsList: {
                data: null,
                isLoading: false,
                isError: true,
              },
            }
  
            case GET_USERS_OPTIONS_LIST_REQUEST:
              return {
              ...state,
                 usersOptionsList: {
                data: null,
                isLoading: true,
                isError: null,
              },
            };
          case GET_USERS_OPTIONS_LIST_SUCCESS:
            return {
              ...state,
               usersOptionsList: {
                data: action.payload,
                isLoading: false,
                isError: null,
              },
            };
          case GET_USERS_OPTIONS_LIST_FAILURE:
            return {
              ...state,
               usersOptionsList: {
                data: null,
                isLoading: false,
                isError: true,
              },
            }

    default:
      return state;
  }
};
