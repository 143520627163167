import React, { Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";


import RadAdminDashboard from "../pages/RadAdmin/Dashboard/Dashboard";
import { DocListWithFeature } from "../pages/RadAdmin/DoctorList/DoctorList";
import PatientList from "../pages/RadAdmin/PatientList/PatientList";
import ConnectedCampaigns, {
  CampaignPanelWithFeature,
} from "../pages/RadAdmin/Campaigns/Campaigns";
import PatientViewWithFeature from "../pages/RadAdmin/PatientList/PatientListView";
import CreateCampaign from "../pages/RadAdmin/Campaigns/Create/CreateCampaign";
import Integrations from "../pages/RadAdmin/Dashboard/Integrations";
import ExportHistory from "../pages/RadAdmin/Exports/ExportHistory";
import RecoConfigWithFeature from "../pages/RadAdmin/RecoConfigurationList/RecoConfig";
import PatientImport from "../pages/RadAdmin/Dashboard/PatientImport";
import ImportHistory from "../pages/RadAdmin/Dashboard/ImportHistory";
import Overview from "../pages/RadAdmin/Overview";
import PreviousUploadsList from "../pages/RadAdmin/PreviousUploadsList";
import { Alert, AlertContainer } from "react-bs-notifier";
import { hideAlert } from "../actions/radminUploads";
import FollowUpListWithFeature from "../pages/RadAdmin/FollowUpList";
import { MessagesWithFeature } from "../pages/RadAdmin/Messages/Messages";
import MessageDeliveries from "../pages/RadAdmin/Messages/MessageDeliveries";
import InboundMessageDeliveries from "../pages/RadAdmin/Messages/MessageDeliveriesIncoming";
import CohortList from "../pages/RadAdmin/Cohorts";
import ContactSequence from "../pages/RadAdmin/Campaigns/Create/ContactSequence";
import CreateCohort from "../pages/RadAdmin/Cohorts/Create";
import CohortPatientView from "../pages/RadAdmin/Cohorts/ViewPatients";
import PatientDetails from "../pages/RadAdmin/PatientList/PatientDetails";
import Settings from "../pages/RadAdmin/Settings";
import Notes from "../pages/RadAdmin/Notes";
import Support from "../pages/RadAdmin/Support";
import CustomCharts from "../pages/RadAdmin/CustomCharts";
import CustomChartsCreate from "../pages/RadAdmin/CustomCharts/CustomChartsCreate";
import SearchReportText from "../pages/RadAdmin/SearchReportText";
import Activity from "../pages/RadAdmin/Activity";
import NoAccessRoute from "../components/NoAccess";
import CohortRecommendationList from "../pages/RadAdmin/RecommendationCohorts";
import CreateCohortRecommendation from "../pages/RadAdmin/RecommendationCohorts/Create";
import CohortRecommendationsPatientView from "../pages/RadAdmin/RecommendationCohorts/ViewRecommendations";
import RestoreRecommendations from "../pages/RadAdmin/RecommendationCohorts/RestoreRecommendations";



const PrivateRoute = ({
  roles = [],
  viewPermissionGroup,
  viewPermissionName,
  noPermission,
  ...props
}) => {
  const role = useSelector((state) => state.auth.user.role);
  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);

  const recoConfigData = useSelector((state) => state.radAdmin.recoConfig.data);

  if (permission) {
    if (noPermission) {
      return <Route {...props} />;
    } else if (
      rolePermissions
        .find((itm) => itm.name === viewPermissionGroup)
        ?.permissions.find((itm) => itm.codename === viewPermissionName)
        ?.is_authorized
    ) {
      if (
        props.path === "/auth/radAdmin/followUpList" &&
        roles.includes(role)
      ) {
        if (recoConfigData) {
          return <Route {...props} />;
        }
      } else {
        return <Route {...props} />;
      }
    }
    return <NoAccessRoute viewPermissionGroup={viewPermissionGroup} />;
  } else if (
    props.path === "/auth/radAdmin/followUpList" &&
    roles.includes(role)
  ) {
    if (recoConfigData) {
      return <Route {...props} />;
    }
  } else if (roles.includes(role)) {
    return <Route {...props} />;
  }

  return null;
};

const AuthRoutes = ({ match: { path } }) => {
  const role = useSelector((state) => state.auth.user.role);
  const alert = useSelector((state) => state.radAdmin.alert);
  const dispatch = useDispatch();

  const onDismiss = useCallback(() => {
    dispatch(hideAlert());
  }, [dispatch]);

  return (
    <Fragment>
      <AlertContainer>
        {alert.active && <Alert {...alert} onDismiss={onDismiss} />}
      </AlertContainer>
      <Switch>
    
       

   
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/Campaigns`}
          component={ConnectedCampaigns}
          exact
          viewPermissionGroup="Campaigns"
          viewPermissionName="view_campaigns"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/Campaigns/:id`}
          component={CreateCampaign}
          exact
          viewPermissionGroup="Campaigns"
          viewPermissionName="add_campaigns"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/Campaigns/:id/create/contact`}
          component={ContactSequence}
          exact
          viewPermissionGroup="Campaigns"
          viewPermissionName="add_campaigns"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/Campaigns/:id/create/:step`}
          component={CreateCampaign}
          exact
          viewPermissionGroup="Campaigns"
          viewPermissionName="add_campaigns"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/Campaigns/create/contact`}
          component={ContactSequence}
          exact
          viewPermissionGroup="Campaigns"
          viewPermissionName="add_campaigns"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/Campaigns/create/:step`}
          component={CreateCampaign}
          exact
          viewPermissionGroup="Campaigns"
          viewPermissionName="add_campaigns"
        />

        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/dashboard`}
          component={RadAdminDashboard}
          exact
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/referringDocs`}
          component={DocListWithFeature}
          exact
          viewPermissionGroup="Quality Metrics"
          viewPermissionName="view_qualitymetrics"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/radiologistLeaderboard`}
          component={DocListWithFeature}
          exact
          viewPermissionGroup="Quality Metrics"
          viewPermissionName="view_qualitymetrics"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/referringDocs/:doctorId/patients`}
          component={PatientList}
          exact
          viewPermissionGroup="Quality Metrics"
          viewPermissionName="view_qualitymetrics"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/Campaigns`}
          component={CampaignPanelWithFeature}
          exact
          viewPermissionGroup="Campaigns"
          viewPermissionName="view_campaigns"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/Integrations`}
          component={Integrations}
          exact
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/Import`}
          component={PatientImport}
          exact
          viewPermissionGroup="Import"
          viewPermissionName="view_import"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/previousUploads`}
          component={PreviousUploadsList}
          exact
          viewPermissionGroup="Import"
          viewPermissionName="view_import"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/exports`}
          component={ExportHistory}
          exact
          viewPermissionGroup="Export"
          viewPermissionName="view_export"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/ImportHistory`}
          component={ImportHistory}
          exact
          viewPermissionGroup="Import"
          viewPermissionName="view_import"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/Overview`}
          component={Overview}
          exact
          viewPermissionGroup="Overview"
          viewPermissionName="view_overview"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/followUpList`}
          component={FollowUpListWithFeature}
          exact
          viewPermissionGroup="Follow-Ups"
          viewPermissionName="view_follow-ups"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/messages`}
          component={MessagesWithFeature}
          exact
          viewPermissionGroup="Incoming"
          viewPermissionName="view_incoming"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/messages/deliveries`}
          component={MessageDeliveries}
          exact
          viewPermissionGroup="Deliveries"
          viewPermissionName="view_deliveries"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/messagesDeliveries/inbound`}
          component={InboundMessageDeliveries}
          exact
          viewPermissionGroup="Incoming"
          viewPermissionName="view_incoming"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/messages/cohorts`}
          component={CohortList}
          exact
          viewPermissionGroup="Cohorts"
          viewPermissionName="view_cohorts"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/messages/cohorts/create`}
          component={CreateCohort}
          exact
          viewPermissionGroup="Cohorts"
          viewPermissionName="add_cohorts"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/messages/cohorts/patientView/:id`}
          component={CohortPatientView}
          exact
          viewPermissionGroup="Cohorts"
          viewPermissionName="view_cohorts"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/followUp/patients`}
          component={PatientViewWithFeature}
          exact
          viewPermissionGroup="Patients"
          viewPermissionName="view_patients"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/followUpList/patients/patientDetails/:id`}
          component={PatientDetails}
          exact
          viewPermissionGroup="Patients"
          viewPermissionName="view_patients"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/followUpList/patients/patientDetails/`}
          component={PatientDetails}
          exact
          viewPermissionGroup="Patients"
          viewPermissionName="view_patients"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/settings`}
          component={Settings}
          exact
          viewPermissionGroup="Settings"
          viewPermissionName="view_settings"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/followUp/notes`}
          component={Notes}
          exact
          viewPermissionGroup="Notes"
          viewPermissionName="view_notes"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/support`}
          component={Support}
          exact
          noPermission={true}
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/custom/charts/view`}
          component={CustomCharts}
          exact
          viewPermissionGroup="Custom Charts"
          viewPermissionName="view_customcharts"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/custom/charts/create`}
          component={CustomChartsCreate}
          exact
          viewPermissionGroup="Custom Charts"
          viewPermissionName="add_customcharts"
        />
          <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/custom/charts/create/:id`}
          component={CustomChartsCreate}
          exact
          viewPermissionGroup="Custom Charts"
          viewPermissionName="change_customcharts"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/followUp/search-report-text`}
          component={SearchReportText}
          exact
          viewPermissionGroup="Search Report Text"
          viewPermissionName="view_searchreporttext"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/activity`}
          component={Activity}
          exact
          viewPermissionGroup="Activity Logs"
          viewPermissionName="view_activitylogs"
        />
         <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/followUp/recommendations/cohorts`}
          component={CohortRecommendationList}
          exact
          viewPermissionGroup="Recommendation Cohorts"
          viewPermissionName="view_recommendationcohorts"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/followUp/recommendations/cohorts/create`}
          component={CreateCohortRecommendation}
          exact
          viewPermissionGroup="Recommendation Cohorts"
          viewPermissionName="add_recommendationcohorts"
        />
          <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/followUp/recommendations/cohorts/details/:id`}
          component={CohortRecommendationsPatientView}
          exact
          viewPermissionGroup="Recommendation Cohorts"
          viewPermissionName="view_recommendationcohorts"
        />
        <PrivateRoute
          roles={["radiology_admin"]}
          path={`${path}/radAdmin/followUp/recommendations/rejected`}
          component={RestoreRecommendations}
          exact
          viewPermissionGroup="Rejected Recommendations"
          viewPermissionName="view_rejectedrecommendations"
        />

     
      </Switch>
    </Fragment>
  );
};

export default AuthRoutes;
