import React, { useEffect, useState } from "react";
import { Card, PageTitle } from "../../../styles";
import Table from "../../../components/Table/Table";
import {
  getOutgoingMessages,
  getOutgoingEmails,
  getOutgoingFaxes,
  showAlert
} from "../../../actions/radminUploads";
import Box from "../../../components/_styles/Box";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { InfoIconOutline as InfoIcon } from "./Icons";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import { ReactComponent as EmptyStateImg } from "../../../assets/empty-state-search.svg";
import { TemplateButton } from "./SmsCreationDialog";
import { StatusBadge } from "./MessageDeliveriesIncoming";
import { Button } from "@material-ui/core";
import MessageContent from "./ShowMessageContent";
import LoadMoreButton from "../../../components/LoadMoreButton";
import ExportMessageModal from "./ExportMessageModal";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import MessageAnalytics from "./MessageAnalytics";
import moment from "moment";
import EmailAnalytics from "./EmailAnalytics";
import Flex from "../../../components/_styles/Flex";
import ExportEmailModal from "./ExportEmailModal";
import FaxAnalytics from "./FaxAnalytics";
import ExportFaxModal from "./ExportFaxModal";
import { useRef } from "react";
import SearchInput from "../FollowUpList/SearchInput";

const ExportContainer = styled.div`
  display: 'flex',
  justify-content: space-between;
`;

const Link = styled.span`
    color: #00AEEF;
    font-size: 14px;
    line-height: 20px;
    &:hover {
        text-decoration: ${(props) => (!props.value ? "none" : "underline")} 
        cursor: ${(props) => (!props.value ? "default" : "pointer")} 
    }
`;

const SwitchContainer = styled.div`
  padding: 4px;
  display: flex;
  background: #e6e9f1;
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0c2945;
  width: fit-content;
  margin-top: 20px;
`;

const SwitchButton = styled.button`
  padding: 7px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? "#0C2945" : "#E6E9F1")};
  color: ${(props) => (props.active ? "#F9FAFC" : "#0C2945")};
  font-weight: ${(props) => (props.active ? 700 : 600)};
  border: 0;
  outline: 0;
  border-radius: 6px;
`;

const useStyles = makeStyles({
  label: {
    display: "flex",
    justifyContent: "spase-between",
  },
  exportButton: {
    width: "183px",
    height: "38px",
    fontFamily: "Open SansMedium",
    fontWeight: "normal",
    textAlign: "center",
    fontSize: "14px",
    borderRadius: "4px",
    backgroundColor: "#00AEEF",
    textTransform: "none",
    outline:'none',
    color:'#FFFFFF',
    border:"2px solid transparent",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color:'#00AEEF',
      border:'2px solid #00AEEF'
    },
  },
});

const columns = [
  { key: "accessionNumber", name: "Accession Number" },
  { key: "created", name: "Created Timestamp" },
  { key: "direction", name: "Direction" },
  {
    key: "status",
    name: "Status",
    displayCell: (cellValue) => <StatusBadge status={cellValue} styles={{
      fontSize:'14px',
      fontWeight: 600,
      width: '112px',
      borderRadius: '8px',
      textTransform: 'capitalize'
    }} />,
  },
  { key: "message_type", name: "Message Type" },
];

const Badge = styled(Flex)`
  width: 112px;
  min-width: 54px;
  padding: 0 5px;
  height: 24px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 8px;
  color: white;
  font-size:14px;
  font-weight: 600;
  text-transform: capitalize;
  background: ${(props) => {
    switch (props.status) {
      case "sent":
        return "#FDBB13";

      case "delivered":
        return "#64C67C";

      case "opened":
        return "#00AEEF";

      case "clicked":
        return  "#4EAC9F";

      case "spammed":
        return "#7C7C7C";

      case "failed":
        return "#9B64C6";

      case "dropped":
        return "#CF3131";

      case "bounced":
        return "#FF5F65";

      case "unsubscribed":
        return "#ADADAD";

      case "converted":
        return "#FF7A00";

      default:
        return "#DFE0EB";
    }
  }};
`;


const FaxBadge = styled(Flex)`
  width: 112px;
  min-width: 54px;
  padding: 0 5px;
  height: 24px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 8px;
  color: white;
  font-size:14px;
  font-weight: 600;
  text-transform: capitalize;
  background: ${(props) => {
    switch (props.status) {
      case "success":
        return "#64C67C";

      case "failed":
        return "#9B64C6";

      case "cancelled":
        return "#CF3131";

      case "transmitting":
        return "#FF7A00";

      case "processing":
        return "#ADADAD";

      default:
        return "#DFE0EB";
    }
  }};
`;

const EmailStatusBadge = ({ status }) => (
  <Badge status={status}>{status}</Badge>
);

const FaxStatusBadge = ({ status }) => (
  <FaxBadge status={status}>{status}</FaxBadge>
);


const SearchInputContainer = styled.div`
  float: right;
  @media (max-width: 991px) {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    float: unset;
    margin-left: 0rem;
  }
  @media (min-width: 1245px) {
    margin-left: 3rem;
  }
`;


const emailColumns = [
  { key: "created", name: "Created Timestamp" },
  {
    key: "status",
    name: "Status",
    displayCell: (cellValue) => <EmailStatusBadge status={cellValue} />,
  },
  { key: "message_type", name: "Message Type" },
];

const faxColumns = [
  { key: "id", name: "Fax ID" },
  { key: "created", name: "Created Date" },
  {
    key: "status",
    name: "Status",
    displayCell: (cellValue) => <FaxStatusBadge status={cellValue} />,
  },
  { key: "message_type", name: "Message Type" },
];
const MessagesDeliveries = () => {
  const messages = useSelector(
    (state) => state.radAdmin.messages?.outgoingMessages?.data
  );
  const totalItems = useSelector(
    (state) => state.radAdmin.messages.outgoingMessages.totalItems
  );
  const isLoading = useSelector(
    (state) => state.radAdmin.messages.outgoingMessages.isLoading
  );
  const emails = useSelector(
    (state) => state.radAdmin.emails?.outgoingMessages?.data
  );

  const faxes = useSelector(
    (state) => state.radAdmin.faxes?.outgoingMessages?.data
  )

  const totalEmailItems = useSelector(
    (state) => state.radAdmin.emails.outgoingMessages.totalItems
  );
  
  const totalFaxItems = useSelector(
    (state) => state.radAdmin.faxes.outgoingMessages.totalItems
  );


  const isLoadingEmails = useSelector(
    (state) => state.radAdmin.emails.outgoingMessages.isLoading
  );

  const isLoadingFaxes = useSelector(
    (state) => state.radAdmin.faxes.outgoingMessages.isLoading
  )

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation()

  let params = new URLSearchParams(history.location.search);
  let view = params.get("view");
  let timeframe = ( view==="email" || view ==="sms" || view==="fax")? params.get("timeframe") : "Last 30 days";
  let direction = params.get("direction");
  let smsStatus = params.get('status');
  let status = params.get("email-status");
  let faxStatus = params.get("fax-status");
  let initialSearchValue  = params.get('searchEmail');
  let initialSearchValuePhone  = params.get('searchPhone');
  let initialSearchValueFax = params.get("searchFax");
  var date = new Date();
  let requiredDate;
  let lastMoment;
  let startingDate;
  let endingDate;
  if (timeframe) {
    if (
      timeframe !== "Last 7 days" &&
      timeframe !== "Last 30 days" &&
      timeframe !== "Last 3 months" &&
      timeframe !== "Last 12 months"
    ) {
      const timeframeData = timeframe.split("-");
      const formattedDateStringStart = timeframeData[0];
      const formattedDateStringEnd = timeframeData[1];
      const dateObjStart = moment(formattedDateStringStart, "MM/DD/YYYY");
      const dateObjEnd = moment(formattedDateStringEnd, "MM/DD/YYYY");

      const day = dateObjStart.format("DD");
      const month = dateObjStart.format("MMM");
      const year = dateObjStart.format("YYYY");

      startingDate = `${dateObjStart.format(
        "ddd"
      )} ${month} ${day} ${year} 00:00:00 GMT+0530 (India Standard Time)`;

      const day2 = dateObjEnd.format("DD");
      const month2 = dateObjEnd.format("MMM");
      const year2 = dateObjEnd.format("YYYY");

      endingDate = `${dateObjEnd.format(
        "ddd"
      )} ${month2} ${day2} ${year2} 00:00:00 GMT+0530 (India Standard Time)`;
    } else {
      if (timeframe === "Last 7 days") {
        lastMoment = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
      } else if (timeframe === "Last 30 days") {
        lastMoment = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000);
      } else if (timeframe === "Last 3 months") {
        lastMoment = new Date(date.getTime() - 90 * 24 * 60 * 60 * 1000);
      }

      if (timeframe === "Last 12 months") {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear() - 1;
        requiredDate = `${month}/${day}/${year}`;
      } else {
        let day = lastMoment?.getDate();
        let month = lastMoment?.getMonth() + 1;
        let year = lastMoment?.getFullYear();
        requiredDate = `${month}/${day}/${year}`;
      }
    }
  }

  const [messageContent, setMessageContent] = useState("");
  const [emailContent, setEmailContent] = useState("");
  const [faxContent, setFaxContent] = useState("")

  const [twilioPhoneNumber, setTwilioPhoneNumber] = useState("-");
  const [patientPhoneNumber, setPatientPhoneNumber] = useState("-");

  const [recipientEmail, setRecipientEmail] = useState("-");
  const [recipientFax, setRecipientFax] = useState()
  const recoConfigData = useSelector((state) => state.radAdmin.recoConfig.data);


  const [showMessageContent, setShowMessageContent] = useState(false);
  const [selectedDate, setSelectedDate] = useState(requiredDate || ""
  );
  const [page, setPage] = useState(1);
  const [emailPage, setEmailPage] = useState(1);
  const [faxPage, setFaxPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(
  startingDate || ""
  );
  const [endDate, setEndDate] = useState( endingDate || "");
  const [selectedDirection, setSelectedDirection] = useState(direction || "");
  const [searchValueFax, setSearchValueFax] = useState(initialSearchValueFax);

  const [selectedDateEmail, setSelectedDateEmail] = useState(
     requiredDate || ""
  );
  const [startDateEmail, setStartDateEmail] = useState(
     startingDate || ""
  );
  const [endDateEmail, setEndDateEmail] = useState(
     endingDate || ""
  );

  
  const [selectedDateFaxes, setSelectedDateFaxes] = useState(
    requiredDate || ""
 );
 const [startDateFaxes, setStartDateFaxes] = useState(
    startingDate || ""
 );
 const [endDateFaxes, setEndDateFaxes] = useState(
    endingDate || ""
 );

 const [searchValuePhone, setSearchValuePhone] = useState(initialSearchValuePhone);
 const [searchValueEmail, setSearchValueEmail] = useState(initialSearchValue);


  const [selectedStatus, setSelectedStatus] = useState(status || "");
  const [selectedFaxStatus, setSelectedFaxStatus] = useState(faxStatus || "");
  const [selectedSmsStatus, setSelectedSmsStatus] = useState(smsStatus || '');
  const [activeTab, setActiveTab] = useState(view || "sms");
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [showFaxModal, setShowFaxModal] = useState(false)
   
  const permission = useSelector((state) => state.auth.user.permission_level); 
  const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
  );
  const exportPermission = rolePermissions?.find(itm=>itm.name ==="Export")?.permissions.find(itm=>itm.codename === "add_Export")?.is_authorized;

  const classes = useStyles();


  useEffect(() => {
    if(activeTab === "sms"){
      if (selectedDate || (startDate && endDate)) {
        dispatch(
          getOutgoingMessages(
            page,
            selectedDate || '',
             startDate && endDate? {startDate, endDate } : {},
            selectedDirection || "",
            searchValuePhone,
            selectedSmsStatus || ""
          )
        );
      }
    
  }
  }, [startDate, endDate, selectedDate, selectedDirection, dispatch, activeTab, searchValuePhone,page, selectedSmsStatus]);

  useEffect(() => {
    if(activeTab ==="email"){
    if ( selectedDateEmail ||  (startDateEmail && endDateEmail)) {
      dispatch(
        getOutgoingEmails(
          emailPage,
          selectedDateEmail || '',
          startDateEmail && endDateEmail? { startDateEmail, endDateEmail }:{},
          selectedStatus || "",
          searchValueEmail
        )
      );
    } 
  }
  }, [
    startDateEmail,
    endDateEmail,
    selectedDateEmail,
    selectedStatus,
    dispatch,
    activeTab,
    searchValueEmail,
    emailPage
  ]);

  
  useEffect(() => {
    if(activeTab ==="fax"){
    if ( selectedDateFaxes || ( startDateFaxes && endDateFaxes)) {
      dispatch(
        getOutgoingFaxes(
          faxPage,
          selectedDateFaxes || "",
          startDateFaxes && endDateFaxes? { startDateFaxes, endDateFaxes }:{},
          selectedFaxStatus || "",
          searchValueFax
        )
      );
    }
  }
  }, [
    startDateFaxes,
    searchValueFax,
    endDateFaxes,
    selectedDateFaxes,
    selectedFaxStatus,
    dispatch,
    activeTab,
    faxPage
  ]);

  const dialogHandler = () => {
    setShowMessageContent(!showMessageContent);
  };
  const onLoadMore = () => {
    if (messages.length <= totalItems) {
      window.analytics.track("Message-Deliveries-Sms-Load-More-Click")
      setPage(page + 1);
      if(!startDate && !endDate && !selectedDirection && !selectedDate && !selectedSmsStatus){
        dispatch(getOutgoingMessages(page + 1, "", {}, "", searchValueEmail,""));
      }
    }
  };

  const onLoadMoreEmails = () => {
    if (emails.length <= totalEmailItems) {
      window.analytics.track("Message-Deliveries-Emails-Load-More-Click")
      setEmailPage(emailPage + 1);
      if(!startDateEmail && !endDateEmail && !selectedStatus && !selectedDateEmail){
        dispatch(getOutgoingEmails(emailPage + 1, "", {}, "", searchValuePhone));
      }
    }
  };

  const onLoadMoreFaxes = () => {
    if (faxes.length <= totalFaxItems) {
      window.analytics.track("Message-Deliveries-Faxes-Load-More-Click")
      setFaxPage(faxPage + 1);
      if(!startDateFaxes && !endDateFaxes && !selectedFaxStatus && !selectedDateFaxes){
        dispatch(getOutgoingFaxes(faxPage + 1, "", {}, ""));
      }
    }
  };


  const ClickHandler = (value) => {
    window.analytics.track("Message-Deliveries-Sms-View-Content-Click")
    setMessageContent(value.message);
    setTwilioPhoneNumber(value.twilio_phone_number);
    setPatientPhoneNumber(value.patient_phone_number);
    setShowMessageContent(!showMessageContent);
  };

  const ClickHandlerEmailContent = (value) => {
    window.analytics.track("Message-Deliveries-Email-View-Content-Click")
    setEmailContent(value.content? value.content : "No available content to display");
    setRecipientEmail(value.email);
    setShowMessageContent(!showMessageContent);
  };

  const ClickHandlerFaxContent = (value) => {
    window.analytics.track("Message-Deliveries-Fax-View-Content-Click")
    setFaxContent(value?.attachment);
    setRecipientFax(value.referrer_fax)
    setShowMessageContent(!showMessageContent);
  };

  const resetFilter = () => {
    window.analytics.track("Message-Deliveries-Sms-Reset-Timeframe-Click")
    dispatch(getOutgoingMessages(1));
    setPage(1);
    setSelectedDate("");
    setStartDate("");
    setEndDate("");
  };

  const resetFilterEmail = () => {
    window.analytics.track("Message-Deliveries-Email-Reset-Timeframe-Click")
    dispatch(getOutgoingEmails(1));
    setEmailPage(1);
    setSelectedDateEmail("");
    setStartDateEmail("");
    setEndDateEmail("");
  };
  
 const  resetFilterFax = () => {
    window.analytics.track("Message-Deliveries-Fax-Reset-Timeframe-Click")
    dispatch(getOutgoingFaxes(1));
    setFaxPage(1);
    setSelectedDateFaxes("");
    setStartDateFaxes("");
    setEndDateFaxes("");
  };

  const handleReset = () => {
    setStartDateEmail("");
    setSearchValueFax("");
    setEndDateEmail("");
    setSelectedDateEmail("");
    setSelectedDate("");
    setStartDate("");
    setEndDate("");
    setEmailPage(1);
    setSelectedStatus("");
    setSelectedFaxStatus("");
    setPage(1);
    setFaxPage(1);
    setSelectedDateFaxes("");
    setStartDateFaxes("");
    setEndDateFaxes("");
    setSelectedDirection("");
    setSearchValueEmail('');
    setSearchValuePhone('');
    setSelectedSmsStatus("");
  };



  
  const handleSubmit = (values) => {
    setEmailPage(1)
    setSearchValueEmail(values.searchValue);

    const existingParams = new URLSearchParams(location.search);

    existingParams.set('searchEmail', values.searchValue);

    const newUrl = `${location.pathname}?${existingParams.toString()}`;
    history.push(newUrl);
    if(!startDateEmail && !endDateEmail && !selectedStatus && !selectedDateEmail){
      dispatch(getOutgoingEmails(1, "", {}, "", values.searchValue));
    }

    window.analytics.track('Message-Deliveries-Search-Submit',{search:values.searchValue});
  };

  const handleResetForm = () => {
    setEmailPage(1)
    setSearchValueEmail(null);    
    const existingParams = new URLSearchParams(location.search);

    existingParams.delete('searchEmail');

    const newUrl = `${location.pathname}?${existingParams.toString()}`;
    history.push(newUrl);
    if(!startDateEmail && !endDateEmail && !selectedStatus && !selectedDateEmail){
      dispatch(getOutgoingEmails(1, "", {}, "", ""));
    }
    window.analytics.track('Message-Deliveries-Search-Reset');
  };


  const handleSubmitPhoneNumber = (values) => {
    setPage(1)
    setSearchValuePhone(values.searchValue);

    const existingParams = new URLSearchParams(location.search);

    existingParams.set('searchPhone', values.searchValue);

    const newUrl = `${location.pathname}?${existingParams.toString()}`;
    history.push(newUrl);
    if(!startDate && !endDate && !selectedDirection && !selectedDate && !selectedSmsStatus){
      dispatch(getOutgoingMessages(1, "", {}, "", values.searchValue, ""));
    }
    window.analytics.track('Message-Deliveries-Search-Submit',{search:values.searchValue});
  };

  const handleResetFormPhoneNumber = () => {
    setPage(1)
    setSearchValuePhone(null);    
    const existingParams = new URLSearchParams(location.search);

    existingParams.delete('searchPhone');

    const newUrl = `${location.pathname}?${existingParams.toString()}`;
    history.push(newUrl);
    if(!startDate && !endDate && !selectedDirection && !selectedDate&& !selectedSmsStatus){
      dispatch(getOutgoingMessages(1, "", {}, "", "",""));
    }
    window.analytics.track('Message-Deliveries-Search-Reset');
  };

  const handleSubmitFax = (values) => {
    setFaxPage(1);
    setSearchValueFax(values.searchValue);

    const existingParams = new URLSearchParams(location.search);

    existingParams.set("searchFax", values.searchValue);

    const newUrl = `${location.pathname}?${existingParams.toString()}`;
    history.push(newUrl);
    if (
      !startDateFaxes &&
      !endDateFaxes &&
      !selectedStatus &&
      !selectedDateFaxes
    ) {
      dispatch(getOutgoingFaxes(1, "", {}, "", values.searchValue));
    }
    window.analytics.track("Message-Deliveries-Search-Submit", {
      search: values.searchValue,
    });
  };

  const handleResetFormFax = () => {
    setFaxPage(1);
    setSearchValueFax(null);
    const existingParams = new URLSearchParams(location.search);

    existingParams.delete("searchFax");

    const newUrl = `${location.pathname}?${existingParams.toString()}`;
    history.push(newUrl);
    if (
      !startDateFaxes &&
      !endDateFaxes &&
      !selectedDateFaxes &&
      !selectedDateFaxes
    ) {
      dispatch(getOutgoingFaxes(1, "", {}, "", ""));
    }
    window.analytics.track("Message-Deliveries-Search-Reset");
  };

  return (
    <Box p={30}>
      <ExportContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <PageTitle>Message Deliveries</PageTitle>
        { activeTab === 'sms' &&
          <SearchInputContainer>
          <SearchInput
            placeholder="Search by Phone Number"
            handleSubmit={handleSubmitPhoneNumber}
            handleResetForm={handleResetFormPhoneNumber}
            style={{
              width: "284px",
              display: "flex",
              alignItems: "center",
            }}
            searchIconStyle={{ marginLeft: "0px", marginTop: "0px" }}
            inputStyle={{ height: "38px" }}
            customPlaceholderStyle={{
              marginLeft: "35px",
              marginTop: "0px",
            }}
            searchValue={searchValuePhone} 
            setSearchValue={setSearchValuePhone}
          />
        </SearchInputContainer>
        }
        {
          activeTab === 'email' && <SearchInputContainer>
          <SearchInput
            placeholder="Search by Email Address"
            handleSubmit={handleSubmit}
            handleResetForm={handleResetForm}
            style={{
              width: "284px",
              display: "flex",
              alignItems: "center",
            }}
            searchIconStyle={{ marginLeft: "0px", marginTop: "0px" }}
            inputStyle={{ height: "38px" }}
            customPlaceholderStyle={{
              marginLeft: "35px",
              marginTop: "0px",
            }}
            searchValue={searchValueEmail} 
            setSearchValue={setSearchValueEmail}
          />
        </SearchInputContainer>
        }

        {activeTab === "fax" && (
          <SearchInputContainer>
            <SearchInput
              placeholder="Search by Unique Id/Fax Number"
              handleSubmit={handleSubmitFax}
              handleResetForm={handleResetFormFax}
              style={{
                width: "284px",
                display: "flex",
                alignItems: "center",
              }}
              searchIconStyle={{ marginLeft: "0px", marginTop: "0px" }}
              inputStyle={{ height: "38px" }}
              customPlaceholderStyle={{
                marginLeft: "35px",
                marginTop: "0px",
              }}
              searchValue={searchValueFax}
              setSearchValue={setSearchValueFax}
            />
          </SearchInputContainer>
        )}
        
        {showModal && (
          <ExportMessageModal
            showModal={showModal}
            closeModal={() => setShowModal(false)}
          />
        )}
        {showEmailModal && (
          <ExportEmailModal
            showModal={showEmailModal}
            closeModal={() => setShowEmailModal(false)}
          />
        )}
        
        {showFaxModal && (
          <ExportFaxModal
            showModal={showFaxModal}
            closeModal={() => setShowFaxModal(false)}
          />
        )}
        
      </ExportContainer>

      <SwitchContainer>
        <SwitchButton
          active={activeTab === "sms"}
          onClick={() => {
            if(activeTab!=='sms'){
              window.analytics.track("Message-Deliveries-Sms-Click")
              setActiveTab("sms");
              handleReset();
            }
           
          }}
        >
          SMS
        </SwitchButton>
        <SwitchButton
          active={activeTab === "email"}
          onClick={() => {
            if(activeTab!=="email"){
              window.analytics.track("Message-Deliveries-Email-Click")
              setActiveTab("email");
              handleReset();
            }
          }}
        >
          Email
        </SwitchButton>
        <SwitchButton
          active={activeTab === "fax"}
          onClick={() => {
            if(activeTab!=="fax"){
              window.analytics.track("Message-Deliveries-Fax-Click")
              setActiveTab("fax");
              handleReset();
            }
          }}
        >
          Fax
        </SwitchButton>
      
      </SwitchContainer>
      {activeTab === "sms" ? (
        <>
          <MessageAnalytics
            setSelectedDate={setSelectedDate}
            setPage={setPage}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            resetFilter={resetFilter}
            selectedDate={selectedDate}
            setSelectedDirection={setSelectedDirection}
            selectedDirection={selectedDirection}
            messages={messages}
            setSelectedSmsStatus={setSelectedSmsStatus}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "34px 0px",
            }}
          >
            <Button
              classes={{ root: classes.exportButton }}
              onClick={() => {
                if((permission && exportPermission) || !permission){
                  window.analytics.track("Message-Deliveries-Messages-Export-Modal-Click")
                  setShowModal(true)
               }else{
                 dispatch(
                   showAlert('danger', 'Error', `You don't have permission to export SMSes`)
                 );
               }
              }}
            >
              Export SMSes
            </Button>
          </div>

          <Card>
            <Table
              rows={messages && messages}
              columns={[
                ...columns,
                {
                  key: "mrn",
                  name: "Recipient MRN",
                  displayCell: (value, cellValue) => (
                    <Link
                      onClick={() =>{
                        window.analytics.track("Message-Deliveries-Patient-Mrn-Click")
                        value !== "--" &&
                        cellValue.accessionNumber !== "--" &&
                        history.push(
                          `/auth/radAdmin/followUpList/patients/patientDetails/${cellValue.patientId}`
                        )
                      }
                      }
                      value={
                        value !== "--" && cellValue.accessionNumber !== "--"
                      }
                    >
                      {value}
                    </Link>
                  ),
                },
                { key: "patient_phone_number", name: "Recipient Phone Number" },
                { key: "message_source", name: "Source", displayCell: (value, cellValue) => (
                  <span> {value ==="customer.io"  ? 'Automatic' : 'Manual'} </span>
                ) },
                {
                  key: "upload_file",
                  name: "",
                  displayCell: (col, cellValue, id) => (
                    <TemplateButton
                      color="#00AEEF"
                      title="View Content"
                      icon={<InfoIcon />}
                      clickHandler={() => ClickHandler(cellValue)}
                      style={{height:'38px', borderRadius:'8px', borderWidth:'2px', padding:"9.5px 12.5px", width:'144px', gap:'10px'}}
                      iconContainerStyles={{padding:"0px"}}
                      textStyles={{fontFamily:'Open Sans', lineHeight:'19px', whiteSpace:'nowrap'}}
                    ></TemplateButton>
                  ),
                },
              ]}
              initialPageNumber={page}
              totalItems={totalItems}
              isLoadingRows={isLoading && page === 1}
              clickHandler={() => null}
              getRowsAction={(pageNumber) =>
                getOutgoingMessages(
                  pageNumber + 1,
                  selectedDate,
                  {},
                  selectedDirection,
                  selectedSmsStatus
                )
              }
              noDataRender={() => (
                <EmptyState
                  title={"No Results"}
                  img={() => <EmptyStateImg />}
                  buttonInfo={{
                    link: "/auth/radAdmin/Import",
                    text: "Import Data",
                  }}
                >
                  Sorry, there are no results
                </EmptyState>
              )}
            />
            {messages && messages.length !== 0 && (
              <LoadMoreButton
                title="Load more"
                isLoading={isLoading && page !== 1}
                handleClick={onLoadMore}
                isDisabled={totalItems === messages.length}
                style={{backgroundColor:'#0C2945', fontWeight:600}}
              />
            )}
          </Card>
          <MessageContent
            messageContent={messageContent}
            show={showMessageContent}
            dialogHandler={dialogHandler}
            patientPhoneNumber={patientPhoneNumber}
            twilioPhoneNumber={twilioPhoneNumber}
          />
        </>
      ) : activeTab ==="email"? (
        <>
          <EmailAnalytics
            setSelectedDate={setSelectedDateEmail}
            setPage={setEmailPage}
            startDate={startDateEmail}
            setStartDate={setStartDateEmail}
            endDate={endDateEmail}
            setEndDate={setEndDateEmail}
            resetFilter={resetFilterEmail}
            selectedDate={selectedDateEmail}
            setSelectedDirection={setSelectedStatus}
            selectedDirection={selectedStatus}
            messages={emails}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "34px 0px",
            }}
          >
            <Button
              classes={{ root: classes.exportButton }}
              onClick={() => {
                if((permission && exportPermission) || !permission){
                  window.analytics.track("Message-Deliveries-Emails-Export-Modal-Click")
                  setShowEmailModal(true)
               }else{
                 dispatch(
                   showAlert('danger', 'Error', `You don't have permission to export Emails`)
                 );
               }   
              }}
            >
              Export Emails

            </Button>
          </div>

          <Card>
            <Table
              rows={emails && emails}
              columns={[
                ...emailColumns,
                {
                  key: "mrn",
                  name: "Recipient MRN",
                  displayCell: (value, cellValue) => (
                    <Link
                      onClick={() =>
                        value !== "--" &&
                        cellValue.accessionNumber !== "--" &&
                        history.push(
                          `/auth/radAdmin/followUpList/patients/patientDetails/${cellValue.patientId}`
                        )
                      }
                      value={
                        value !== "--" && cellValue.accessionNumber !== "--"
                      }
                    >
                      {value}
                    </Link>
                  ),
                },
                { key: "email", name: "Recipient Email" },
                {
                  key: "upload_file",
                  name: "",
                  displayCell: (col, cellValue, id) => (
                    <TemplateButton
                      width="100"
                      color="#00AEEF"
                      title="View Content"
                      icon={<InfoIcon />}
                      clickHandler={() => ClickHandlerEmailContent(cellValue)}
                      style={{height:'38px', borderRadius:'8px', borderWidth:'2px', padding:"9.5px 12.5px", width:'144px', gap:'10px'}}
                      iconContainerStyles={{padding:"0px"}}
                      textStyles={{fontFamily:'Open Sans', lineHeight:'19px', whiteSpace:'nowrap'}}
                    ></TemplateButton>
                  ),
                },
              ]}
              initialPageNumber={emailPage}
              totalItems={totalEmailItems}
              isLoadingRows={isLoadingEmails && emailPage === 1}
              clickHandler={() => null}
              getRowsAction={(pageNumber) =>
                getOutgoingEmails(
                  pageNumber + 1,
                  selectedDate,
                  {},
                  selectedStatus
                )
              }
              noDataRender={() => (
                <EmptyState
                  title={"No Results"}
                  img={() => <EmptyStateImg />}
                  buttonInfo={{
                    link: "/auth/radAdmin/Import",
                    text: "Import Data",
                  }}
                >
                  Sorry, there are no results
                </EmptyState>
              )}
            />
            {emails && emails.length !== 0 && (
              <LoadMoreButton
                title="Load more"
                isLoading={isLoadingEmails && emailPage !== 1}
                handleClick={onLoadMoreEmails}
                isDisabled={totalEmailItems === emails.length}
                style={{backgroundColor:'#0C2945', fontWeight:600}}

              />
            )}
          </Card>
          <MessageContent
            messageContent={emailContent}
            show={showMessageContent}
            dialogHandler={dialogHandler}
            recipientEmail={recipientEmail}
            sourceEmail={ recoConfigData?.name || "-"}
            customHeader="Email Content"
            isEmailType={true}
          />
        </>
      ):
      (
        <>
          <FaxAnalytics
            setSelectedDate={setSelectedDateFaxes}
            setPage={setFaxPage}
            startDate={startDateFaxes}
            setStartDate={setStartDateFaxes}
            endDate={endDateFaxes}
            setEndDate={setEndDateFaxes}
            resetFilter={resetFilterFax}
            selectedDate={selectedDateFaxes}
            setSelectedDirection={setSelectedFaxStatus}
            selectedDirection={selectedFaxStatus}
            messages={faxes}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "34px 0px",
            }}
          >
            <Button
              classes={{ root: classes.exportButton }}
              onClick={() => {

                if((permission && exportPermission) || !permission){
                  window.analytics.track("Message-Deliveries-Fax-Export-Modal-Click")
                  setShowFaxModal(true)
               }else{
                 dispatch(
                   showAlert('danger', 'Error', `You don't have permission to export Faxes`)
                 );
               }
              }}
            >
              Export Faxes
            </Button>
          </div>

          <Card>
            <Table
              rows={faxes && faxes}
              columns={[
                ...faxColumns,
                { key: "referrer_fax", name: "Recipient Fax Number" },
                { key: "referrer_full_name", name: "Referring First & Last Name" },
                { key: "referring_physician", name: "Referrer ID" },
                { key: "referrer_unique_id", name: "Referrer Unique ID/NPI" },
                {
                  key: "upload_file",
                  name: "",
                  displayCell: (col, cellValue, id) => (
                    <TemplateButton
                      width="100"
                      color="#00AEEF"
                      title="View Content"
                      icon={<InfoIcon />}
                      clickHandler={() => ClickHandlerFaxContent(cellValue)}
                      style={{height:'38px', borderRadius:'8px', borderWidth:'2px', padding:"9.5px 12.5px", width:'144px', gap:'10px'}}
                      iconContainerStyles={{padding:"0px"}}
                      textStyles={{fontFamily:'Open Sans', lineHeight:'19px', whiteSpace:'nowrap'}}
                    ></TemplateButton>
                  ),
                },
              ]}
              initialPageNumber={faxPage}
              totalItems={totalFaxItems}
              isLoadingRows={isLoadingFaxes && faxPage === 1}
              clickHandler={() => null}
              getRowsAction={(pageNumber) =>
                getOutgoingFaxes(
                  pageNumber + 1,
                  selectedDate,
                  {},
                  selectedFaxStatus
                )
              }
              noDataRender={() => (
                <EmptyState
                  title={"No Results"}
                  img={() => <EmptyStateImg />}
                  buttonInfo={{
                    link: "/auth/radAdmin/Import",
                    text: "Import Data",
                  }}
                >
                  Sorry, there are no results
                </EmptyState>
              )}
            />
            {faxes && faxes.length !== 0 && (
              <LoadMoreButton
                title="Load more"
                isLoading={isLoadingFaxes && faxPage !== 1}
                handleClick={onLoadMoreFaxes}
                isDisabled={totalFaxItems === faxes.length}
                style={{backgroundColor:'#0C2945', fontWeight:600}}

              />
            )}
          </Card>
          <MessageContent
            messageContent={faxContent}
            show={showMessageContent}
            dialogHandler={dialogHandler}
            customHeader="Fax Content"
            isEmailType={false}
            recipientFax={recipientFax}
            sourceFax={ recoConfigData?.name || "-"}
            isPdf={true}
            styles={{width:'50rem'}}
          />
       
        </>
      )
    }
    </Box>
  );
};

export default MessagesDeliveries;
