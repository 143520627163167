import React, { useState, useEffect, forwardRef } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { capitalize, isNull } from "lodash";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import loadMoreIcon from "../../../assets/load-more.svg";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import { isArray } from "lodash";

import { ReactComponent as RemoveRecoIcon } from "../../../assets/FollowUpDeleteIcon.svg";
import { ReactComponent as RemoveRecoIconHover } from "../../../assets/delete-hover.svg";
import RemoveConfirmation from "./RemoveConfirmation";
import { ReactComponent as ValidateIcon } from "../../../assets/validate-icon.svg";
import { ReactComponent as ValidateIconHover } from "../../../assets/validate-icon-hover.svg";

import { ReactComponent as CalendarIcon } from "../../../assets/calendar-outline.svg";
import { ReactComponent as RemoveDateIcon } from "../../../assets/close-outline.svg";
import {
  getNotes,
  showAlert,
  getUsersList,
  getRecommendationsCountWithCohortId,
  getModalitiesListAction,
  getAnatomiesListAction,
  updateRecommendationTaskElements,
  getFindingsBoi,
  validateRecommendationInCohort,
  deleteRecommendationInCohort,
  getRecommendationsWithCohortId,
  getDropdownValuesForCohort,
} from "../../../actions/radminUploads/index";
import { createTask } from "../../../actions/radminUploads";

import { ReactComponent as CloseIcon } from "../../../assets/cancel-outline.svg";

import styled from "styled-components";
import EditNote from "../../../components/Dialog/EditNote";
import NewNote from "../../../components/Dialog/NewNote";
import Flex from "../../../components/_styles/Flex";
import moment from "moment";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { ReactComponent as EditIcon } from "../../../assets/EditIcon.svg";
import {
  AvatarContainer,
  UserAvatar,
  UserEmptyAvatar,
} from "../../../components/Table/Table";
import EditFormReport from "../../../components/Dialog/EditFormReport";
import { ReactComponent as RecoMoveLeft } from "../../../assets/LeftChevron.svg";
import { ReactComponent as RecoMoveRight } from "../../../assets/RightChevron.svg";
import { useHistory } from "react-router";

const IconContainerBox = styled.span`
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;

  svg {
    position: absolute;
    top: 0px;
    left: 0;
    transition: opacity 0.1s ease;
  }

  svg.dark {
    opacity: 1;
  }

  svg.light {
    opacity: 0;
  }

  &:hover svg.dark {
    opacity: 0;
  }

  &:hover svg.light {
    opacity: 1;
  }

  .label {
    display: none;
    font-size: 9px;
    font-weight: 700;
    color: ${(props) => props.color};
  }

  &:hover .label {
    display: block;
    position: absolute;
    top: ${(props) => props.startGap || "-40%"};
    line-height: 12px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const CustomDatePickerTime = styled.div`
  & .react-datepicker__time-container {
    width: 100px;
  }

  & .react-datepicker__time {
    width: 100px;
  }

  & .react-datepicker__time-box {
    width: 100px !important;
  }
`;

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "28px",
    fontWeight: 700,
    color: "#0C2945",
    fontFamily: "Roboto Condensed",
  },
  saveBtn: {
    background: "#4dabf5",
    color: "white",
    padding: "3px 20px",
    marginTop: "24px",
    marginBottom: "21px",
    width: "145px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#4dabf5",
    },
    "&:disabled": {
      background: "#00AEEF4D",
      color: "white",
    },
  },
  accessionNumLabel: {
    fontFamily: "Roboto Condensed",
    fontSize: 12,
    lineHeight: "14.6px",
    fontWeight: 400,
    color: "#6D6E6E",
    textTransform: "uppercase",
  },
  accessionNum: {
    fontSize: 16,
    lineHeight: "21.7px",
    fontWeight: 600,
    color: "##000000",
  },
  iconConatiner: {
    cursor: "pointer",
  },
  backdropContainer: {
    height: "100vh",
    position: "absolute",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    boxShadow: "inset 0 0 0 1000px rgba(0,0,0,.5)",
  },
  backdropInnerConatiner: {
    margin: "0 auto",
    width: "95%",
    justifyContent: "space-between",
    display: "flex",
  },
  hide: {
    opacity: 0,
    pointerEvents:'none'

  },
  deleteBtn: {
    border: "none",
    background: "#F12B2C",
    color: "white",
    borderRadius: "20px",
    fontSize: "14px",
    padding: "3px 18px",
    lineHeight: "16.8px",
    fontWeight: 600,
  },
  noteBoxHeading: {
    fontSize: "18px",
    fontWeight: 700,
    color: "#0C2945",
    fontFamily: "Roboto Condensed",
  },
  noteBoxHeadingSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftGrid: {
    height: "auto",
    maxHeight: "529px",
    overflow: "scroll",
  },
  rightGrid: {
    height: "auto",
    maxHeight: "307px",
    overflow: "scroll",
  },
  innerLeftGrid: {
    width: "100%",
  },
  date: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#252733",
  },
  dateSection: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  headingFirstSection: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  noteBox: {
    border: "1px solid #D3D3D3",
    display: "flex",
    borderRadius: "4px",
    marginBottom: 20,
    padding: "20px 0px",
    background: "#FFFFFF",
  },
  noteHistoryBox: {
    display: "flex",
    borderRadius: "15px",
  },
  noteBoxRightSide: {
    width: "80%",
    cursor: "pointer",
  },
  noteBoxLeftSide: {
    width: "7%",
    display: "flex",
    justifyContent: "center",
  },
  historyHeading: {
    marginBottom: "20px",
    marginLeft: "4%",
    fontSize: "18px",
    fontWeight: "bold",
  },
  historyDate: {
    color: "rgba(0,0,0,.5)",
    fontSize: " 12px",
    marginBottom: 10,
  },
  addCircle: {
    cursor: "pointer",
    color: "#4dabf5",
  },
  noteIcon: {
    paddingRight: 5,
  },
  noData: {
    color: " #AAAEBD",
    textAlign: "center",
    fontSize: 16,
    padding: "15px 0",
  },
  notesBtnContainer: {
    marginBottom: "20px",
    width: "100%",
    display: "flex",
    gap: "22px",
    alignItems: "center",
  },
  // saveBtn: {
  //     background: '#4dabf5',
  //     color: 'white',
  //     width: '117px',
  //     height:'38px',
  //     textTransform:'capitalize',
  //     '&:hover': {
  //         background: '#4dabf5',
  //     }
  // },
  orderBtn: {
    background: "transparent",
    color: "#00AEEF",
    // width: '117px',
    height: "19px",
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "capitalize",
    "&:hover": {
      background: "transparent",
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  let { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <>{children}</>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "98%",
    margin: "0 auto",
  },
}))(MuiDialogContent);

const DialogContentStyled = styled(DialogContent)``;

const StyledRowHeader = styled.h2`
  font-family: Roboto Condensed;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.4px;
  color: #0c2945;
  margin-bottom: 15px;
`;

const StyledRowContainer = styled.div`
  display: flex;
  border: 1px solid #cacaca;
  padding: 20px;
  border-radius: 8px;
  gap: 15px;
  background: #fafafa;
  margin-bottom: 30px;
  flex-wrap: wrap;
`;

const StyledRowCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

const StyledRowLabel = styled.span`
  font-family: Roboto Condensed;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  letter-spacing: -0.03em;
`;

const StyledRowValue = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  letter-spacing: -0.03em;
`;

const SpinnerContainer = styled(Flex)`
  justify-content: center;
  margin: 30px 0;
  align-items: center;
`;

const StyledGrid = styled(Grid)`
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
`;

const ArrowUpwardIconStyled = styled(ArrowUpwardIcon)`
  cursor: pointer;
  color: ${({ active }) => (active ? "#00AEEF" : "#c3c3c3")};
`;

const ArrowDownwardIconStyled = styled(ArrowDownwardIcon)`
  cursor: pointer;
  color: ${({ active }) => (active ? "#00AEEF" : "#c3c3c3")};
`;

const NoteDescriptionSection = styled.div`
  display: -webkit-box;
  minheight: ${(props) => (props.isNoteClicked ? "180px" : "47px")};
  -webkit-line-clamp: ${(props) => (props.isNoteClicked ? "" : "2")};
  font-size: 13px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 21px;
  font-weight: 100px;
`;

const ClearButton = styled.button`
  position: absolute;
  margin-left: 550px;
  border-radius: 5%;
  z-index: 1;
  border: none;
  cursor: pointer;
  color: #000000;
  background-color: transparent;
  transform: translateX(2px);
  @media (max-width: 768px) {
    margin-left: -30px;
  }
`;

const DateClearButton = styled.div`
  cursor: pointer;
  display: flex;
`;

const TimeframeContainer = styled(Flex)`
  width: 80%;
  flex-direction: column;
  gap: 8px;
`;

const Timeframe = styled.p`
  margin: 0;
  padding: 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16.4px;
  letter-spacing: 0.2px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const DateFilterContainer = styled(Flex)`
  margin-bottom: 0px;
  justify-content: center;
  display: ${(props) =>
    props.isSelected !== undefined
      ? props.isSelected
        ? "block"
        : "none"
      : "block"};
`;

const DatePickerContainer = styled(Flex)`
  width: 227px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

const DatePickerButton = styled(Flex)`
  padding: 0px 10px;
  height: 43px;
  width: 227px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`;

const DateContainer = styled(Flex)`
  font-size: 14px;
  color: #828282;
  font-family: Open Sans;
  font-weight: 400;
`;

const taskOptions = [
  { value: "call", label: "Call" },
  { value: "fax", label: "Fax" },
  { value: "email", label: "Email" },
  { value: "sms", label: "SMS" },
  { value: "letter", label: "Letter" },
  { value: "general", label: "General" },
];

const priorityOptions = [
  { value: "high", label: "High" },
  { value: "medium", label: "Medium" },
  { value: "low", label: "Low" },
];

const SelectContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const SelectDropdownTitle = styled.div`
  margin-bottom: 4px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const IndicatorsContainer = (props) => {
  return (
    <>
      <components.Option {...props}>
        <span style={{ marginRight: 5, paddingTop: 5 }}>
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
            style={{ marginTop: 5 }}
          />{" "}
        </span>
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};

const BlobText = styled.span`
  font-size: 16px;
  color: #00aeef;
  margin-bottom: 0px;
  font-weight: 600;
  text-decoration: underline;
  line-height: 1.3;
  margin-right: 4px;
  width: 100%;
  text-decoration-thickness: 0.1px;
  text-underline-offset: 1px;
`;

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}))(MuiDialogActions);

const UserDataBlock = ({ note, avatar }) => {
  let [firstName, lastName] = note?.user?.name?.split(' ')
  let firstChar = note  && firstName?.[0] || ""
  let secondChar = note  && lastName?.[0] || ""
  return (
    <>
      <AvatarContainer style={{ marginRight: 0 }}>
        {avatar ? (
          <UserAvatar />
        ) : (
          <UserEmptyAvatar style={{ background: "#006186", color: "#ffffff" }}>
            {firstChar + secondChar}
          </UserEmptyAvatar>
        )}
      </AvatarContainer>
    </>
  );
};

const BackdropComponent = ({
  chevronHandler,
  selectedData,
  activeStatus,
  notShowBottom,
  setNotesOrder,
  setNotesPage,
  countsList,
  activeTab,
  setRequiredIndex,
  requiredIndex,
}) => {
  const classes = useStyles();

  const cohortsRecommendations = useSelector(
    (state) => state.radAdmin?.cohortsRecommendations?.recommendations
  );

  if (!selectedData || notShowBottom) return null;
  return (
    <div className={classes.backdropContainer}>
      <div className={classes.backdropInnerConatiner}>
        <span
          className={requiredIndex === 0 ? classes.hide : classes.iconConatiner}
          onClick={() => {
            setNotesOrder("ascending");
            setNotesPage(1);
            setRequiredIndex((prev) => prev - 1);
            chevronHandler("previous", requiredIndex - 1);
          }}
        >
          <RecoMoveLeft />
        </span>
        <span
          className={
            (activeTab === "to_be_validated"
              ? countsList?.to_be_validated_reco
              : activeTab === "validated"
              ? countsList?.validated_reco?.["empty"]
              : countsList?.validated_reco?.[activeTab]) !==
              cohortsRecommendations?.data?.length ||
            requiredIndex !== cohortsRecommendations?.data?.length - 1
              ? classes.iconConatiner
              : classes.hide
          }
          onClick={() => {
            setNotesOrder("ascending");
            setNotesPage(1);
            setRequiredIndex((prev) => prev + 1);
            chevronHandler("forward", requiredIndex + 1);
          }}
        >
          <RecoMoveRight />
        </span>
      </div>
    </div>
  );
};

export default function CreateTask({
  openDialog,
  selectedData,
  editModalHandle,
  setCountsList,
  cohortId,
  activeTab,
  setPage,
  setIsLoadingValidateRecommendation,
  chevronHandler,
  countsList,
  setSelectedData,
  page,
  setRequiredIndex,
  requiredIndex,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const modalities = useSelector(
    (state) => state.radAdmin.followUpList.modalities.data
  );
  const anatomies = useSelector(
    (state) => state.radAdmin.followUpList.anatomies.data
  );

  const fixedValues = useSelector(
    (state) => state.radAdmin.cohorts.fixedValues?.data
  );

  const modalityOptions = modalities?.length
    ? [
        {
          value: "empty",
          label: "Empty",
          category: "modality",
        },
      ].concat(
        modalities
          .map((item) => ({
            value: item,
            label: capitalize(item),
            category: "modality",
          }))
          .filter((item) => item.value)
      )
    : [];
  const anatomyOptions = anatomies?.length
    ? [
        {
          value: "empty",
          label: "Empty",
          category: "anatomy",
        },
      ].concat(
        anatomies
          .map((item) => ({
            value: item,
            label: capitalize(item),
            category: "anatomy",
          }))
          .filter((item) => item.value)
      )
    : [];

  const guidelineOptions = fixedValues?.length
    ? fixedValues
        .find((val) => val.attribute === "guideline_type")
        ?.values?.map((itm) => ({
          value: itm,
          label: itm,
          category: "guideline_type",
        }))
    : [];

  const conditionalOptions = [
    {
      value: "true",
      label: "true",
      category: "conditional",
    },
    {
      value: "false",
      label: "false",
      category: "conditional",
    },
  ];

  const testOptions = [
    { value: "screening", label: "Screening", category: "exam_type" },
    { value: "diagnostic", label: "Diagnostic", category: "exam_type" },
  ];

  const recommemdationOptions = [
    {
      value: "imaging_followup",
      label: "Imaging Followup",
      category: "recommendation_type",
    },
    {
      value: "non_imaging_followup",
      label: "Non Imaging Followup",
      category: "recommendation_type",
    },
  ];

  const contrastOptions = [
    { value: "with", label: "With", category: "contrast" },
    {
      value: "without",
      label: "Without",
      category: "contrast",
    },
    {
      value: "with-without",
      label: "With and Without",
      category: "contrast",
    },
  ];

  const [notesPage, setNotesPage] = useState(1);
  const [notesOrder, setNotesOrder] = useState("descending");
  const [savingTask, setSavingTask] = useState(false);
  const [state, setState] = useState({
    ptFirstName: "",
    ptLastName: "",
    ptEmail: "",
    ptPhone: "",
    ptDOB: "",
    ptAge: "",
    physicianFirstName: "",
    physicianLastName: "",
    physicianEmail: "",
    physicianPhone: "",
    physicianNpi: "",
    radFirstName: "",
    radLastName: "",
    radEmail: "",
    radPhone: "",
    anatomy: "",
    modality: "",
    range_start: "",
    range_end: "",
    reportText: "",
    relevantBlob: "",
    ptMrn: "",
    physicianFax: "",
    conditional: "",
    guidelineType: "",
    contrast: "",
    examType: "",
    recommendationType: "",
    contrast: "",
  });

  const notes = useSelector((state) => state.radAdmin.notes.data);
  const totalItems = useSelector((state) => state.radAdmin.notes.totalItems);
  const isLoading = useSelector((state) => state.radAdmin.notes.isLoading);
  const findingsBoiData = useSelector(
    (state) => state.radAdmin.followUpList.findingsBoi.data
  );
  const cohortsRecommendations = useSelector(
    (state) => state.radAdmin?.cohortsRecommendations?.recommendations
  );
  const [isNoteClicked, setIsNoteClicked] = useState(false);
  const [openEditNote, setOpenEditNote] = useState(false);
  const [openNewNote, setOpenNewNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);

  const [type, setType] = useState("");
  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);

  const updatePermission = rolePermissions
    ?.find((itm) => itm.name === "Recommendation Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "change_recommendationcohorts"
    )?.is_authorized;

  const addPermission = rolePermissions
    ?.find((itm) => itm.name === "Recommendation Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "add_recommendationcohorts"
    )?.is_authorized;

  const [selectedTask, setSelectedTask] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const [selectedAssignedTo, setSelectedAssignedTo] = useState("");
  const [date, setDate] = useState(null);

  const onChange = (dates) => {
    if (isArray(dates)) {
      const [start, end] = dates;
      setState((prev) => ({ ...prev, range_start: start, range_end: end }));
    }
  };
  const [assignedToOptions, setAssignedToOptions] = useState([]);

  useEffect(() => {
    dispatch(getModalitiesListAction());
    dispatch(getAnatomiesListAction());
  }, []);

  const clickHandler = (index) => {
    if (index === isNoteClicked) {
      setIsNoteClicked(false);
      return;
    }
    setIsNoteClicked(index);
  };

  const editClickHandler = (data) => {
    setSelectedNote(data);
    setOpenEditNote(!openEditNote);
    window.analytics.track("Recommendation-Task-List-Edit-Note");
  };
  const addNoteClickHandler = () => {
    if ((permission && addPermission) || !permission) {
      setOpenNewNote(!openNewNote);
      window.analytics.track("Recommendation-Task-List-New-Note");
    } else {
      dispatch(
        showAlert(
          "danger",
          "Error",
          `You don't have permission to change reco note`
        )
      );
    }
  };

  useEffect(() => {
    if (!isNull(selectedData) && selectedData !== undefined) {
      let {
        report,
        anatomy,
        modality,
        range_start,
        range_end,
        reportText,
        initialExamDate,
        conditional,
        guidelineType,
        examType,
        recommendationType,
        contrast,
      } = selectedData;
      let { patient, referring_physician, radiologist } = report;
      setState({
        ...state,
        ptFirstName: patient && patient.first_name ? patient.first_name : "",
        ptLastName: patient && patient.last_name ? patient.last_name : "",
        ptEmail: patient && patient.email ? patient.email : "",
        ptPhone: patient && patient.phone_number ? patient.phone_number : "",
        ptDOB: patient && patient.date_of_birth ? patient.date_of_birth : "",
        ptAge: patient && patient.age ? patient.age : "",
        physicianNpi:
          referring_physician && referring_physician.unique_id
            ? referring_physician.unique_id
            : "",
        physicianFirstName:
          referring_physician && referring_physician.first_name
            ? referring_physician.first_name
            : "",
        physicianLastName:
          referring_physician && referring_physician.last_name
            ? referring_physician.last_name
            : "",
        physicianEmail:
          referring_physician && referring_physician.email
            ? referring_physician.email
            : "",
        physicianPhone:
          referring_physician && referring_physician.phone_number
            ? referring_physician.phone_number
            : "",
        radFirstName:
          radiologist && radiologist.first_name ? radiologist.first_name : "",
        radLastName:
          radiologist && radiologist.last_name ? radiologist.last_name : "",
        radEmail: radiologist && radiologist.email ? radiologist.email : "",
        radPhone:
          radiologist && radiologist.phone_number
            ? parseInt(radiologist.phone_number)
            : "",
        anatomy: anatomy ? { label: anatomy, value: anatomy } : "",
        modality: modality ? { label: modality, value: modality } : "",
        range_start: range_start ? new Date(range_start) : "",
        range_end: range_end ? new Date(range_end) : "",
        reportText: reportText || "",
        initialExamDate: initialExamDate || "",
        relevantBlob: selectedData.relevantBlob || "",
        ptMrn: patient && patient?.mrn ? patient?.mrn : "",
        physicianFax:
          referring_physician && referring_physician?.fax
            ? referring_physician?.fax
            : "",
        conditional:
          (conditional === true
            ? { label: "true", value: "true" }
            : conditional === false
            ? { label: "false", value: "false" }
            : "") || "",
        guidelineType: guidelineType
          ? {
              label: capitalize(guidelineType.replaceAll("-", " ")),
              value: guidelineType,
            }
          : "",
        examType: examType ? { label: examType, value: examType } : "",
        recommendationType: recommendationType
          ? {
              label: capitalize(recommendationType.replaceAll("_", " ")),
              value: recommendationType,
            }
          : "",
        contrast: contrast
          ? {
              label: capitalize(contrast.replaceAll("-", " ")),
              value: contrast,
            }
          : "",
      });
    }
    if (selectedData) {
      dispatch(getNotes(selectedData.patientId, notesPage, notesOrder));
    }
  }, [selectedData?.id]);

  useEffect(() => {
    dispatch(getFindingsBoi(selectedData?.id));
  }, [selectedData?.id]);

  useEffect(() => {
    dispatch(getDropdownValuesForCohort("Recommendation", "guideline_type"));
  }, []);

  const LoadMoreNotes = () => {
    setNotesPage(notesPage + 1);
    dispatch(getNotes(selectedData.patientId, notesPage + 1, notesOrder));
  };

  const toggleNotesOrder = () => {
    setNotesOrder((prev) =>
      prev === "ascending" ? "descending" : "ascending"
    );
    setNotesPage(1);
    dispatch(
      getNotes(
        selectedData.patientId,
        1,
        notesOrder === "ascending" ? "descending" : "ascending"
      )
    );
  };

  useEffect(() => {
    dispatch(getUsersList()).then((res) => {
      if (res) {
        setAssignedToOptions(
          res.map((itm) => ({
            value: itm.id,
            label: itm.first_name + itm.last_name,
          }))
        );
      }
    });
  }, []);

  const DatePickerInput = forwardRef(({ onClick, startDate, endDate }, ref) => {
    const formatDate = (date) => {
      return date ? moment(date).format("MMM, DD, YYYY") : "No Date";
    };
    return (
      <DatePickerButton onClick={onClick} ref={ref}>
        <DateContainer>
          {startDate && endDate
            ? `${formatDate(startDate)} - ${formatDate(endDate)}`
            : "Select date range"}
        </DateContainer>
        {(!startDate || !endDate) && <CalendarIcon />}
        {startDate && endDate && (
          <DateClearButton
            onClick={() => {
              setState((prev) => ({ ...prev, startDate: null, endDate: null }));
            }}
          >
            <RemoveDateIcon style={{ height: "13px" }} />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });

  const DateTimePickerInput = forwardRef(({ onClick, date }, ref) => {
    const formatDate = (date) => {
      return date ? moment(date).format("MMM, DD, YYYY at HH:mm") : "No Date";
    };
    return (
      <DatePickerButton onClick={onClick} ref={ref}>
        <DateContainer>
          {date ? `${formatDate(date)}` : "Select date & time"}
        </DateContainer>
        {!date && <CalendarIcon />}
        {date && (
          <DateClearButton
            onClick={() => {
              setDate(null);
            }}
          >
            <RemoveDateIcon style={{ height: "13px" }} />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });

  const validateRecommendationHandler = () => {
    setIsLoadingValidateRecommendation(true);
    const query = new URLSearchParams(history.location.search);

    let search = query.get("search") ?? "";
    dispatch(
      validateRecommendationInCohort(
        selectedData.id,
        page,
        cohortId,
        search,
        activeTab,
        activeTab === "to_be_validated"
          ? countsList?.to_be_validated_reco ===
              cohortsRecommendations?.data?.length
          : activeTab === "validated"
          ? countsList?.validated_reco["empty"] ===
              cohortsRecommendations?.data?.length
          : countsList?.validated_reco?.[activeTab] ===
              cohortsRecommendations?.data?.length
      )
    ).then((res) => {
      setIsLoadingValidateRecommendation(false);
      if (res) {
        setNotesOrder("ascending");
        setNotesPage(1);
        chevronHandler("forward", requiredIndex);
        dispatch(getRecommendationsCountWithCohortId(cohortId, search)).then(
          (res) => {
            if (res) {
              setCountsList(res);
            }
          }
        );
      }
    });
  };

  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  const [selectedId, setSelectedId] = useState(null);

  const dialogHandler = () => {
    setRemoveDialogOpen(!removeDialogOpen);
    setSelectedId(selectedData.id);
    window.analytics.track("Recommendation-Delete-Button-Click");
  };

  const [isLoadingDeleteRecommendation, setIsLoadingDeleteRecommendation] =
    useState(false);

  const deleteRecommendationHandler = () => {
    setIsLoadingDeleteRecommendation(true);
    const query = new URLSearchParams(history.location.search);

    let search = query.get("search") ?? "";
    dispatch(
      deleteRecommendationInCohort(
        selectedId,
        page,
        cohortId,
        search,
        activeTab,
        activeTab === "to_be_validated"
          ? countsList?.to_be_validated_reco ===
              cohortsRecommendations?.data?.length
          : activeTab === "validated"
          ? countsList?.validated_reco["empty"] ===
              cohortsRecommendations?.data?.length
          : countsList?.validated_reco?.[activeTab] ===
              cohortsRecommendations?.data?.length
      )
    ).then((res) => {
      setIsLoadingDeleteRecommendation(false);
      if (res) {
        setNotesOrder("ascending");
        setNotesPage(1);
        chevronHandler("forward", requiredIndex);
        setRemoveDialogOpen(false);
        dispatch(getRecommendationsCountWithCohortId(cohortId, search)).then(
          (res) => {
            if (res) {
              setCountsList(res);
            }
          }
        );
      }
    });
  };

  return (
    <div>
      <Dialog
        fullWidth
        onClose={editModalHandle}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        maxWidth="lg"
        BackdropComponent={() => (
          <BackdropComponent
            setRequiredIndex={setRequiredIndex}
            requiredIndex={requiredIndex}
            activeTab={activeTab}
            setNotesOrder={setNotesOrder}
            setNotesPage={setNotesPage}
            chevronHandler={chevronHandler}
            selectedData={selectedData}
            countsList={countsList}
          />
        )}
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{
            boxShadow: "0px 8px 10px 0px #0000001A",
            zIndex: "99999!important",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 40,
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "25px",
              }}
            >
              <Typography className={classes.heading}>
                {activeTab === "to_be_validated"
                  ? "Recommendation Details"
                  : "Create Task"}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography className={classes.accessionNumLabel}>
                  Accession Number
                </Typography>
                <Typography className={classes.accessionNum}>
                  {selectedData && selectedData.accessionNumber}
                </Typography>
              </div>
              {activeTab !== "completed_tasks" && activeTab !=="not_completed_tasks" && (
                <>
                {
                  activeTab === "to_be_validated" && <>
                   <IconContainerBox color="#643268">
                    <ValidateIcon
                      className="dark"
                      onClick={(e) => {
                        e.stopPropagation();
                        if ((permission && updatePermission) || !permission) {
                          validateRecommendationHandler();
                        } else {
                          dispatch(
                            showAlert(
                              "danger",
                              "Error",
                              `You don't have permission to validate recommendation`
                            )
                          );
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <ValidateIconHover
                      onClick={(e) => {
                        e.stopPropagation();
                        if ((permission && updatePermission) || !permission) {
                          validateRecommendationHandler();
                        } else {
                          dispatch(
                            showAlert(
                              "danger",
                              "Error",
                              `You don't have permission to validate recommendation`
                            )
                          );
                        }
                      }}
                      className="light"
                      style={{ cursor: "pointer" }}
                    />
                  </IconContainerBox>
                  </>
                }
                 
                  <IconContainerBox color="#643268">
                    <RemoveRecoIcon
                      className="dark"
                      onClick={(e) => {
                        e.stopPropagation();
                        if ((permission && updatePermission) || !permission) {
                          dialogHandler();
                        } else {
                          dispatch(
                            showAlert(
                              "danger",
                              "Error",
                              `You don't have permission to delete recommendation`
                            )
                          );
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <RemoveRecoIconHover
                      onClick={(e) => {
                        e.stopPropagation();
                        if ((permission && updatePermission) || !permission) {
                          dialogHandler();
                        } else {
                          dispatch(
                            showAlert(
                              "danger",
                              "Error",
                              `You don't have permission to delete recommendation`
                            )
                          );
                        }
                      }}
                      className="light"
                      style={{ cursor: "pointer" }}
                    />
                  </IconContainerBox>
                </>
              )}
            </div>

            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={editModalHandle}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContentStyled>
          <div>
            {activeTab !== "to_be_validated" && (
              <>
                <StyledRowHeader>Task</StyledRowHeader>
                <StyledRowContainer>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <SelectDropdownTitle>Task *</SelectDropdownTitle>
                    <SelectContainer style={{ width: "227px" }}>
                      <Select
                        components={{ Option: IndicatorsContainer }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={taskOptions}
                        value={selectedTask}
                        onChange={(value) => setSelectedTask(value)}
                        styles={{
                          option: (styles) => ({ ...styles, fontSize: "14px" }),
                        }}
                      />
                    </SelectContainer>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <SelectDropdownTitle>Assigned To *</SelectDropdownTitle>
                    <SelectContainer style={{ width: "227px" }}>
                      <Select
                        components={{ Option: IndicatorsContainer }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={assignedToOptions}
                        value={selectedAssignedTo}
                        onChange={(value) => setSelectedAssignedTo(value)}
                        styles={{
                          option: (styles) => ({ ...styles, fontSize: "14px" }),
                        }}
                      />
                    </SelectContainer>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <SelectDropdownTitle>Due Date & Time *</SelectDropdownTitle>
                    <CustomDatePickerTime>
                      <DatePicker
                        selected={date}
                        onChange={(date) => setDate(date)}
                        showTimeSelect
                        timeIntervals={30}
                        customInput={<DateTimePickerInput date={date} />}
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: "viewport",
                          },
                        }}
                        minDate={new Date()}
                      />
                    </CustomDatePickerTime>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <SelectDropdownTitle>Priority *</SelectDropdownTitle>
                    <SelectContainer style={{ width: "227px" }}>
                      <Select
                        components={{ Option: IndicatorsContainer }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={priorityOptions}
                        value={selectedPriority}
                        onChange={(value) => setSelectedPriority(value)}
                        styles={{
                          option: (styles) => ({ ...styles, fontSize: "14px" }),
                        }}
                      />
                    </SelectContainer>
                  </div>
                </StyledRowContainer>
              </>
            )}

            <div style={{ marginBottom: "30px" }}>
              <Grid container>
                <EditNote
                  openDialog={openEditNote}
                  editClickHandler={editClickHandler}
                  selectedData={selectedData}
                  selectedNote={selectedNote}
                />
                {openNewNote && (
                  <NewNote
                    notesOrder={notesOrder}
                    openDialog={openNewNote}
                    addNoteClickHandler={addNoteClickHandler}
                    selectedData={selectedData}
                    setNotesPage={setNotesPage}
                  />
                )}
                <StyledGrid item xs={12} sm={12} lg={12}>
                  <Grid className={classes.innerLeftGrid}>
                    <Grid>
                      <StyledRowHeader>Notes</StyledRowHeader>
                    </Grid>
                    <StyledGrid
                      style={{
                        border: "1px solid #CACACA",
                        borderRadius: "8px",
                        backgroundColor: "#FAFAFA",
                        padding: "20px",
                        height: "auto",
                        maxHeight: "529px",
                        overflowY: "scroll",
                      }}
                    >
                      <div className={classes.notesBtnContainer}>
                        <Button
                          className={classes.saveBtn}
                          onClick={addNoteClickHandler}
                        >
                          New Note
                        </Button>
                        <Button
                          disableRipple
                          endIcon={
                            notesOrder === "ascending" ? (
                              <ArrowUpwardIconStyled
                                style={{ fontSize: "18px" }}
                                active={true}
                              />
                            ) : (
                              <ArrowDownwardIconStyled
                                style={{ fontSize: "18px" }}
                                active={true}
                              />
                            )
                          }
                          className={classes.orderBtn}
                          onClick={toggleNotesOrder}
                        >
                          {notesOrder === "ascending"
                            ? "Oldest to Newest"
                            : "Newest to Oldest"}
                        </Button>
                      </div>
                      {(isNull(notes) || (notes && notes.length === 0)) &&
                      !isLoading ? (
                        <Grid>
                          <p className={classes.noData}>No data</p>{" "}
                        </Grid>
                      ) : (
                        ""
                      )}
                      <Grid>
                        {isLoading ? (
                          <SpinnerContainer>
                            {" "}
                            <Spinner
                              animation="border"
                              role="status"
                              style={{ width: 30, height: 30 }}
                            />
                          </SpinnerContainer>
                        ) : (
                          notes &&
                          notes.length > 0 &&
                          notes.map((note, index) => {
                            return (
                              <Grid key={index} className={classes.noteBox}>
                                <Grid className={classes.noteBoxLeftSide}>
                                  <UserDataBlock note={note} />
                                </Grid>
                                <Grid
                                  className={classes.noteBoxRightSide}
                                  onClick={() => clickHandler(index)}
                                >
                                  <Grid
                                    className={classes.noteBoxHeadingSection}
                                  >
                                    <Typography
                                      className={classes.noteBoxHeading}
                                    >
                                      {note.title}
                                    </Typography>
                                    <Grid className={classes.dateSection}>
                                      <span className={classes.date}>
                                        {moment(note.created).format("ll")}
                                      </span>
                                    </Grid>
                                  </Grid>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      gap: "19px",
                                    }}
                                  >
                                    <NoteDescriptionSection
                                      isNoteClicked={isNoteClicked === index}
                                    >
                                      {note.note_text}
                                    </NoteDescriptionSection>
                                    <span
                                      onClick={() => editClickHandler(note)}
                                      style={{
                                        cursor: "pointer",
                                        marginRight: "13px",
                                      }}
                                    >
                                      <EditIcon />
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            );
                          })
                        )}
                      </Grid>
                      {!isLoading && notes && notes.length !== 0 && (
                        <button
                          disabled={totalItems === notes.length}
                          onClick={
                            totalItems !== notes.length
                              ? () => LoadMoreNotes()
                              : null
                          }
                          style={{
                            border: "2px solid #00AEEF",
                            color: "#00AEEF",
                            background: "white",
                            borderRadius: "2px",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            margin: "0 auto",
                            alignItems: "center",
                            cursor:
                              totalItems === notes.length
                                ? "not-allowed"
                                : "pointer",
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            {isLoading ? (
                              <Spinner
                                animation="border"
                                role="status"
                                size="sm"
                              />
                            ) : (
                              <>
                                Load More
                                <img src={loadMoreIcon} />
                              </>
                            )}
                          </span>
                        </button>
                      )}
                    </StyledGrid>
                  </Grid>
                </StyledGrid>
              </Grid>
            </div>

            <StyledRowHeader>Recommendation Elements</StyledRowHeader>
            <StyledRowContainer>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SelectDropdownTitle>Anatomy</SelectDropdownTitle>
                <SelectContainer style={{ width: "227px" }}>
                  <Select
                    components={{ Option: IndicatorsContainer }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={anatomyOptions}
                    value={state.anatomy}
                    onChange={(value) =>
                      setState((prev) => ({ ...prev, anatomy: value }))
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                    }}
                  />
                </SelectContainer>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SelectDropdownTitle>Modality</SelectDropdownTitle>
                <SelectContainer style={{ width: "227px" }}>
                  <Select
                    components={{ Option: IndicatorsContainer }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={modalityOptions}
                    value={state.modality}
                    onChange={(value) =>
                      setState((prev) => ({ ...prev, modality: value }))
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                    }}
                  />
                </SelectContainer>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SelectDropdownTitle>Timeframe</SelectDropdownTitle>

                <DateFilterContainer>
                  <TimeframeContainer>
                    <DatePickerContainer>
                      <DatePicker
                        popperPlacement={"auto"}
                        selected={state.range_start}
                        onChange={onChange}
                        startDate={state.range_start}
                        endDate={state.range_end}
                        customInput={
                          <DatePickerInput
                            startDate={state.range_start}
                            endDate={state.range_end}
                          />
                        }
                        shouldCloseOnSelect={false}
                        selectsRange
                        dateFormat={"dd/MM/yyyy"}
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: "5px, 10px",
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: "viewport",
                          },
                        }}
                      />
                    </DatePickerContainer>
                  </TimeframeContainer>
                </DateFilterContainer>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SelectDropdownTitle>Conditional</SelectDropdownTitle>
                <SelectContainer style={{ width: "227px" }}>
                  <Select
                    components={{ Option: IndicatorsContainer }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={conditionalOptions}
                    value={state.conditional}
                    onChange={(value) =>
                      setState((prev) => ({ ...prev, conditional: value }))
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                    }}
                  />
                </SelectContainer>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SelectDropdownTitle>Guideline Type</SelectDropdownTitle>
                <SelectContainer style={{ width: "227px" }}>
                  <Select
                    components={{ Option: IndicatorsContainer }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={guidelineOptions}
                    value={state.guidelineType}
                    onChange={(value) =>
                      setState((prev) => ({ ...prev, guidelineType: value }))
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                    }}
                  />
                </SelectContainer>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SelectDropdownTitle>Exam Type</SelectDropdownTitle>
                <SelectContainer style={{ width: "227px" }}>
                  <Select
                    components={{ Option: IndicatorsContainer }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={testOptions}
                    value={state.examType}
                    onChange={(value) =>
                      setState((prev) => ({ ...prev, examType: value }))
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                    }}
                  />
                </SelectContainer>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SelectDropdownTitle>Contrast</SelectDropdownTitle>
                <SelectContainer style={{ width: "227px" }}>
                  <Select
                    components={{ Option: IndicatorsContainer }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={contrastOptions}
                    value={state.contrast}
                    onChange={(value) =>
                      setState((prev) => ({ ...prev, contrast: value }))
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                    }}
                  />
                </SelectContainer>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SelectDropdownTitle>Recommendation Type</SelectDropdownTitle>
                <SelectContainer style={{ width: "227px" }}>
                  <Select
                    components={{ Option: IndicatorsContainer }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={recommemdationOptions}
                    value={state.recommendationType}
                    onChange={(value) =>
                      setState((prev) => ({
                        ...prev,
                        recommendationType: value,
                      }))
                    }
                    styles={{
                      option: (styles) => ({ ...styles, fontSize: "14px" }),
                    }}
                  />
                </SelectContainer>
              </div>
            </StyledRowContainer>

            <StyledRowHeader>Patient Details</StyledRowHeader>
            <StyledRowContainer>
              <StyledRowCol>
                <StyledRowLabel>First Name</StyledRowLabel>
                <StyledRowValue>{state?.ptFirstName}</StyledRowValue>
              </StyledRowCol>
              <StyledRowCol>
                <StyledRowLabel>Last Name</StyledRowLabel>
                <StyledRowValue>{state?.ptLastName}</StyledRowValue>
              </StyledRowCol>
              <StyledRowCol>
                <StyledRowLabel>Email</StyledRowLabel>
                <StyledRowValue>{state?.ptEmail}</StyledRowValue>
              </StyledRowCol>
              <StyledRowCol>
                <StyledRowLabel>Date Of Birth</StyledRowLabel>
                <StyledRowValue>{state?.ptDOB}</StyledRowValue>
              </StyledRowCol>
              <StyledRowCol>
                <StyledRowLabel>Phone Number</StyledRowLabel>
                <StyledRowValue>{state?.ptPhone}</StyledRowValue>
              </StyledRowCol>
            </StyledRowContainer>
            <StyledRowHeader>Radiologists</StyledRowHeader>
            <StyledRowContainer>
              <StyledRowCol>
                <StyledRowLabel>First Name</StyledRowLabel>
                <StyledRowValue>{state?.radFirstName}</StyledRowValue>
              </StyledRowCol>
              <StyledRowCol>
                <StyledRowLabel>Last Name</StyledRowLabel>
                <StyledRowValue>{state?.radLastName}</StyledRowValue>
              </StyledRowCol>
              <StyledRowCol>
                <StyledRowLabel>Email</StyledRowLabel>
                <StyledRowValue>{state?.radEmail}</StyledRowValue>
              </StyledRowCol>
              <StyledRowCol>
                <StyledRowLabel>Phone Number</StyledRowLabel>
                <StyledRowValue>{state?.radPhone}</StyledRowValue>
              </StyledRowCol>
            </StyledRowContainer>
            <StyledRowHeader>Referring Physician</StyledRowHeader>
            <StyledRowContainer>
              <StyledRowCol>
                <StyledRowLabel>First Name</StyledRowLabel>
                <StyledRowValue>{state?.physicianFirstName}</StyledRowValue>
              </StyledRowCol>
              <StyledRowCol>
                <StyledRowLabel>Last Name</StyledRowLabel>
                <StyledRowValue>{state?.physicianLastName}</StyledRowValue>
              </StyledRowCol>
              <StyledRowCol>
                <StyledRowLabel>Email</StyledRowLabel>
                <StyledRowValue>{state?.physicianEmail}</StyledRowValue>
              </StyledRowCol>
              <StyledRowCol>
                <StyledRowLabel>Phone Number</StyledRowLabel>
                <StyledRowValue>{state?.physicianPhone}</StyledRowValue>
              </StyledRowCol>
            </StyledRowContainer>
            <StyledRowHeader>
              Recommendation Statement Extracted
            </StyledRowHeader>
            <StyledRowContainer>
              {state.relevantBlob ? (
                <BlobText>{state.relevantBlob}</BlobText>
              ) : (
                <div className={classes.noData}> No Data</div>
              )}
            </StyledRowContainer>

            <StyledRowHeader>Structured Findings</StyledRowHeader>
            <StyledRowContainer>
              <div style={{ display: "flex" }}>
                {findingsBoiData?.data?.results?.filter((itm) => itm.boi)
                  ?.length === 0 ? (
                  <div className={classes.noData}> No Data</div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    {" "}
                    {findingsBoiData?.data?.results.slice(0, 10).map((itm) => (
                      <BlobText style={{ padding: "0px" }}>{itm.boi}</BlobText>
                    ))}
                  </div>
                )}
              </div>
            </StyledRowContainer>

            <div style={{ marginTop: "30px" }}>
              <EditFormReport state={state} />
            </div>
          </div>
        </DialogContentStyled>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            boxShadow: "0px -4px 10px 0px #0000001A",
          }}
        >
          {activeTab === "to_be_validated" ? (
            <Button
              className={classes.saveBtn}
              onClick={() => {
                if (
                  selectedData?.anatomy !== (state.anatomy?.value ?? null) ||
                  selectedData?.modality !== (state.modality?.value ?? null) ||
                  moment(selectedData?.range_start).format("YYYY-MM-DD") !==
                    moment(state.range_start).format("YYYY-MM-DD") ||
                  moment(selectedData?.range_end).format("YYYY-MM-DD") !==
                    moment(state.range_end).format("YYYY-MM-DD") ||
                  selectedData?.contrast !== (state.contrast?.value ?? null) ||
                  selectedData?.guidelineType !==
                    (state.guidelineType?.value ?? null) ||
                  selectedData?.examType !== (state.examType?.value ?? null) ||
                  selectedData?.conditional !==
                    (state.conditional?.value ?? null) ||
                  selectedData?.recommendationType !==
                    (state.recommendationType?.value ?? null)
                ) {
                  if ((permission && updatePermission) || !permission) {
                    setSavingTask(true);
                    setType("others");
                    dispatch(
                      updateRecommendationTaskElements(selectedData.id, {
                        user_range_start: state.range_start
                          ? moment(state.range_start).format("YYYY-MM-DD")
                          : "",
                        user_range_end: state.range_end
                          ? moment(state.range_end).format("YYYY-MM-DD")
                          : "",
                        user_modality: state.modality?.value,
                        user_anatomy: state.anatomy?.value,
                        contrast: state.contrast?.value,
                        conditional: state.conditional?.value,
                        recommendation_type: state.recommendationType?.value,
                        guideline_type: state.guidelineType?.value,
                        exam_type: state.examType?.value,
                      })
                    ).then((res) => {
                      editModalHandle();
                    });
                  } else {
                    dispatch(
                      showAlert(
                        "danger",
                        "Error",
                        `You don't have permission to update reco details`
                      )
                    );
                  }
                } else {
                  editModalHandle();
                }
              }}
            >
              {savingTask && type === "others" ? (
                <Spinner
                  animation="border"
                  role="status"
                  style={{ width: 30, height: 30 }}
                />
              ) : (
                "Save"
              )}
            </Button>
          ) : (
            <Button
              disabled={
                !selectedPriority ||
                !selectedAssignedTo ||
                !selectedTask ||
                !date
              }
              className={classes.saveBtn}
              onClick={() => {
                if ((permission && addPermission) || !permission) {
                  setSavingTask(true);
                  setType("others");
                  dispatch(
                    createTask({
                      due_at: date,
                      assigned_to: selectedAssignedTo?.value,
                      recommendation: selectedData?.id,
                      priority: selectedPriority?.value,
                      type: selectedTask?.value,
                    })
                  ).then((res) => {
                    setSavingTask(false);
                    if (res) {
                      dispatch(
                        getRecommendationsCountWithCohortId(cohortId)
                      ).then((res) => {
                        if (res) {
                          setCountsList(res);
                        }
                      });
                    }
                  });
                } else {
                  dispatch(
                    showAlert(
                      "danger",
                      "Error",
                      `You don't have permission to add task`
                    )
                  );
                }

                if (
                  selectedData?.anatomy !== (state.anatomy?.value ?? null) ||
                  selectedData?.modality !== (state.modality?.value ?? null) ||
                  moment(selectedData?.range_start).format("YYYY-MM-DD") !==
                    moment(state.range_start).format("YYYY-MM-DD") ||
                  moment(selectedData?.range_end).format("YYYY-MM-DD") !==
                    moment(state.range_end).format("YYYY-MM-DD") ||
                  selectedData?.contrast !== (state.contrast?.value ?? null) ||
                  selectedData?.guidelineType !==
                    (state.guidelineType?.value ?? null) ||
                  selectedData?.examType !== (state.examType?.value ?? null) ||
                  selectedData?.conditional !==
                    (state.conditional?.value ?? null) ||
                  selectedData?.recommendationType !==
                    (state.recommendationType?.value ?? null)
                ) {
                  if ((permission && updatePermission) || !permission) {
                    dispatch(
                      updateRecommendationTaskElements(selectedData.id, {
                        user_range_start: state.range_start
                          ? moment(state.range_start).format("YYYY-MM-DD")
                          : "",
                        user_range_end: state.range_end
                          ? moment(state.range_end).format("YYYY-MM-DD")
                          : "",
                        user_modality: state.modality?.value,
                        user_anatomy: state.anatomy?.value,
                        contrast: state.contrast?.value,
                        conditional: state.conditional?.value,
                        recommendation_type: state.recommendationType?.value,
                        guideline_type: state.guidelineType?.value,
                        exam_type: state.examType?.value,
                      })
                    ).then((res) => {
                      editModalHandle();
                    });
                  } else {
                    dispatch(
                      showAlert(
                        "danger",
                        "Error",
                        `You don't have permission to update reco details`
                      )
                    );
                  }
                } else {
                  editModalHandle();
                }
              }}
            >
              {savingTask && type === "others" ? (
                <Spinner
                  animation="border"
                  role="status"
                  style={{ width: 30, height: 30 }}
                />
              ) : (
                "Save"
              )}
            </Button>
          )}
        </div>
      </Dialog>
      <RemoveConfirmation
        content="Are you sure you want to remove this recommendation?"
        openDialog={removeDialogOpen}
        dialogHandler={dialogHandler}
        isLoading={isLoadingDeleteRecommendation}
        deleteHandler={deleteRecommendationHandler}
        heading={"Remove Recommendation"}
        removeHeader={"Yes, Remove Recommendation"}
      />
    </div>
  );
}
