import React, { useEffect, useState } from "react";
import Table from "../../../components/DetailsTable";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import moment from "moment";

import { ReactComponent as IconExpanded } from "../../../assets/expanded.svg";
import { ReactComponent as IconCollapsed } from "../../../assets/collapsed.svg";

import {
  getNotesAnalytics,
  getNotesData,
  showAlert,
} from "../../../actions/radminUploads";

import { PaginationLeftArrow, PaginationRightArrow } from "../Cohorts/Icons";
import NotesExportModal from "./NotesExportModal";
import RecoNotesAnalytics from "../FollowUpList/RecoNotesAnalytics";

const Heading = styled.p`
    text-align: ${(props) => (props.align ? props.align : "center")}
    font-weight: 700;
    font-size: 24px;
    line-height: 28.8px;
    font-family: 'Roboto Condensed';
    color: #0C2945;
`;

const Container = styled.div`
  margin: 0 auto;
  width: 98%;
  margin-top: 20px;
`;

export const NotesContainer = styled.div`
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  border-radius: 8px;
  padding: 15px;
  height: 20rem;
`;
export const PrescriptionContainer = styled.div`
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  border-radius: 8px;
  padding: 15px;
`;

const PageContainer = styled.div`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: center;
`;
const IconContainer = styled.div`
  cursor: pointer;
  padding-right: ${(props) => props.paddingRight};
  padding-left: ${(props) => props.paddingLeft}
  margin-top: ${(props) => props.marginTop}
`;

const NoteComponent = ({ noteText }) => {
  const [showFullNote, setShowFullNote] = useState(false);

  const toggleNoteVisibility = () => {
    window.analytics.track(`Notes-List-Note-Visibility-${!showFullNote?'Expanded':'Collapsed'}-Click`);
    setShowFullNote(!showFullNote);
  };

  return (
    <span>
      {showFullNote ? (
        <div style={{ display: "flex", alignItems: "flex-start", justifyContent:'flex-start', gap: "21px" }}>
          <span style={{ maxWidth: "400px" }}>{noteText}</span>

          <IconExpanded
            style={{ width: 18, height: 18, cursor: "pointer" }}
            onClick={toggleNoteVisibility}
          />
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "flex-start", justifyContent:'flex-start', gap: "21px" }}>
          <span style={{ maxWidth: "400px" }}>{ noteText.length>=100? noteText.slice(0, 100) + '...' : noteText}</span>
          {
            noteText.length>=100 &&   <IconCollapsed
            style={{ width: 18, height: 18, cursor: "pointer" }}
            onClick={toggleNoteVisibility}
          />
          }
        </div>
      )}
    </span>
  );
};

const Notes = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const notesColumns = [
    {
      key: "id",
      name: "Id",
    },
    {
      key: "created",
      name: "Created Date",
    },
    {
      key: "user",
      name: "User",
    },
    {
      key: "patient",
      name: "Patient",
      displayCell: (patient) => (
        <span
          style={{ cursor: "pointer", color: "#00AEEF" }}
          onClick={() =>{
            window.analytics.track(`Notes-List-Patient-MRN-Click`);
            history.push(
              `/auth/radAdmin/followUpList/patients/patientDetails/${patient.id}`
            )
          }
        }
        >{`${patient.name}`}</span>
      ),
    },
    {
      key: "task",
      name: "Task",
    },
    {
      key: "recipient",
      name: "Recipient",
    },
    {
      key: "title",
      name: "Title",
    },
    {
      key: "note_text",
      name: "Note text",
      maxWidth:"500px",
      width:'500px',
      displayCell: (note) => {
        return <NoteComponent noteText={note} />;
      },
    },
  ];

  const notesData = useSelector((state) => state.radAdmin.allNotes?.data);

  const isLoadingNotes = useSelector(
    (state) => state.radAdmin.allNotes?.isLoading
  );
  const totalNotes = useSelector(
    (state) => state.radAdmin.allNotes?.totalItems
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [openModal, setOpenModal] = useState(false);

  const totalNotesPages = Math.ceil(totalNotes / 10);
    
  const permission = useSelector((state) => state.auth.user.permission_level); 
  const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
  );
  const exportPermission = rolePermissions?.find(itm=>itm.name ==="Export")?.permissions.find(itm=>itm.codename === "add_export")?.is_authorized;
 
  const notesRows = notesData?.map((note) => {
    return {
      id: note.id,
      user: `${note.user.email}`,
      created: moment(note.created).format("MM-DD-YYYY"),
      task: note.task,
      recipient: note.recipient,
      title: note.title,
      note_text: note.note_text,
      patient: note.patient,
    };
  });

  useEffect(() => {
    dispatch(getNotesAnalytics());
  }, []);

  useEffect(() => {
    dispatch(getNotesData(pageNumber));
  }, [pageNumber]);

  const handlePreviousPage = () => {
    window.analytics.track(`Notes-List-Pagination-Previous-Click`);
    pageNumber > 1 && setPageNumber(pageNumber - 1);
  };

  const handleNextPage = () => {
    window.analytics.track(`Notes-List-Pagination-Next-Click`);
    totalNotesPages > pageNumber && setPageNumber(pageNumber + 1);
  };

  const handleClose = () => {
    window.analytics.track("Notes-Export-Modal-Closed")
    setOpenModal(false);
  };

  return (
    <>
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems:'center'
          }}
        >
            <Heading style={{ textAlign: "left" }}>Notes</Heading>
          <button
            style={{
              background: "#06739D",
              color: "white",
              borderRadius: "4px",
              border: "none",
              height:'38px',
              fontWeight:600,
              fontSize:'14px',
              width:'114px'
            }}
            onClick={() => {
              if((permission && exportPermission) || !permission){
                window.analytics.track("Notes-Export-Modal-Click")
                setOpenModal(true)
             }else{
               dispatch(
                 showAlert('danger', 'Error', `You don't have permission to export notes`)
               );
             }
             
            }}
          >
            Export Notes
          </button>
        </div>
  
        <RecoNotesAnalytics containerStyles={{marginTop:'29px'}} />
  
        <Row style={{ marginBottom: "20px" }}>
          <Col sm={12} lg={12} xl={12}>
            <PrescriptionContainer>
              <Table
                columns={notesColumns}
                rows={notesRows && notesRows}
                isLoadingRow={isLoadingNotes}
                isPaginated={true}
              />
              {!isLoadingNotes && notesRows?.length ? (
                <PageContainer>
                  <IconContainer
                    paddingRight="7px"
                    onClick={handlePreviousPage}
                  >
                    <PaginationLeftArrow /> Previous
                  </IconContainer>
                  {` ${pageNumber} of ${totalNotesPages} `}
                  <IconContainer
                    marginTop="-1px"
                    paddingLeft="7px"
                    onClick={handleNextPage}
                  >
                    Next <PaginationRightArrow />
                  </IconContainer>
                </PageContainer>
              ) : (
                <></>
              )}
            </PrescriptionContainer>
          </Col>
        </Row>
      </Container>

      {openModal && (
        <NotesExportModal opened={openModal} onClose={handleClose} />
      )}
    </>
  );
};

export default Notes;
