import API from '../libs/api';

export const GET_CONSTANTS_FETCH = 'GET_CONSTANTS_FETCH';
export const GET_CONSTANTS_RESOLVE = 'GET_CONSTANTS_RESOLVE';
export const GET_CONSTANTS_REJECT = 'GET_CONSTANTS_REJECT';

export const getConstants = () => async (dispatch, getState) => {
    try {
        const loading = getState().constants.loading;
        if (loading) return;

        dispatch({ type: GET_CONSTANTS_FETCH });

        const resFlags = await API.getRequest('/api/v1/feature_flag/?page_size=100')

        dispatch({
            type: GET_CONSTANTS_RESOLVE,
            payload: {
                featureFlags: resFlags?.data?.results?.map((r) => ({ ...r, value: r.value.toLowerCase() === 'true' }))
            }
        });
    } catch (error) {
        console.error(error);
        dispatch({ type: GET_CONSTANTS_REJECT });
    }
};