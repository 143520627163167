import API from '../../libs/api';
import { showAlert } from '../radminUploads';

export const LOGIN = 'login';
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_FAILED = 'login_failed';
export const LOGIN_COMPLETED = 'LOGIN_COMPLETED';


export const LOGGED_IN = 'logged_in';
export const LOGOUT = 'logout';
export const LOGGED_OUT = 'logged_out';

export const GET_USER_INFO = 'get_user_info';
export const GET_USER_INFO_SUCCESS = 'get_user_info_success';

export const RESET_AUTH_ERROR = 'RESET_AUTH_ERROR';




export const CREATE_VERIFICATION_DEVICE_REQUEST = 'CREATE_VERIFICATION_DEVICE_REQUEST';
export const CREATE_VERIFICATION_DEVICE_SUCCESS = 'CREATE_VERIFICATION_DEVICE_SUCCESS';

export const SET_IS_HAVE_UPLOADS = 'SET_IS_HAVE_UPLOADS';

export const GET_RECO_CONFIG_SUCCESS = 'GET_RECO_CONFIG_SUCCESS';

export const GET_PROVIDERS_LIST_SUCCESS = 'GET_PROVIDERS_LIST_SUCCESS';


export const login = ({ email, password, history }) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: LOGIN });

      const response = await API.postRequest('/auth/token/login/', { email, password });

      dispatch({ type: LOGIN_SUCCESS, payload: { token: response.data.auth_token, user: { email } } });

      const user = await dispatch(loadUserInfo());
      if (user.mfa_enabled && user.verification_device === null) {
        history.push('/verification-for-device');
        return;
      }
      if (user.mfa_enabled && user.verification_device && !user.is_verified) {
        history.push('/multi-factor-authentication');
        return;
      }

      if(user.is_verified){
        dispatch({ type: LOGIN_SUCCESS, payload: { token: getState().auth.token, user: { email: getState().auth.user.email }, isVerifiedUser: true } });
        window.analytics.track('Login Success', { email });

        if (user.role === 'doctor') {
          history.push('/auth/appointments');
          return;
        }
        const isUserHaveUploads = getState().auth.user.isHaveUploads;
  
        if (user.role === 'radiology_admin' && isUserHaveUploads) {
          history.push('/auth/radAdmin/Overview');
          return;
        }
        if (user.role === 'radiology_admin' && !isUserHaveUploads) {
          history.push('/auth/radAdmin/Dashboard');
          return;
        }
  
        const planId = getState().checkout.planId;
  
        if (planId) {
          history.push(`/plans/${planId}/checkout`);
          return;
        }
  
        history.push('/auth/dashboard');
      }
   
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_FAILED, payload: { error: 'Incorrect email and/or password.' } });
      window.analytics.track('Login Error', { email });
    } finally{
      dispatch({ type: LOGIN_COMPLETED });
    }
  };
};


export const loadUserInfo = () => {
  return async (dispatch) => {
    try {
      const { data : user } = await API.getRequest(`/api/v1/user/me/`);
      const results = user.reco_config  
      const providerData = user.provider
      const data = user.providers

      const responseData = {
        conditional: providerData?.see_conditional_recommendation,
        negated: providerData?.see_negated_recommendation,
        provider: results[0]?.provider,
        rangeStart: results[0]?.default_days_to_maturity,
        rangeEnd: results[0]?.default_days_to_expiry,
        unequalModRangeStart: results[0]?.unequal_modality_default_days_to_maturity,
        unequalModRangeEnd: results[0]?.unequal_modality_default_days_to_expiry,
        nlp_post_impression: providerData?.nlp_post_impression,
        id: results[0]?.id,
        name: providerData?.name,
        impliedModality: results[0]?.implied_modality_imputation,
        excludedExamCodes: results[0]?.excluded_exam_codes,
        mammogramInEmails:  results[0]?.mammogram_in_emails,
        attributionValue: results[0]?.attribution_window_adherence,
        defaultRangeStart: results[0]?.default_initial_exam_date_gte_days,
        defaultRangeEnd: results[0]?.default_initial_exam_date_lte_days,
        showDefaultDate: results[0]?.default_initial_exam_date_enabled,
        showStatusOverride: results[0]?.calculate_reco_status,
        patientAgeInterval:results[0]?.patient_age_interval
      };

      
      dispatch({ type: GET_RECO_CONFIG_SUCCESS, payload: responseData });

      dispatch({ type: GET_PROVIDERS_LIST_SUCCESS, payload: data });
      
      let roles;
      if(user?.permission_level){
       roles = await API.getRequest(`/api/v1/invited-users-access/?permission_level=${user.permission_level}&page_size=${100}`);
      }
      if(user?.mfa_enabled && !user?.is_verified){
        return user
      }

      window.analytics.identify(user.id, { email: user.email, role: user.role });
    
      const isHaveUploads = true;

      const payload = {
        ...user,
        isHaveUploads,
        permissions:roles?.data?.results || []
      };

      dispatch({
        type: GET_USER_INFO_SUCCESS,
        payload: {
          user: payload
        }
      });


      return payload;
    } catch (error) {
      console.error(error);
      dispatch(logout());
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    await API.postRequest('/auth/token/logout/').catch(console.error);
    dispatch({ type: LOGOUT });
    window.analytics.reset();
  };
};

export const resetAuthError = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: RESET_AUTH_ERROR
      });
    } catch (err) {}
  };
};



export const SetupVerificationDevice = ( phoneNumber ) => {
  return async (dispatch) => {
      dispatch({ type: CREATE_VERIFICATION_DEVICE_REQUEST })
      await API.postRequest(`/api/v1/verification_device/`, {
        phone_number: `+${phoneNumber}`
      })
  };
};

export const MultiFactorAuthentication = ( code ) => {
  return async (dispatch, getState) => {
    try {
      await API.postRequest(`/api/v1/verification_device/verify_token/`, {
        token: code
      });
      const user = await dispatch(loadUserInfo())
      if(user.is_verified){
        dispatch({ type: LOGIN_SUCCESS, payload: { token: getState().auth.token, user: { email: getState().auth.user.email }, isVerifiedUser: true } });
        return {success: true, user: getState().auth.user }
      }
    } catch (err) {
      return err
    }
  };
};

export const resendVerificationToken = () => {
  return async (dispatch) => {
    try {
      await API.getRequest(`/api/v1/verification_device/resend_token`);
      return {
        success: true
      }
    } catch (err) {
      dispatch(showAlert('danger', 'Error', err.message));
    }
  };
};