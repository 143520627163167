import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import styled from 'styled-components';

import Login from './components/Account/Login';
import MultiFactorAuthentication from './components/Account/MultiFactorAuth';
import VerificationForDevice from './components/Account/VerificationForDevice'
import ResetPassword from './components/Account/ResetPassword';
import AuthenticateUser from './components/auth/Authentication';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Dashboard from './components/Dashboard/Dashboard';
import ForgotPassword from './components/Account/ForgotPassword';

const MainWrapper = styled.div`
  height: inherit;
`;

const PublicRoutes = () => {
  return (
    <MainWrapper className='main-wrapper'>
      <ErrorBoundary>
        <Switch>
          <Redirect exact from='/' to='/login' />      
          <Route exact path='/login' component={Login} />
          <Route exact path='/multi-factor-authentication' component={MultiFactorAuthentication} />
          <Route exact path='/verification-for-device' component={VerificationForDevice} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route exact path='/password/reset/confirm/:uid/:token' component={ResetPassword} />
          <Route path='/auth' component={AuthenticateUser(Dashboard)} />
          <Redirect to='/login' />
        </Switch>
      </ErrorBoundary>
    </MainWrapper>
  );
};

export default PublicRoutes;
