import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
} from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { isArray, uniqBy } from 'lodash';
import TextsmsIcon from '@material-ui/icons/Textsms';
import MenuIcon from '@material-ui/icons/Menu';
import Select from 'react-select';
import filterOutline from "../../../assets/IO-icons/filter-outline.svg";


import Box from '../../../components/_styles/Box';
import SmsCreationDialog from '../Messages/SmsCreationDialog';

import { Card } from '../../../styles';
import { ReactComponent as TriangleDownIcon } from '../../../assets/triangle-down.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar-outline.svg';
import { ReactComponent as ConditionalIcon } from '../../../assets/conditional-icon.svg';
import { ReactComponent as ConditionalIconHover } from '../../../assets/conditional-icon-hover.svg';
import { ReactComponent as FindingsBoiIcon } from '../../../assets/findings-boi-icon.svg';
import { ReactComponent as FindingsBoiIconHover } from '../../../assets/findings-boi-icon-hover.svg';
import { ReactComponent as FaxIcon } from '../../../assets/fax-icon.svg';
import { ReactComponent as FaxIconHover } from '../../../assets/fax-icon-hover.svg';
import { ReactComponent as MessageIcon } from '../../../assets/messageIcon.svg';
import { ReactComponent as MessageIconHover } from '../../../assets/message-icon-hover.svg';
import { ReactComponent as LetterIcon } from '../../../assets/letter-icon.svg';
import { ReactComponent as LetterIconHover } from '../../../assets/letter-icon-hover.svg';
import { ReactComponent as DropdownUp } from '../../../assets/dropdown-outline-white-up.svg';
import { ReactComponent as DropdownDown } from '../../../assets/dropdown-outline-white.svg';
import { ReactComponent as CloseIcon } from "../../../assets/cancel-outline.svg";
import { ReactComponent as ChevronRightIcon } from '../../../assets/chevron_right.svg';
import { Nav } from 'react-bootstrap';

import { Arrow } from './Icons';
import {ReactComponent as  RecoNoteNavIcon } from '../../../assets/reco-notes.svg';
import {ReactComponent as RemoveRecoIcon } from '../../../assets/close-outline.svg';
import {
  getFollowUpListAction,
  getExportReportAction,
  getRadiologistsListAction,
  changeActionStatusAction,
  getTotalStatusesAction,
  getModalitiesListAction,
  getAnatomiesListAction,
  getTimeframeStatusesListAction,
  getTwilioConfig,
  getRecentIncomingMessages,
  getRadiologistForDropdown,
  getReferrersForDropdown,
  getDropdownValuesForCohort,
  getNotesAnalytics,
  getSavedFilterRecommendations,
  getSavedFilterRecommendationsInitial,
  getCohortsData,
  changeStatusBulkReco,
  removeBulkReco,
  showAlert,
  getRadiologistName,
  getRefName,
  getCohort,
  getRecoHistoryAction,
} from '../../../actions/radminUploads';
import EditReco from '../../../components/Dialog/EditReco';
import RemoveReco from '../../../components/Dialog/RemoveReco';
import RecoNotesAnalytics from './RecoNotesAnalytics';
import Flex from '../../../components/_styles/Flex';
import DoubleDataCell from '../PatientList/LastScanCell';
import { StatusHeaderTooltip } from './StatusHeaderTooltip';
import ExportModal from '../DoctorList/ExportModal';
import EnableFeature from '../../../components/EnableFeature/EnableFeature';
import PatientDetailsModal from './PatientDetailsModal';
import ActionStatusList from './ActionStatusList';
import TimelineDetails from './TimelineDetails';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import LoadMoreButton from '../../../components/LoadMoreButton';
import SearchInput from './SearchInput';
import { ReactComponent as EmptyStateImg } from '../../../assets/empty-followup.svg';
import { FollowUpListFilter, DEFAULT_VALUES, FilterButton, FilterContainer } from './FollowUpListFilter';
import close from '../../../assets/cancel-outline-chip.svg';
import RecoNoteModal from '../SharedComponents/Modal';
import ColumnFilterDropdown from './ColumnFilterDropdown';
import SaveModal from './SaveFilter';
import ReactSelect, { components } from 'react-select';
import EditModal from './EditFilter';
import NotesExportModal from '../Notes/NotesExportModal';
import AddToCohort from './AddToCohort';
import { NotesTooltip } from './NotesTooltip';
import FaxTemplateModal from '../Messages/FaxCreationDialog';
import { ClickAwayListener, Radio, Tooltip, makeStyles, withStyles } from '@material-ui/core';
import LetterTemplateModal from '../Messages/LetterCreationDialog';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import RemoveConfirmation from '../Cohorts/RemoveConfirmation';
import { useTable } from '../../../components/TableInfiniteScroll/useTable';
import TableInfiniteScroll from '../../../components/TableInfiniteScroll/Table';
import CustomTimeline from './CustomTimeline';
import { resetFollowUpListDateFiltersQuery, resetFollowUpListFiltersQuery } from './utils';



const editIcon = `${process.env.PUBLIC_URL}/${'Edit.svg'}`;
const cancelIcon = `${process.env.PUBLIC_URL}/${'cancel-circled.svg'}`;



const StyledTooltip = (props) => {
  const { className, ...other } = props;

  return (
    <Tooltip
      {...other}
      PopperProps={{
        className: className,
        style: {
          backgroundColor: "#AEE3F8",
          marginBottom: "20px",
          borderRadius: "4px",
          zIndex: 3,
        },
      }}
    />
  );
};

const StyledTooltipWrapper = withStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    color: "black!important",
    fontSize:"14px!important",
    fontWeight:400 + "!important",
    width: "196px",
    padding: "10px",
    textAlign:'center',
    margin: "0px",
    position: 'relative',   
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '-10px',
      left: '50%',
      transform: 'translateX(-50%)',
      borderTop: `10px solid #AEE3F8`,
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
    },
  },
 
}))(StyledTooltip);


const Container = styled.div`
  height: 310px;
  background-color: #FFFFFF;
  z-index: 2;
  left: 0px;
  @media (max-width: 768px) {
    left: 0;
    margin-left: 31px;
  }
`

const HeaderContainerTimeline = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #FFD57C;
  justify-content:space-between;
  padding:0px 10px;
`

const Header = styled.p`
  margin: 0;
  padding: 0;
  background-color: #FFD57C;
  text-transform: uppercase;
`

const StyledChevron = styled(ChevronRightIcon)`
  transform: rotate(${props => props.showdropdown&&'-'}90deg);
  & > path {
    fill: ${props => props.chevronFillColor? props.chevronFillColor: '#9FA2B4'} ;
  }
 cursor:pointer;
`

const StyledLinkArrow = styled(Nav.Link)`
  padding: 0;
  &:hover {
    cursor: ${props => props.isDetails? 'pointer' : 'default'};
  }
`


const StatusesContainer = styled.div`
  cursor: pointer;
  border-radius: 8px;
  width:244px;

`

const StyledLink = styled(Link)`
 color:#00AEEF;
 font-size:14px;
 font-weight:400;
 text-decoration:underline;
`

const IconContainerBox = styled.span`
 position: relative;
 cursor: pointer;
 display: inline-block;
 width: 30px; 
 height: 30px; 

 svg {
  position: absolute;
  top: 0px;
  left: 0;
  transition: opacity 0.1s ease;
 }

 svg.dark {
  opacity: 1;
 }

 svg.light {
  opacity: 0; 
 }

 &:hover svg.dark {
  opacity: 0;
 }

 &:hover svg.light {
  opacity: 1;
 }

 .label {
  display: none;
  font-size:9px;
  font-weight: 700;
  color:${props=>props.color};
 }

 &:hover .label {
  display: block;
  position: absolute;
  top: ${props=>props.startGap || '-40%'};
  line-height:12px;
  left: 50%;
  transform: translateX(-50%);
  z-index:2;
 }
`;



export const SelectedItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap:8px;
  min-width: fit-content;
  height: 32px;
  margin-top: 2px;
  padding:4px 10px 4px 10px;
  background: #EEEEEE;
  border-radius: 25px;
  @media (max-width: 768px) {
    width: 120px;
  }
`;

export const Image = styled.img`
  display: block;
  width: 20px;
`;

export const SelectedItem = styled.div`
  margin: 7px 3px 6px 8px;
  min-width: 50px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color:#A1A1A1;
`;

export const CloseSign = styled.div`
  width: 7px;
  height: 7px;
  color: #00AEEF;
  margin-right: 7px;
  opacity: 1;
  &:hover {
  cursor:pointer;
  }
`;

const HeaderContainer = styled(Flex)`
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-right: 0;
    overflow-x: scroll;
    overflow-y: auto;
    margin-bottom: -91px;
  }
  @media (max-width: 1090px) {
    padding-top: 2px;
  }
`;

const FilterCard = styled(Flex)`
  justify-content: space-around;
  font-family: Open Sans, sans-serif;
  margin-top:auto;
`;

const Badge = styled(Flex)`
  width: fit-content;
  min-width: 54px;
  padding: 0 16px;
  height: 24px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 100px;
  color: ${(props) => (props.status === 'non_mature' ? 'black' : 'white')};
  background: ${(props) => {
    switch (props.status) {
      case 'coming_due':
        return '#FFD57C';
      case 'overdue':
        return '#F73B3B';
      case 'non_compliant':
        return '#6f42c1';
      case 'adherent':
        return '#64C67C';
      case 'non_mature':
        return '#DFE0EB';
      default:
        return '#00AEEF';
    }
  }};
`;

const TooltipHeader = styled.p`
  margin: 0;
  padding: 0;
`;

const ControlsContainer = styled.div`
  display: flex;
  align-items:flex-end;
`;

const TabsContainer = styled.ul`
  position: relative;
  left: -24px;
  bottom: 0px;
  display: flex;
  list-style: none;
  margin-bottom:0px;
`;

const Tab = styled.li`
  position: relative;
  padding: 5px 28px 5px 31px;
  border: 1px solid #dfe0eb;
  border-radius: 5px;
  border-bottom-left-radius:0px;
  border-bottom-right-radius:0px;
  background-color: #fff;
  font-family: Roboto Condensed;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3px;
  white-space: nowrap;
  color: #252733;
  cursor: pointer;
  ${(props) => {
    if (props.active) {
      return `border-bottom: none;
      top: 1px;
      `;
    }
  }}
  &:hover {
     border-bottom: none;
      top: 1px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
  }
`;

const ExportContainer = styled.div`
  width: 150px;
  height: 38px;
  @media (max-width: 768px) {
    margin-top: 16px;
  }
`;

const NotesContainer = styled.div`
  height: 38px;
  @media (max-width: 768px) {
    margin-top: 16px;
  }
`;


const ExportButton = styled.button`
  width: 100%;
  height: 38px;
  border-radius: 5px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  border: 0;
  background-color: ${props=>props.bg || '#00AEEF'} ;
  color: #ffffff;
`;

const SearchInputContainer = styled.div`
  float: right;
  height:100%;
  display:flex;
  @media (max-width: 991px) {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    float: unset;
    margin-left: 0rem;
  }
  @media (min-width: 1245px) {
    margin-left: 3rem;
  }
  & form {
    margin-top:auto
  }
`;

export const ViewDetailsButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 38px;
  background-color: #ffffff;
  border: 2px solid #00AEEF;
  border-radius: 5px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #00AEEF;
  font-weight:600px;
`;

export const IconContainer = styled.span`
  margin-right: 5px;
`;

export const DatePickerContainer = styled(Flex)`
  min-width: 247px;
   & > div > div > div {
    height: 38px;
    min-width:247px;
  }
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

export const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 5px;
  font-size: 18px;
  width: max-content;
  display:flex;
  justify-content:space-between;
  &:hover {
    cursor: pointer;
  }
`;

const ChangeButtonTitle = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #252733;
`;

const ChangeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 137px;
  height: 32px;
  background: #fff;
  border: 1px solid #dfe0eb;
  box-shadow: 0px 2px 3px rgba(194, 204, 217, 0.4);
  border-radius: 3px;
  border-bottom-left-radius:0px;
  border-bottom-right-radius:0px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

const ArrowContainer = styled.span`
  margin-left: 10px;
  color: #252733;
`;

export const DateClearButton = styled.div`
  cursor: pointer;
  display:flex;
`;

export const DateContainer = styled(Flex)`
  margin-right: 15px;
  font-size:12px;
`;
const RecoNoteIconContainer = styled.span`
  cursor: pointer;
  display:flex;
`;
const RecoNoteTabContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
  gap:8px;
  justify-content:center;
`;

export const SettingsContainer = styled.div`
  width: 215px;
  background-color: #f7f8ff;
  border: 1px solid #d2d3d7;
  box-sizing: border-box;
  border-radius: 5px;
  position: absolute;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  margin-top: ${(props) => (props.margin ? '460px' : '360px')};
  z-index: 2;
`;
const ActionList = styled.ul`
  margin: 31px -157px -213px -322px
  padding: 0;
  z-index:2;
  list-style: none;
  @media (min-width: 1408px) {
    margin: 32px -157px -213px -222px
  }
`;
export const PageTitle = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 120%;
  color: #0c2945;
  margin-top:auto;
  @media (max-width: 991px) {
    justify-content: center;
    display: flex;
    transform: translateY(-16px);
  }
  @media (max-width: 768px) {
    justify-content: center;
    display: flex;
    transform: translateY(4px);
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  gap:6px;
  @media (max-width: 991px) {
    justify-content: center;
    margin-left: 65px;
  }
`;

const DateRangeWrapper = styled.div`
font-size:12px;
  & > div > div > div {
    height: 38px;
    width:208px;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items:center;
  margin-top: 7px;
  gap:10px;
  @media (max-width: 1190px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 7px;
    width: max-content;
  }
  @media (max-width: 1190px) {
    width: auto;
  }
`;

const DateFieldLabel = styled.p`
font-size: 9.2px;
font-weight: 400;
margin-bottom:5px;
backdrop-filter:blur(1px);
`

const RadioBtn = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 50%;
  border-color: ${props=>props.borderColor};
  background-color: ${props=>props.bg};
  padding:1%!important;
  background-clip: content-box;
  margin-right:7px;
  cursor:pointer;
`

const RadioLabel = styled.label`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 0px;
`

const RadioContainer = styled.div`
border: 1px solid #c3c3c3;
width: 247px;
display: flex;
flex-direction: column;
background: white;
padding-left:10px;
padding-top:10px;
padding-bottom:10px;
gap:12px;
border-radius:4px;
`

const InitialSelect = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
height:38px;
padding:9.5px 12px;
width:247px;
background:white;
border:1px solid #DFE0EB;
cursor:pointer;
border-radius:5px; 
`

export const getRadiologistNameById = (radId,radiologists) => {
  if (radiologists && radiologists.length) {
    const selectedRad = radiologists.find((r) => r.id == radId);
    return `${selectedRad?.first_name} ${selectedRad?.last_name}`;
  }
};
export const getReferrerNameById = (refId,referrers, dispatch) => {
  if(!referrers){
    dispatch(getReferrersForDropdown(`?id=${refId}`));
  }
  if (referrers && referrers.length) {
    const selectedRef = referrers.find((r) => r.id == refId);
    return `${selectedRef?.first_name} ${selectedRef?.last_name}`;
  }
};



const CustomOption = ({ innerProps, label, setEditing, setFilterValuesForRequest,setValue, value, setPageNumber, option,  isFocused, isSelected, setModalityOperator,setExamCodeOperator,setAnatomyOperator, setReportModalityOperator, setInitialExamStartDate, setInitialExamEndDate, setInitialExamDatesFilter,  setInitialRangeEndDate,
  setInitialRangeDatesFilter,setInitialRangeStartDate, setDate, setSelectedConfiguration, setSelectedOption,
  setAllSelected,
  setSelectedIds }) => {
    const history = useHistory();

  const handleEditClick = (event) => {
    event.stopPropagation();
    setEditing({visible:true, type:option });
  };

  const handleOptionClick = () => {
                  const opt = option;
                    const mergedFilters = {
                      radiologist: '',
                      referrers: '',
                      modality:'',
                      anatomy:'',
                      timeframe: '',
                      has_note: '',
                      age: '',
                      sort: '',
                      message_sent:'',
                      location:'',
                      report_modality:'',
                      cohort:'',
                      exam_code:'',
                      guideline_type:'',
                      task_type:'',
                      patient_language:"",
                      recommendation_type:"",
                      exam_type:"",
                      contrast:"",
                      source:""
                    };
                    let params = new URLSearchParams(history.location.search);

                    resetFollowUpListDateFiltersQuery(params)
                    resetFollowUpListFiltersQuery(params)


                   
                    const initialExamDateObj =  opt.filters.find(itm=>itm.category === 'initial_exam_date')
                    if(!initialExamDateObj){
                      setInitialExamStartDate(null)
                      setInitialExamEndDate(null)
                      setInitialExamDatesFilter({
                        startDate:null,
                        endDate: null
                      })
                    }
                    if(initialExamDateObj){      
                    let startingDate =  new Date(initialExamDateObj.startDate);
                    let endingDate =  new Date (initialExamDateObj.endDate)
                    setInitialExamStartDate( startingDate)
                    setInitialExamEndDate( endingDate)
                    setInitialExamDatesFilter({
                    startDate: startingDate,
                    endDate: endingDate
                    })
                  

                    params.append("startDate", moment(startingDate).format('YYYY-MM-DD'));
                    params.append("endDate", moment(endingDate).format('YYYY-MM-DD'));

                    }

                    const initialRecoRangeDateObj =  opt.filters.find(itm=>itm.category === 'initial_reco_range_date')
                    if(!initialRecoRangeDateObj){
                      setInitialRangeStartDate(null)
                      setInitialRangeEndDate(null)
                      setInitialRangeDatesFilter({
                        startDate:null,
                        endDate:null
                      })
                      setDate(null)
                    }
                    if(initialRecoRangeDateObj?.startDate && initialRecoRangeDateObj?.endDate){
                    let startingDate =  new Date(initialRecoRangeDateObj.startDate)
                    let endingDate = new Date (initialRecoRangeDateObj.endDate)
                    setInitialRangeStartDate(startingDate)
                    setInitialRangeEndDate(endingDate)
                    setInitialRangeDatesFilter({
                    startDate: startingDate,
                    endDate: endingDate
                    })
                    setSelectedConfiguration(initialRecoRangeDateObj.selectedConfiguration)
                    setSelectedOption(initialRecoRangeDateObj.selectedOption)
                    let rangePrefix = initialRecoRangeDateObj?.selectedOption === "range start" ? "rangeStart" : "rangeEnd"
                  
                    params.append(rangePrefix +"DateStartGte", moment(startingDate).format('YYYY-MM-DD'));
                    params.append(rangePrefix +"DateEndLte", moment(endingDate).format('YYYY-MM-DD'));
                    }

                    if(initialRecoRangeDateObj?.date){
                    let startingDate = new Date(initialRecoRangeDateObj.date) 
                    setDate(startingDate);
                    setSelectedConfiguration(initialRecoRangeDateObj.selectedConfiguration)
                    setSelectedOption(initialRecoRangeDateObj.selectedOption)
                    let rangePrefix =  initialRecoRangeDateObj?.selectedOption === "range start" ? "rangeStart" : "rangeEnd";
                    let rangeSuffix =  initialRecoRangeDateObj?.selectedConfiguration === "gte" ? "DateGte" : "DateLte"
                    params.append(rangePrefix +rangeSuffix, moment(startingDate).format('YYYY-MM-DD'));
                    }

                    for(let itm of opt.filters.filter(itm=> itm.category !== 'initial_exam_date' && itm.category !== 'initial_reco_range_date')){
                      if(mergedFilters[itm.category].length>0){
                        mergedFilters[itm.category] = [...mergedFilters[itm.category],itm]
                      }else{
                        mergedFilters[itm.category] = [itm]
                      }


              if(itm.category === 'modality' && itm.negation === 'isNotEqual') {
               setModalityOperator( {value: 'isNotEqual', label: 'is not equal to', category: 'modality'})
              }
               if (itm.category ==='exam_code'  && itm.negation === 'isNotEqual') {
                setExamCodeOperator( {value: 'isNotEqual', label: 'is not equal to', category: 'exam_code'})
                  }
              if(itm.category === 'anatomy'  && itm.negation === 'isNotEqual') { 
                setAnatomyOperator( {value: 'isNotEqual', label: 'is not equal to', category: 'anatomy'})
               }
              if (itm.category ==='report_modality'  && itm.negation === 'isNotEqual') {
                setReportModalityOperator( {value: 'isNotEqual', label: 'is not equal to', category: 'report_modality'})
              }
          
              if (mergedFilters.modality?.length > 0) {
                mergedFilters.modality.forEach((item) => {
                  if (itm.negation === 'isNotEqual') {
                    params.append("modality!", item.value);
                  } else {
                    params.append("modality", item.value);
                  }
                });
              }
          
              if (mergedFilters.anatomy?.length > 0) {
                mergedFilters.anatomy.forEach((item) => {
                  if (itm.negation === 'isNotEqual') {
                    params.append("anatomy!", item.value);
                  } else {
                    params.append("anatomy", item.value);
                  }
                });
              }
          
              if (mergedFilters.radiologist?.length > 0) {
                mergedFilters.radiologist.forEach((item) => {
                  params.append("radiologist", item.value);
                });
              }
          
              if (mergedFilters.exam_type?.length > 0) {
                mergedFilters.exam_type.forEach((item) => {
                  params.append("exam_type", item.value);
                });
              }
          
              if (mergedFilters.guideline_type?.length > 0) {
                mergedFilters.guideline_type.forEach((item) => {
                  params.append("guideline_type", item.value);
                });
              }
          
              if (mergedFilters.timeframe?.length > 0) {
                mergedFilters.timeframe.forEach((item) => {
                  params.append("timeframe", item.value);
                });
              }
          
              if (mergedFilters.has_note?.length > 0) {
                mergedFilters.has_note.forEach((item) => {
                  params.append("has_note", item.value);
                });
              }
          
              if (mergedFilters.task_type?.length > 0) {
                mergedFilters.task_type.forEach((item) => {
                  params.append("task_type", item.value);
                });
              }
          
              if (mergedFilters.recommendation_type?.length > 0) {
                mergedFilters.recommendation_type.forEach((item) => {
                  params.append("recommendation_type", item.value);
                });
              }
          
              if (mergedFilters.contrast?.length > 0) {
                mergedFilters.contrast.forEach((item) => {
                  params.append("contrast", item.value);
                });
              }

              if (mergedFilters.source?.length > 0) {
                mergedFilters.source.forEach((item) => {
                  params.append("source", item.value);
                });
              }
          

              // -------------------
          
              if (mergedFilters.report_modality?.length > 0) {
                mergedFilters.report_modality.forEach((item) => {
                  if (itm.negation === 'isNotEqual') {
                    params.append("report_modality!", item.value);
                  } else {
                    params.append("report_modality", item.value);
                  }
                });
              }
          
              if (mergedFilters.exam_code?.length > 0) {
                mergedFilters.exam_code.forEach((item) => {
                  if (itm.negation === 'isNotEqual') {
                    params.append("exam_code!", item.value);
                  } else {
                    params.append("exam_code", item.value);
                  }
                });
              }
          
              // -------------------
          
              if (mergedFilters.patient_language?.length > 0) {
                mergedFilters.patient_language.forEach((item) => {
                  params.append("patient_language", item.value);
                });
              }
              if (mergedFilters.age?.length > 0) {
                mergedFilters.age.forEach((item) => {
                  params.append("age", item.value);
                });
              }
          
              //  -----------------
          
              if (mergedFilters.cohort?.length > 0) {
                mergedFilters.cohort.forEach((item) => {
                  params.append("cohort", item.value);
                });
              }
          
              if (mergedFilters.message_sent?.length > 0) {
                mergedFilters.message_sent.forEach((item) => {
                  params.append("message_sent", item.value);
                });
              }
          
              //------------------
          
              if (mergedFilters.referrers?.length > 0) {
                mergedFilters.referrers.forEach((item) => {
                  params.append("referrers", item.value);
                });
              }
          
              if (mergedFilters.location?.length > 0) {
                mergedFilters.location.forEach((item) => {
                  params.append("location", item.value);
                });
              }
          
              history.replace({
                search: params.toString(),
              });

                    }
                    setPageNumber(1)
                    setFilterValuesForRequest(mergedFilters)
                    setValue(opt)
                    setAllSelected(false);
                    setSelectedIds([])
                  
                 
  };

  return (
    <div   style={{display:'flex', alignItems:'center' ,justifyContent:'space-between', cursor:'pointer', padding:'6px 9px', height:'32px', marginTop:'10px',   backgroundColor: isSelected ? '#E4E7F0' : isFocused ? '#F0F2F7' : 'transparent',}} {...innerProps} onClick={handleOptionClick}>
      <span  style={{fontSize:'12px', fontWeight:600 , color:'#252733'}}>{label}</span>
      <img height="14px" width="14px" src={editIcon} onClick={handleEditClick} />
    </div>
  );
};
// const capitalize = str => (str ? str[0].toUpperCase() + str.slice(1) : '');
const toUpperCase = (str) => (str ? str.toUpperCase() : '');

export const StatusBadge = ({ status }) => (
  <Badge status={status}>{status}</Badge>
);

const sortOptions = [
  { value: 'Reco Max Asc', label: 'Reco Max Asc' },
  { value: 'Reco Max Desc', label: 'Reco Max Desc' },
  { value: 'Reco Min Asc', label: 'Reco Min Asc' },
  { value: 'Reco Min Desc', label: 'Reco Min Desc' },
];

export const filterTypes = [
  'radiologist',
  'referrers',
  'modality',
  'anatomy',
  'timeframe',
  'age',
];
const itemsPortionSize = 10;

const restStatuses = [
  'unknown',
  'not_clinically_necessary',
  'acknowledged',
  'completed_elsewhere',
  'needs_prior_auth',
  'needs_prescription',
  'unresponsive',
  'unsubscribed'
];
const timeframeStatuses = [
  'completed_after_timeframe',
  'completed_before_timeframe',
];
const scheduledTabStatuses = ['scheduled_not_completed', 'rx_received'];
const scheduledTabStatusesObj = [
  { status: 'scheduled_not_completed' },
  { status: 'rx_received' },
];
const timeframeStatusesObj = [
  { status: 'completed_after_timeframe' },
  { status: 'completed_before_timeframe' },
];
export const restStatusesObj = [
  { status: 'unknown' },
  { status: 'not_clinically_necessary' },
  { status: 'acknowledged' },
  { status: 'completed_elsewhere' },
  { status: 'needs_prior_auth' },
  { status: 'needs_prescription' },
  { status: 'unresponsive' },
  { status: 'unsubscribed' }
];

export const getCountByStatus = (statusName, totalStatuses) => {
  const obj =
    totalStatuses && totalStatuses.find(({ status }) => status === statusName);
  if (obj) {
    return obj.id__count;
  } else if (statusName === 'completed_before_and_after_timeframe') {
    const timeframe =
      totalStatuses &&
      totalStatuses.filter(({ status }) => timeframeStatuses.includes(status));
    let timeframeCount = timeframe
      ? timeframe.reduce((total, item) => total + item.id__count, 0)
      : 0;
    return timeframeCount;
  } else if (statusName === 'Scheduled/Ordered') {
    let scheduledTab =
      totalStatuses &&
      totalStatuses.filter(({ status }) =>
        scheduledTabStatuses.includes(status)
      );
    let count = scheduledTab
      ? scheduledTab.reduce((total, item) => total + item.id__count, 0)
      : 0;
    return count;
  } else if (!obj && statusName) {
    return 0;
  } else {
    const restObj =
      totalStatuses &&
      totalStatuses.filter(({ status }) => restStatuses.includes(status));
    const count = restObj
      ? restObj.reduce((total, item) => total + item.id__count, 0)
      : 0;
    return count;
  }
};

export const getCountByStatusWithStatusFormat = (
  activeStatus,
  totalStatuses
) => {
  let formattedValue = 'coming_due';
  switch (activeStatus) {
    case 'Coming Due': {
      formattedValue = 'coming_due';
      break;
    }
    case 'Non-Mature': {
      formattedValue = 'non_mature';
      break;
    }
    case 'Overdue': {
      formattedValue = 'overdue';
      break;
    }
    case 'expired': {
      formattedValue = 'expired';
      break;
    }
    case 'Adherent': {
      formattedValue = 'adherent';
      break;
    }
    case 'Completed Before/After Timeframe': {
      formattedValue = 'completed_before_and_after_timeframe';
      break;
    }
    case 'Scheduled/Ordered': {
      formattedValue = 'Scheduled/Ordered';
      break;
    }
    case 'Manually Reviewed': {
      formattedValue = undefined;
      break;
    }
    default: {
      formattedValue = 'coming_due';
    }
  }
  return getCountByStatus(formattedValue, totalStatuses);
};


const RecoOptions = [
  {
    value: "last7days",
    label: "Last 7 days",
    start: new Date(moment().subtract(7, "days")),
    end: new Date(moment()),
  },
  {
    value: "last30days",
    label: "Last 30 days",
    start: new Date(moment().subtract(30, "days")),
    end: new Date(moment()),
  },
  {
    value: "last3months",
    label: "Last 3 months",
    start: new Date(moment().subtract(3, "months")),
    end: new Date(moment()),
  },
  {
    value: "last6months",
    label: "Last 6 months",
    start: new Date(moment().subtract(6, "months")),
    end: new Date(moment()),
  },
  {
    value: "last12months",
    label: "Last 12 months",
    start: new Date(moment().subtract(12, "months")),
    end: new Date(moment()),
  },
  { value: "custom", label: "Custom date range", start: null, end: null },
];


const FollowUpList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tableRef = useRef(null);
  const columnData = [
    {
      key: 'accessionNumber',
      name: 'Accession Number',
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
      {
      key: "note",
      name:'Reco Note',
      displayName: () => (
        <RecoNoteTabContainer>
          <span>Reco Note</span>
          <RecoNoteIconContainer
            onClick={(e) => {
              e.stopPropagation()
              setIsOpenRecoNoteModal(true);
              dispatch(getNotesAnalytics());
            }}
          >
            <RecoNoteNavIcon />
          </RecoNoteIconContainer>
        </RecoNoteTabContainer>
      ),
      displayCell: (cellValue, rowData) => (
        <NotesTooltip notes={rowData.notesData}>
          <span>
          <DoubleDataCell  mainInfo={"note"} rowData={rowData} />
          </span>

        </NotesTooltip>
      ),
    },
    {
      key:'conditional',
      name:'Conditional',
      displayCell: (cellValue, rowData) => (
       cellValue? 
       <IconContainerBox  color="#B44FCE">
         <span className="label" >
          CONDITIONAL
         </span>
         <ConditionalIcon className='dark'/>
         <ConditionalIconHover className='light'/>
       </IconContainerBox>
       : null
      ),
    },
    {
      key:'boi',
      name:'Structured Findings',
      displayCell: (cellValue, rowData) => (
       cellValue? 
       <IconContainerBox startGap={'-80%'} color="#FF9900">
         <span className="label" >
          STRUCTURED FINDINGS
         </span>
         <FindingsBoiIcon className='dark'/>
         <FindingsBoiIconHover className='light'/>
       </IconContainerBox>
       : null
      ),
    },
    {
      key: 'examType',
      name: 'Test Type',
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: 'recommendationType',
      name: 'Recommendation Type',
      displayCell: (cellValue) => {
        let displayText;
        if (cellValue === 'imaging_followup') {
          displayText = 'Imaging';
        } else if (cellValue === 'non_imaging_followup') {
          displayText = 'Non-imaging';
        } else {
          displayText = cellValue; // Fallback to the original value if it doesn't match the specified cases
        }
        return <DoubleDataCell mainInfo={displayText} />;
      },
    },
    {
      key: 'contrast',
      name: 'Contrast',

      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: 'initialExamDate',
      name: 'Exam Date',
      enableSort: true,
      sortKey: 'Reco Exam',
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: 'examCode',
      name: 'Exam Code',
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: 'reportModality',
      name: 'Report Modality',
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue)} />
      ),
    },
    {
      key: 'rangeStart',
      name: 'Min Due Date' ,
      enableSort: true,
      sortKey: 'Reco Min',
      displayCell: (cellValue) =><DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: 'rangeEnd',
      name: 'Max Due Date',
      enableSort: true,
      sortKey: 'Reco Max',
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: 'anatomy',
      name: 'Reco Anatomy',
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue)} />
      ),
    },
    {
      key: 'modality',
      name: 'Reco Modality',
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue)} />
      ),
    },
    {
      key: 'guidelineType',
      name: 'Guideline Type',
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue)} />
      ),
    },
    {
      key: 'source',
      name: 'Reo Source',
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue)} />
      ),
    },
    {
      key: 'timeframeStatusExtracted',
      name: 'Timeframe',
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue)} />
      ),
    },
    {
      key: 'patientMRN',
      name: 'Patient MRN',
      displayCell: (value,cellValue) => (
        <StyledLink
        to={`/auth/radAdmin/followUpList/patients/patientDetails/${cellValue.patientId}`}
        target='_blank'
        onClick={(e)=>{
          e.stopPropagation();
          window.analytics.track('Follow-Up-List-Patient-MRN-Click',{patientId: cellValue.patientId})
        }}
        
      >
        {value}
      </StyledLink>
      ),
    },
    {
      key: 'status',
      name: (
        <StatusHeaderTooltip>
          <TooltipHeader>Status</TooltipHeader>
        </StatusHeaderTooltip>
      ),
      displayCell: (cellValue) => (
        <StatusBadge
          status={cellValue === 'expired' ? 'non_compliant' : cellValue}
        />
      ),
    },
    {
      key: "action status",
      name: "Action Status",
      displayCell: (cellValue, row) => (
        <ActionStatusList
          onSetSelected={onSetActionStatus}
          optionsTitle={"radiologist"}
          options={radiologistOptions}
          canShow={canChangeAction}
          id={row.id}
        />
      ),
    },
  ];
  
  const {
    columnCheckbox,
    columnFilterDropdown,
    columns,
    handleColumnChange,
    setColumnFilterDropdown,
  } = useTable(columnData);
  const followUpList = useSelector((state) => state.radAdmin.followUpList.data);
  const isLoadingFollowUpList = useSelector(
    (state) => state.radAdmin.followUpList.isLoading
  );
  const totalItems = useSelector(
    (state) => state.radAdmin.followUpList.totalItems
  );
  const csv = useSelector((state) => state.radAdmin.exportReport.data);
  const totalStatuses = useSelector(
    (state) => state.radAdmin.followUpList.totalStatuses.data
  );
  const radiologists = useSelector(
    (state) => state.radAdmin.followUpList.dropdownRadiologists.data
  );
  const referrers = useSelector(
    (state) => state.radAdmin.followUpList.dropdownReferrers.data
  );

  const cohorts = useSelector(
    (state) => state.radAdmin.followUpList.dropdownCohorts.data
  );

  const permission = useSelector((state) => state.auth.user.permission_level); 
  const rolePermissions = useSelector(
     (state) =>state.auth.user.permissions
   );
  const exportPermission = rolePermissions?.find(itm=>itm.name ==="Export")?.permissions.find(itm=>itm.codename === "add_export")?.is_authorized
  const updatePermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "change_follow-ups")?.is_authorized
  const deletePermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "delete_follow-ups")?.is_authorized

  const recoConfigData = useSelector((state) => state.radAdmin.recoConfig.data);
  const savedFilterRecommendations =  useSelector((state)=>state.radAdmin.followUpListSavedFilters.data).map(itm => ({...itm, label:itm.title, value:itm.title})) 
  const [filteredSort, setFilteredSort] = useState(null);
  const [displayItemsCount, setDisplayItemsCount] = useState(itemsPortionSize);
  const [pageNumber, setPageNumber] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [needExport, setNeedExport] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showPatientDetails, setShowPatientDetails] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const [filterBy, setFilterBy] = useState([]);
  const [canChangeAction, setCanChangeAction] = useState(true);
  const [actionStatus, setActionStatus] = useState(null);
  const [filterValuesForRequest, setFilterValuesForRequest] =
    useState(DEFAULT_VALUES);
  const [openEditReco, setOpenEditReco] = useState(false);
  const [openRemoveReco, setOpenRemoveReco] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [openMessage, setOpenMessage] = useState(false);
  const [openFax, setOpenFax] = useState(false);
  const [openLetter, setOpenLetter] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(DEFAULT_VALUES);

 
  const [selectedExportFilters, setSelectedExportFilters] = useState({});
  const [isOpenRecoNoteModal, setIsOpenRecoNoteModal] = useState(false);
  const [filterData, setFilterData] = useState();
  const [showMoreTabs, setShowMoreTabs] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const location = useLocation();
  const [opened, setOpened] = useState(false)
  const [cohortOpened, setCohortOpened] = useState(false)
  const [editing, setEditing] = useState({visible:false, type:{}})
  const [showSaveFilter, setShowSaveFilter] = useState(false)
  const [showAddCohort, setShowAddCohort] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const handleOptionChange = (event) => {
    resetRangeDateFilter()
    setSelectedOption(event.target.value);
  };

  const handleChangeConfiguration = (event) => {
    resetRangeDateFilter()
    setSelectedConfiguration(event.target.value);
  };
  
  const onChange = dates => {
      setDate(dates);
      setPageNumber(1)
      const existingParams = new URLSearchParams(location.search);
      let label='';
      if(selectedOption === 'range start' && selectedConfiguration === 'gte'){
       label = 'rangeStartDateGte'
      }
      if(selectedOption === 'range start' && selectedConfiguration === 'lte'){
        label = 'rangeStartDateLte'
       }
       if(selectedOption === 'range end' && selectedConfiguration === 'gte'){
        label = 'rangeEndDateGte'
       }
       if(selectedOption === 'range end' && selectedConfiguration === 'lte'){
        label = 'rangeEndDateLte'
       }
   
                  
      existingParams.set(label,  moment(dates).format('YYYY-MM-DD'));
  
  
      const newUrl = `${location.pathname}?${existingParams.toString()}`;
      history.push(newUrl);
  };



  const onClose = () => {
    setOpened(false);
  };

  const onCohortClose = () => {
    setCohortOpened(false);
  };

  const queryParams = new URLSearchParams(location.search);
  let initialSearchValue = "";
  let initialModalityFilter =  { value: "none", label: "none", category: "modality" };
  let initialAnatomyFilter =  { value: "none", label: "none", category: "anatomy" };
  let initialReportModalityFilter = {
    value: "none",
    label: "none",
    category: "report_modality",
  };
  let initialExamCodeFilter = {
    value: "none",
    label: "none",
    category: "exam_code",
  };
  let initStatus = "Overdue";

  if(queryParams.has('search')){
    initialSearchValue = queryParams.get('search')
  }


  if(queryParams.has('status')){
    initStatus = queryParams.get('status')
  }

  const [searchValue, setSearchValue] = useState(initialSearchValue );


  if (queryParams.has('modality!')) {
    initialModalityFilter =  {value: 'isNotEqual', label: 'is not equal to', category: 'modality'}
  }
  if (queryParams.has('anatomy!')) {
    initialAnatomyFilter = {value: 'isNotEqual', label: 'is not equal to', category: 'anatomy'} 
  }
  
  if (queryParams.has('report_modality!')) {
    initialReportModalityFilter =  {value: 'isNotEqual', label: 'is not equal to', category: 'report_modality'}
  }

  if (queryParams.has('exam_code!')) {
    initialExamCodeFilter =  {value: 'isNotEqual', label: 'is not equal to', category: 'exam_code'}
  }

  let initialDate = '';
  let initialSelectedOption = '';
  let initialSelectedConfiguration = ''

  if(queryParams.has('rangeStartDateGte')  ){
   initialDate = (new Date(queryParams.get('rangeStartDateGte'))) 
   initialSelectedOption = 'range start'
   initialSelectedConfiguration = 'gte'
  }

  if( queryParams.has('rangeStartDateLte') ){
    initialDate =  (new Date(queryParams.get('rangeStartDateLte')))  
    initialSelectedOption = 'range start'
    initialSelectedConfiguration = 'lte'
  }
  if( queryParams.has('rangeEndDateGte') ){
    initialDate = (new Date(queryParams.get('rangeEndDateGte')))  
    initialSelectedOption = 'range end'
    initialSelectedConfiguration = 'gte'
  }
  if( queryParams.has('rangeEndDateLte')){
    initialDate = (new Date(queryParams.get('rangeEndDateLte'))) 
    initialSelectedOption = 'range end'
    initialSelectedConfiguration = 'lte'
  }
   
      let initRangeStart='';
      let initRangeEnd= '';
      let initRange ={
        startDate:'',
        endDate:''
      };
      if(queryParams.has('rangeStartDateStartGte') && queryParams.has('rangeStartDateEndLte')){
        initialSelectedConfiguration = 'gte and lte'
        initialSelectedOption = 'range start'
        initRangeStart= (new Date(queryParams.get('rangeStartDateStartGte'))) 
        initRangeEnd = (new Date(queryParams.get('rangeStartDateEndLte'))) 
        initRange = {
          startDate:(new Date(queryParams.get('rangeStartDateStartGte'))) ,
          endDate: (new Date(queryParams.get('rangeStartDateEndLte'))) 
        }
      }

      
      if(queryParams.has('rangeEndDateStartGte') && queryParams.has('rangeEndDateEndLte')){
        initialSelectedConfiguration = 'gte and lte'
        initialSelectedOption = 'range end'
        initRangeStart= (new Date(queryParams.get('rangeEndDateStartGte'))) 
        initRangeEnd = (new Date(queryParams.get('rangeEndDateEndLte'))) 
        initRange = {
          startDate:(new Date(queryParams.get('rangeEndDateStartGte'))) ,
          endDate: (new Date(queryParams.get('rangeEndDateEndLte'))) 
        }
      }

      let initExamRangeStart= '';
      let initExamRangeEnd= '';
      let initExamRange ={
        startDate:  '',
        endDate:''
      };

      if(recoConfigData && queryParams.has('startDate') && queryParams.has('endDate')){
       
        initExamRangeStart= (new Date(queryParams.get('startDate'))) 
        initExamRangeEnd = (new Date(queryParams.get('endDate'))) 
        initExamRange = {
          startDate:(new Date(queryParams.get('startDate'))) ,
          endDate: (new Date(queryParams.get('endDate'))) 
        }
      }

     if(recoConfigData && recoConfigData?.showDefaultDate && !initExamRangeStart && !initExamRangeEnd){
        initExamRangeStart = new Date(moment().subtract(recoConfigData?.defaultRangeStart, "days"));
        initExamRangeEnd = new Date ( moment().subtract(recoConfigData?.defaultRangeEnd, "days"));
        initExamRange= {
          startDate:  new Date(moment().subtract(recoConfigData?.defaultRangeStart, "days")),
          endDate:new Date ( moment().subtract(recoConfigData?.defaultRangeEnd, "days"))
        }   
       }

  const [date, setDate] = useState(initialDate)
  const [activeStatus, setActiveStatus] = useState(initStatus);
  
  const [initialExamStartDate, setInitialExamStartDate] = useState(initExamRangeStart);
  const [initialExamEndDate, setInitialExamEndDate] = useState(initExamRangeEnd );
  const [initialExamDatesFilter, setInitialExamDatesFilter] = useState( initExamRange );
  const [initialRangeStartDate, setInitialRangeStartDate] = useState(initRangeStart);
  const [initialRangeEndDate, setInitialRangeEndDate] = useState(initRangeEnd);
  const [initialRangeDatesFilter, setInitialRangeDatesFilter] = useState(initRange);
  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);
  const [selectedConfiguration, setSelectedConfiguration] = useState(initialSelectedConfiguration);
  const [modalityOperator, setModalityOperator] = useState(
    initialModalityFilter
  );
  const [anatomyOperator, setAnatomyOperator] = useState(initialAnatomyFilter);
  const [reportModalityOperator, setReportModalityOperator] = useState(
    initialReportModalityFilter
  );
  const [examCodeOperator, setExamCodeOperator] = useState(initialExamCodeFilter)
  const [activeSortColumn, setActiveSortColumn] = useState('Reco Max Desc')

  const radiologistOptions = radiologists?.length
    ? radiologists.map((item) => ({
        value: item.id,
        label: `${item.first_name} ${item.last_name}`,
        category: 'radiologist',
      }))
    : [];
  const referrerOptions = referrers?.length
    ? referrers.map((item) => ({
        value: item.id,
        label: `${item.first_name} ${item.last_name}`,
        category: 'referrers',
      }))
    : [];

  

  const DatePickerInput = forwardRef(({ onClick, startDate, endDate }, ref) => {
    const formatDate = (date) => {
      return date ? moment(date).format('MMM, DD, YYYY') : 'No Date';
    };

    return (
      <DatePickerButton onClick={onClick} ref={ref}>
        <DateContainer>
          {!startDate && !endDate
            ? 'Select Exam Date Range'
            : `${formatDate(startDate)} - ${formatDate(endDate)}`}
        </DateContainer>
        {!initialExamStartDate && !initialExamEndDate &&  <CalendarIcon />}
        {(initialExamStartDate || initialExamEndDate) && (
          <DateClearButton onClick={()=>{
            resetExamDateFilter();
            setSelectedSubOption2(null);
            setInitView1(true)
          
          }}>
            <RemoveRecoIcon />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });



  const resolveStatusNameForReq = useCallback(
    (status, isDefaultFilterState = false) => {
      if (isDefaultFilterState && status.indexOf('Manually') !== -1) {
        return restStatusesObj;
      }
      if (status === 'Completed Before/After Timeframe') {
        return timeframeStatusesObj;
      }
      if (status === 'Scheduled/Ordered') {
        return scheduledTabStatusesObj;
      }
      return status.indexOf('Adherent') !== -1
        ? 'adherent'
        : status.indexOf('Manually') !== -1
        ? restStatusesObj || 'empty'
        : status.replace('-', '_');
    },
    []
  );

  useEffect(() => {
    const updateDimensions = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    dispatch(getDropdownValuesForCohort('Recommendation', 'status'));
  }, []);

  useEffect(() => {
    if (!isLoadingFollowUpList) {
      setIsLoadingMore(false);
    }
  }, [isLoadingFollowUpList]);

  const mounted = useRef()

  const getSelectedFilters = () => {
    let filtersArr = Object.keys(DEFAULT_VALUES);
    let selected = {};
    for (let i = 0; i < filtersArr.length; i++) {
      const queryParams = new URLSearchParams(location.search);
      if (  queryParams.has(filtersArr[i]+ '!') ||  queryParams.has(filtersArr[i])) {
        const search = location.search;
        const data = new URLSearchParams(search).getAll(filtersArr[i] +'!').length>0? new URLSearchParams(search).getAll(filtersArr[i] +'!') :  new URLSearchParams(search).getAll(filtersArr[i]);
        selected[`${filtersArr[i]}`] = data?.map(itm=>({
          value: itm,
          label:
            filtersArr[i] === 'radiologist' ? getRadiologistNameById(itm, radiologists) : filtersArr[i] === 'referrers' ? getReferrerNameById(itm, referrers,dispatch) : itm,
          category: filtersArr[i],
        }));
      } else {
        selected[`${filtersArr[i]}`] = '';
      }
      ;
    }
    return selected
  }

  useEffect(() => {
    if(recoConfigData && location.search){
    let statusName;
      statusName = resolveStatusNameForReq(activeStatus);
    const selectedFilters = getSelectedFilters()
   
    const searchVal = searchValue || null;
    dispatch(
      getFollowUpListAction(
        pageNumber,
        statusName,
        searchVal,
        { ...selectedFilters, modalityOperator, anatomyOperator,sort:activeSortColumn, reportModalityOperator, examCodeOperator },
        initialExamDatesFilter,
        date,
        selectedConfiguration,
        selectedOption,
        initialRangeDatesFilter,  
        )
    );

    }   
  }, [
    activeStatus,
    dispatch,
    searchValue,
    filteredSort,
    resolveStatusNameForReq,
    location.search,
    pageNumber,
    initialExamEndDate,
    date,
    initialRangeDatesFilter.endDate,
    activeSortColumn,
    recoConfigData
  ]); 
  


  useEffect(() => {
    if(recoConfigData && location.search){
      const selectedFilters = getSelectedFilters()
      dispatch(
        getTotalStatusesAction(
          { ...selectedFilters, modalityOperator, anatomyOperator, reportModalityOperator, examCodeOperator },
          searchValue,
          initialExamDatesFilter,
          date,
          selectedConfiguration,
          selectedOption,
          initialRangeDatesFilter
        )
      );
      // dispatch(getRecentIncomingMessages());
      setActionStatus(null);
    }

  }, [
    location.search,
    searchValue,
    initialExamDatesFilter.endDate,
    modalityOperator,
    reportModalityOperator,
    examCodeOperator,
    anatomyOperator,
    date,
    initialRangeDatesFilter.endDate,
    recoConfigData
  ]);
  
  useEffect(() => {
    const statusName = resolveStatusNameForReq(activeStatus);
    if (activeStatus) {
      dispatch(getRadiologistsListAction(statusName));
      dispatch(getModalitiesListAction(statusName));
      dispatch(getAnatomiesListAction(statusName));
      dispatch(getTimeframeStatusesListAction(statusName));
    }
  }, [activeStatus, resolveStatusNameForReq, dispatch]);

  useEffect(() => {
    dispatch(getRadiologistForDropdown());
    dispatch(getCohortsData())
  }, [dispatch]);


  useEffect(() => {
    if (needExport && startDate && endDate) {
      dispatch(
        getExportReportAction(startDate, endDate, selectedExportFilters)
      );
      setNeedExport(false);
    }
  }, [needExport, startDate, endDate, selectedExportFilters, dispatch]);


  useEffect(() => {
    if (actionStatus) {
      dispatch(changeActionStatusAction(actionStatus.id, actionStatus.status)).then((res)=>{

        let statusName;
        statusName = resolveStatusNameForReq(activeStatus);
      const selectedFilters = getSelectedFilters()
     
      const searchVal = searchValue || null;
      dispatch(
        getFollowUpListAction(
          1,
          statusName,
          searchVal,
          { ...selectedFilters, modalityOperator, anatomyOperator,sort:activeSortColumn, reportModalityOperator, examCodeOperator },
          initialExamDatesFilter,
          date,
          selectedConfiguration,
          selectedOption,
          initialRangeDatesFilter,  
          )
      );
      dispatch(
        getTotalStatusesAction(
          { ...selectedFilters, modalityOperator, anatomyOperator, reportModalityOperator, examCodeOperator },
          searchValue,
          initialExamDatesFilter,
          date,
          selectedConfiguration,
          selectedOption,
          initialRangeDatesFilter
        )
      );
      });
      setActionStatus(null);
    
    }
  }, [actionStatus, dispatch]);
  useEffect(() => {
    const fetchData = async () => {
      let filters = Object.keys(DEFAULT_VALUES);
      let selected = {};
      const queryParams = new URLSearchParams(location.search);

      for (let i = 0; i < filters.length; i++) {
        if (queryParams.has(filters[i]) || queryParams.has(filters[i] + '!')) {
          const search = location.search;
          const data = new URLSearchParams(search).getAll(filters[i]).length > 0
            ? new URLSearchParams(search).getAll(filters[i])
            : new URLSearchParams(search).getAll(filters[i] + '!');
          
          const promises = data.map(async (itm) => {
            let label = itm.replace(/_/g, ' ').replace(
              /\b\w/g,
              function (char) {
                return char.toUpperCase();
              }
            );
            if (filters[i] === 'radiologist') {
              const result = await dispatch(getRadiologistName(itm));
              label = result.first_name+ ' ' + result.last_name;
            } else if (filters[i] === 'referrers') {
              const result = await dispatch(getRefName(itm));
              label = result.first_name + ' ' + result.last_name;
            }
            else if (filters[i] === 'cohort') {
              const result = await dispatch(getCohort(itm));
              label = result?.cohort_title;
            }

            return {
              value: itm,
              label: label,
              category: filters[i],
            };
          });

          selected[filters[i]] = await Promise.all(promises);
        } else {
          selected[filters[i]] = '';
        }
      }

      setSelectedFilters(selected);
      setFilterValuesForRequest(selected);
    };

    fetchData();
  }, [location.search, dispatch]);

  useEffect(() => {
    let filters = ['status'];
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has(filters)) {
      const search = location.search;
      const data = new URLSearchParams(search).get(filters);
      setActiveStatus(data);
    }
  }, []);

  useEffect(() => {
    let paramsStartDate = ['startDate'];
    let firstDate = '';
    let lastDate = '';
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has(paramsStartDate)) {
      const search = location.search;
      const data = new URLSearchParams(search).get(paramsStartDate);
      setInitialExamStartDate(new Date(data));
      firstDate = data;
    }
    let paramsEndDate = ['endDate'];
    if (queryParams.has(paramsEndDate)) {
      const search = location.search;
      const data = new URLSearchParams(search).get(paramsEndDate);
      setInitialExamEndDate(new Date(data));
      lastDate = data;
    }
    if(firstDate && lastDate){
    setInitialExamDatesFilter({
      startDate: firstDate ? moment(firstDate).format('YYYY-MM-DD') : '',
      endDate: lastDate ? moment(lastDate).format('YYYY-MM-DD') : '',
    });
  }
  }, []);

  useEffect(() => {
    if (filterData === undefined || filterData === '') {
      let filters = Object.keys(DEFAULT_VALUES);
      let selected = '';
      for (let i = 0; i < filters.length; i++) {
        const queryParams = new URLSearchParams(location.search);

        if (  queryParams.has(filters[i] +'!') ||  queryParams.has(filters[i])) {
          const search = location.search;
          const data = new URLSearchParams(search).getAll(filters[i] + '!').length>0? new URLSearchParams(search).getAll(filters[i] + '!')  :  new URLSearchParams(search).getAll(filters[i]);
          selected +=  data.map(itm =>queryParams.has(filters[i] +'!')? `&${filters[i]}!=${itm}` : `&${filters[i]}=${itm}` ).join('') 
        }
        setFilterData(selected);
      }
    }
  }, [filterData, location.search]);

  useEffect(()=>{
    const existingParams = new URLSearchParams(location.search);

    existingParams.set('status', activeStatus);
    const queryParams = new URLSearchParams(location.search);
    if( recoConfigData?.showDefaultDate && !queryParams.has('startDate') && !queryParams.has('endDate')){
      existingParams.set('startDate', moment().subtract(recoConfigData?.defaultRangeStart, "days").format("YYYY-MM-DD"));
      existingParams.set('endDate', moment().subtract(recoConfigData?.defaultRangeEnd, "days").format("YYYY-MM-DD"));
      }
    const newUrl = `${location.pathname}?${existingParams.toString()}`;
    history.push(newUrl);

  },[])



  const onNeedExport = (rangeStart, rangeEnd, filters) => {
    setNeedExport(true);
    setStartDate(rangeStart);
    setEndDate(rangeEnd);
    setSelectedExportFilters({ ...filters });
  };

  const onNeedDates = () => {
    if((permission && exportPermission) || !permission){
    setShowModal(true);
    window.analytics.track('Follow-Up-List-Export-Reports-Button-Click');
   }else{
     dispatch(
       showAlert('danger', 'Error', `You don't have permission to export reports`)
     );
   }

  };

  const openMessageHandler = (state, id) => {
    window.analytics.track('Follow-Up-List-Send-Message-Button-Click');
    setPatientId(id);
    setOpenMessage(state);
    // dispatch(getRecentIncomingMessages());
    // dispatch(getTwilioConfig());
  };

  
  const openFaxHandler = (state, id) => {
    window.analytics.track('Follow-Up-List-Send-Fax-Button-Click');
    setPatientId(id);
    setOpenFax(state);
  };

  const openLetterHandler = (state, id) => {
    window.analytics.track('Follow-Up-List-Send-Letter-Button-Click');
    setPatientId(id);
    setOpenLetter(state);
  };



  const onSetFilteredSort = (sort) => {
    setFilteredSort(sort);
    window.analytics.track('Follow-Up-List-Set-Sort');
  };

  const onLoadMore = () => {
    if(!isLoadingMore){
    setIsLoadingMore(true);
    setAllSelected(false);
    if (followUpList.length <= totalItems) {
      setPageNumber((prevNumber) => prevNumber + 1);
      setDisplayItemsCount(displayItemsCount + itemsPortionSize);
      window.analytics.track('Follow-Up-List-Load-More-Click');
    }
  }
  };

  

  const handleSubmit = (values) => {
    setPageNumber(1)
    setSearchValue(values.searchValue);
    setAllSelected(false);
    setSelectedIds([])


    const existingParams = new URLSearchParams(location.search);

    existingParams.set('search', values.searchValue);

    const newUrl = `${location.pathname}?${existingParams.toString()}`;
    history.push(newUrl);


    window.analytics.track('Follow-Up-List-Search-Submit',{search:values.searchValue});
  };

  const handleResetForm = () => {
    setPageNumber(1)
    setSearchValue(null);  
    setAllSelected(false);
    setSelectedIds([])
  
    const existingParams = new URLSearchParams(location.search);

    existingParams.delete('search');

    const newUrl = `${location.pathname}?${existingParams.toString()}`;
    history.push(newUrl);
    window.analytics.track('Follow-Up-List-Search-Reset');
  };

  const onSetActiveStatus = (status) => {
    window.analytics.track(`Follow-Up-List-Tab-${status}`);
    setSelectedIds([]);
    setAllSelected(false);
    setPageNumber(1);
    const existingParams = new URLSearchParams(location.search);

   

    if (status === 'Scheduled') {
      setActiveStatus('scheduled_not_completed');
      existingParams.set('status', 'scheduled_not_completed');
    } else if (status === 'Non-Compliant') {
      setActiveStatus('expired');
      existingParams.set('status', 'expired');
    } else {
      setActiveStatus(status);
      existingParams.set('status', status);
    }

    
    const newUrl = `${location.pathname}?${existingParams.toString()}`;
    history.push(newUrl);
  };

  const EditRecoHandler = (data) => {
    setSelectedData(data);
    setOpenEditReco(!openEditReco);
    window.analytics.track('Follow-Up-List-Edit-Reco-Click');
  };

  const RemoveRecoHandler = (data) => {
    setSelectedData(data);
    setOpenRemoveReco(!openRemoveReco);
  };

  const editModalHandle = () => {
    setOpenEditReco(false);
  };

  const onSetActionStatus = (status) => {
    if((permission && updatePermission) || !permission){
      setActionStatus(status);
      window.analytics.track('Follow-Up-List-Action-Status-Change-Event');
     }else{
       dispatch(
         showAlert('danger', 'Error', `You don't have permission to change reco status`)
       );
     }
   
  };

  const TabComponent = ({ name, color, count, cb }) => {
    const isActive = name === activeStatus;
    return (
      <Tab
        color={color}
        onClick={() => cb(name)}
        active={
          activeStatus === 'expired' && name === 'Non-Compliant'
            ? true
            : activeStatus === 'scheduled_not_completed' && name === 'Scheduled'
            ? true
            : isActive
        }
      >{`${name} (${count || 0})`}</Tab>
    );
  };


  const closeSelected = (category, selectedItem) => {
    setPageNumber(1);
    setAllSelected(false);
    setSelectedIds([])

    if(category === 'modality'){ 
    // setModalityOperator('')
    }
    if(category === 'anatomy'){
      // setAnatomyOperator('')
    }
    const filters = filterValuesForRequest[category].filter(item => {return item !== selectedItem});
    setFilterValuesForRequest({ ...filterValuesForRequest, [category]: filters.length>0?filters:"" });
    cancelSelected(category,selectedItem);
    removeCancelledFilter(category, selectedItem);
  };

  const onInitialExamDatesChange = (dates) => {
    if (isArray(dates)) {
      const [start, end] = dates;
      setInitialExamStartDate(start);
      setInitialExamEndDate(end);
      setInitialExamDatesFilter({
        startDate: start ? moment(start).format('YYYY-MM-DD') : '',
        endDate: end ? moment(end).format('YYYY-MM-DD') : '',
      });
      const existingParams = new URLSearchParams(location.search);

                  
      existingParams.set('startDate',  start ? moment(start).format('YYYY-MM-DD') : '');
      existingParams.set('endDate',  end ? moment(end).format('YYYY-MM-DD') : '');
  
  
      const newUrl = `${location.pathname}?${existingParams.toString()}`;
      history.push(newUrl);
    }
  };

  const onInitialRangeDatesChange = (dates) => {
    if (isArray(dates)) {
      const [start, end] = dates;
        setInitialRangeStartDate(start);
        setInitialRangeEndDate(end);
        setInitialRangeDatesFilter({
          startDate: start ? moment(start).format('YYYY-MM-DD') : '',
          endDate: end ? moment(end).format('YYYY-MM-DD') : '',
        });
        setPageNumber(1)
        const existingParams = new URLSearchParams(location.search);
        let labelStart='';
        let labelEnd='';
        if(selectedOption === 'range start' && selectedConfiguration === "gte and lte" ){
         labelStart = 'rangeStartDateStartGte'
         labelEnd = 'rangeStartDateEndLte'
        }

         if(selectedOption === 'range end' && selectedConfiguration === 'gte and lte'){
          labelStart = 'rangeEndDateStartGte'
          labelEnd = 'rangeEndDateEndLte'
         }
      
                    
        existingParams.set(labelStart,   start ? moment(start).format('YYYY-MM-DD') : '');
        existingParams.set(labelEnd,  end ? moment(end).format('YYYY-MM-DD') : '');

    
        const newUrl = `${location.pathname}?${existingParams.toString()}`;
        history.push(newUrl);
      
     
    }
  };

  const chevronHandler = (direction) => {
    let requiredIndex = followUpList.findIndex(
      (reco) => reco.id === selectedData.id
    );
    if (direction === 'forward') {
      if (requiredIndex < followUpList.length - 1) {
        setSelectedData(followUpList[requiredIndex + 1]);
        window.history.replaceState(
          null,
          null,
          `${
            history.location.pathname + history.location.search
          }&recommendation=${followUpList[requiredIndex + 1]?.id}`
        );
      } else if (followUpList.length <= totalItems) {
        onLoadMore();
      }
    } else {
      if (requiredIndex !== 0) {
        setSelectedData(followUpList[requiredIndex - 1]);
        window.history.replaceState(
          null,
          null,
          `${
            history.location.pathname + history.location.search
          }&recommendation=${followUpList[requiredIndex - 1]?.id}`
        );
      }
    }
  };

  const getFilterDetail = (data) => {
    if (data) setFilterData(data);
  };

  const removeCancelledFilter = (category, itm) => {
    if (typeof URLSearchParams !== 'undefined') {
      const params = new URLSearchParams(filterData);
 

const categoryToDelete = category;

const valuesToDelete = [
  ...queryParams.getAll(categoryToDelete),
  ...queryParams.getAll(categoryToDelete + "!"),
];

const valueToDelete = itm.value;

const updatedValues = valuesToDelete.filter(
  (value) => value !== valueToDelete && value !== valueToDelete + "!"
);

params.delete(categoryToDelete);
params.delete(categoryToDelete + "!");

updatedValues.forEach((value) => params.append(categoryToDelete, value));

      const qString = params.toString();
      qString ? setFilterData(`&${qString}`) : setFilterData(qString);
    }
  };

  const cancelSelected = (category,itm) => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has(category + '!') ||  queryParams.has(category)) {
      // queryParams.delete(category);
      // queryParams.delete(category + '!');
      
const categoryToDelete = category;

const valuesToDelete = [
  ...queryParams.getAll(categoryToDelete),
  ...queryParams.getAll(categoryToDelete + "!"),
];

const valueToDelete = itm.value;

const updatedValues = valuesToDelete.filter(
  (value) => value !== valueToDelete && value !== valueToDelete + "!"
);

queryParams.delete(categoryToDelete);
queryParams.delete(categoryToDelete + "!");


updatedValues.forEach(value => queryParams.append(categoryToDelete, value));
      history.replace({
        search: queryParams.toString(),
      });
    }
  };
  const resetExamDateFilter = () => {
    const queryParams = new URLSearchParams(location.search);
    let paramsStartDate = ['startDate'];
    let paramsEndDate = ['endDate'];
    if (queryParams.has(paramsEndDate && paramsEndDate)) {
      queryParams.delete(paramsStartDate);
      queryParams.delete(paramsEndDate);
      history.replace({
        search: queryParams.toString(),
      });
    }
    setPageNumber(1)
    setAllSelected(false);
    setSelectedIds([])

    setInitialExamStartDate(null);
    setInitialExamEndDate(null);
    setInitialExamDatesFilter({
      startDate: null,
      endDate: null,
    });
  };


  const resetRangeDateFilter = () => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("rangeStartDateGte")) {
      queryParams.delete(["rangeStartDateGte"]);
      history.replace({
        search: queryParams.toString(),
      });
    }

    if (queryParams.has("rangeStartDateLte")) {
      queryParams.delete(["rangeStartDateLte"]);
      history.replace({
        search: queryParams.toString(),
      });
    }
    if (queryParams.has("rangeEndDateGte")) {
      queryParams.delete(["rangeEndDateGte"]);
      history.replace({
        search: queryParams.toString(),
      });
    }
    if (queryParams.has("rangeEndDateLte")) {
      queryParams.delete(["rangeEndDateLte"]);
      history.replace({
        search: queryParams.toString(),
      });
    }

    if (
      queryParams.has("rangeStartDateStartGte") &&
      queryParams.has("rangeStartDateEndLte")
    ) {
      queryParams.delete(["rangeStartDateStartGte"]);
      queryParams.delete(["rangeStartDateEndLte"]);
      history.replace({
        search: queryParams.toString(),
      });
    }

    if (
      queryParams.has("rangeEndDateStartGte") &&
      queryParams.has("rangeEndDateEndLte")
    ) {
      queryParams.delete(["rangeEndDateStartGte"]);
      queryParams.delete(["rangeEndDateEndLte"]);
      history.replace({
        search: queryParams.toString(),
      });
    }

    setPageNumber(1);
    setAllSelected(false);
    setSelectedIds([])

    setDate("");
    setInitialRangeStartDate("");
    setInitialRangeEndDate("");
    setInitialRangeDatesFilter({
      startDate: "",
      endDate: "",
    });
  };


  const handleOperatorsValue = (modality, anatomy, reportModality, examCodeOperator) => {
    if(modality){
      setModalityOperator(modality);
    }
    if(anatomy){
    setAnatomyOperator(anatomy);
    }
    if(reportModality){
      setReportModalityOperator(reportModality)
    }
    if(examCodeOperator){
      setExamCodeOperator(examCodeOperator)
    }
  };

  const handleMoreTabs = () => {
    setShowMoreTabs(!showMoreTabs);
  };



  const [selectedValues, setSelectedValues] = useState('');



  const SelectContainer = styled.div`
  width: 220px;
  height:38px;
  margin-right: 10px;
  position:relative;
  font-size:14px;
  font-weight: 600;
  line-height:20px;
`;

const [nextPage, setNextPage] = useState()
const [loadingMore, setLoadingMore] = useState(false)

useEffect(()=>{
dispatch(getSavedFilterRecommendationsInitial()).then(res=> {
  if(res){
    if(res.next){
      setNextPage(2)
    }
  }
});
},[])



const handleClearValue = () => {
setPageNumber(1)
setAllSelected(false);
setSelectedIds([])
setSelectedFilters(DEFAULT_VALUES)
setFilterValuesForRequest(DEFAULT_VALUES)
setSelectedValues(null)
setFilterData()
setNextPage()
setShowSaveFilter(false)


if(recoConfigData?.showDefaultDate){
  setInitialExamStartDate(new Date(moment().subtract(recoConfigData?.defaultRangeStart, "days")))
  setInitialExamEndDate( new Date(moment().subtract(recoConfigData?.defaultRangeEnd, "days")))
  setInitialExamDatesFilter({
   startDate:new Date(moment().subtract(recoConfigData?.defaultRangeStart, "days")),
   endDate:new Date ( moment().subtract(recoConfigData?.defaultRangeEnd, "days"))
  })
}else{
  setInitialExamStartDate(null)
  setInitialExamEndDate(null)
  setInitialExamDatesFilter({
   startDate:null,
   endDate: null
})
}

setInitialRangeStartDate(null);
setInitialRangeEndDate(null);
setInitialRangeDatesFilter({
  startDate:null,
  endDate:null
})
setDate(null)
dispatch(getSavedFilterRecommendationsInitial()).then(res=> {
if(res){
  if(res.next){
    setNextPage(2)
  }
}
});
history.push({
  pathname: '/auth/radAdmin/followUpList',
  search: `?status=${activeStatus}`,
});
};



const loadMoreOptions =  () => {
  if(nextPage && !loadingMore){
    setLoadingMore(true)
    dispatch(getSavedFilterRecommendations(nextPage)).then(res=> {
      setLoadingMore(false)
      if(res){
        if(res.next){
          setNextPage(prev=>prev+1)
          } else{
            setNextPage()
          }
      }
      
    });
    
  }

}

const handleClose = () => {
  setOpenModal(false)
}


const [activeRow, setActiveRow] = useState(null)

const handleTimelineClick = (e,row) => {
 handleClickTimeline(e,row)
};

let storedCols = JSON.parse(localStorage.getItem('col-order')) || []
const [colsOrder, setColsOrder] = useState( storedCols.length>0? storedCols :  columns.map(itm=>itm.key))
const columsData =  colsOrder.map(itm => columns.find(it=>it.key ===itm)).filter(Boolean)


const filteredObject = {...selectedFilters};
delete filteredObject.cohort;




const options = [
  { value: "Last 7 days", label: "Last 7 days", start: moment().subtract(7, 'days'), end: moment() },
  { value: "Last 30 days", label: "Last 30 days", start: moment().subtract(30, 'days'), end: moment() },
  { value: "Last 3 months", label: "Last 3 months", start: moment().subtract(3, 'months'), end: moment() },
  { value: "Last 6 months", label: "Last 6 months", start: moment().subtract(6, 'months'), end: moment() },
  { value: "Last 12 months", label: "Last 12 months", start: moment().subtract(12, 'months'), end: moment() },
  { value: "Custom date range", label: "Custom date range", start: null, end: null },
];

const [selectedSubOption2, setSelectedSubOption2] = useState(null);



const handleSubOptionChange2 = (subOption) => {
  setSelectedSubOption2(subOption);
};

const DatePickerInputField = React.forwardRef((props, ref) => {
  const { onClick, date } = props;
  const formatDate = (date, time) => {
    return `${moment(date).format("MMM, DD, YYYY")}`;
  };
  return (
    <DatePickerButton
      onClick={onClick}
      style={{ padding: "8px 24px", fontSize: "12px", width:'247px' }}
    >
      <DateContainer style={{ fontSize: 12 }}>
        {date ? `${formatDate(date)}` : "Select Reco Date"}
      </DateContainer>
      {!date && <CalendarIcon />}
      {date && (
        <DateClearButton
          onClick={() => {
            resetRangeDateFilter();
            setSelectedOption(null);
            setSelectedConfiguration(null);
            setSelectedSubOption2(null);
            setInitView(true);
          }}
        >
          <RemoveRecoIcon />
        </DateClearButton>
      )}
    </DatePickerButton>
  );
});

const DatePickerInputRange = forwardRef(
  ({ onClick, startDate, endDate }, ref) => {
    const formatDate = (date) => {
      return date ? moment(date).format("MMM, DD, YYYY") : "No Date";
    };

    return (
      <DatePickerButton onClick={onClick} ref={ref}>
        <DateContainer>
          {!startDate && !endDate
            ? "Select Reco Date Range"
            : `${formatDate(startDate)} - ${formatDate(endDate)}`}
        </DateContainer>

        {!initialRangeStartDate && !initialRangeEndDate && <CalendarIcon />}
        {(initialRangeStartDate || initialRangeEndDate) && (
          <DateClearButton
            onClick={() => {
              resetRangeDateFilter();
              setSelectedOption(null);
              setSelectedConfiguration(null);
              setSelectedSubOption2(null);
              setInitView(true)
            }}
          >
            <RemoveRecoIcon />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  }
);




const [initView, setInitView] = useState(true);
const [initView1, setInitView1] = useState(true);

const [selectedIds, setSelectedIds] = useState([]);
const [allSelected, setAllSelected] = useState(false);

const checkboxHandler = (data) => {
  if (selectedIds.includes(data.id)) {
    const filteredItems = selectedIds.filter((item) => {
      return item !== data.id;
    });
    setSelectedIds([...filteredItems]);
  }
  if (!selectedIds.includes(data.id)) {
    setSelectedIds(prev=>([...prev,data.id]));
  }
};

const checkboxHandlerAll = (data) => {
  if(allSelected){
    setSelectedIds([])
  }else{
    setSelectedIds([...data.map(itm=>itm.id)]);
  }
  setAllSelected(prev=>!prev)
};

const [anchorEl, setAnchorEl] = useState(null);

const handleClickBulk = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleCloseBulk = () => {
  setAnchorEl(null);
};

const open = Boolean(anchorEl);
const id = open ? 'bulk-actions-popover' : undefined;


const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
const [isLoadingDeleteBulk, setIsLoadingDeleteBulk] = useState(false)

const dialogHandler = () => {
  setRemoveDialogOpen(!removeDialogOpen);
  window.analytics.track('Reco-Delete-Button-Click');
}


const deleteBulkRecoHandler = () => {
  setIsLoadingDeleteBulk(true)
  dispatch(removeBulkReco({
    "action": "remove",
    "reco_ids":selectedIds
  }))
  .then((res) => {
    if(res){
      setRemoveDialogOpen(false);
      handleCloseBulk();
      setAllSelected(false);
      setSelectedIds([])
      const selectedFilters = getSelectedFilters()
      if(pageNumber === 1){
        let statusName;
        statusName = resolveStatusNameForReq(activeStatus);   
        const searchVal = searchValue || null;
        dispatch(
        getFollowUpListAction(
          pageNumber,
          statusName,
          searchVal,
          { ...selectedFilters, modalityOperator, anatomyOperator,sort:activeSortColumn, reportModalityOperator, examCodeOperator },
          initialExamDatesFilter,
          date,
          selectedConfiguration,
          selectedOption,
          initialRangeDatesFilter,  
          )
      );
      }else{
        setPageNumber(1)
      }
   
     dispatch(
       getTotalStatusesAction(
         { ...selectedFilters, modalityOperator, anatomyOperator, reportModalityOperator, examCodeOperator },
         searchValue,
         initialExamDatesFilter,
         date,
         selectedConfiguration,
         selectedOption,
         initialRangeDatesFilter
       )
     );
     
    }
    setIsLoadingDeleteBulk(false)
    
  })
}

const [anchorElStatusBulk, setAnchorElStatusBulk] = React.useState(null);
const node = useRef();
const [selectedStatus, setSelectedStatus] = useState("");



const handleClickStatusBulk = (event,id) => {
  setAnchorElStatusBulk(event.currentTarget);
};

const handleCloseStatusBulk = () => {
  setAnchorElStatusBulk(null);
};

const openStatusBulk = Boolean(anchorElStatusBulk);
const popupIdStausBulk = openStatusBulk ? 'simple-popover' : undefined;
const [filterOpen, setFilterOpen] = useState(false);

const handleFilter = () => {
  setFilterOpen(true);
  window.analytics.track("Follow-Up-List-Filter-Button-Click");
};

const [bulkActionClicked,setBulkActionClicked] = useState(false);
const [recoTabExportClicked,setRecoTabExportClicked] = useState(false);


const handleSelectedRecoExport = ()=> {
  if((permission && exportPermission) || !permission){
  window.analytics.track('Follow-Up-List-Export-Selected-Reports-Button-Click');  
  setBulkActionClicked(true);
  dispatch(
   getExportReportAction(
    null,null,{ recoIdList: selectedIds
     }
   )
 ).then((res) => {
  setBulkActionClicked(false);
   if(res){
   handleCloseBulk()
   setAllSelected(false);
   setSelectedIds([])
   }
 });
   }else{
     dispatch(
       showAlert('danger', 'Error', `You don't have permission to export reports`)
     );
   }

   }

const handleTabRecoReportExport = ()=> {

  if((permission && exportPermission) || !permission){
    window.analytics.track('Follow-Up-List-Export-Tab-Reports-Button-Click');
    const prepareStatusForReq = (status) =>
      `${status.trim().replace(' ', '_').toLowerCase()}`;
    let statusValue = resolveStatusNameForReq(activeStatus);
    let statusName = typeof statusValue === 'string'
    ? prepareStatusForReq(statusValue)
    : statusValue
        .filter((item) => item)
        .map(({ status }) => prepareStatusForReq(status));


    let rangeLabel;
    if(selectedOption === 'range start' && selectedConfiguration === 'gte' && date){
    rangeLabel = 'nlp_range_start_greater_than_filter';
    }
    if(selectedOption === 'range start' && selectedConfiguration === 'lte' && date){
      rangeLabel =  'nlp_range_start_less_than_filter';
    }
    if(selectedOption === 'range end' && selectedConfiguration === 'gte' && date){
      rangeLabel =  'nlp_range_end_greater_than_filter';
    }
    if(selectedOption === 'range end' && selectedConfiguration === 'lte' && date){
      rangeLabel = 'nlp_range_end_less_than_filter';
    }

    let rangeLabelGte = `${selectedOption === 'range end' ? 'nlp_range_end' : 'nlp_range_start'}_greater_than_filter`;
    let rangeLabelLte = `${selectedOption  === 'range end' ? 'nlp_range_end' : 'nlp_range_start'}_less_than_filter`;

  const filterObj =  {
    conditional:"",
    location:selectedFilters?.['location'].length>0 ? selectedFilters?.['location']?.map(itm=>itm.value):null,
    radiologist:selectedFilters?.['radiologist'].length>0?  selectedFilters?.['radiologist']?.map(itm=>Number(itm.value)): null,
    referringPhysician: selectedFilters?.['referrers'].length>0?  selectedFilters?.['referrers']?.map(itm=>Number(itm.value)): null,
    examCode: selectedFilters?.['exam_code'].length>0? selectedFilters?.['exam_code']?.map(itm=>itm.value): null,
    messageSent: selectedFilters?.['message_sent'].length>0? selectedFilters?.['message_sent']?.map(itm=>Boolean(itm.value))?.[0] : null,
    examDate:selectedFilters?.filters?.filter(itm=>itm.category ==='exam_date').map(itm=>itm.value)?.[0],
    modality: selectedFilters?.['report_modality'].length> 0 ? selectedFilters?.['report_modality']?.map(itm=>itm.value): null,
    recoModality: selectedFilters?.['modality'].length>0?  selectedFilters?.['modality']?.map(itm=>itm.value):null,
    anatomy:selectedFilters?.['anatomy'].length>0? selectedFilters?.['anatomy']?.map(itm=>itm.value): null,
    recommendation: statusName? statusName : null,
    contrast: selectedFilters?.['contrast'].length>0 ?  selectedFilters?.['contrast']?.map(itm=>itm.value)?.[0] : null,
    examType: selectedFilters?.['exam_type'].length>0? selectedFilters?.['exam_type']?.map(itm=>itm.value)?.[0]: null,
    taskType: selectedFilters?.['task_type'].length>0?  selectedFilters?.['task_type']?.map(itm=>itm.value): null,
    hasNote: selectedFilters?.['has_note'].length>0?  selectedFilters?.['has_note']?.map(itm=>Boolean(itm.value))?.[0]: null,
    guidelineType: selectedFilters?.['guideline_type'].length>0? selectedFilters?.['guideline_type']?.map(itm=>itm.value): null,
    recommendationType:selectedFilters?.['recommendation_type'].length>0? selectedFilters?.['recommendation_type']?.map(itm=>itm.value)?.[0]: null,
    timeframe: selectedFilters?.['timeframe'].length>0? selectedFilters?.['timeframe']?.map(itm=>itm.value): null,
    language: selectedFilters?.['patient_language']?.length>0? selectedFilters?.['patient_language']?.map(itm=>itm.value)?.[0] : null,
    age:selectedFilters?.['age'].length>0?  selectedFilters?.['age'].map(itm=>itm.value): null,
    rangeLabel:rangeLabel, 
    rangeDate:date,
    rangeDateGte: initialRangeDatesFilter.startDate,
    rangeDateLte: initialRangeDatesFilter.endDate ,
    rangeLabelGte:rangeLabelGte ,
    rangeLabelLte: rangeLabelLte,
    cohort: selectedFilters?.['cohort'].length>0 ? selectedFilters?.['cohort']?.map(itm=>itm.value):null,
    recoSource: selectedFilters?.['source'].length>0?  selectedFilters?.['source']?.map(itm=>itm.value)?.[0]: null,
  }
  if(examCodeOperator?.value === "isNotEqual" && selectedFilters?.['exam_code'].length>0){
    delete filterObj.examCode;
    filterObj.examCodeNegated = selectedFilters?.['exam_code'].map(itm=>itm.value)
  }
  if(anatomyOperator?.value === "isNotEqual" && selectedFilters?.['anatomy'].length>0){
    delete filterObj.anatomy;
    filterObj.anatomyNegated = selectedFilters?.['anatomy'].map(itm=>itm.value)
  }

  if(reportModalityOperator?.value === "isNotEqual" && selectedFilters?.['report_modality'].length>0 ){
    delete filterObj.modality;
    filterObj.modalityNegated = selectedFilters?.['report_modality'].map(itm=>itm.value)
    }

  if(modalityOperator?.value === "isNotEqual" && selectedFilters?.['modality'].length>0 ){
    delete filterObj.recoModality;
    filterObj.recoModalityNegated = selectedFilters?.['modality'].map(itm=>itm.value)
    }
    setRecoTabExportClicked(true);
    dispatch(
     getExportReportAction(
      initialExamDatesFilter.startDate ? moment(initialExamDatesFilter.startDate).format("YYYY-MM-DD") : null,
      initialExamDatesFilter.endDate ? moment(initialExamDatesFilter.endDate).format("YYYY-MM-DD") : null,
        filterObj
     )
   ).then((res) => {
    setRecoTabExportClicked(false);
     if(res){
     handleCloseBulk()
     }
   });
   }else{
     dispatch(
       showAlert('danger', 'Error', `You don't have permission to export reports`)
     );
   }

  
     }
  
const tableRefVal = useRef(null);
const [scrollPosition, setScrollPosition] = useState(0);


useEffect(() => {
  const table = tableRefVal.current;

  const handleScroll = () => {
    setScrollPosition(table.scrollLeft);
  };

  if (table) {
    table.addEventListener('scroll', handleScroll);
    setScrollPosition(table.scrollLeft);
  }

  return () => {
    if (table) {
      table.removeEventListener('scroll', handleScroll);
    }
  };
}, []);


const handleScrollLeft = () => {
  const table = tableRefVal.current;
  if (table) {
    table.scrollTo({
      left: table.scrollLeft - 800,
      behavior: 'smooth',
    });
  }
};

const handleScrollRight = () => {
  const table = tableRefVal.current;
  if (table) {
    table.scrollTo({
      left: table.scrollLeft + 800,
      behavior: 'smooth',
    });
  }
};


const [isAtStart,setIsAtStart] = useState(true);
const [isAtEnd, setIsAtEnd] = useState(false);

useEffect(()=>{
  if(tableRefVal?.current){
    setIsAtStart(scrollPosition === 0);
    setIsAtEnd( scrollPosition ===0? false : (Math.ceil(scrollPosition) >= tableRefVal.current.scrollWidth - tableRefVal.current.clientWidth));

  }
},[scrollPosition,tableRefVal.current])

const [anchorElTimeline, setAnchorElTimeline] = React.useState(null);
const [showDropdown, setShowDropdown] = useState(false);

const openTimeline = Boolean(anchorElTimeline);
const popoveridTimeline = openTimeline ? 'simple-popover' : undefined;
const showHorizontalScrollChevrons = ( openModal || editing?.visible || opened || openEditReco || filterOpen ) ? false: true;

const handleClickTimeline = (event, row) => {
if(row){
  setAnchorElTimeline(event.currentTarget);
  setShowDropdown(true);
  setActiveRow(row);
  dispatch(getRecoHistoryAction(row.id, row));
}

};

const handleCloseTimeline = () => {
  setAnchorElTimeline(null);
  setActiveRow(null)
  setShowDropdown(false)
};



  return (
    <Box p={30}>
      {
        openFax &&  <FaxTemplateModal 
        openMessage={openFax}
        openMessageHandler={openFaxHandler}
        patientDetails={
          followUpList &&
          patientId &&
          followUpList.find(({ id }) => id === patientId)
        }
       />
      }
       {
        openLetter &&  <LetterTemplateModal 
        openMessage={openLetter}
        openMessageHandler={openLetterHandler}
        patientDetails={
          followUpList &&
          patientId &&
          followUpList.find(({ id }) => id === patientId)
        }
        selectedIds={selectedIds}
       />
      }
       {
        openMessage && <SmsCreationDialog
        openMessage={openMessage}
        openMessageHandler={openMessageHandler}
        patientDetails={
          followUpList &&
          patientId &&
          followUpList.find(({ id }) => id === patientId)
        }
      />
       }
      
      {
        openEditReco &&   <EditReco
        openDialog={openEditReco}
        selectedData={selectedData}
        EditRecoHandler={EditRecoHandler}
        chevronHandler={chevronHandler}
        activeStatus={activeStatus}
        RemoveRecoHandler={RemoveRecoHandler}
        openRemoveRecoDialog={openRemoveReco}
        editModalHandle={editModalHandle}
        setSelectedData={setSelectedData}
      />
      }
    
      <RemoveReco
        openDialog={openRemoveReco}
        selectedData={selectedData}
        RemoveRecoHandler={RemoveRecoHandler}
        editModalHandle={editModalHandle}
      />
      <RecoNoteModal
        isOpen={isOpenRecoNoteModal}
        onClose={() => setIsOpenRecoNoteModal(false)}
        title="Recommended Notes"
        borderBottom="1px solid #DFE0EB"
        coponent={<RecoNotesAnalytics />}
      />
      <HeaderContainer>
        <Row style={{ width: "100%", marginLeft: "0px", marginRight: "0px" }}>
          <Col style={{ paddingLeft: "0px" }} lg={2} md={12} sm={12}>
            <div style={{height:'100%', display:'flex'}}>
            <PageTitle>Follow up List</PageTitle>

            </div>
          </Col>
          <Col lg={8} md={12} sm={12}>
            <ColumnContainer>
              <ControlsContainer>
              {filterOpen === false && (
                <FilterContainer>
                  <FilterButton onClick={() => handleFilter()}>
                  <Image src={filterOutline} alt={"settings icon"} />
                  </FilterButton>
                </FilterContainer>
          
         )}
         {
          filterOpen === true &&  <FilterCard>
          <FollowUpListFilter
            filterBy={filterBy}
            setFilter={setFilterBy}
            filterTypes={filterTypes.concat(["contains note"])}
            setFilterBy={setFilterBy}
            setPageNumber={setPageNumber}
            filterValuesForRequest={filterValuesForRequest}
            setFilterValuesForRequest={setFilterValuesForRequest}
            getFilterDetail={getFilterDetail}
            operatorValues={handleOperatorsValue}
            anatomyOperator={anatomyOperator}
            modalityOperator={modalityOperator}
            reportModalityOperator={reportModalityOperator}
            examCodeOperator={examCodeOperator}
            setShowSaveFilter={setShowSaveFilter}
            setShowAddCohort={setShowAddCohort}
            setSelectedIds={setSelectedIds}
            setAllSelected={setAllSelected}
            filterOpen={filterOpen}
            setFilterOpen={setFilterOpen}
          />
        </FilterCard>
         }
             
              </ControlsContainer>

              <label
                style={{
                  width: "220px",
                  height: "38px",
                  position: "relative",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "20px",
                  marginBottom:'0px',
                  marginTop:'auto'
                }}
              >
                <ReactSelect
                  width="200px"
                  height="38px"
                  components={{
                    Option: (props) => (
                      <CustomOption
                        {...props}
                        editing={editing}
                        setEditing={setEditing}
                        setFilterValuesForRequest={setFilterValuesForRequest}
                        setValue={setSelectedValues}
                        value={selectedValues}
                        setPageNumber={setPageNumber}
                        option={props.data}
                        setAnatomyOperator={setAnatomyOperator}
                        setModalityOperator={setModalityOperator}
                        setExamCodeOperator={setExamCodeOperator}
                        setReportModalityOperator={setReportModalityOperator}
                        setInitialExamStartDate={setInitialExamStartDate}
                        setInitialExamEndDate={setInitialExamEndDate}
                        setInitialExamDatesFilter={setInitialExamDatesFilter}
                        setInitialRangeEndDate={setInitialRangeEndDate}
                        setInitialRangeDatesFilter={setInitialRangeDatesFilter}
                        setInitialRangeStartDate={setInitialRangeStartDate}
                        setDate={setDate}
                        setSelectedConfiguration={setSelectedConfiguration}
                        setSelectedOption={setSelectedOption}
                        setAllSelected={setAllSelected}
                        setSelectedIds={setSelectedIds}
                      />
                    ),
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={savedFilterRecommendations}
                  isLoading={loadingMore}
                  onMenuScrollToBottom={loadMoreOptions}
                  isDisabled={selectedValues || Object.values(selectedFilters).filter(Boolean).length > 0 }
                  value={selectedValues}
                  styles={{
                    option: (styles, { isSelected }) => ({
                      ...styles,
                      fontSize: "14px",
                      height: "38px",
                      maxHeight: "200px",
                      overflow: "scroll",
                      pointerEvents: selectedValues ? "none" : "auto", // Disable pointer events for selected options
                      opacity: selectedValues ? 0.5 : 1,
                    }),
                    control: (provided) => ({
                      ...provided,
                      height: "38px",
                      background: "#EEEEEE",
                      color: "#A1A1A1",
                    }),
                    menu: (provided) => ({
                      ...provided,
                    }),
                    placeholder: (styles) => ({
                      ...styles,
                      color: "#A1A1A1", // Change to the desired placeholder color
                    }),
                  }}
                  placeholder={
                    loadingMore
                      ? "Loading..."
                      : `Saved Filter Sets (${savedFilterRecommendations?.length})`
                  }
                />
                {selectedValues && (
                  <img
                    style={{
                      position: "absolute",
                      right: 45,
                      top: "50%",
                      cursor: "pointer",
                      transform: 'translateY(-50%)'
                    }}
                    height="14px"
                    width="14px"
                    src={cancelIcon}
                    onClick={handleClearValue}
                  />
                )}
              </label>
              <div style={{display:'flex', position:'relative', width:'500px', height:'56px', gap:'6px'}}>

             
              <div style={{ position:'absolute', left:0, zIndex: openModal || editing.visible || cohortOpened || opened ? 0:  2}}>
              <DateFieldLabel>
                  EXAM DATE RANGE
                </DateFieldLabel>

{ initView1 && !initialExamStartDate && !initialExamEndDate ?   
    <InitialSelect onClick={()=>setInitView1(false)}  >
     <span>Select...</span>
     <CalendarIcon/>
    </InitialSelect> :
  initialExamStartDate && initialExamEndDate ? (
    <DatePickerContainer>
      <DatePicker
        popperPlacement={"auto"}
        selected={initialExamStartDate}
        // onChange={onInitialRangeDatesChange}
        startDate={initialExamStartDate}
        endDate={initialExamEndDate}
        customInput={
          <DatePickerInput startDate={initialExamStartDate} endDate={initialExamEndDate} />
        }
        shouldCloseOnSelect={false}
        selectsRange
        dateFormat={"dd/MM/yyyy"}
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "5px, 10px",
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport",
          },
        }}
      />
    </DatePickerContainer>
  ) :  (
    <ClickAwayListener onClickAway={()=>{
      setInitView1(true)
      setSelectedSubOption2()
      }}>
   <div>
      <RadioContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap:'6px'
            }}
          >
           

          
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap:'6px'
                }}
              >
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="custom"
                    checked={selectedSubOption2 === "custom"}
                    onChange={() => {handleSubOptionChange2("custom")
                  }}

                  borderColor={selectedSubOption2 === "custom"
                  ? "#00AEEF"
                  : "#D4D4D4"}
                   bg={selectedSubOption2 === "custom" ? "#00AEEF" : "#FFF"}
                  />
                  Custom Range
                </RadioLabel>
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="last7days"
                    checked={selectedSubOption2 === "last7days"}
                    onChange={() =>{ handleSubOptionChange2("last7days")
                    let obj = RecoOptions.find(
                      (itm) => itm.value === "last7days"
                    );
                    setInitialExamStartDate(obj.start);
                    setInitialExamEndDate(obj.end);
                    setInitialExamDatesFilter({
                      startDate: obj.start ? moment(obj.start).format('YYYY-MM-DD') : '',
                      endDate: obj.end ? moment(obj.end).format('YYYY-MM-DD') : '',
                    });
                    setPageNumber(1)
                    const existingParams = new URLSearchParams(location.search);

                  
                    existingParams.set('startDate',obj.start ? moment(obj.start).format('YYYY-MM-DD') : '' );
                    existingParams.set('endDate',  obj.end ? moment(obj.end).format('YYYY-MM-DD') : '');
                
                
                    const newUrl = `${location.pathname}?${existingParams.toString()}`;
                    history.push(newUrl);
                 
                    }}
                    borderColor={selectedSubOption2 === "last7days"
                    ? "#00AEEF"
                    : "#D4D4D4"}
                     bg={selectedSubOption2 === "last7days" ? "#00AEEF" : "#FFF"}
                  />
                  Last 7 Days
                </RadioLabel>
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="last30days"
                    checked={selectedSubOption2 === "last30days"}
                    onChange={() =>{ handleSubOptionChange2("last30days")
                    let obj = RecoOptions.find(
                      (itm) => itm.value === "last30days"
                    );
                    setInitialExamStartDate(obj.start);
                    setInitialExamEndDate(obj.end);
                    setInitialExamDatesFilter({
                      startDate: obj.start ? moment(obj.start).format('YYYY-MM-DD') : '',
                      endDate: obj.end ? moment(obj.end).format('YYYY-MM-DD') : '',
                    });
                    setPageNumber(1)
                    const existingParams = new URLSearchParams(location.search);

                  
                    existingParams.set('startDate',obj.start ? moment(obj.start).format('YYYY-MM-DD') : '' );
                    existingParams.set('endDate',  obj.end ? moment(obj.end).format('YYYY-MM-DD') : '');
                
                
                    const newUrl = `${location.pathname}?${existingParams.toString()}`;
                    history.push(newUrl);
                  }
                  }
                  borderColor={selectedSubOption2 === "last30days"
                  ? "#00AEEF"
                  : "#D4D4D4"}
                   bg={selectedSubOption2 === "last30days" ? "#00AEEF" : "#FFF"}
                  />
                  Last 30 Days
                </RadioLabel>
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="last6months"
                    checked={selectedSubOption2 === "last6months"}
                    onChange={() =>{ handleSubOptionChange2("last6months")
                    let obj = RecoOptions.find(
                      (itm) => itm.value === "last6months"
                    );
                    setInitialExamStartDate(obj.start);
                    setInitialExamEndDate(obj.end);
                    setInitialExamDatesFilter({
                      startDate: obj.start ? moment(obj.start).format('YYYY-MM-DD') : '',
                      endDate: obj.end ? moment(obj.end).format('YYYY-MM-DD') : '',
                    });
                    setPageNumber(1)
                    const existingParams = new URLSearchParams(location.search);

                  
                    existingParams.set('startDate',obj.start ? moment(obj.start).format('YYYY-MM-DD') : '' );
                    existingParams.set('endDate',  obj.end ? moment(obj.end).format('YYYY-MM-DD') : '');
                
                
                    const newUrl = `${location.pathname}?${existingParams.toString()}`;
                    history.push(newUrl);
                 
                  }}
                  borderColor={selectedSubOption2 === "last6months"
                  ? "#00AEEF"
                  : "#D4D4D4"}
                   bg={selectedSubOption2 === "last6months" ? "#00AEEF" : "#FFF"}
                  />
                  Last 6 Months
                </RadioLabel>
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="last12months"
                    checked={selectedSubOption2 === "last12months"}
                    onChange={() => {
                      handleSubOptionChange2("last12months");
                      let obj = RecoOptions.find(
                        (itm) => itm.value === "last12months"
                      );
                      setInitialExamStartDate(obj.start);
                      setInitialExamEndDate(obj.end);
                      setInitialExamDatesFilter({
                        startDate: obj.start ? moment(obj.start).format('YYYY-MM-DD') : '',
                        endDate: obj.end ? moment(obj.end).format('YYYY-MM-DD') : '',
                      });
                      setPageNumber(1)
                      const existingParams = new URLSearchParams(location.search);

                  
                      existingParams.set('startDate',obj.start ? moment(obj.start).format('YYYY-MM-DD') : '' );
                      existingParams.set('endDate',  obj.end ? moment(obj.end).format('YYYY-MM-DD') : '');
                  
                  
                      const newUrl = `${location.pathname}?${existingParams.toString()}`;
                      history.push(newUrl);
                   
                  }}
                  borderColor={selectedSubOption2 === "last12months"
                  ? "#00AEEF"
                  : "#D4D4D4"}
                   bg={selectedSubOption2 === "last12months" ? "#00AEEF" : "#FFF"}
                  />
                  Last 12 Months
                </RadioLabel>
              </div>
        
          </div>
      </RadioContainer>
     
      {selectedSubOption2 === "custom" && (
        <div style={{position:'absolute', top:'70%'}}>
          <DatePicker
            selected={initialExamStartDate}
            onChange={onInitialExamDatesChange}
            startDate={initialExamStartDate}
            endDate={initialExamEndDate}
            selectsRange
            inline
            shouldCloseOnSelect
          />
        </div>
      )}
    </div>
    </ClickAwayListener>
  )}
                
              
              </div>
<div style={{ position:'absolute',right:0, zIndex: openModal || editing.visible || cohortOpened || opened ? 0: 2}}>
               <DateFieldLabel>
                RECO DUE DATE RANGE
                </DateFieldLabel>

  {initView && !date && !initialRangeStartDate && !initialRangeEndDate ? 
    <InitialSelect onClick={()=>setInitView(false)}  >
      <span>Select...</span>
      <CalendarIcon/>
     </InitialSelect> :
      date  ? (
      <DatePickerContainer>
      <DatePicker
       popperPlacement={"auto"}
      onChange={onChange}
      dateFormat="MM/dd/yyyy"
      selected={date}
      customInput={<DatePickerInputField date={date} />}
      // className={classes.datePicker}
      />
      </DatePickerContainer>
    
  ) : initialRangeStartDate && initialRangeEndDate ? (
    <DatePickerContainer>
      <DatePicker
        popperPlacement={"auto"}
        selected={initialRangeStartDate}
        // onChange={onInitialRangeDatesChange}
        startDate={initialRangeStartDate}
        endDate={initialRangeEndDate}
        customInput={
          <DatePickerInputRange startDate={initialRangeStartDate} endDate={initialRangeEndDate} />
        }
        shouldCloseOnSelect={false}
        selectsRange
        dateFormat={"dd/MM/yyyy"}
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "5px, 10px",
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport",
          },
        }}
      />
    </DatePickerContainer>
  ) :  (
    <ClickAwayListener onClickAway={()=>{
      setInitView(true)
      setSelectedConfiguration();
      setSelectedOption()
      setSelectedSubOption2()
      }}>
 <div>
      <RadioContainer>
        <RadioLabel>
          <RadioBtn
            type="radio"
            value="range start"
            borderColor={selectedOption === "range start"
            ? "#00AEEF"
            : "#D4D4D4"}
             bg={selectedOption === "range start" ? "#00AEEF" : "#FFF"}
            checked={selectedOption === "range start"}
            onChange={(e) => {
              handleOptionChange(e);
              setSelectedConfiguration(null);
              setSelectedSubOption2(null);
            }}
          />
          Min Due Date
        </RadioLabel>
        {selectedOption === "range start" && (
          <div
            style={{
              marginLeft: "34px",
              display: "flex",
              flexDirection: "column",
              gap:'6px'
            }}
          >
            <RadioLabel>
              <RadioBtn
                type="radio"
                value="lte"
                checked={selectedConfiguration === "lte"}
                onChange={handleChangeConfiguration}
                borderColor={selectedConfiguration === "lte"
                ? "#00AEEF"
                : "#D4D4D4"}
                 bg={selectedConfiguration === "lte" ? "#00AEEF" : "#FFF"}
              />
              Before
            </RadioLabel>
            <RadioLabel>
              <RadioBtn
                type="radio"
                value="gte"
                checked={selectedConfiguration === "gte"}
                onChange={handleChangeConfiguration}
                borderColor={selectedConfiguration === "gte"
                ? "#00AEEF"
                : "#D4D4D4"}
                 bg={selectedConfiguration === "gte" ? "#00AEEF" : "#FFF"}
              />
              After
            </RadioLabel>
            <RadioLabel>
              <RadioBtn
                type="radio"
                value="gte and lte"
                checked={selectedConfiguration === "gte and lte"}
                onChange={ handleChangeConfiguration}
                borderColor={selectedConfiguration === "gte and lte"
                ? "#00AEEF"
                : "#D4D4D4"}
                 bg={selectedConfiguration === "gte and lte" ? "#00AEEF" : "#FFF"}
              />
              Between
            </RadioLabel>
            {selectedConfiguration === "gte and lte" && (
              <div
                style={{
                  marginLeft: "23px",
                  display: "flex",
                  flexDirection: "column",
                  gap:'6px'
                }}
              >
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="custom"
                    checked={selectedSubOption2 === "custom"}
                    onChange={() => handleSubOptionChange2("custom")}
                    borderColor={selectedSubOption2 === "custom"
                    ? "#00AEEF"
                    : "#D4D4D4"}
                     bg={selectedSubOption2 === "custom" ? "#00AEEF" : "#FFF"}
                  />
                  Custom Range
                </RadioLabel>
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="last7days"
                    checked={selectedSubOption2 === "last7days"}
                    onChange={() => {
                      handleSubOptionChange2("last7days");
                      let obj = RecoOptions.find(
                        (itm) => itm.value === "last7days"
                      );
                      setInitialRangeStartDate(obj.start);
                      setInitialRangeEndDate(obj.end);
                      setInitialRangeDatesFilter({
                        startDate: obj.start ? moment(obj.start).format('YYYY-MM-DD') : '',
                        endDate: obj.end ? moment(obj.end).format('YYYY-MM-DD') : '',
                      });
                      setPageNumber(1)
                      const existingParams = new URLSearchParams(location.search);

                      existingParams.set('rangeStartDateStartGte',obj.start ? moment(obj.start).format('YYYY-MM-DD') : '' );
                      existingParams.set('rangeStartDateEndLte',  obj.end ? moment(obj.end).format('YYYY-MM-DD') : '');
                  
                  
                      const newUrl = `${location.pathname}?${existingParams.toString()}`;
                      history.push(newUrl);
                     
                    }}
                    borderColor={selectedSubOption2 === "last7days"
                    ? "#00AEEF"
                    : "#D4D4D4"}
                     bg={selectedSubOption2 === "last7days" ? "#00AEEF" : "#FFF"}
                  />
                  Last 7 Days
                </RadioLabel>
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="last30days"
                    checked={selectedSubOption2 === "last30days"}
                    onChange={() => {
                      handleSubOptionChange2("last30days");
                      let obj = RecoOptions.find(
                        (itm) => itm.value === "last30days"
                      );
                      setInitialRangeStartDate(obj.start);
                      setInitialRangeEndDate(obj.end);
                      setInitialRangeDatesFilter({
                        startDate: obj.start ? moment(obj.start).format('YYYY-MM-DD') : '',
                        endDate: obj.end ? moment(obj.end).format('YYYY-MM-DD') : '',
                      });
                      setPageNumber(1)
                      const existingParams = new URLSearchParams(location.search);

                      existingParams.set('rangeStartDateStartGte',obj.start ? moment(obj.start).format('YYYY-MM-DD') : '' );
                      existingParams.set('rangeStartDateEndLte',  obj.end ? moment(obj.end).format('YYYY-MM-DD') : '');
                  
                  
                  
                      const newUrl = `${location.pathname}?${existingParams.toString()}`;
                      history.push(newUrl);
                     
                    }}
                    borderColor={selectedSubOption2 === "last30days"
                    ? "#00AEEF"
                    : "#D4D4D4"}
                     bg={selectedSubOption2 === "last30days" ? "#00AEEF" : "#FFF"}
                  />
                  Last 30 Days
                </RadioLabel>
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="last6months"
                    checked={selectedSubOption2 === "last6months"}
                    onChange={() => {
                      handleSubOptionChange2("last6months");
                      let obj = RecoOptions.find(
                        (itm) => itm.value === "last6months"
                      );
                      setInitialRangeStartDate(obj.start);
                      setInitialRangeEndDate(obj.end);
                      setInitialRangeDatesFilter({
                        startDate: obj.start ? moment(obj.start).format('YYYY-MM-DD') : '',
                        endDate: obj.end ? moment(obj.end).format('YYYY-MM-DD') : '',
                      });
                      setPageNumber(1)
                      const existingParams = new URLSearchParams(location.search);

                      existingParams.set('rangeStartDateStartGte',obj.start ? moment(obj.start).format('YYYY-MM-DD') : '' );
                      existingParams.set('rangeStartDateEndLte',  obj.end ? moment(obj.end).format('YYYY-MM-DD') : '');
                  
                  
                  
                      const newUrl = `${location.pathname}?${existingParams.toString()}`;
                      history.push(newUrl);
                     
                    }}
                    borderColor={selectedSubOption2 === "last6months"
                    ? "#00AEEF"
                    : "#D4D4D4"}
                     bg={selectedSubOption2 === "last6months" ? "#00AEEF" : "#FFF"}
                  />
                  Last 6 Months
                </RadioLabel>
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="last12months"
                    checked={selectedSubOption2 === "last12months"}
                    onChange={() => {
                      handleSubOptionChange2("last12months");

                      let obj = RecoOptions.find(
                        (itm) => itm.value === "last12months"
                      );
                      setInitialRangeStartDate(obj.start);
                      setInitialRangeEndDate(obj.end);
                      setInitialRangeDatesFilter({
                        startDate: obj.start ? moment(obj.start).format('YYYY-MM-DD') : '',
                        endDate: obj.end ? moment(obj.end).format('YYYY-MM-DD') : '',
                      });
                      setPageNumber(1)
                      const existingParams = new URLSearchParams(location.search);

                  
                      existingParams.set('rangeStartDateStartGte',obj.start ? moment(obj.start).format('YYYY-MM-DD') : '' );
                      existingParams.set('rangeStartDateEndLte',  obj.end ? moment(obj.end).format('YYYY-MM-DD') : '');
                  
                  
                  
                      const newUrl = `${location.pathname}?${existingParams.toString()}`;
                      history.push(newUrl);
                   
                    }}
                    borderColor={selectedSubOption2 === "last12months"
                    ? "#00AEEF"
                    : "#D4D4D4"}
                     bg={selectedSubOption2 === "last12months" ? "#00AEEF" : "#FFF"}
                  />
                  Last 12 Months
                </RadioLabel>
              </div>
            )}
          </div>
        )}
        <RadioLabel>
          <RadioBtn
            type="radio"
            value="range end"
            borderColor={selectedOption === "range end"
            ? "#00AEEF"
            : "#D4D4D4"}
             bg={selectedOption === "range end" ? "#00AEEF" : "#FFF"}
            checked={selectedOption === "range end"}
            onChange={(e) => {
              handleOptionChange(e);
              setSelectedConfiguration(null);
              setSelectedSubOption2(null);
            }}
          />
          Max Due Date
        </RadioLabel>
        {selectedOption === "range end" && (
          <div
            style={{
              marginLeft: "34px",
              display: "flex",
              flexDirection: "column",
              gap:'6px'
            }}
          >
            <RadioLabel>
              <RadioBtn
                type="radio"
                value="lte"
                checked={selectedConfiguration === "lte"}
                onChange={handleChangeConfiguration}
                borderColor={selectedConfiguration === "lte"
                ? "#00AEEF"
                : "#D4D4D4"}
                 bg={selectedConfiguration === "lte" ? "#00AEEF" : "#FFF"}
              />
              Before
            </RadioLabel>
            <RadioLabel>
              <RadioBtn
                type="radio"
                value="gte"
                checked={selectedConfiguration === "gte"}
                onChange={ handleChangeConfiguration}
                borderColor={selectedConfiguration === "gte"
                ? "#00AEEF"
                : "#D4D4D4"}
                 bg={selectedConfiguration === "gte" ? "#00AEEF" : "#FFF"}
              />
              After
            </RadioLabel>
            <RadioLabel>
              <RadioBtn
                type="radio"
                value="gte and lte"
                checked={selectedConfiguration === "gte and lte"}
                onChange={handleChangeConfiguration}
                borderColor={selectedConfiguration === "gte and lte"
                ? "#00AEEF"
                : "#D4D4D4"}
                 bg={selectedConfiguration === "gte and lte" ? "#00AEEF" : "#FFF"}
              />
              Between
            </RadioLabel>

            {selectedConfiguration === "gte and lte" && (
              <div
                style={{
                  marginLeft: "23px",
                  display: "flex",
                  flexDirection: "column",
                  gap:'6px'
                }}
              >
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="custom"
                    checked={selectedSubOption2 === "custom"}
                    onChange={() => {handleSubOptionChange2("custom")
                  }}
                  borderColor={selectedSubOption2 === "custom"
                  ? "#00AEEF"
                  : "#D4D4D4"}
                   bg={selectedSubOption2 === "custom" ? "#00AEEF" : "#FFF"}
                  />
                  Custom Range
                </RadioLabel>
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="last7days"
                    checked={selectedSubOption2 === "last7days"}
                    onChange={() =>{ handleSubOptionChange2("last7days")
                    let obj = RecoOptions.find(
                      (itm) => itm.value === "last7days"
                    );
                    setInitialRangeStartDate(obj.start);
                    setInitialRangeEndDate(obj.end);
                    setInitialRangeDatesFilter({
                      startDate: obj.start ? moment(obj.start).format('YYYY-MM-DD') : '',
                      endDate: obj.end ? moment(obj.end).format('YYYY-MM-DD') : '',
                    });
                    setPageNumber(1)
                    const existingParams = new URLSearchParams(location.search);

                  
                    existingParams.set('rangeEndDateStartGte',obj.start ? moment(obj.start).format('YYYY-MM-DD') : '' );
                    existingParams.set('rangeEndDateEndLte',  obj.end ? moment(obj.end).format('YYYY-MM-DD') : '');
                
                
                    const newUrl = `${location.pathname}?${existingParams.toString()}`;
                    history.push(newUrl);
                 
                    }}
                    borderColor={selectedSubOption2 === "last7days"
                    ? "#00AEEF"
                    : "#D4D4D4"}
                     bg={selectedSubOption2 === "last7days" ? "#00AEEF" : "#FFF"}
                  />
                  Last 7 Days
                </RadioLabel>
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="last30days"
                    checked={selectedSubOption2 === "last30days"}
                    onChange={() =>{ handleSubOptionChange2("last30days")
                    let obj = RecoOptions.find(
                      (itm) => itm.value === "last30days"
                    );
                    setInitialRangeStartDate(obj.start);
                    setInitialRangeEndDate(obj.end);
                    setInitialRangeDatesFilter({
                      startDate: obj.start ? moment(obj.start).format('YYYY-MM-DD') : '',
                      endDate: obj.end ? moment(obj.end).format('YYYY-MM-DD') : '',
                    });
                    setPageNumber(1)
                    const existingParams = new URLSearchParams(location.search);

                    
                    existingParams.set('rangeEndDateStartGte',obj.start ? moment(obj.start).format('YYYY-MM-DD') : '' );
                    existingParams.set('rangeEndDateEndLte',  obj.end ? moment(obj.end).format('YYYY-MM-DD') : '');
                
                
                    const newUrl = `${location.pathname}?${existingParams.toString()}`;
                    history.push(newUrl);
                  }
                  }
                  borderColor={selectedSubOption2 === "last30days"
                  ? "#00AEEF"
                  : "#D4D4D4"}
                   bg={selectedSubOption2 === "last30days" ? "#00AEEF" : "#FFF"}
                  />
                  Last 30 Days
                </RadioLabel>
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="last6months"
                    checked={selectedSubOption2 === "last6months"}
                    onChange={() =>{ handleSubOptionChange2("last6months")
                    let obj = RecoOptions.find(
                      (itm) => itm.value === "last6months"
                    );
                    setInitialRangeStartDate(obj.start);
                    setInitialRangeEndDate(obj.end);
                    setInitialRangeDatesFilter({
                      startDate: obj.start ? moment(obj.start).format('YYYY-MM-DD') : '',
                      endDate: obj.end ? moment(obj.end).format('YYYY-MM-DD') : '',
                    });
                    setPageNumber(1)
                    const existingParams = new URLSearchParams(location.search);

                  
                     
                    existingParams.set('rangeEndDateStartGte',obj.start ? moment(obj.start).format('YYYY-MM-DD') : '' );
                    existingParams.set('rangeEndDateEndLte',  obj.end ? moment(obj.end).format('YYYY-MM-DD') : '');
                
                
                    const newUrl = `${location.pathname}?${existingParams.toString()}`;
                    history.push(newUrl);
                 
                  }}
                  borderColor={selectedSubOption2 === "last6months"
                  ? "#00AEEF"
                  : "#D4D4D4"}
                   bg={selectedSubOption2 === "last6months" ? "#00AEEF" : "#FFF"}
                  />
                  Last 6 Months
                </RadioLabel>
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="last12months"
                    checked={selectedSubOption2 === "last12months"}
                    onChange={() => {
                      handleSubOptionChange2("last12months");
                      let obj = RecoOptions.find(
                        (itm) => itm.value === "last12months"
                      );
                      setInitialRangeStartDate(obj.start);
                      setInitialRangeEndDate(obj.end);
                      setInitialRangeDatesFilter({
                        startDate: obj.start ? moment(obj.start).format('YYYY-MM-DD') : '',
                        endDate: obj.end ? moment(obj.end).format('YYYY-MM-DD') : '',
                      });
                      setPageNumber(1)
                      const existingParams = new URLSearchParams(location.search);

                     
                      existingParams.set('rangeEndDateStartGte',obj.start ? moment(obj.start).format('YYYY-MM-DD') : '' );
                      existingParams.set('rangeEndDateEndLte',  obj.end ? moment(obj.end).format('YYYY-MM-DD') : '');
                  
                  
                  
                      const newUrl = `${location.pathname}?${existingParams.toString()}`;
                      history.push(newUrl);
                   
                  }}
                  borderColor={selectedSubOption2 === "last12months"
                  ? "#00AEEF"
                  : "#D4D4D4"}
                   bg={selectedSubOption2 === "last12months" ? "#00AEEF" : "#FFF"}
                  />
                  Last 12 Months
                </RadioLabel>
              </div>
            )}
          </div>
        )}
      </RadioContainer>
      {!initialRangeStartDate &&
      (selectedConfiguration === "gte" || selectedConfiguration === "lte") ? (
        <div  style={{position:'absolute', top:'70%'}}>
          <DatePicker
            selected={initialRangeStartDate}
            onChange={onChange}
            inline
          />
        </div>
      ) : null}
      {selectedConfiguration === "gte and lte" && selectedSubOption2 === "custom" && (
        <div style={{position:'absolute', top:'70%'}}>
          <DatePicker
            selected={initialRangeStartDate}
            onChange={onInitialRangeDatesChange}
            startDate={initialRangeStartDate}
            endDate={initialRangeEndDate}
            selectsRange
            inline
            shouldCloseOnSelect
          />
        </div>
      )}
    </div>
    </ClickAwayListener>
    )}
            </div>
            </div>
         </ColumnContainer>

          </Col>
          <Col style={{ paddingRight: "0px" }} lg={2} md={12} sm={12}>
            <SearchInputContainer>
              <SearchInput
                placeholder="Search by Patient Phone Number, Name, MRN, or Accession Number"
                handleSubmit={handleSubmit}
                handleResetForm={handleResetForm}
                style={{
                  width: "420px",
                  display: "flex",
                  alignItems: "center",
                }}
                searchIconStyle={{ marginLeft: "0px", marginTop: "0px" }}
                inputStyle={{ height: "38px" }}
                customPlaceholderStyle={{
                  marginLeft: "35px",
                  marginTop: "0px",
                  fontSize:'12px'
                }}
                searchValue={searchValue} 
                setSearchValue={setSearchValue}
                clearButtonStyle={{
                  marginLeft:'0px'
                }}
              />
            </SearchInputContainer>
          </Col>
        </Row>
      </HeaderContainer>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginTop:'20px'
        }}
      >
        <div>
          <FiltersContainer>
            {Object.values(selectedFilters).map((item, index) => {
              if (!item || !Array.isArray(item)) return null;
               return item.map((i,idx) =>  (
                <SelectedItemContainer key={index + idx}>
                   <SelectedItem>
                                        {" "}
                                        {i.category
                                          .replace(/_/g, " ")
                                          .replace(/\b\w/g, function (char) {
                                            return char.toUpperCase();
                                          })}{" "}
                                        :{" "}
                                        {((i.category === "modality" &&
                                          modalityOperator.value === "isNotEqual") ||
                                          (i.category === "anatomy" &&
                                            anatomyOperator.value === "isNotEqual") ||
                                          (i.category === "report_modality" &&
                                            reportModalityOperator.value ===
                                              "isNotEqual") ||
                                          (i.category === "exam_code" &&
                                            examCodeOperator.value === "isNotEqual")) &&
                                          "Is Not"}{" "}
                                        {i.category === "cohort" || i.category === "referrers" ||

                                            i.category === "radiologist"
                                          ? i.label
                                          : i.value.replace(/_/g, ' ').replace(
                                              /\b\w/g,
                                              function (char) {
                                                return char.toUpperCase();
                                              }
                                            )}
                                      </SelectedItem>
                  <CloseSign onClick={() => closeSelected(i.category,i)}>
                    <Image src={close} alt={"close sign"} />
                  </CloseSign>
                </SelectedItemContainer>
              ) ) 
            
            })}
            {showSaveFilter &&
              Object.values(selectedFilters).filter(Boolean).length > 0 && (
                <span
                  style={{
                    color: "#00AEEF",
                    fontSize: "14px",
                    cursor: "pointer",
                    marginLeft: "10px",
                    fontWeight: 600,
                  }}
                  onClick={() => {
                    window.analytics.track('Follow-Up-List-Save-Filter-Set-Button-Click');
                    setOpened(true)
                  }}
                >
                  Save Filter Set
                </span>
              )}
              {showAddCohort &&
              Object.values(filteredObject).filter(Boolean).length > 0 && (
                <span
                  style={{
                    color: "#00AEEF",
                    fontSize: "14px",
                    cursor: "pointer",
                    marginLeft: "10px",
                    fontWeight: 600,
                  }}
                  onClick={() => {
                    window.analytics.track('Follow-Up-List-Add-To-Cohort-Button-Click');
                    setCohortOpened(true)
                  }}
                >
                  Add to Cohort
                </span>
              )}
          </FiltersContainer>
        </div>
        <div
          style={{ display: "flex", gap: "6px", justifyContent: "flex-end" }}
        >
         <ExportContainer>  
         <ExportButton  style={{
                background: "#00AEEFs",
                color:'white',
                border:"2px solid #00AEEF",
                display:'flex',
                alignItems:'center',
                gap:'10px',
                justifyContent:'center'
              }}
               type={"button"} onClick={handleClickBulk}>
                Bulk Actions {open? <DropdownUp/> : <DropdownDown/>}
              </ExportButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseBulk}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 'center',
        }}
      >
        <div style={{display:'flex', flexDirection:'column', gap:'10px', padding:'10px'}}>
      
             <ExportButton type={"button"} bg={selectedIds.length ===0? "#AEE3F8" : "#00AEEF"} disabled={selectedIds.length ===0} onClick={()=>{
               setOpenLetter(true)
             }}>
                Print ({selectedIds.length})
             </ExportButton>
       
            
             <ExportButton type={"button"} bg={selectedIds.length ===0? "#AEE3F8" : "#00AEEF"} disabled={selectedIds.length ===0}
              onClick={(e)=>{
                if((permission && updatePermission) || !permission){
                  handleClickStatusBulk(e)
                 }else{
                   dispatch(
                     showAlert('danger', 'Error', `You don't have permission to change reco status`)
                   );
                 }
              }}
             >
                Change Status ({selectedIds.length})
             </ExportButton>
         
             <ExportButton type={"button"} bg={selectedIds.length ===0? "#AEE3F8" : "#00AEEF"} disabled={selectedIds.length ===0} onClick={()=>{
                if((permission && deletePermission) || !permission){
                  dialogHandler()
                }else{
                   dispatch(
                     showAlert('danger', 'Error', `You don't have permission to delete reco`)
                   );
                 }
             }}>
                Remove ({selectedIds.length})
             </ExportButton>
             <ExportButton type={"button"} bg={selectedIds.length ===0? "#AEE3F8" : "#00AEEF"} disabled={selectedIds.length ===0} onClick={handleSelectedRecoExport}>
               {bulkActionClicked? <Spinner animation='border' role="status" size="sm" /> : `Export (${selectedIds.length})` } 
             </ExportButton>
             {
               getCountByStatusWithStatusFormat(
                activeStatus,
                totalStatuses
              ) > 0 &&   <ExportButton type={"button"} bg={getCountByStatusWithStatusFormat(
                activeStatus,
                totalStatuses
              ) ===0? "#AEE3F8" : "#00AEEF"} disabled={ 
                 getCountByStatusWithStatusFormat(
                activeStatus,
                totalStatuses
              ) ===0
              }  onClick={handleTabRecoReportExport}>
                {recoTabExportClicked? <Spinner animation='border' role="status" size="sm" /> : `Export ${activeStatus} (${getCountByStatusWithStatusFormat(
                activeStatus,
                totalStatuses
              )})` } 
                 </ExportButton>
    
             }
           

             <EnableFeature featureName={"view-export-followup-list"}>
            <ExportContainer style={{width:'100%'}}>
              <ExportButton  style={{
                background: "#00AEEF",
                color:'white',
                border:"2px solid #00AEEF"
              }}
               type={"button"} onClick={onNeedDates}>
                Export All

              </ExportButton>
              {
                showModal &&  <ExportModal
                showModal={showModal}
                closeModal={() => setShowModal(false)}
                csvData={csv}
                onNeedExport={onNeedExport}
              />
              }
              
            </ExportContainer>
          </EnableFeature>
            {
              open &&<Popover
              id={popupIdStausBulk}
              open={openStatusBulk}
              anchorEl={anchorElStatusBulk}
              onClose={handleCloseStatusBulk}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  borderRadius: '10px', 
                  border: '2px solid #DFE0EB',
                  width:'244px',
                  background: '#F7F8FF',
                  boxShadow:'none',
                  marginTop:'10px'
                },
              }}
            >
               <StatusesContainer ref={node}>
                      <ActionStatusList
                        selectedStatus={selectedStatus}
                        handleSelectStatus={(data) => {
                          setSelectedStatus(data.status);
                           dispatch(changeStatusBulkReco({
                           "action": "update",
                           "status_override": data.status,
                           "reco_ids":selectedIds
                           })).then(res=>{
                          if(res){
                           setAllSelected(false);
                           setSelectedIds([])
                           const selectedFilters = getSelectedFilters()
                           if(pageNumber === 1){
                            let statusName;
                            statusName = resolveStatusNameForReq(activeStatus);        
                            const searchVal = searchValue || null;
                            dispatch(
                            getFollowUpListAction(
                              pageNumber,
                              statusName,
                              searchVal,
                              { ...selectedFilters, modalityOperator, anatomyOperator,sort:activeSortColumn, reportModalityOperator, examCodeOperator },
                              initialExamDatesFilter,
                              date,
                              selectedConfiguration,
                              selectedOption,
                              initialRangeDatesFilter,  
                              )
                          );
                           
                          }else{
                            setPageNumber(1)
                          }
                           dispatch(
                           getTotalStatusesAction(
                           { ...selectedFilters, modalityOperator, anatomyOperator, reportModalityOperator, examCodeOperator },
                           searchValue,
                           initialExamDatesFilter,
                           date,
                           selectedConfiguration,
                           selectedOption,
                           initialRangeDatesFilter
                           )
                           );
                           handleCloseStatusBulk();
                           handleCloseBulk();
                            }
                          })
                        }
                      }
                        fromMessage
                      />
                    </StatusesContainer>      
            </Popover>
            }      
          </div>
          </Popover>
          </ExportContainer>
         

        
    
          <NotesContainer>
            <ExportButton
              style={{
                background: "#06739D",
                color: "white",
                borderRadius: "4px",
                border: "none",
                width: "114px",
              }}
              type={"button"}
              onClick={() => {
                if((permission && exportPermission) || !permission){
                window.analytics.track('Follow-Up-List-Export-Notes-Button-Click');
                setOpenModal(true)
               }else{
                 dispatch(
                   showAlert('danger', 'Error', `You don't have permission to export notes`)
                 );
               }
              
              }}
            >
              Export Notes
            </ExportButton>
          </NotesContainer>
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          marginTop:40,
          left:'30px',
          right:"30px"
        }}
      >
        <TabsContainer>
          <TabComponent
            name={"Non-Mature"}
            color={"#DFE0EB"}
            count={getCountByStatus("non_mature", totalStatuses)}
            cb={onSetActiveStatus}
          />
          <TabComponent
            name={"Coming Due"}
            color={"#FFD57C"}
            count={getCountByStatus("coming_due", totalStatuses)}
            cb={onSetActiveStatus}
          />
          <TabComponent
            name={"Overdue"}
            color={"#F73B3B"}
            count={getCountByStatus("overdue", totalStatuses)}
            cb={onSetActiveStatus}
          />
          {screenWidth > "834" && (
            <TabComponent
              name={"Non-Compliant"}
              color={"#6F42C1"}
              count={getCountByStatus("expired", totalStatuses)}
              cb={onSetActiveStatus}
            />
          )}
          {screenWidth > "1000" && (
            <TabComponent
              name={"Adherent"}
              color={"#64C67C"}
              count={getCountByStatus("adherent", totalStatuses)}
              cb={onSetActiveStatus}
            />
          )}
          {screenWidth > "1226" && (
            <TabComponent
              name={"Manually Reviewed"}
              color={"#00AEEF"}
              count={getCountByStatus(null, totalStatuses)}
              cb={onSetActiveStatus}
            />
          )}

          {screenWidth > "1560" && (
            <TabComponent
              name={"Scheduled/Ordered"}
              color={"#00AEEF"}
              count={getCountByStatus("Scheduled/Ordered", totalStatuses)}
              cb={onSetActiveStatus}
            />
          )}
          {screenWidth > "1645" && (
            <TabComponent
              name={"Completed Before/After Timeframe"}
              color={"#00AEEF"}
              count={getCountByStatus(
                "completed_before_and_after_timeframe",
                totalStatuses
              )}
              cb={onSetActiveStatus}
            />
          )}
          {
            <>
              {screenWidth <= "1645" && (
                <>
                  <ChangeButton onClick={handleMoreTabs}>
                    <ChangeButtonTitle>{"More Tabs"}</ChangeButtonTitle>
                    <ArrowContainer>
                      {screenWidth < "768" ? <MenuIcon /> : <Arrow />}
                    </ArrowContainer>
                  </ChangeButton>
                  <ActionList onClick={handleMoreTabs}>
                    {showMoreTabs && (
                      <>
                        {screenWidth <= "834" && (
                          <TabComponent
                            name={"Non-Compliant"}
                            color={"#6F42C1"}
                            count={getCountByStatus("expired", totalStatuses)}
                            cb={onSetActiveStatus}
                          />
                        )}
                        {screenWidth <= "1000" && (
                          <TabComponent
                            name={"Adherent"}
                            color={"#64C67C"}
                            count={getCountByStatus("adherent", totalStatuses)}
                            cb={onSetActiveStatus}
                          />
                        )}
                        {screenWidth <= "1226" && (
                          <TabComponent
                            name={"Manually Reviewed"}
                            color={"#00AEEF"}
                            count={getCountByStatus(null, totalStatuses)}
                            cb={onSetActiveStatus}
                          />
                        )}
                        {screenWidth <= "1560" && (
                          <TabComponent
                            name={"Scheduled/Ordered"}
                            color={"#00AEEF"}
                            count={getCountByStatus(
                              "Scheduled/Ordered",
                              totalStatuses
                            )}
                            cb={onSetActiveStatus}
                          />
                        )}
                        {screenWidth <= "1645" && (
                          <TabComponent
                            name={"Completed Before/After Timeframe"}
                            color={"#00AEEF"}
                            count={getCountByStatus(
                              "completed_before_and_after_timeframe",
                              totalStatuses
                            )}
                            cb={onSetActiveStatus}
                          />
                        )}
                      </>
                    )}
                  </ActionList>
                </>
              )}
            </>
          }
        </TabsContainer>
        <Card  
         style={{ 
         overflowX:'auto', 
         overflowY:'hidden',
         borderTopLeftRadius:'0px',
         borderTopRightRadius:'0px', 
         marginTop:0,
        
         }} ref={tableRefVal}>
       
          <TableInfiniteScroll
           showCustomColor={true}
           showCustomLabel={true}
           customColorKey="source"
           customColorValue={{
            user:`azure`,
            nlp:'#FFFFFF'
           }
           }
           customLabelKey="source"
           customLabelValue={{
            user:'user-created',
            nlp:'nlp-created'
           }
           }
           customLabelColorValue={{
            user:"#0E5492",
            nlp:'#4F4F4F'
           }
           }
           customLabelBackgroundColorValue={{
            user:"#E1F1FF",
            nlp:'#F6F6F6'
           }
           }
           showHorizontalScrollChevrons={showHorizontalScrollChevrons}
           handleScrollLeft={handleScrollLeft}
           handleScrollRight={handleScrollRight}
           isAtStart={isAtStart}
           isAtEnd={isAtEnd}
           hasMore={
            followUpList?.length >0 &&  getCountByStatusWithStatusFormat(
            activeStatus,
            totalStatuses
          ) !== followUpList?.length
             }
            onLoadMore={onLoadMore}
            dataLength={followUpList?.length ?? 0}
            ref={tableRef}
            cb={handleTimelineClick}
            rows={followUpList && followUpList}
            setActiveSortColumn={setActiveSortColumn}
            activeSortColumn={activeSortColumn}
            allSelected={allSelected}
            selectedIds={selectedIds}
            selectAllCb={(rows,e) => {
              e.stopPropagation();
              checkboxHandlerAll(rows)
            }}
            columns={[ {
              key: "",
              name: "",
              displayCell: (cellValue, rowData) => (
                <div style={{display:'flex', gap:'20px', marginTop:'20px'}}>
                <DoubleDataCell
                  mainInfo={"checkbox"}
                  checkboxHandler={(e) => {
                    e.stopPropagation();
                    checkboxHandler(rowData)
                  }}
                  selectedIds={selectedIds}
                  checked={selectedIds?.includes(rowData?.id)}
                />
                <DoubleDataCell
                customGap={true}
                customGapValue='20px'
                mainInfo={"edit"}
                EditRecoHandler={(e) =>{
                e.stopPropagation()
                EditRecoHandler(rowData)
                } 
                }
                RemoveRecoHandler={(e) =>{
                e.stopPropagation();
                if((permission && deletePermission) || !permission){
                RemoveRecoHandler(rowData)
                }else{
                 dispatch(
                   showAlert('danger', 'Error', `You don't have permission to delete reco`)
                 );
               }      
             } 
                }
                />
               <EnableFeature featureName={"enable-outgoing-messaging"}>
               <IconContainerBox color="#A4A4A4">
               <span className="label" >
                MESSAGE
               </span>
               <MessageIcon
               className='dark'
               onClick={(e) =>{
                 e.stopPropagation()
                 openMessageHandler(true, rowData?.id)
               } 
             }
             style={{ color: "#00AEEF", cursor:'pointer' }}
             >
               <PatientDetailsModal
                 patientDetails={
                   followUpList &&
                   patientId &&
                   followUpList.find(({ id }) => id === patientId)
                 }
                 showDetails={showPatientDetails}
                 closeDetails={() => setShowPatientDetails(false)}
               />
             
             </MessageIcon>

             <MessageIconHover
             className='light'
               onClick={(e) =>{
                 e.stopPropagation()
                 openMessageHandler(true, rowData?.id)
               } 
             }
             style={{ color: "#00AEEF", cursor:'pointer' }}
             >
               <PatientDetailsModal
                 patientDetails={
                   followUpList &&
                   patientId &&
                   followUpList.find(({ id }) => id === patientId)
                 }
                 showDetails={showPatientDetails}
                 closeDetails={() => setShowPatientDetails(false)}
               />
             
             </MessageIconHover>

             </IconContainerBox>          
           </EnableFeature>

           <EnableFeature featureName={"enable-fax"}>   
           <IconContainerBox color="#06739D">
             <span className="label" >
             FAX
             </span>
                 <FaxIcon 
                 className='dark'
                 onClick={(e) =>{
                   e.stopPropagation()
                   openFaxHandler(true, rowData?.id)
                  }} 
                 style={{ color: "#00AEEF", cursor:'pointer' }}
                 />
                 <FaxIconHover
                 className="light" 
                  onClick={(e) =>{
                   e.stopPropagation()
                   openFaxHandler(true, rowData?.id)
                  }} 
                   style={{ color: "#00AEEF", cursor:'pointer' }}
                 />

             </IconContainerBox> 
              
           </EnableFeature>

           <IconContainerBox color="#643268" >
             <span className="label" >
             PRINT
             </span>
             <LetterIcon
                  className='dark'
                  onClick={(e) =>{
                   e.stopPropagation()
                   openLetterHandler(true, rowData?.id)
                  }} 
                   style={{  cursor:'pointer' }}
                 />
                 <LetterIconHover
                  onClick={(e) =>{
                   e.stopPropagation()
                   openLetterHandler(true, rowData?.id)
                  }} 
                  className="light"
                   style={{  cursor:'pointer' }}
                 />
           </IconContainerBox>   

           </div>
              
              ),
              enableCheckBox:true,
              align:'left'
            }
          ].concat(
              columsData,
              {
                key: "columnFilter",
                displayName: () => (
                  <ColumnFilterDropdown
                    columnCheckbox={columnCheckbox}
                    columnData={columnData}
                    isOpen={columnFilterDropdown}
                    handleColumnChange={handleColumnChange}
                    handleColumnFilterDropdown={setColumnFilterDropdown}
                    setColsOrder={setColsOrder}
                  />
                ),
              },
              {
                key: "",
                name: "",
                displayCell: (cellValue, row) => (
                <StyledLinkArrow onClick={(e)=>handleTimelineClick(e,row)}>
                    <StyledChevron chevronFillColor={"#333333"} showdropdown={showDropdown && row.id ===activeRow.id ? 1 : undefined}/>
                </StyledLinkArrow> 
                ),
              }
            )}
            initialPageNumber={null}
            totalItems={null}
            isLoadingRows={pageNumber === 1 && isLoadingFollowUpList}
            shouldGetRowActions={false}
            noDataRender={() => (
              <EmptyState title={"No Results"} img={() => <EmptyStateImg />}  height={"50vh"}  imageContainerStyles={{width:'73px', height:'81px',}}>
                Sorry, there are no results
              </EmptyState>
            )}

          />
        </Card>
      </div>
      {opened && (
        <SaveModal
          setSelectedValues={setSelectedValues}
          selectedFilters={selectedFilters}
          opened={opened}
          onClose={onClose}
          setShowSaveFilter={setShowSaveFilter}
          modalityOperator={modalityOperator}
          anatomyOperator={anatomyOperator}
          reportModalityOperator={reportModalityOperator}
          examCodeOperator={examCodeOperator}
          initialExamStartDate={initialExamStartDate}
          initialExamEndDate={initialExamEndDate}
          initialExamDatesFilter={initialExamDatesFilter}
          initialRangeStartDate={initialRangeStartDate}
          initialRangeEndDate={initialRangeEndDate}
          initialRangeDatesFilter={initialRangeDatesFilter}
          date={date}
          selectedConfiguration={selectedConfiguration}
          selectedOption={selectedOption}
        />
      )}
       { cohortOpened && (
        <AddToCohort
          selectedFilters={selectedFilters}
          opened={cohortOpened}
          onClose={onCohortClose}
          modalityOperator={modalityOperator}
          anatomyOperator={anatomyOperator}
          reportModalityOperator={reportModalityOperator}
          examCodeOperator={examCodeOperator}
          setShowAddCohort={setShowAddCohort}
        />
      )}
      {editing.visible && (
        <EditModal
          selectedValues={editing.type}
          opened={editing.visible}
          onClose={() => setEditing({ visible: false, type: {} })}
        />
      )}
      {openModal && (
        <NotesExportModal opened={openModal} onClose={handleClose} />
      )}
             <RemoveConfirmation
                content={ selectedIds.length ===1?`Are you sure you want to remove this 1 recommendation?` : `Are you sure you want to remove these ${selectedIds.length} recommendations?`}
                openDialog={removeDialogOpen}
                dialogHandler={dialogHandler}
                isLoading={isLoadingDeleteBulk}
                deleteHandler={deleteBulkRecoHandler}
                heading={'Remove Recommendations'}
                removeHeader={'Yes, Remove Them'}
            />
 
       <Popover
        id={popoveridTimeline}
        open={openTimeline}
        anchorEl={anchorElTimeline}
        onClose={handleCloseTimeline}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            left:'30px!important',
            right:'30px!important',
          },
        }}
        >
           <Container style={{width: '100%'}}>
          <HeaderContainerTimeline>
            <Header>RECOMMENDATION TIMELINE DETAILS</Header>
            <CloseIcon
             style={{ cursor: "pointer" }}
             onClick={handleCloseTimeline}
            />
          </HeaderContainerTimeline>
          <CustomTimeline
            start={activeRow?.rangeStart}
            end={activeRow?.rangeEnd}
            status={activeRow?.status}
            accessionNumber={activeRow?.accessionNumber}
            initialExamDate={activeRow?.initialExamDate} 
          />
        </Container>
        </Popover>
    </Box>
  );
};

export default FollowUpList;

export const FollowUpListWithFeature = (props) => {
  return (
    <EnableFeature featureName={'enable-follow-up-list-view'} {...props}>
      <FollowUpList />
    </EnableFeature>
  );
};
