export const resetFollowUpListFiltersQuery = (params) => {
  // recommendation
  params.delete("modality");
  params.delete("modality!");
  params.delete("anatomy");
  params.delete("anatomy!");
  params.delete("radiologist");
  params.delete("exam_type");
  params.delete("guideline_type");
  params.delete("timeframe");
  params.delete("has_note");
  params.delete("task_type");
  params.delete("recommendation_type");
  params.delete("contrast");
  params.delete("source");

  // reports
  params.delete("report_modality");
  params.delete("report_modality!");
  params.delete("exam_code");
  params.delete("exam_code!");

  // demographics
  params.delete("patient_language");
  params.delete("age");

  //  messaging
  params.delete("cohort");
  params.delete("message_sent");

  // general
  params.delete("referrers");
  params.delete("location");
};


export const resetFollowUpListDateFiltersQuery = (params) =>{
    
    params.delete("rangeStartDateStartGte");
    params.delete("rangeStartDateEndLte");
    params.delete("rangeEndDateStartGte");
    params.delete("rangeEndDateEndLte");

    
    params.delete("rangeStartDateGte");
    params.delete("rangeStartDateLte");
    params.delete("rangeEndDateGte");
    params.delete("rangeEndDateLte");

    params.delete("startDate");
    params.delete("endDate");
}