import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { capitalize } from "lodash";
import { Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { isEmpty } from "lodash";
import { Spinner } from "react-bootstrap";
import { Card, PageTitle } from "../../../styles";
import Box from "../../../components/_styles/Box";
import { TextField, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RightArrow, DownArrow } from "./Icons";
import { CalendarIcon } from "../../../components/Dialog/Icon";
import { ReactComponent as RemoveRecoIcon } from "../../../assets/FollowUpDeleteIcon.svg";
import { ReactComponent as RemoveRecoIconHover } from "../../../assets/delete-hover.svg";

import RemoveConfirmation from "./RemoveConfirmation";

import {
  DateContainer,
  DatePickerButton,
} from "../../../components/Dialog/EditFormCenter";
import {
  createCohort,
  updateCohort,
  getAttributesForDimension,
  getDimensionsToEdit,
  getDropdownValuesForCohort,
} from "../../../actions/radminUploads";
import Select, { components } from "react-select";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SecondSection = styled.div`
  display: flex;
  gap: 20px;
  position: relative;
`;
const Container = styled.div`
  width: 95%;
  margin: 0 auto;
`;
const Section = styled.div`
  display: flex;
  gap: 150px;
`;

const TitleContainer = styled.div`
  width: 21%;
`;
const DropDown = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border: 1px solid #C2CCD9;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin-top:${(props) => (props.marginTop ? "3px" : "0px")}
    background-color: ${(props) => props.bg}
`;
const MenuItemConainer = styled.div`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  height: auto;
  max-height: 10rem;
  overflow: scroll;
  margin-top: 5px;
  border-radius: 5px;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;
const SelectedValue = styled.div`
  margin: 0;
  color: #3f4857;
`;
const RemoveContainer = styled.div``;

const Remove = styled.div`
  cursor: pointer;
`;

const MenuItem = styled.div`
  text-align: left;
  margin: 2px 0px;
  padding-left: 3%;
  color: #8692a4;
  cursor: pointer;
  &:hover {
    background-color: #e6e9f1;
    color: #3f4857;
  }
`;
const DaysFrom = styled.div`
  height: 42px;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: #3f4857;
  margin-left: 5px;
`;
const RowContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  gap: 20px;
`;
const RemoveIconContainer = styled.div`
  display: flex;
  width: 15%;
  justify-content: space-between;
`;
const useStyles = makeStyles({
  label: {
    padding: "5px 0px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  saveButton: {
    color: "#FFFFFF",
    backgroundColor: "#00AEEF",
    textTransform: "none",
    border: "2px solid transparent",
    width: "200px",
    "&:hover": {
      backgroundColor: "transparent",
      border: "2px solid #00AEEF",
      color: "#00AEEF",
    },
  },
  additionalDimensionsButton: {
    color: "#00AEEF",
    textTransform: "none",
    width: "200px",
    background: "transparent",
    border: "2px solid #00AEEF",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  inputLabel: {
    marginTop: "35px",
    fontSize: "16px",
    textAlign: "left",
    color: "#3F4857",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  textField: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      fontSize: 14,
      padding: "13.5px 14px",
    },
  },
  title: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      fontSize: 14,
      padding: "13.5px 14px",
    },
  },
  datePicker: {
    width: "100%",
    "& .react-datepicker": {
      fontSize: "1rem !important",
      top: "58px",
    },
  },
  valuePicker: {
    width: "25%",
  },
});

const dimensionFirst = [
  "Patient",
  "Physician",
  "Report2",
  "Recommendation",
  "SMSMessage",
];
const operatorsArray = [
  "Contains",
  "Is equal to",
  "Is not equal to",
  "Is a timestamp after",
  "Is a timestamp before",
  "Is a timestamp greater than",
  "Is a timestamp less than",
];

const operatorsArrayDropdownOnly = [
  "Contains",
  "Does not contain",
  "Is equal to",
  "Is not equal to",
];

const operatorsArrayBooleanOnly = ["Is equal to"];

const operatorsArrayText = ["Contains", "Does not contain" , "Is equal to", "Is not equal to"];

const operatorsArrayDate = [
  "Is equal to",
  "Is not equal to",
  "Is a timestamp after",
  "Is a timestamp before",
  "Is a timestamp greater than",
  "Is a timestamp less than",
];

const messageOnlyOperator = ["Is equal to", "Is not equal to"];
const constants = ["Dimensions", "Operators", "Values"];

const booleanValues = ["true", "false"];

const needsToBeBooleanValues = [
  "phone_unsubscribed",
  "phone_closed",
  "follow_up_recommended",
  "message_sent",
  "contains_note",
];
const needsToBeDateInputValues = [
  "range_start",
  "range_end",
  "initial_exam_date",
];
const needsToBeDropdownValues = [
  "modality",
  "anatomy",
  "status",
  "timeframe_status_extracted",
  "sms_message_statuses",
  "guideline_type",
  "note_task_type",
  "patient_language",
  "exam_type",
  "recommendation_type",
  "contrast",
  "source"
];
const needsToBeFixedDropdownValues = [
  "age_group"
];

const groupCohortOptions = [
  { value: "and", label: "AND", category: "Operator" },
  { value: "or", label: "OR", category: "Operator" },
];

export const SelectDropdownTitle = styled.div`
  margin-bottom: 0.5rem;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-align: left;
  text-transform: uppercase;
`;

const SelectContainer = styled.div`
  width: 250px;
  //   margin-top: 35px;
  display: flex;
  flex-direction: column;
  div.select__value-container {
    height: 38px;
    cursor: pointer;
  }
`;

const SwitchContainer = styled.div`
  padding: 4px;
  display: flex;
  background: #e6e9f1;
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0c2945;
  width: fit-content;
`;

const SwitchButton = styled.button`
  padding: 7px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? "#0C2945" : "#E6E9F1")};
  color: ${(props) => (props.active ? "#F9FAFC" : "#0C2945")};
  font-weight: ${(props) => (props.active ? 700 : 600)};
  border: 0;
  outline: 0;
  border-radius: 6px;
`;


const IconContainer = styled.span`
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 30px; 
  height: 20px; 

  &:hover svg:not(.light) {
    display: none;
  }

  &:hover svg.light {
    display: block;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  svg.light {
    display: none;
  }
`;


const RadioOption = (props) => {
  return (
    <>
      <components.Option {...props}>
        <span style={{ marginRight: 5, paddingTop: 5 }}>
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
            style={{ marginTop: 5 }}
          />{" "}
        </span>
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};

const IndicatorsContainer = (props) => {
  return (
    <div style={{ backgroundColor: "#FFF" }}>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};

const DatePickerInput = React.forwardRef((props, ref) => {
  const { onClick, date, time } = props;
  const formatDate = (date, time) => {
    return `${moment(date).format("ll")}`;
  };
  return (
    <DatePickerButton
      onClick={onClick}
      style={{ padding: "8px 24px", height: "38px" }}
    >
      <DateContainer style={{ fontSize: 17 }}>
        {date ? `${formatDate(date, time)}` : "Pick any date"}
      </DateContainer>
      <CalendarIcon />
    </DatePickerButton>
  );
});

const isDropdownRequired = (attribute) => {
  if (
    needsToBeBooleanValues.includes(attribute) ||
    needsToBeDropdownValues.includes(attribute) || 
    needsToBeFixedDropdownValues.includes(attribute)
  )
    return true;
  return false;
};

const transformText = (string) =>
  capitalize(string.replace("2", "s").replace(/_/g, " ").replace(/SMS/g, ""));

export const DropDownField = ({
  selectedValue,
  isClicked,
  clickHandler,
  onChange,
  items,
  specified,
  setSpecified,
  id,
  isLoading,
  availableAttributes,
  index,
  selectedIndex,
  dropdownValues = constants,
  width = "25%",
}) => (
  <div style={{ width, position: "relative" }}>
    <DropDown
      bg={!dropdownValues.includes(selectedValue) ? "#E6E9F1" : "none"}
      onClick={() => clickHandler(index)}
    >
      <SelectedValue>{transformText(selectedValue)}</SelectedValue>
      <span>{isClicked ? <DownArrow /> : <RightArrow />}</span>
    </DropDown>
    {!isEmpty(specified) && (
      <DropDown marginTop bg="#E6E9F1">
        <SelectedValue>
          {capitalize(specified.replace(/_/g, " "))}
        </SelectedValue>
      </DropDown>
    )}
    <MenuItemConainer isOpen={isClicked}>
      {dropdownValues.includes(selectedValue) &&
        isClicked &&
        items.map((x) => (
          <MenuItem key={JSON.stringify(x)} onClick={() => onChange(x)}>
            {transformText(x)}
          </MenuItem>
        ))}
      {id === "1" &&
        isEmpty(specified) &&
        !dropdownValues.includes(selectedValue) &&
        availableAttributes &&
        availableAttributes.map((x, i) => (
          <MenuItem key={JSON.stringify(x)} onClick={() => setSpecified(x)}>
            {capitalize(x.attribute.replace(/_/g, " "))}
          </MenuItem>
        ))}
      {isEmpty(availableAttributes) && isLoading && index === selectedIndex && (
        <Spinner animation="border" role="status" size="sm" />
      )}
    </MenuItemConainer>
  </div>
);

function getDateDiff(date) {
  let today = new Date();

  let otherDate = new Date(date);
  today.setHours(0, 0, 0, 0);
  otherDate.setHours(0, 0, 0, 0);

  let differenceInMs = otherDate - today;

  const differenceInDays = Math.abs(Math.round(differenceInMs / (1000 * 60 * 60 * 24)));

  return differenceInDays;
}

function Create(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [firstFilterId, setFirstFilterId] = useState("");

  const isLoading = useSelector((state) => state.radAdmin.cohorts.isLoading);
  const availableAttributes = useSelector(
    (state) => state.radAdmin.cohorts.availableDimensions?.data
  );
  const isLoadingAttributes = useSelector(
    (state) => state.radAdmin.cohorts.availableDimensions.isLoading
  );
  const dimensionsToEdit = useSelector(
    (state) => state.radAdmin.cohorts.dimensionsToEdit?.data
  );
  const fixedValues = useSelector(
    (state) => state.radAdmin.cohorts.fixedValues.data
  );
  const recoConfigData = useSelector((state) => state.radAdmin.recoConfig.data);


  const [dimension, setDimension] = useState("Dimensions");
  const [attribute, setAttribute] = useState("");
  const [dimensionId, setDimensionId] = useState(null);
  const [operator, setOperator] = useState("Operators");
  const [value, setValue] = useState("Values");
  const [isClickedDimension, setIsClickedDimension] = useState(false);
  const [isClickedOperator, setIsClickedOperator] = useState(false);
  const [isClickedValuesDropdown, setIsClickedValuesDropdown] = useState(false);
  const [cohortName, setCohortName] = useState("");
  const [allowToAddAdditionalDimention, setAllowToAddAdditionalDimention] =
    useState(false);
  const [additionalFiltersArray, setAdditionalFiltersArray] = useState([]);
  const [checkingForUpdateInArray, setCheckingForUpdateInArray] =
    useState(false);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [isLoadingDeleteCohort, setIsLoadingDeleteCohort] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [cohortFilters, setCohortFilters] = useState(
    props.location.state?.data?.raw_filters
  );
  const [selectedOperator, setSelectedOperator] = useState(
    props.location.state?.data?.raw_filters?.condition === "or"
      ? { value: "or", label: "OR", category: "Operator" }
      : { value: "and", label: "AND", category: "Operator" }
  );
  const [removeIdx, setRemoveIdx] = useState();

  const [ageOptions, setAgeOptions] = useState([]);

  const generateOptions = (interval) => {
    const maxAge = 100;
    const newOptions = [];
    let start = 1;
  
    const numGroups = Math.floor(maxAge / interval);
    let end = 0;
  
    for (let i = 0; i < numGroups; i++) {
      end = start + interval - 1;
  
      newOptions.push({
        value: `${start}-${end}`,
        label: `${start}-${end}`,
        category: "age",
      });
  
      start = end + 1;
    }
  
    if (interval === 5 || interval === 10 || interval === 20 || interval === 25 || interval === 50) {
      newOptions.push({
        value: `101-150`,
        label: `101+`,
        category: "age",
      });
    } else if (interval === 15 || interval === 30) {
      newOptions.push({
        value: `91-150`,
        label: `91+`,
        category: "age",
      });
    } else if (interval === 35) {
      newOptions.push({
        value: `71-150`,
        label: `71+`,
        category: "age",
      });
    } else if (interval === 40) {
      newOptions.push({
        value: `81-150`,
        label: `81+`,
        category: "age",
      });
    } else if (interval === 45) {
      newOptions.push({
        value: `91-150`,
        label: `91+`,
        category: "age",
      });
    }

    setAgeOptions(newOptions);
  };
  
  
  useEffect(() => {
    generateOptions(recoConfigData?.patientAgeInterval ?? 5);
  }, [recoConfigData]);
  

  useEffect(() => {
    if (dimension !== "Dimensions" && dimension?.value) {
      dispatch(getAttributesForDimension(dimension?.value));
    }
  }, [dimension]);

  useEffect(() => {
    if (
      (dimension !== "Dimensions" &&
        attribute &&
        operator !== "Operators" &&
        value !== "Values") ||
      date !== ""
    ) {
      setAllowToAddAdditionalDimention(true);
    }
  }, [dimension, attribute, operator, value, date]);

  useEffect(() => {
    if (props.location.state?.data) {
      setIsEditClicked(true);
      const { data } = props.location.state;
      let filter = cohortFilters?.filters[0];
      setCohortName(data.cohort_title);

      if (filter.operator === "__gt") {
        setOperator({
          label: "Is a timestamp after",
          value: "Is a timestamp after",
        });
        setDate(new Date(filter.value));
      } else if (filter.operator === "__lt") {
        setOperator({
          label: "Is a timestamp before",
          value: "Is a timestamp before",
        });
        setDate(new Date(filter.value));
      } else if (filter.operator === "__icontains") {
        if(filter.exclude ===1){
          setOperator({ label: "Does not contain", value: "Does not contain" });
        }else{
        setOperator({ label: "Contains", value: "Contains" });
        }
        if(isDropdownRequired(filter.attribute)){
          setValue({label:filter.value, value:filter.value});
        }else{
        setValue(filter.value);
        }
      } else if (filter.operator === "__gte") {
        setOperator({
          label: "Is a timestamp greater than",
          value: "Is a timestamp greater than",
        });
       if(isDropdownRequired(filter.attribute)){
          setValue({label:filter.value, value:filter.value});
        }else{
        setValue(getDateDiff(filter.value));
        }
      } else if (filter.operator === "__lte") {
        setOperator({
          label: "Is a timestamp less than",
          value: "Is a timestamp less than",
        });
       if(isDropdownRequired(filter.attribute)){
          setValue({label:filter.value, value:filter.value});
        }else{
          setValue(getDateDiff(filter.value));
        }
      } else if (filter.exclude === 1) {
        setOperator({ label: "Is not equal to", value: "Is not equal to" });
       if(isDropdownRequired(filter.attribute)){
          setValue({label:filter.value, value:filter.value});
        }else{
        setValue(filter.value);
        }
      } else {
        setOperator({ label: "Is equal to", value: "Is equal to" });
       if(isDropdownRequired(filter.attribute)){
          setValue({label:filter.value, value:filter.value});
        }else{
        setValue(filter.value);
        }
      }
    }
  }, [dispatch, cohortFilters]);

  useEffect(() => {
    if (props.location.state?.data) {
      let filtersArray = cohortFilters?.filters;
      let data = filtersArray[0];
      let { model, attribute, id } = data;
      if( needsToBeDropdownValues.includes(attribute) && isDropdownRequired(attribute)){
        dispatch(
          getDropdownValuesForCohort(
            model,
            attribute
          )
        );
      }
  
      setDimension({ label: (model ==='Report2' ? 'Report' : model), value: model });
      setAttribute({ label: capitalize(attribute?.replace(/_/g, " ")), value: attribute });
      setFirstFilterId(id);
      if (!isEmpty(filtersArray)) {
        let arr = filtersArray.slice(1, filtersArray.length).map((item) => {
          let { model, attribute } = item;
          if( needsToBeDropdownValues.includes(attribute) && isDropdownRequired(attribute)){
            dispatch(
              getDropdownValuesForCohort(
                model,
                attribute
              )
            );
          }
          return {
            id: item.id,
            dimensionId: item.dimension,
            dimension: { label: (model ==='Report2' ? 'Report' : model), value: model },
            operator:
              item.operator === "__gt"
                ? {
                    label: "Is a timestamp after",
                    value: "Is a timestamp after",
                  }
                : item.operator === "__icontains" && item.exclude ===0
                ? {
                  label: "Contains",
                  value: "Contains",
                }:
                item.operator === "__icontains" && item.exclude ===1
                ? {
                  label: "Does not contain",
                  value: "Does not contain",
                }
                : item.operator === "__lt"
                ? {
                    label: "Is a timestamp before",
                    value: "Is a timestamp before",
                  }
                : item.operator === "__gte"
                ? {
                    label: "Is a timestamp greater than",
                    value: "Is a timestamp greater than",
                  }
                : item.operator === "__lte"
                ? {
                    label: "Is a timestamp less than",
                    value: "Is a timestamp less than",
                  }
                : item.operator === "" && item.exclude === 0
                ? { label: "Is equal to", value: "Is equal to" }
                : { label: "Is not equal to", value: "Is not equal to" },
            value:
              item.operator === "__gte"
                ? getDateDiff(item.value)
                : item.operator === "__lte"
                ? getDateDiff(item.value)
                : item.operator === "__gt" || item.operator === "__lt"
                ? new Date(item.value)
                : isDropdownRequired(attribute)
                ? { label: item.value, value: item.value }
                : item.value,
            exclude: item.exclude,
            attribute: {
              label: capitalize(attribute?.replace(/_/g, " ")),
              value: attribute,
            },
            isClicked: {
              dimension: false,
              attribute: false,
              operator: false,
              value: false,
            },
          };
        });
        setAdditionalFiltersArray(arr);
      }
    }
  }, [dimensionsToEdit, cohortFilters]);

  const cohortSaveHandler = (additionalFilters, fromDelete = false) => {
    if (isEmpty(cohortName)) {
      alert("Cohort Name is required!");
      return;
    } else if (dimension === "Dimensions" || !dimension?.value) {
      alert("Choose a dimension");
      return;
    } else if (operator === "Operators" || !operator?.value) {
      alert("Choose a Operator");
      return;
    } else if (
        !value && !date
    ) {
      alert("Choose a value");
      return;
    }
    let operatorVal = operator?.value === "Does not contain"?
       "__icontains"
     : operator?.value === "Is equal to"
        ? ""
        : operator?.value === "Contains"
        ? "__icontains"
        : operator?.value === "Is a timestamp after"
        ? "__gt"
        : operator?.value === "Is a timestamp before"
        ? "__lt"
        : operator?.value === "Is a timestamp greater than"
        ? "__gte"
        : operator?.value === "Is a timestamp less than"
        ? "__lte"
        : "";
    let arr = [];
    let isValuesMissing = false;

    if (!isEmpty(additionalFilters)) {
      arr = additionalFilters.map((item) => {
        let today = moment();
        if (
          !item?.dimension?.value ||
          !item?.operator?.value ||
          !item?.value
        ) {
          alert("Please select all values");
          isValuesMissing = true;
          return;
        }
        let op = item.operator?.value === 'Does not contain'? 
        "__icontains"
         : item.operator?.value === "Is equal to"
            ? ""
            : item.operator?.value === "Contains"
            ? "__icontains"
            : item.operator?.value === "Is a timestamp after"
            ? "__gt"
            : item.operator?.value === "Is a timestamp before"
            ? "__lt"
            : item.operator?.value === "Is a timestamp greater than"
            ? "__gte"
            : item.operator?.value === "Is a timestamp less than"
            ? "__lte"
            : "";
        return {
          operator: op,
          model: item.dimension?.value,
          attribute: item.attribute?.value,
          exclude: item.operator?.value === "Is not equal to" || item.operator?.value === "Does not contain" ? 1 : 0,
          value:
            (item.attribute?.value === "range_start" ||
              item.attribute?.value === "range_end" ||
              item.attribute?.value === "initial_exam_date") &&
            item.operator?.value === "Is a timestamp greater than"
              ? today.add(item.value, "days").format("YYYY-MM-DD")
              : (item.attribute?.value === "range_start" ||
                  item.attribute?.value === "range_end" ||
                  item.attribute?.value === "initial_exam_date") &&
                item.operator?.value === "Is a timestamp less than"
              ? today.subtract(item.value, "days").format("YYYY-MM-DD")
              : ((item.operator?.value === "Is equal to" ||
                  item.operator?.value === "Is not equal to" ||
                  item.operator?.value === "Contains" || item.operator?.value === "Does not contain") &&
                  !needsToBeDateInputValues.includes(item.attribute?.value)) ||
                ((item.operator?.value === "Is a timestamp greater than" ||
                  item.operator?.value === "Is a timestamp less than") &&
                  needsToBeDateInputValues.includes(item.attribute?.value))
              ? item.dimension?.value === "Patient" ||
                item.dimension?.value === "Physician"
                ? item.value?.value ?? item.value
                : item.value?.value ?? item.value
              : moment(item.value).format("yyyy-MM-DD"),
        };
      });
    }
    if (isValuesMissing) {
      return;
    }

    let todayDate = moment();

    const dataToSend = {
      cohort_title: cohortName,
      raw_filters: {
        condition: selectedOperator.value,
        filters: [
          {
            model: dimension?.value,
            attribute: attribute?.value,
            operator: operatorVal,
            value:
              (attribute?.value === "range_start" ||
                attribute?.value === "range_end" ||
                attribute?.value === "initial_exam_date") &&
              operator?.value === "Is a timestamp greater than"
                ? todayDate.add(value, "days").format("YYYY-MM-DD")
                : (attribute?.value === "range_start" ||
                    attribute?.value === "range_end" ||
                    attribute?.value === "initial_exam_date") &&
                  operator?.value === "Is a timestamp less than"
                ? todayDate.subtract(value, "days").format("YYYY-MM-DD")
                : (!needsToBeDateInputValues.includes(attribute?.value) &&
                    operator?.value === "Is equal to") ||
                  operator?.value === "Is not equal to" ||
                  operator?.value === "Contains" ||
                  operator?.value === "Does not contain" ||
                  operator?.value === "Is a timestamp greater than" ||
                  operator?.value === "Is a timestamp less than"
                ? value?.value ?? value
                : moment(date).format("yyyy-MM-DD"),
            exclude: operator?.value === "Is not equal to" || operator?.value === "Does not contain" ? 1 : 0,
          },
        ].concat(arr || []),
      },
    };

    if (isEditClicked) {
      let id = props.location.state.data.id;
      dispatch(updateCohort(id, dataToSend)).then(() => {
        if (fromDelete) {
          history.push("/auth/radAdmin/messages/cohorts/create", {
            data: { ...dataToSend, id },
          });
        } else {
          history.push(`/auth/radAdmin/messages/cohorts/patientView/${id}`);
        }
      });
    } else {
      dispatch(createCohort(dataToSend)).then((res) => {
        if (fromDelete) {
          history.push("/auth/radAdmin/messages/cohorts/create", {
            data: dataToSend,
          });
        } else if (res && res.success) {
          history.push("/auth/radAdmin/messages/cohorts");
        }
      });
    }
  };

  const addAdditioanlDimensionHandler = () => {
    let obj = {
      dimensionId: null,
      dimension: null,
      operator: null,
      value: "Values",
      exclude: false,
      attribute: null,
      isClicked: {
        dimension: false,
        attribute: false,
        operator: false,
        value: false,
      },
    };
    setAllowToAddAdditionalDimention(false);
    setAdditionalFiltersArray([...additionalFiltersArray, obj]);
  };

  const checkForAllValuesFilled = () => {
    setCheckingForUpdateInArray(!checkingForUpdateInArray);
    for (let i = 0; i < additionalFiltersArray.length; i++) {
      let item = additionalFiltersArray[i];
      if (
        !item?.dimension?.value ||
        !item?.operator?.value ||
        !item?.value
      ) {
        return;
      }
    }
    setAllowToAddAdditionalDimention(true);
  };
  const onChange = (dates) => {
    setDate(dates);
  };

  const getFixedValues = (model, attribute) => {
    let data = fixedValues.find(
      (val) => val.model === model && val.attribute === attribute
    );
    if (!data) return [];
    return data.values;
  };

  const dialogHandler = (idx) => {
    setRemoveIdx(idx);
    setRemoveDialogOpen(!removeDialogOpen);
  };

  const deleteCohortHandler = () => {
    const { data } = props.location.state;
    const deletedCohort = data?.cohort_filters[0];
    const filteredData = data?.cohort_filters.filter((item) => {
      return item.dimension !== deletedCohort.dimension;
    });
    setRemoveDialogOpen(false);
    setCohortFilters(filteredData);
  };

  return (
    <Box p={30}>
      {removeDialogOpen && (
        <RemoveConfirmation
          content="Are you sure you want to remove this Dimension?"
          openDialog={removeDialogOpen}
          dialogHandler={dialogHandler}
          isLoading={isLoadingDeleteCohort}
          deleteHandler={() => {
            let arr = [...additionalFiltersArray];
            arr.splice(removeIdx, 1);
            setAdditionalFiltersArray(arr);
            setAllowToAddAdditionalDimention(true);
            setRemoveIdx();
            setRemoveDialogOpen(false);
            // cohortSaveHandler(arr, true);
          }}
          removeHeader={"Yes, Remove Dimension"}
          heading={"Remove Dimension"}
        />
      )}
      <HeaderContainer>
        <PageTitle color="#00AEEF">
          {isEditClicked ? "Cohort Edit" : "Cohort Creation"}
        </PageTitle>
      </HeaderContainer>
      <Card
        style={{ paddingBottom: "30px", paddingTop: "30px", textAlign: "left" }}
      >
        <Container>
          <Section>
            <SelectContainer>
              <SelectDropdownTitle>Cohort Name *</SelectDropdownTitle>
              <TextField
                id="outlined-uncontrolled"
                variant="outlined"
                value={cohortName}
                onChange={(e) => setCohortName(e.target.value)}
                className={classes.title}
                placeholder="Cohort Name"
                InputProps={{
                  style: {
                    height: "38px",
                  },
                }}
              />
            </SelectContainer>
          </Section>
          <RowContainer>
            <SecondSection>
              <SelectContainer>
                <SelectDropdownTitle>Dimension *</SelectDropdownTitle>
                <Select
                  components={{ IndicatorsContainer, Option: RadioOption }}
                  classNamePrefix="select"
                  options={dimensionFirst.map((itm) => ({
                    label: itm ==='Report2'? 'Report' :itm,
                    value: itm,
                  }))}
                  value={dimension}
                  onChange={(itm) => {
                    setDimension(itm);
                    setAttribute(null);
                    setOperator(null);
                    setValue("");
                  }}
                  styles={{
                    option: (styles) => ({
                      ...styles,
                      fontSize: "14px",
                    }),
                  }}
                  isClearable
                />
              </SelectContainer>

              {dimension?.value && (
                <SelectContainer>
                  <SelectDropdownTitle>Attribute *</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: RadioOption }}
                    classNamePrefix="select"
                    options={availableAttributes.map((itm) => ({
                      label: capitalize(itm.attribute?.replace(/_/g, " ")),
                      value: itm.attribute,
                      id: itm.id,
                    }))}
                    value={attribute}
                    onChange={(itm) => {
                      setAttribute(itm);
                      setDimensionId(itm?.id);
                      if (needsToBeDropdownValues.includes(itm?.value)) {
                        dispatch(
                          getDropdownValuesForCohort(
                            dimension?.value,
                            itm.value
                          )
                        );
                      }
                      setValue("Values");
                      setOperator(null);
                    }}
                    styles={{
                      option: (styles) => ({
                        ...styles,
                        fontSize: "14px",
                      }),
                    }}
                    isClearable
                  />
                </SelectContainer>
              )}
              {dimension?.value && (
                <SelectContainer>
                  <SelectDropdownTitle>Operator *</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: RadioOption }}
                    classNamePrefix="select"
                    options={
                      dimension?.value === "SMSMessage"
                        ? messageOnlyOperator.map((itm) => ({
                            label: itm,
                            value: itm,
                          }))
                        : (needsToBeDropdownValues.includes(attribute?.value) ||
                          needsToBeFixedDropdownValues.includes(attribute?.value) 
                         )
                        ? operatorsArrayDropdownOnly.map((itm) => ({
                            label: itm,
                            value: itm,
                          }))
                        : needsToBeBooleanValues.includes(attribute?.value)
                        ? operatorsArrayBooleanOnly.map((itm) => ({
                            label: itm,
                            value: itm,
                          }))
                        : needsToBeDateInputValues.includes(attribute?.value)
                        ? operatorsArrayDate.map((itm) => ({
                            label: itm,
                            value: itm,
                          }))
                        : operatorsArrayText.map((itm) => ({
                            label: itm,
                            value: itm,
                          }))
                    }
                    value={operator}
                    onChange={(itm) => {
                      setOperator(itm);
                      setValue("");
                      setDate();
                    }}
                    styles={{
                      option: (styles) => ({
                        ...styles,
                        fontSize: "14px",
                      }),
                    }}
                    isClearable
                  />
                </SelectContainer>
              )}

              {dimension?.value && attribute?.value && operator?.value && (
                <SelectContainer>
                  <SelectDropdownTitle>value *</SelectDropdownTitle>
                  {isDropdownRequired(attribute?.value) &&
                  operator !== "Is a timestamp after" &&
                  operator !== "Is a timestamp before" &&
                  !needsToBeDateInputValues.includes(attribute?.value) ? (
                    <Select
                      components={{ IndicatorsContainer, Option: RadioOption }}
                      classNamePrefix="select"
                      options={   needsToBeFixedDropdownValues.includes(attribute?.value)?
                        ageOptions
                        :
                        needsToBeDropdownValues.includes(attribute?.value)
                          ? getFixedValues(
                              dimension?.value,
                              attribute?.value
                            )?.map((itm) => ({ label: itm, value: itm }))
                          :  booleanValues.map((itm) => ({
                              label: itm,
                              value: itm,
                            }))
                      }
                      value={value}
                      onChange={(value) => setValue(value)}
                      styles={{
                        option: (styles) => ({
                          ...styles,
                          fontSize: "14px",
                        }),
                      }}
                      isClearable
                     
                    />
                  ) : attribute?.value === "fields_completed_percentage" ? (
                    <TextField
                      id="outlined-uncontrolled"
                      variant="outlined"
                      type="number"
                      value={value === "Values" ? "" : value}
                      onChange={(e) => setValue(e.target.value)}
                      className={classes.textField}
                      placeholder="Enter number between 0-100"
                      InputProps={{
                        style: {
                          height: "38px",
                        },
                      }}
                    />
                  ) : ((operator?.value === "Is equal to" ||
                      operator?.value === "Is not equal to" ||
                      operator?.value === "Operators" ||
                      operator?.value === "Contains" || operator?.value === "Does not contain") &&
                      !needsToBeDateInputValues?.includes(attribute?.value)) ||
                    ((operator?.value === "Is a timestamp greater than" ||
                      operator?.value === "Is a timestamp less than") &&
                      needsToBeDateInputValues?.includes(attribute?.value)) ? (
                    <TextField
                      id="outlined-uncontrolled"
                      variant="outlined"
                      type={
                        operator?.value === "Is a timestamp greater than" ||
                        operator?.value === "Is a timestamp less than"
                          ? "number"
                          : "text"
                      }
                      value={value === "Values" ? "" : value}
                      onChange={(e) => setValue(e.target.value)}
                      className={classes.textField}
                      placeholder={
                        operator?.value === "Is a timestamp greater than" ||
                        operator?.value === "Is a timestamp less than"
                          ? "Enter # of days from today"
                          : "Enter value"
                      }
                      InputProps={{
                        style: {
                          height: "38px",
                        },
                      }}
                    />
                  ) : (
                    <DatePicker
                      onChange={onChange}
                      dateFormat="MM/dd/yyyy"
                      selected={date}
                      customInput={<DatePickerInput date={date} time={time} />}
                      className={classes.datePicker}
                      popperPlacement="bottom" 
                      popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: false }, hide: { enabled: false } }} 
                    />
                  )}
                </SelectContainer>
              )}
            </SecondSection>
            <RemoveIconContainer>
              {/* <div style={{ width: "80%" }}>
                {(operator === "Is a timestamp greater than" ||
                  operator === "Is a timestamp less than") &&
                  needsToBeDateInputValues.includes(attribute?.value) && (
                    <DaysFrom>days from today</DaysFrom>
                  )}
              </div> */}
              {cohortFilters?.length > 1 && (
                <RemoveContainer>
                  <Remove onClick={dialogHandler}>
                    <RemoveRecoIcon />
                  </Remove>
                </RemoveContainer>
              )}
            </RemoveIconContainer>
          </RowContainer>
          {!isEmpty(additionalFiltersArray) &&
            additionalFiltersArray.map((item, i) => {
              return (
                <div>
                  <RowContainer>
                    <SwitchContainer>
                      <SwitchButton
                        active={selectedOperator.value === "and"}
                        onClick={() => {
                          setSelectedOperator({
                            value: "and",
                            label: "AND",
                            category: "Operator",
                          });
                        }}
                      >
                        and
                      </SwitchButton>
                      <SwitchButton
                        active={selectedOperator.value === "or"}
                        onClick={() => {
                          setSelectedOperator({
                            value: "or",
                            label: "OR",
                            category: "Operator",
                          });
                        }}
                      >
                        or
                      </SwitchButton>
                    </SwitchContainer>
                  </RowContainer>
                  <RowContainer>
                    <SecondSection key={i}>
                      <SelectContainer>
                        <SelectDropdownTitle>Dimension *</SelectDropdownTitle>
                        <Select
                          components={{
                            IndicatorsContainer,
                            Option: RadioOption,
                          }}
                          classNamePrefix="select"
                          options={dimensionFirst.map((itm) => ({
                            label: itm ==='Report2'? 'Report' :itm,
                            value: itm,
                          }))}
                          value={item.dimension}
                          onChange={(item) => {
                            let arr = additionalFiltersArray;
                            arr[i] = {
                              ...item,
                              dimension: item,
                              attribute: null,
                              operator: null,
                            };
                            setAdditionalFiltersArray(arr);
                            checkForAllValuesFilled();
                            if(item?.value){
                              dispatch(getAttributesForDimension(item?.value));
                            }
                          }}
                          styles={{
                            option: (styles) => ({
                              ...styles,
                              fontSize: "14px",
                            }),
                          }}
                          isClearable
                        />
                      </SelectContainer>

                      {item?.dimension?.value && (
                        <SelectContainer>
                          <SelectDropdownTitle>Attribute *</SelectDropdownTitle>
                          <Select
                            components={{
                              IndicatorsContainer,
                              Option: RadioOption,
                            }}
                            classNamePrefix="select"
                            options={availableAttributes.map((itm) => ({
                              label: capitalize(itm.attribute?.replace(/_/g, " ")),
                              value: itm.attribute,
                              id: itm.id,
                            }))}
                            value={item.attribute}
                            onChange={(value) => {
                              let arr = additionalFiltersArray;
                              arr[i] = {
                                ...item,
                                attribute: value,
                                dimensionId: value?.id,
                                operator: null,
                                value:null
                              };
                              setAdditionalFiltersArray(arr);
                              checkForAllValuesFilled();
                              if (
                                needsToBeDropdownValues.includes(value?.value)
                              ) {
                                dispatch(
                                  getDropdownValuesForCohort(
                                    item.dimension?.value,
                                    value.value
                                  )
                                );
                              }
                            }}
                            styles={{
                              option: (styles) => ({
                                ...styles,
                                fontSize: "14px",
                              }),
                            }}
                            isClearable
                          />
                        </SelectContainer>
                      )}

                      {item?.dimension?.value && (
                        <SelectContainer>
                          <SelectDropdownTitle>Operator *</SelectDropdownTitle>
                          <Select
                            components={{
                              IndicatorsContainer,
                              Option: RadioOption,
                            }}
                            classNamePrefix="select"
                            options={
                              item.dimension?.value === "SMSMessage"
                                ? messageOnlyOperator.map((itm) => ({
                                    label: itm,
                                    value: itm,
                                  }))
                                :(needsToBeDropdownValues.includes(item.attribute?.value) ||
                                  needsToBeFixedDropdownValues.includes(item.attribute?.value) 
                                )
                                ? operatorsArrayDropdownOnly.map((itm) => ({
                                    label: itm,
                                    value: itm,
                                  }))
                                : needsToBeBooleanValues.includes(
                                    item.attribute?.value
                                  )
                                ? operatorsArrayBooleanOnly.map((itm) => ({
                                    label: itm,
                                    value: itm,
                                  }))
                                : needsToBeDateInputValues.includes(
                                    item.attribute?.value
                                  )
                                ? operatorsArrayDate.map((itm) => ({
                                    label: itm,
                                    value: itm,
                                  }))
                                : operatorsArrayText.map((itm) => ({
                                    label: itm,
                                    value: itm,
                                  }))
                            }
                            value={item.operator}
                            onChange={(value) => {
                              let arr = additionalFiltersArray;
                              arr[i] = {
                                ...item,
                                operator: value,
                                isClicked: {
                                  ...arr[i].isClicked,
                                  operator: false,
                                },
                                attribute: item.attribute,
                                value:""
                              };
                              setAdditionalFiltersArray(arr);
                              checkForAllValuesFilled();
                            }}
                            styles={{
                              option: (styles) => ({
                                ...styles,
                                fontSize: "14px",
                              }),
                            }}
                            isClearable
                          />
                        </SelectContainer>
                      )}

                      {item.dimension?.value &&
                        item.attribute?.value &&
                        item.operator?.value && (
                          <SelectContainer>
                            <SelectDropdownTitle>Value *</SelectDropdownTitle>
                            {isDropdownRequired(item.attribute?.value) &&
                            item.operator?.value !== "Is a timestamp after" &&
                            item.operator?.value !== "Is a timestamp before" &&
                            !needsToBeDateInputValues.includes(
                              item.attribute?.value
                            ) ? (
                              <Select
                                components={{
                                  IndicatorsContainer,
                                  Option: RadioOption,
                                }}
                                classNamePrefix="select"
                                options={
                                  needsToBeFixedDropdownValues.includes(item?.attribute?.value)?
                                  ageOptions
                                  :
                                  needsToBeDropdownValues.includes(
                                    item.attribute?.value
                                  )
                                    ? getFixedValues(
                                        item.dimension?.value,
                                        item.attribute?.value
                                      ).map((itm) => ({
                                        label: itm,
                                        value: itm,
                                      }))
                                    : booleanValues.map((itm) => ({
                                        label: itm,
                                        value: itm,
                                      }))
                                }
                                value={item.value}
                                onChange={(itm) => {
                                  let arr = additionalFiltersArray;
                                  arr[i] = {
                                    ...item,
                                    value: itm,
                                    isClicked: {
                                      ...arr[i].isClicked,
                                      value: false,
                                    },
                                  };
                                  setAdditionalFiltersArray(arr);
                                  checkForAllValuesFilled();
                                }}
                                styles={{
                                  option: (styles) => ({
                                    ...styles,
                                    fontSize: "14px",
                                  }),
                                }}
                                isClearable
                              />
                            ) : item.attribute?.value ===
                              "fields_completed_percentage" ? (
                              <TextField
                                id="outlined-uncontrolled"
                                variant="outlined"
                                type="number"
                                value={
                                  item.value === "Values" ? "" : item.value
                                }
                                onChange={(e) => {
                                  let arr = additionalFiltersArray;
                                  arr[i] = { ...item, value: e.target.value };
                                  setAdditionalFiltersArray(arr);
                                  checkForAllValuesFilled();
                                }}
                                className={classes.textField}
                                placeholder="Enter number between 0-100"
                                InputProps={{
                                  style: {
                                    height: "38px",
                                  },
                                }}
                              />
                            ) : ((item.operator?.value === "Is equal to" ||
                                item.operator?.value === "Is not equal to" ||
                                item.operator?.value === "Operators" ||
                                item.operator?.value === "Contains" || item.operator?.value === "Does not contain") &&
                                !needsToBeDateInputValues.includes(
                                  item.attribute?.value
                                )) ||
                              ((item.operator?.value ===
                                "Is a timestamp greater than" ||
                                item.operator?.value ===
                                  "Is a timestamp less than") &&
                                needsToBeDateInputValues.includes(
                                  item.attribute?.value
                                )) ? (
                              <TextField
                                id="outlined-uncontrolled"
                                variant="outlined"
                                type={
                                  item.operator?.value ===
                                    "Is a timestamp greater than" ||
                                  item.operator?.value ===
                                    "Is a timestamp less than"
                                    ? "number"
                                    : "text"
                                }
                                value={
                                  item.value === "Values" ? "" : item.value
                                }
                                onChange={(e) => {
                                  let arr = additionalFiltersArray;
                                  arr[i] = { ...item, value: e.target.value };
                                  setAdditionalFiltersArray(arr);
                                  checkForAllValuesFilled();
                                }}
                                className={classes.textField}
                                placeholder={
                                  item.operator?.value ===
                                    "Is a timestamp greater than" ||
                                  item.operator?.value ===
                                    "Is a timestamp less than"
                                    ? "Enter # of days from today"
                                    : "Enter value"
                                }
                                InputProps={{
                                  style: {
                                    height: "38px",
                                  },
                                }}
                              />
                            ) : (
                              <DatePicker
                                onChange={(date) => {
                                  let arr = additionalFiltersArray;
                                  arr[i] = { ...item, value: date };
                                  setAdditionalFiltersArray(arr);
                                  checkForAllValuesFilled();
                                }}
                                dateFormat="MM/dd/yyyy"
                                popperPlacement="bottom" 
                                popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: false }, hide: { enabled: false } }} 
                                customInput={
                                  <DatePickerInput
                                    date={
                                      item.value === "Values" ? "" : item.value
                                    }
                                    time={time}
                                  />
                                }
                                className={classes.datePicker}
                              />
                            )}
                          </SelectContainer>
                        )}
                    </SecondSection>
                    <SelectContainer>
                      <SelectDropdownTitle style={{ visibility: "hidden" }}>
                        delete
                      </SelectDropdownTitle>
                      <Remove
                        onClick={() => {
                          dialogHandler(i);
                        }}
                      >
                        <IconContainer>
                        <RemoveRecoIcon />
                        <RemoveRecoIconHover className="light" />
                        </IconContainer>
                      </Remove>
                    </SelectContainer>
                  </RowContainer>
                </div>
              );
            })}
          {
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 40,
                justifyContent: "flex-end",
                gap: "20px",
              }}
            >
              {allowToAddAdditionalDimention && (
                <Button
                  classes={{
                    label: classes.label,
                    root: classes.additionalDimensionsButton,
                  }}
                  onClick={addAdditioanlDimensionHandler}
                >
                  Add Additional Dimensions
                </Button>
              )}
              <Button
                classes={{ label: classes.label, root: classes.saveButton }}
                onClick={() => cohortSaveHandler(additionalFiltersArray)}
              >
                {isLoading ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  "Save Cohort"
                )}
              </Button>
            </div>
          }
        </Container>
      </Card>
    </Box>
  );
}

export default Create;
