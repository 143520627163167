import React, { useState } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import {
  saveContactStepName,
} from "../../../actions/radminUploads";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { showAlert } from "../../../actions/radminUploads";
import { ReactComponent as CloseIcon } from "../../../assets/cancel-outline.svg";
import { useHistory, useLocation } from "react-router";

ReactModal.setAppElement("#root");

const Modal = styled(ReactModal)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 444px !important;
  padding: 20px 24px !important;
  height: 218px !important;
  background:white;
  margin:0 auto;

  margin: 15% auto;
  background: white;

  @media (max-width: 768px) {
    width: auto !important;
    margin: 30px !important;
    margin-top: calc(15% + 30px) !important;
  }
`;

const Label = styled.label`
  align-self: flex-start;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #3f4857;
`;

const Input = styled.input.attrs({ type: "text" })`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #c2ccd9;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #8692a4;
  height: 38px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
  justify-content: center;
`;

const Button = styled.button`
  width: 227px;
  height: 38px;
  background: ${({ isPrimary, disabled }) =>
    disabled ? "#00AEEF4D" : isPrimary ? "#00AEEF" : "transparent"};
  border-radius: 5px;
  border: ${({ isPrimary, disabled }) =>
    disabled
      ? "2px solid transparent"
      : isPrimary
      ? "2px solid #00AEEF"
      : "2px solid #CFCFCF"};
  outline: none !important;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ isPrimary }) => (isPrimary ? "white" : "#000000")};
  &:hover {
    background: ${({ isPrimary }) => (isPrimary ? "transparent" : "#CFCFCF")};
    color: ${({ isPrimary }) => (isPrimary ? "#00aeef" : "white")};
    border: ${({ isPrimary, disabled }) =>
      isPrimary ? "2px solid #00AEEF" : "2px solid #CFCFCF"};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #0c2945;
  font-weight: 700;
  font-family: Roboto Condensed;
  margin-bottom: 0;
`;

const customStyle = {
  overlay: {
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(12, 41, 69, 0.38)",
    zIndex: 3,
  },
  content: {
    borderRadius: "4px",
  },
};

const AddContactStep = ({ opened, onClose, data,setActiveTab, setCountsList, activeTab }) => {
  const [contactStepName, setContactStepName] = useState( data?.id? data?.name : "");
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const addPermission = rolePermissions
    ?.find((itm) => itm.name === "Recommendation Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "add_recommendationcohorts"
    )?.is_authorized;

    const updatePermission = rolePermissions
    ?.find((itm) => itm.name === "Recommendation Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "change_recommendationcohorts"
    )?.is_authorized;


    const updateURLParams = (paramsToUpdate) => {
      const params = new URLSearchParams(location.search);
  
      Object.keys(paramsToUpdate).forEach((key) => {
        if (paramsToUpdate[key]) {
          params.set(key, paramsToUpdate[key]);
        } else {
          params.delete(key);
        }
      });
  
      history.replace({ search: params.toString() });
    };

  const onChangeContactStepName = (e) => {
    setContactStepName(e.target.value);
  };

  const handleContactStepSave = async (e) => {
    e.preventDefault();

    if(data){
      if ((permission && updatePermission) || !permission) {
        window.analytics.track("Contact-Step-Edit-Submit", {
          title: contactStepName,
        });
        setLoading(true);
        dispatch(saveContactStepName(contactStepName, data?.id)).then((res) => {
          setLoading(false);
          onClose();
          if(res){
            setCountsList(prev=>({...prev, validated_reco:{ ...prev.validated_reco , [res.name]: prev?.validated_reco[activeTab]}  }))
            setActiveTab(res.name);
            updateURLParams({
              type: res.name,
              search: "",
              "communication-step": "",
              task: "",
              tabId: res.id
            });
          }
        
        });
      } else {
        dispatch(
          showAlert(
            "danger",
            "Error",
            `You don't have permission to edit contact step`
          )
        );
      }
    }else{

    if ((permission && addPermission) || !permission) {
      window.analytics.track("Contact-Step-Create-Submit", {
        title: contactStepName,
      });
      setLoading(true);
      dispatch(saveContactStepName(contactStepName)).then((res) => {
        setLoading(false);
        onClose();
      });
    } else {
      dispatch(
        showAlert(
          "danger",
          "Error",
          `You don't have permission to add contact step`
        )
      );
    }
  }
  };

  return (
    <Modal
      style={customStyle}
      isOpen={opened}
      onRequestClose={onClose}
      contentLabel="Save Filter Set"
    >
      <Header>
        <Title>{data? "Edit Contact Step" : "Add Contact Step"}</Title>
        <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
      </Header>
      <form style={{ width: "100%" }} onSubmit={handleContactStepSave}>
        <Label children="Contact Step Name" htmlFor="contactStep" />
        <Input
          onChange={onChangeContactStepName}
          value={contactStepName}
          id="contactStep"
          placeholder="e.g. phone call, email, fax"
        />
        <ButtonContainer>
          <Button
            type="submit"
            isPrimary={true}
            disabled={!contactStepName}
            children={
              loading ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                data ? "Edit Step":"Create Step"
              )
            }
          />
        </ButtonContainer>
      </form>
    </Modal>
  );
};

export default AddContactStep;
