import React, { useState, useEffect,forwardRef } from 'react'
import { Typography, Grid, FormControl, MenuItem } from '@material-ui/core'
import { makeStyles, styled as materialStyle } from '@material-ui/core/styles';
import styled from "styled-components";
import { isArray } from 'lodash';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Flex from '../../components/_styles/Flex';
import EditFormBottom from './EditFormBottom';
import { Spinner } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import {  ReactComponent as CalendarIcon} from '../../assets/calendar-outline.svg'
import Select, { components } from "react-select";
import { getDropdownValuesForCohort } from '../../actions/radminUploads';


const useStyles = makeStyles((theme) => ({
    p: {
        fontSize: '13px',
        marginBottom: '0px',
        fontWeight: 'bold',
        lineHeight: 1.3
    },
    blobText: {
        fontSize: '14px',
        color: '#00AEEF',
        marginBottom: '0px',
        fontWeight: 'bold',
        textDecoration: 'underline'
    },
    pSecond: {
        marginTop: '15px',
        fontSize: '13px',
        color: '#AAAEBD',
        marginBottom: '0px',
        fontWeight: 'bold'
    },
    reportTextBox: {
        backgroundColor: '#FAFAFA',
        borderRadius: '8px',
        overflow: "auto",
        padding: '16px',
        margin: '10px 0',
        border: '1px solid #AAAEBD',
        display:'flex',
        gap:'14px'
    },
    formControlDropdown: {
        width: '227px'
    },
    recommendationsSection: {
    },
    inputFieldsSection: {
        display: 'flex',
        marginBottom: '20px',
        gap:'20px'
    },
    heading: {
        fontSize: '22px',
        fontWeight: 700,
        color:'#0C2945',
        fontFamily:'Roboto Condensed',
        marginBottom:'15px'
    },
    selectInput: {
        padding: 13,
        fontSize: 16,
        width:'227px',
        background: '#FFFFFF',
        '& .MuiOutlinedInput-input': {
            padding: 0
        },
        '& .MuiOutlinedInput-input:focus': {
            backgroundColor: 'transparent',
            outline: 'none',
          },
    },
    saveBtn: {
        background: '#4dabf5',
        color: 'white',
        padding: '3px 20px',
        width: '145px',
        textTransform:'capitalize',
        '&:hover': {
            background: '#4dabf5',
        }
    },
    noData: {
        color: ' #AAAEBD',
        textAlign: 'center',
        fontSize: 16,
        padding: '0px'
    },

}))

export const Label = materialStyle(Typography)({
    color: '#6D6E6E',
    fontSize: 14,
    fontWeight:700,
    fontFamily:'Roboto Condensed',
    marginBottom:'8px',
    textTransform:'uppercase'
});



export const DatePickerButton = styled(Flex)`
  padding: 13px;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D2D3D7;
  border-radius: 5px;
  width:227px;
  height:45px;
  justify-content:space-between;
  &:hover {
    cursor: pointer;
  }
`
export const DateContainer = styled(Flex)`
  margin-right:4px;
  font-size:14px;
  color:#3F4857;
`
export const DatePickerContainer = styled(Flex)`
margin-left:4%;
`
const ExamDateLabel = styled.p`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #3F4857;
  margin: 5px 0px;
`

const BlobTextHeading = styled.p`
    font-size: 15px;
    font-weight: 600;
    font-family: 'Roboto Condensed';
    letter-spacing: 0.2px;
    line-height: 20px;
    width: 20%
`
const BlobText = styled.span`
    font-size: 16px;
    color: #00AEEF;
    margin-bottom: 0px;
    font-weight: 600;
    text-decoration: underline;
    line-height: 1.3;
    margin-right: 4px;
    width: 100%;
    padding:20px;
    text-decoration-thickness: 0.1px;
    text-underline-offset: 1px; 
`

const DatePickerInput = forwardRef(({ onClick, startDate, endDate }, ref) => {
    const formatDate = (date) => {
        return date ? moment(date).format("MMM, DD, YYYY") : 'No Date';
    }
    return (
        <DatePickerButton onClick={onClick} ref={ref}>
            <DateContainer>{`${formatDate(startDate)} - ${formatDate(endDate)}`}</DateContainer>
            <CalendarIcon />
        </DatePickerButton>
    )
});

const IndicatorsContainer = (props) => {
    return (
      <>
        <components.Option {...props}>
          <span style={{ marginRight: 5, paddingTop: 5 }}>
            <input
              type="radio"
              checked={props.isSelected}
              onChange={() => null}
              style={{ marginTop: 5 }}
            />{" "}
          </span>
          <label>{props.label}</label>
        </components.Option>
      </>
    );
  };

function EditFormCenter({ state, handleChange, changeDateHandler, selectedData, notShowBottom, LoadMoreNotes, toggleNotesOrder, notesOrder,setNotesPage, saveHandler, isLoadingFollowUpList, type, handleChangeRecommendationValues}) {
    const classes = useStyles();
    const formatedEndDate = `${state.endDate}T05:30:00`;
    const formattedStartDate = `${state.startDate}T05:30:00`;
    const [startDate, setStartDate] = useState(new Date(formattedStartDate));
    const [endDate, setEndDate] = useState(new Date(formatedEndDate));

    const [onChangeCalled, setOnChangeCalled] = useState(false);
    const dispatch = useDispatch();

    const anatomyData = useSelector(state => state.radAdmin.followUpList.anatomies.data);
    const modalityData = useSelector(state => state.radAdmin.followUpList.modalities.data);
    const findingsBoiData = useSelector(state => state.radAdmin.followUpList.findingsBoi.data);

    useEffect(() => {
        dispatch(getDropdownValuesForCohort("Recommendation", "guideline_type"));
      }, []);
    
    
      const fixedValues = useSelector(
        (state) => state.radAdmin.cohorts.fixedValues?.data
      );

    const guidelineOptions = fixedValues?.length
    ? fixedValues
        .find((val) => val.attribute === "guideline_type")
        ?.values?.map((itm) => ({
          value: itm,
          label: itm,
          category: "guideline_type",
        }))
    : [];


    const conditionalOptions = [
        {
          value: "true",
          label: "true",
          category: "conditional",
        },
        {
          value: "false",
          label: "false",
          category: "conditional",
        },
      ];
    
      const testOptions = [
        { value: "screening", label: "Screening", category: "exam_type" },
        { value: "diagnostic", label: "Diagnostic", category: "exam_type" },
      ];
    
      const recommemdationOptions = [
        {
          value: "imaging_followup",
          label: "Imaging Followup",
          category: "recommendation_type",
        },
        {
          value: "non_imaging_followup",
          label: "Non Imaging Followup",
          category: "recommendation_type",
        },
      ];
    
      const contrastOptions = [
        { value: "with", label: "With", category: "contrast" },
        {
          value: "without",
          label: "Without",
          category: "contrast",
        },
        {
          value: "with-without",
          label: "With and Without",
          category: "contrast",
        },
      ];

    useEffect(() => {
       if(!onChangeCalled){
        setStartDate(new Date(formattedStartDate));
        setEndDate(new Date(formatedEndDate))
       }
    }, [state])

    const onChange = dates => {
        if (isArray(dates)) {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
            changeDateHandler(start, end)
            window.analytics.track('Recommendations-Chart-Datetime-Selection');
        }
        setOnChangeCalled(true)
    };

    return (
      <div style={{ display: "flex", gap: "20px", marginBottom: "30px" }}>
        <div style={{ flexBasis: "50%" }}>
          <div
            style={{ marginBottom: "30px" }}
            className={classes.recommendationsSection}
          >
            <Typography className={classes.heading}>
              Structured Findings
            </Typography>
            <div
              style={{
                border: "1px solid #CACACA",
                borderRadius: "8px",
                background: "#FAFAFA",
                padding: "20px",
              }}
            >
              <div style={{ display: "flex" }}>
                {findingsBoiData?.data?.results?.length === 0 ? (
                  <div className={classes.noData}> No Data</div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    {" "}
                    {findingsBoiData?.data?.results.slice(0, 10).map((itm) => (
                      <BlobText style={{ padding: "0px" }}>{itm.boi}</BlobText>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          {!selectedData.isNoRecommendation && (
            <div className={classes.recommendationsSection}>
              <Typography className={classes.heading}>
                Recommendation Statement Extracted
              </Typography>
              <div
                style={{
                  border: "1px solid #CACACA",
                  borderRadius: "8px",
                  background: "#FAFAFA",
                }}
              >
                <div style={{ display: "flex" }}>
                  <BlobText>{state.relevantBlob}</BlobText>
                </div>
                <Grid container>
                  <div
                    style={{ padding: "0px 20px" }}
                    className={classes.inputFieldsSection}
                  >
                    <FormControl
                      variant="outlined"
                      className={classes.formControlDropdown}
                    >
                      <Label>Anatomy</Label>
                      <Select
                        value={state.anatomy}
                        onChange={(val) =>
                          handleChangeRecommendationValues("anatomy", val)
                        }
                        name="anatomy"
                        options={anatomyData
                          ?.filter((item) => Boolean(item))
                          .map((item) => ({ label: item, value: item }))}
                        components={{ Option: IndicatorsContainer }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControlDropdown}
                    >
                      <Label>Modality</Label>
                      <Select
                        value={state.modality}
                        onChange={(val) =>
                          handleChangeRecommendationValues("modality", val)
                        }
                        name="modality"
                        options={modalityData
                          ?.filter((item) => Boolean(item))
                          .map((item) => ({ label: item, value: item }))}
                        components={{ Option: IndicatorsContainer }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </FormControl>
                  </div>
                  <div
                    style={{ padding: "0px 20px" }}
                    className={classes.inputFieldsSection}
                  >
                    <FormControl
                      variant="outlined"
                      className={classes.formControlDropdown}
                    >
                      <Label>Timeframe</Label>

                      <DatePickerContainer style={{ marginLeft: "0px" }}>
                        <DatePicker
                          onChange={onChange}
                          startDate={startDate}
                          selected={startDate}
                          endDate={endDate}
                          dateFormat="MM/dd/yyyy"
                          customInput={
                            <DatePickerInput
                              startDate={startDate}
                              endDate={endDate}
                            />
                          }
                          selectsRange
                          shouldCloseOnSelect={false}
                          popperPlacement="auto"
                        />

                      </DatePickerContainer>
                    </FormControl>

                    <FormControl
                      variant="outlined"
                      className={classes.formControlDropdown}
                    >
                      <Label>Conditional</Label>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={state.conditional}
                        onChange={(val) =>
                          handleChangeRecommendationValues("conditional", val)
                        }
                        name="conditional"
                        options={conditionalOptions}
                        components={{ Option: IndicatorsContainer }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </FormControl>
                  </div>

                  <div
                    style={{ padding: "0px 20px" }}
                    className={classes.inputFieldsSection}
                  >
                    <FormControl
                      variant="outlined"
                      className={classes.formControlDropdown}
                    >
                      <Label>Guideline Type</Label>
                      <Select
                        components={{ Option: IndicatorsContainer }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={guidelineOptions}
                        value={state.guidelineType}
                        onChange={(val) =>
                          handleChangeRecommendationValues("guidelineType", val)
                        }
                        styles={{
                          option: (styles) => ({ ...styles, fontSize: "14px" }),
                        }}
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControlDropdown}
                    >
                      <Label>Exam Type</Label>
                      <Select
                        components={{ Option: IndicatorsContainer }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={testOptions}
                        value={state.examType}
                        onChange={(val) =>
                          handleChangeRecommendationValues("examType", val)
                        }
                        name="examType"
                        styles={{
                          option: (styles) => ({ ...styles, fontSize: "14px" }),
                        }}
                      />
                    </FormControl>
                  </div>

                  <div
                    style={{ padding: "0px 20px" }}
                    className={classes.inputFieldsSection}
                  >
                    <FormControl
                      variant="outlined"
                      className={classes.formControlDropdown}
                    >
                      <Label>Contrast</Label>
                      <Select
                        components={{ Option: IndicatorsContainer }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={contrastOptions}
                        value={state.contrast}
                        onChange={(val) =>
                          handleChangeRecommendationValues("contrast", val)
                        }
                        name="contrast"
                        styles={{
                          option: (styles) => ({ ...styles, fontSize: "14px" }),
                        }}
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControlDropdown}
                    >
                      <Label>Recommendation Type</Label>
                      <Select
                        components={{ Option: IndicatorsContainer }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={recommemdationOptions}
                        value={state.recommendationType}
                        onChange={(val) =>
                          handleChangeRecommendationValues(
                            "recommendationType",
                            val
                          )
                        }
                        name="recommendationType"
                        styles={{
                          option: (styles) => ({ ...styles, fontSize: "14px" }),
                        }}
                      />
                    </FormControl>
                  
                  </div>
                  <div style={{ padding:'0px 20px'}} className={classes.inputFieldsSection}>
                      <FormControl variant="outlined" className={classes.formControlDropdown}>
                            <Label>Recommendation Source</Label>
                            <span>{state.recoSource}</span>
                      </FormControl>
                    </div>
                </Grid>
                {!notShowBottom && (
                  <div style={{ paddingLeft: "20px", paddingBottom: "20px" }}>
                    <Button onClick={saveHandler} className={classes.saveBtn}>
                      {isLoadingFollowUpList && type === "reco" ? (
                        <Spinner
                          animation="border"
                          role="status"
                          style={{ width: 30, height: 30 }}
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {!notShowBottom && (
          <EditFormBottom
            state={state}
            handleChange={handleChange}
            selectedData={selectedData}
            LoadMoreNotes={LoadMoreNotes}
            toggleNotesOrder={toggleNotesOrder}
            notesOrder={notesOrder}
            setNotesPage={setNotesPage}
          />
        )}
      </div>
    );
}

export default EditFormCenter
