import styled from 'styled-components';
import React from 'react';
import Flex from '../../../components/_styles/Flex';
import { ReactComponent as EditRecoIcon } from '../../../assets/editRecoIcon.svg'
import { ReactComponent as RemoveRecoIcon } from '../../../assets/FollowUpDeleteIcon.svg'
import { ReactComponent as NoteIcon } from '../../../assets/notesCircled.svg'
import { ReactComponent as NoteIconHovered } from '../../../assets/notesCircledHovered.svg'
import { ReactComponent as GeneralTaskIcon } from '../../../assets/general-task-dark.svg'
import { ReactComponent as LetterTaskIcon } from '../../../assets/letter-task-dark.svg'
import { ReactComponent as SmsTaskIcon } from '../../../assets/sms-task-dark.svg'
import { ReactComponent as EmailTaskIcon } from '../../../assets/email-task-dark.svg'
import { ReactComponent as FaxTaskIcon } from '../../../assets/fax-task-dark.svg'
import { ReactComponent as CallTaskIcon } from '../../../assets/call-task-dark.svg'
import { ReactComponent as GeneralTaskLightIcon } from '../../../assets/general-task-light.svg'
import { ReactComponent as LetterTaskLightIcon } from '../../../assets/letter-task-light.svg'
import { ReactComponent as SmsTaskLightIcon } from '../../../assets/sms-task-light.svg'
import { ReactComponent as EmailTaskLightIcon } from '../../../assets/email-task-light.svg'
import { ReactComponent as FaxTaskLightIcon } from '../../../assets/fax-task-light.svg'
import { ReactComponent as CallTaskLightIcon } from '../../../assets/call-task-light.svg'
import { ReactComponent as RemoveRecoIconHover } from "../../../assets/delete-hover.svg";
import { ReactComponent as EditRecoIconHover } from '../../../assets/edit-icon-hover.svg'



import { useState } from 'react';
import { Tooltip, withStyles } from '@material-ui/core';

const Container = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const MainInfo = styled.div`
  font-family: Open Sans;
  font-style: normal;
  text-align: ${props => props.textAlign ? props.textAlign : 'center'};
  font-weight:${props => props.fontWeight? props.fontWeight : '600'};
  font-size: ${props => props.textSize ? props.textSize : '16px'};
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
`

const AdditionalInfo = styled.div`
  height: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  text-align: ${props => props.textAlign ? props.textAlign : 'center'};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #72808A;
`
const EditSection = styled.div`
  display:flex; 
  justify-content:space-between'
  width: 100px;
  gap:${props=>props.customGapValue?props.customGapValue:'0px'};
`
const Space = styled.span`
opacity: -0.5;
`


const IconContainer = styled.span`
 position: relative;
 cursor: pointer;
 display: inline-block;
 width: 30px; 
 height: 30px; 

 svg {
  position: absolute;
  top: 0px;
  left: 0;
  transition: opacity 0.1s ease;
 }

 svg.dark {
  opacity: 1;
 }

 svg.light {
  opacity: 0; 
 }

 &:hover svg.dark {
  opacity: 0;
 }

 &:hover svg.light {
  opacity: 1;
 }

 .label {
  display: none;
  font-size:9px;
  font-weight: 700;
  color:${props=>props.color};
 }

 &:hover .label {
  display: block;
  position: absolute;
  left: 50%;
  top: ${props=>props.startGap || '-40%'};
  line-height:12px;
  transform: translateX(-50%);
  z-index:2;
 }
`;


const StyledTooltip = (props) => {
  const { className, ...other } = props;

  return (
    <Tooltip
      {...other}
      PopperProps={{
        className: className,
        style: {
          backgroundColor: "#AEE3F8",
          marginBottom: "20px",
          borderRadius: "4px",
          zIndex: 3,
        },
      }}
    />
  );
};

const StyledTooltipWrapper = withStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    color: "black!important",
    fontSize:"14px!important",
    fontWeight:400 + "!important",
    width: "220px",
    padding: "10px",
    textAlign:'center',
    margin: "0px",
    position: 'relative',
  },
 
}))(StyledTooltip);



const DoubleDataCell = ({ mainInfo, additionalInfo, textAlign, fontWeight, RemoveRecoHandler, EditRecoHandler, rowData, checkboxHandler, showEditIcon = true, textSize="16px", selectedIds, checked, customGap=false, customGapValue="", isDisabled=false, disabledTooltipText="", isActive=true }) => {
  const header = rowData?.notesData?.slice(0,3).find(itm=> itm.task)?.task;
  const getTaskIcon = (header) => {
    switch (header) {
      case 'general':
        return (
          <>
            <GeneralTaskIcon className='dark'/>
            <GeneralTaskLightIcon className="light" />
          </>
        );
      case 'letter':
        return (
          <>
            <LetterTaskIcon className='dark'/>
            <LetterTaskLightIcon className="light" />
          </>
        );
      case 'sms':
        return (
          <>
            <SmsTaskIcon className='dark' />
            <SmsTaskLightIcon className="light" />
          </>
        );
      case 'email':
        return (
          <>
            <EmailTaskIcon className='dark'/>
            <EmailTaskLightIcon className="light" />
          </>
        );
      case 'call':
        return (
          <>
            <CallTaskIcon className='dark'/>
            <CallTaskLightIcon className="light" />
          </>
        );
      case 'fax':
        return (
          <>
            <FaxTaskIcon className='dark'/>
            <FaxTaskLightIcon className="light" />
          </>
        );
      default:
        return (
          <>
            <LetterTaskIcon className='dark'/>
            <LetterTaskLightIcon className="light" />
          </>
        );
    }
  };

  if(mainInfo === 'checkbox') {
    return (
       <input type="checkbox" onClick={checkboxHandler}  checked={checked}  />
    )
  }
  if (mainInfo === 'edit') {
    return (
      <Container>
        <MainInfo textAlign={textAlign} fontWeight={fontWeight}>
          <EditSection customGapValue={customGapValue}>
            {
              isDisabled ? <>
                {showEditIcon && <div>
                  <StyledTooltipWrapper placement="top" title={disabledTooltipText}>
                   <EditRecoIcon style={{cursor:'not-allowed'}} className='dark' />
                  </StyledTooltipWrapper>
                </div>}
              {
              !customGap && <Space>xxx</Space>
              }
              </> : 
              <>
              {
              showEditIcon && <div onClick={EditRecoHandler}>
                  <IconContainer color="#00AEEF">
                    <span className='label'>EDIT</span>
                     <EditRecoIcon className='dark' />
                     <EditRecoIconHover className='light' />
                  </IconContainer>
                  </div>
              }
              {
              !customGap && <Space>xxx</Space>
              }
             </>
            }
            {
              isDisabled && isActive &&
                <div>
                  <StyledTooltipWrapper placement="top" title={disabledTooltipText}>
                   <RemoveRecoIcon style={{cursor:'not-allowed'}}  className='dark' />
                  </StyledTooltipWrapper>
                </div>
            } 
            { 
             !isDisabled &&  isActive &&  
                <div onClick={RemoveRecoHandler}>
                  <IconContainer color="#CF3131">
                   <span className='label'>DELETE</span>
                   <RemoveRecoIcon className='dark' />
                   <RemoveRecoIconHover className="light" />
                  </IconContainer>
                </div>
            }
          
          </EditSection>
        </MainInfo>
      </Container>
    )
  }else if(mainInfo === 'note'){
    return rowData.havingNotes?
    <IconContainer>
    {rowData.havingNotes && getTaskIcon(header)}
   </IconContainer>
    : ""
  } else {
    return (
      <Container>
        <MainInfo textAlign={textAlign} fontWeight={fontWeight} textSize={textSize}>{mainInfo}</MainInfo >
        {!!additionalInfo && <AdditionalInfo textAlign={textAlign}>{additionalInfo}</AdditionalInfo>
        }
      </Container >
    );
  }

}

export default DoubleDataCell;
