import API from '../../libs/api';
import { showAlert } from '../../actions/radminUploads'


export const GET_RADMIN_EXPORT_REQUEST = 'GET_RADMIN_EXPORT_REQUEST';
export const GET_RADMIN_EXPORT_SUCCESS = 'GET_RADMIN_EXPORT_SUCCESS';
export const GET_RADMIN_EXPORT_FAILURE = 'GET_RADMIN_EXPORT_FAILURE';

export const GET_MESSAGE_EXPORT_REQUEST = 'GET_MESSGAE_EXPORT_REQUEST';
export const GET_MESSAGE_EXPORT_SUCCESS = 'GET_MESSGAE_EXPORT_SUCCESS';
export const GET_MESSAGE_EXPORT_FAILURE = 'GET_MESSGAE_EXPORT_FAILURE';

export const GET_NOTES_EXPORT_REQUEST = 'GET_NOTES_EXPORT_REQUEST';
export const GET_NOTES_EXPORT_SUCCESS = 'GET_NOTES_EXPORT_SUCCESS';
export const GET_NOTES_EXPORT_FAILURE = 'GET_NOTES_EXPORT_FAILURE';

export const GET_EMAILS_EXPORT_REQUEST = 'GET_EMAILS_EXPORT_REQUEST';
export const GET_EMAILS_EXPORT_SUCCESS = 'GET_EMAILS_EXPORT_SUCCESS';
export const GET_EMAILS_EXPORT_FAILURE = 'GET_EMAILS_EXPORT_FAILURE';

export const GET_FAXES_EXPORT_REQUEST = 'GET_FAXES_EXPORT_REQUEST';
export const GET_FAXES_EXPORT_SUCCESS = 'GET_FAXES_EXPORT_SUCCESS';
export const GET_FAXES_EXPORT_FAILURE = 'GET_FAXES_EXPORT_FAILURE';


export const getRadminExports = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_RADMIN_EXPORT_REQUEST, payload : pageNumber });

    if (pageNumber === 0) {
      dispatch({
        type: GET_RADMIN_EXPORT_SUCCESS,
        payload: { items: [], totalItems: 0, next: null, pageNumber: 1 },
      });
      return;
    }

    const { data } = await API.getRequest(
      `/api/v1/export/?page=${pageNumber}&ordering=-created`
    );

    
    dispatch({
      type: GET_RADMIN_EXPORT_SUCCESS,
      payload: {
        items: data.results,
        totalItems: data.count,
        next: data.results.next,
        pageNumber: pageNumber,
      },
    });
  } catch (error) {
    dispatch({ type: GET_RADMIN_EXPORT_FAILURE });
    dispatch(showAlert('danger', 'Error'));

  }
};

export const getExportMessages = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_MESSAGE_EXPORT_REQUEST, payload: pageNumber });
    if (pageNumber === 0) {
      dispatch({
        type: GET_MESSAGE_EXPORT_SUCCESS,
        payload: { items: [], totalItems: 0, next: null, pageNumber: 1 },
      });
      return;
    }

    const { data } = await API.getRequest(
      `/api/v1/message_export/?page=${pageNumber}&ordering=-created`
    );
    let mappedData = data.results.map((item) => {
      return {
        ...item,
        timeRange: `${item.created_greater_than_filter} / ${item.created_less_than_filter}`,
      };
    });
      
      dispatch({
        type: GET_MESSAGE_EXPORT_SUCCESS,
        payload: {
          items: mappedData,
          totalItems: data.count,
          next: data.results.next,
          pageNumber: pageNumber,
        },
      });
  } catch (error) {
    dispatch({ type: GET_MESSAGE_EXPORT_FAILURE });
    dispatch(showAlert("danger", "Error"));
  }
};



export const getExportNotes = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_NOTES_EXPORT_REQUEST, payload: pageNumber });
    if (pageNumber === 0) {
      dispatch({
        type: GET_NOTES_EXPORT_SUCCESS,
        payload: { items: [], totalItems: 0, next: null, pageNumber: 1 },
      });
      return;
    }
    const { data } = await API.getRequest(
      `/api/v1/notes_export/?page=${pageNumber}&ordering=-created`
    );

    let notesData = data.results
    notesData = notesData.map((itm, i) => {
      return {
        ...itm,
        patient_filter: itm?.patient_filter?.name,
        user: itm?.user?.name
      };
    });
    
      dispatch({
        type: GET_NOTES_EXPORT_SUCCESS,
        payload: {
          items: notesData,
          totalItems: data?.count,
          next: data?.results?.next,
          pageNumber: pageNumber,
        },
      });
  } catch (error) {
    dispatch({ type: GET_NOTES_EXPORT_FAILURE });
    dispatch(showAlert("danger", "Error"));
  }
};


export const getExportEmails = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_EMAILS_EXPORT_REQUEST, payload: pageNumber });
    if (pageNumber === 0) {
      dispatch({
        type: GET_EMAILS_EXPORT_SUCCESS,
        payload: { items: [], totalItems: 0, next: null, pageNumber: 1 },
      });
      return;
    }
    const { data } = await API.getRequest(
      `/api/v1/email-export/?page=${pageNumber}&ordering=-created`
    );

    let emailsData = data.results
    emailsData = emailsData.map((itm, i) => {
      return {
        ...itm,
        status_filter: itm.status_filter || 'all',
        user: itm?.user?.name
      };
    });

      
      dispatch({
        type: GET_EMAILS_EXPORT_SUCCESS,
        payload: {
          items: emailsData,
          totalItems: data.count,
          next: data.results.next,
          pageNumber: pageNumber,
        },
      });
  } catch (error) {
    dispatch({ type: GET_EMAILS_EXPORT_FAILURE });
    dispatch(showAlert("danger", "Error"));
  }
};



export const getExportFaxes = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_FAXES_EXPORT_REQUEST, payload: pageNumber });
    if (pageNumber === 0) {
      dispatch({
        type: GET_FAXES_EXPORT_SUCCESS,
        payload: { items: [], totalItems: 0, next: null, pageNumber: 1 },
      });
      return;
    }
    const { data } = await API.getRequest(
      `/api/v1/fax-export/?page=${pageNumber}&ordering=-created`
    );

    let faxesData = data.results
    faxesData = faxesData.map((itm, i) => {
      return {
        ...itm,
        status_filter: itm.status_filter || 'all',
        user: itm?.user?.name
      };
    });
      
      dispatch({
        type: GET_FAXES_EXPORT_SUCCESS,
        payload: {
          items: faxesData,
          totalItems: data?.count,
          next: data?.results?.next,
          pageNumber: pageNumber,
        },
      });
  } catch (error) {
    dispatch({ type: GET_FAXES_EXPORT_FAILURE });
    dispatch(showAlert("danger", "Error"));
  }
};