import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "../../../styles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import LoadMoreButton from "../../../components/LoadMoreButton";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import { ReactComponent as EmptyStateImg } from "../../../assets/empty-state-search.svg";
import { ReactComponent as DownArrow } from "../../../assets/dropdown-outline-white.svg";
import { ReactComponent as StatusIcon } from "../../../assets/statusIcon.svg";
import filterOutline from "../../../assets/IO-icons/filter-outline.svg";
import close from "../../../assets/cancel-outline-chip.svg";
import MenuIcon from "@material-ui/icons/Menu";

import Box from "../../../components/_styles/Box";
import {
  getRecommendationsCohortWithId,
  getRecommendationsCountWithCohortId,
  getRecommendationsWithCohortId,
  deleteRecommendationInCohort,
  validateRecommendationInCohort,
  getTasksWithCohortId,
  markAsComplete,
  markAsCompleteAll,
  getCommunicationStepList,
  updateRecommendationCommunicationStage,
  handleSnooze,
  deleteTask,
  changeActionStatusRecoCohort,
  clearRecommendationsCohortWithId,
  updateRecommendationTasksCommunicationStage,
} from "../../../actions/radminUploads";
import { makeStyles } from "@material-ui/core/styles";
import { showAlert } from "../../../actions/radminUploads";
import Table from "../../../components/Table/Table";
import DoubleDataCell from "../PatientList/LastScanCell";
import { useTable } from "../../../components/Table/useTable";
import { Link } from "react-router-dom";
import ColumnFilterDropdown from "../FollowUpList/ColumnFilterDropdown";
import { ReactComponent as RemoveRecoIcon } from "../../../assets/FollowUpDeleteIcon.svg";
import { ReactComponent as RemoveRecoIconHover } from "../../../assets/delete-hover.svg";
import RemoveConfirmation from "./RemoveConfirmation";
import { ReactComponent as AddTaskIcon } from "../../../assets/add-task-icon.svg";
import { ReactComponent as AddTaskIconHover } from "../../../assets/add-task-icon-hover.svg";

import { ReactComponent as ValidateIcon } from "../../../assets/validate-icon.svg";
import { ReactComponent as ValidateIconHover } from "../../../assets/validate-icon-hover.svg";

import { ReactComponent as TaskListIcon } from "../../../assets/task-list-icon.svg";
import { ReactComponent as TaskListIconHover } from "../../../assets/task-list-icon-hover.svg";

import { ReactComponent as SnoozeIcon } from "../../../assets/snooze-icon.svg";
import { ReactComponent as SnoozeIconHover } from "../../../assets/snooze-icon-hover.svg";

import { ReactComponent as OverdueIcon } from "../../../assets/overdue-icon.svg";
import { ReactComponent as EditRecoIconHover } from "../../../assets/edit-icon-hover.svg";
import { ReactComponent as EditRecoIcon } from "../../../assets/editRecoIcon.svg";
import { ReactComponent as ConditionalIcon } from "../../../assets/conditional-icon.svg";
import { ReactComponent as ConditionalIconHover } from "../../../assets/conditional-icon-hover.svg";
import { ReactComponent as FindingsBoiIcon } from "../../../assets/findings-boi-icon.svg";
import { ReactComponent as FindingsBoiIconHover } from "../../../assets/findings-boi-icon-hover.svg";
import { ReactComponent as EditIcon } from "../../../assets/edit-icon-pencil.svg";
import { ReactComponent as CustomTabIcon } from "../../../assets/custom-tab-icon.svg";

import { BackNavigateIcon } from "./Icons";

import CreateTask from "./CreateTask";
import ViewRecoTask from "./ViewRecoTask";
import moment from "moment";
import { useFormik } from "formik";
import { ReactComponent as Search } from "../../../assets/searchIconGrey.svg";
import AddContactStep from "./AddContactStep";
import Popover from "@material-ui/core/Popover";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Spinner } from "react-bootstrap";
import EditTask from "./EditTask";
import { Arrow } from "../FollowUpList/Icons";
import { capitalize } from "lodash";
import { ReactComponent as DropdownDown } from "../../../assets/dropdown-outline-white.svg";
import ActionStatusList from "../FollowUpList/ActionStatusList";
import Flex from "../../../components/_styles/Flex";
import { Filters, RecoFilters } from "./Filters";
import { ClickAwayListener } from "@material-ui/core";
import { parseQuery } from "./util";
import DescriptionChipGroups from "./DescriptionChipGroups";
const cancelIcon = `${process.env.PUBLIC_URL}/${"cancel-circled.svg"}`;

const ArrowContainer = styled.span`
  margin-left: 10px;
  color: #252733;
`;

const ChangeButtonTitle = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #252733;
`;

const ChangeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 137px;
  height: 32px;
  background: #fff;
  border: 1px solid #c3c3c3;
  box-shadow: 0px 2px 3px rgba(194, 204, 217, 0.4);
  border-radius: 3px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

const ActionList = styled.ul`
  padding: 0;
  right: 0;
  top: 40px;
  z-index: 2;
  list-style: none;
  position: absolute;
`;

const Row = styled.div`
  display: flex;
  gap: ${(props) => props.gap || "10px"};
  justify-content: ${(props) => props.justifyContent || "space-between"};
`;

const SelectedItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  min-width: fit-content;
  height: 32px;
  margin-top: 2px;
  padding: 4px 10px 4px 10px;
  background: #eeeeee;
  border-radius: 25px;
  @media (max-width: 768px) {
    width: 120px;
  }
`;

const SelectedItem = styled.div`
  margin: 7px 3px 6px 8px;
  min-width: 50px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color: #a1a1a1;
`;

const CloseSign = styled.div`
  width: 7px;
  height: 7px;
  color: #00aeef;
  margin-right: 7px;
  opacity: 1;
  &:hover {
    cursor: pointer;
  }
`;

const LoadingLabel = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #00aeef;
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 26px;
  margin-bottom: 53px;
  gap: 5px;
`;

const Description = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
  text-align: initial;
  color: #000000;
  margin: 0px;
`;

const DescriptionValue = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-size: 14px;
  text-align: initial;
  color: #000000;
  margin: 0px;
  display: flex;
  gap: 15px;
  fex-wrap: wrap;
`;

const TabsContainer = styled.ul`
  position: relative;
  left: -24px;
  bottom: 0px;
  display: flex;
  list-style: none;
  margin-bottom: 0px;
`;

const Tab = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding: 5px 28px 5px 31px;
  border: ${(props) => (props.bg ? "none" : "1px solid #c3c3c3")};
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #fff;
  font-size: 12px;
  background-color: ${(props) => props.bg ?? "white"};
  font-weight: ${(props) => (props.isActive ? 700 : 400)};
  font-style: normal;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3px;
  white-space: nowrap;
  color: #000000;
  cursor: pointer;
  ${(props) => {
    if (props.isActive) {
      return `border-bottom: none;
    top: -2.5px;
    transform: scaley(1.2);
      `;
    }
  }}
  &:hover {
    border-bottom: none;
    top: -2.5px;
    transform: scaley(1.2);
  }
  &:before {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
  }
`;

const ContactStepTab = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding: 5px 28px 5px 31px;
  background-color: ${(props) => props.bg};
  font-size: 12px;
  font-weight: ${(props) => (props.isActive ? 700 : 400)};
  font-style: normal;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3px;
  white-space: nowrap;
  color: #000000;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
  }
`;

const ApplyButton = styled.button`
  width: 100%;
  height: 38px;
  background-color: #00aeef;
  color: #ffffff;
  opacity: ${(props) => (props.disabled ? 0.65 : 1)};
  border-radius: 4px;
  border: 2px solid #00aeef;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  margin-top: 30px;
  &:hover {
    background: transparent;
    color: #00aeef;
  }
`;

const RadioBtn = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 50%;
  border-color: ${(props) => props.borderColor};
  background-color: ${(props) => props.bg};
  padding: 1% !important;
  background-clip: content-box;
  margin-right: 7px;
  cursor: pointer;
`;

const RadioLabel = styled.label`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 0px;
`;

const RadioContainer = styled.div`
  width: 247px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 4px;
`;

const IconContainerBox = styled.span`
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;

  svg {
    position: absolute;
    top: 0px;
    left: 0;
    transition: opacity 0.1s ease;
  }

  svg.dark {
    opacity: 1;
  }

  svg.light {
    opacity: 0;
  }

  &:hover svg.dark {
    opacity: 0;
  }

  &:hover svg.light {
    opacity: 1;
  }

  .label {
    display: none;
    font-size: 9px;
    font-weight: 700;
    color: ${(props) => props.color};
  }

  &:hover .label {
    display: block;
    position: absolute;
    top: ${(props) => props.startGap || "-40%"};
    line-height: 12px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const useStyles = makeStyles({
  label: {
    padding: "5px 0px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  paper: {},
  arrow: {
    border: "2px solid #A764C6",
    backgroundColor: "#E9D8F1",
    overflowX: "unset",
    overflowY: "unset",
    boxShadow: "none",
    "&::before": {
      content: '""',
      position: "absolute",
      marginRight: "-0.71em",
      bottom: 0,
      right: 0,
      width: 18,
      height: 18,
      backgroundColor: "#E9D8F1",
      left: "0px",
      top: "50%",
      border: "2px solid #A764C6",
      transform: "translate(-50%, -50%) rotate(225deg)",
      clipPath:
        "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
    },
  },
});

const HeadingLabel = styled.h1`
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  color: #0c2945;
  margin-bottom: 0px;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const SearchIcon = styled.i`
  display: inline-block;
  position: absolute;
  left: 10px;
  color: green;
  min-width: 40px;
  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;
const CustomPlaceholder = styled.span`
  position: absolute;
  left: 30px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #666666;
  @media (max-width: 768px) {
    margin-left: 60px;
  }
`;
const Input = styled.input`
  width: 484px;
  background: white;
  border: 1px solid #d2d3d7;
  border-radius: 5px;
  height: 38px;
  font: normal 16px "Roboto Condensed", sans-serif;
  padding-left: 35px;
`;

const ClearButton = styled.button`
  position: absolute;
  right: 10px;
  border-radius: 5%;
  z-index: 1;
  border: none;
  cursor: pointer;
  color: #000000;
  background-color: transparent;
  transform: translateX(2px);
`;

const AddContactStepBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #00aeef;
  height: 38px;
  width: 145px;
  color: #00aeef;
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  color: #00aeef;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
`;

const Image = styled.img`
  display: block;
  width: 20px;
`;

const FilterButton = styled(Flex)`
  width: 41px;
  height: 38px;
  justify-content: space-around;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #00aeef;
  background: #fff;
  cursor: pointer;
  border: 1px solid #dfe0eb;
  border-radius: 4px;
`;

const Badge = styled(Flex)`
  width: fit-content;
  min-width: 54px;
  padding: 0 5px;
  height: 24px;
  cursor: pointer;
  align-items: center;
  text-transform: capitalize;
  justify-content: center;
  margin: 0 auto;
  border-radius: 100px;
  font-size: 11px;
  font-weight: 600;
  font-family: Open Sans;
  white-space: nowrap;
  padding: 5px 16px 5px 16px;
  color: white;
  background: ${(props) => {
    switch (props.status) {
      case "coming_due":
        return "#FFD57C";
      case "acknowledged":
        return "#00AEEF";
      case "overdue":
        return "#F73B3B";
      case "unsubscribed":
        return "#F73B3B";
      case "unresponsive":
        return "#808080";
      case "needs_prior_auth":
        return "#DD6ECD";
      case "needs_prescription":
        return "#FF5F65";
      case "non_compliant":
        return "#6f42c1";
      case "rx_received":
        return "#186EF0";
      case "navigator_closed":
        return "#A7A9AD";
      case "completed_elsewhere":
        return "#6F52ED";
      case "patient_deceased":
        return "#2E2F30";
      case "scheduled_not_completed":
        return "#FF7A00";
      case "not_clinically_necessary":
        return "#FFD57C";
      case "adherent":
        return "#64C67C";
      case "non_mature":
        return "#DFE0EB";
      case "expired":
        return "#6F42C1";
      case "Not available":
        return "none";
      default:
        return "#00AEEF";
    }
  }};
`;

const StatusBadge = ({ status, onClick, styles = {}, dropDown = true }) => (
  <Badge onClick={onClick} status={status} style={styles}>
    {status?.replaceAll("_", " ")}
    {!dropDown &&
      (status === "adherent" ||
        status === "scheduled_not_completed" ||
        status === "rx_received") && (
        <StatusIcon style={{ marginLeft: "10px" }} />
      )}
    {dropDown && <DownArrow style={{ marginLeft: "10px" }} />}{" "}
  </Badge>
);

const StatusesContainer = styled.div`
  cursor: pointer;
  border-radius: 8px;
`;

const toUpperCase = (str) => (str ? str.toUpperCase() : "");

const colors = [
  "#FF6B6B",
  "#FFD93D",
  "#9BDE7A",
  "#6495ED",
  "#FF69B4",
  "#9370DB",
  "#00CED1",
  "#FF8C00",
  "#7FFFD4",
  "#8B4513",
];

function ViewRecommendations() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const node = useRef();

  const query = new URLSearchParams(history.location.search);

  let search = query.get("search") ?? "";

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showMoreTabs, setShowMoreTabs] = useState(false);

  const [showRecoFilters, setShowRecoFilters] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const [anchorElStatus, setAnchorElStatus] = React.useState(null);

  const openStatus = Boolean(anchorElStatus);
  const popupId = openStatus ? "simple-popover" : undefined;

  const [openedRow, setOpenedRow] = useState();

  const [selectedRecommendationStatus, setSelectedRecommendationStatus] =
    useState("");

  const cohortWithId = useSelector(
    (state) => state.radAdmin.cohortsRecommendations.cohortWithId.data
  );

  const cohortsRecommendations = useSelector(
    (state) => state.radAdmin?.cohortsRecommendations?.recommendations
  );
  const communicationStepList = useSelector(
    (state) =>
      state.radAdmin?.cohortsRecommendations?.communicationOptions?.data
  );

  const tasksData = useSelector(
    (state) => state.radAdmin?.cohortsRecommendations?.tasks
  );

  const [page, setPage] = useState(1);
  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const updatePermission = rolePermissions
    ?.find((itm) => itm.name === "Recommendation Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "change_recommendationcohorts"
    )?.is_authorized;

  const addPermission = rolePermissions
    ?.find((itm) => itm.name === "Recommendation Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "add_recommendationcohorts"
    )?.is_authorized;

  const deletePermission = rolePermissions
    ?.find((itm) => itm.name === "Recommendation Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "delete_recommendationcohorts"
    )?.is_authorized;

  const location = useLocation();
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRecRowId, setSelectedRecRowId] = useState("");
  const [selectedRecTaskRowId, setSelectedRecTaskRowId] = useState("");
  const [existingCommunicationStage, setExistingCommunicationStage] =
    useState("");

  const [countsList, setCountsList] = useState([]);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [removeTaskDialogOpen, setRemoveTaskDialogOpen] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [isLoadingDeleteRecommendation, setIsLoadingDeleteRecommendation] =
    useState(false);
  const [isLoadingDeleteTask, setIsLoadingDeleteTask] = useState(false);
  const [isLoadingValidateRecommendation, setIsLoadingValidateRecommendation] =
    useState(false);
  const [activeTab, setActiveTab] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get("type") || "to_be_validated";
  });
  const [activeTabId, setActiveTabId] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get("tabId") || "";
  });
  const [openCreateTask, setOpenCreateTask] = useState(false);
  const [openEditTask, setOpenEditTask] = useState(false);

  const [openViewRecoTask, setOpenViewRecoTask] = useState(false);
  const [openTaskData, setOpenTaskData] = useState({});
  const [openEditTaskData, setOpenEditTaskData] = useState({});

  const [opened, setOpened] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const inputRef = useRef(null);

  const open = Boolean(anchorEl);
  const communicationPopoverId = open ? "simple-popover" : undefined;

  const [snoozeAnchorEl, setSnoozeAnchorEl] = React.useState(null);
  const [selectedTaskRow, setSelectedTaskRow] = useState();

  const openSnoozePopovers = Boolean(snoozeAnchorEl);
  const snoozePopoverId = openSnoozePopovers
    ? "simple-popover-snooze"
    : undefined;

  const [selectedOption, setSelectedOption] = useState("7 days");
  const [customDate, setCustomDate] = useState(null);
  const [customOptionVisible, setCustomOptionVisible] = useState(false);
  const [loadingSnoozing, setLoadingSnoozing] = useState(false);
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);

  const tableRefVal = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [visibleTabs, setVisibleTabs] = useState([]);
  const [overflowTabs, setOverflowTabs] = useState([]);
  const containerRef = useRef(null);
  const tabRefs = useRef([]);
  const [tabsFitCount, setTabsFitCount] = useState(0);

  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const intervalRef = useRef(null);

  const [nextChevronLoading, setNextChevronLoading] = useState({
    loading: true,
    value: null,
  });
  const [requiredIndex, setRequiredIndex] = useState();

  const timeoutRef = useRef(null);
  const { parents, operators } = parseQuery(cohortWithId?.description);

  const closeSelected = (category, selectedItem) => {
    setPage(1);
    const filters = selectedFilters[category].filter((item) => {
      return item.value !== selectedItem.value;
    });
    let newFilters = {
      ...selectedFilters,
      [category]: filters.length > 0 ? filters : [],
    };
    setSelectedFilters(newFilters);

    let params = new URLSearchParams(history.location.search);

    params.delete("communication-step");
    params.delete("task");

    if (newFilters?.["communication-step"]?.length > 0) {
      newFilters["communication-step"].forEach((itm) => {
        params.append("communication-step", itm.value);
      });
    }
    if (newFilters?.["task"]?.length > 0) {
      newFilters["task"].forEach((itm) => {
        params.append("task", itm.value);
      });
    }
    if (activeTab === "to_be_validated" || activeTab === "validated") {
      dispatch(
        getRecommendationsWithCohortId(
          id,
          page,
          activeTab,
          search,
          newFilters,
          "null"
        )
      );
    } else if (
      activeTab === "not_completed_tasks" ||
      activeTab === "completed_tasks"
    ) {
      dispatch(
        getTasksWithCohortId(
          id,
          page,
          activeTab === "not_completed_tasks" ? false : true,
          null,
          newFilters
        )
      );
    } else {
      dispatch(
        getRecommendationsWithCohortId(
          id,
          page,
          "validated",
          search,
          newFilters,
          activeTabId
        )
      );
    }
    dispatch(getRecommendationsCountWithCohortId(id, search, newFilters)).then(
      (res) => {
        if (res) {
          setCountsList(res);
        }
      }
    );
    setPage(1);

    history.replace({
      search: params.toString(),
    });
  };

  const handleCloseStatus = () => {
    setAnchorElStatus(null);
    setOpenedRow();
  };

  const handleClickStatus = (event, id) => {
    setAnchorElStatus(event.currentTarget);
    setOpenedRow(id);
  };

  const validateRecosColumnData = [
    {
      key: "patientName",
      name: "Patient",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "patientMRN",
      name: "Patient MRN",
      displayCell: (value, cellValue) => (
        <StyledLink
          to={`/auth/radAdmin/followUpList/patients/patientDetails/${cellValue.patientId}`}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
            window.analytics.track(
              "Recommendation-Task-List-Patient-MRN-Click",
              {
                patientId: cellValue.patientId,
              }
            );
          }}
        >
          {value}
        </StyledLink>
      ),
    },
    {
      key: "accessionNumber",
      name: "Accession Number",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "conditional",
      name: "Conditional",
      displayCell: (cellValue, rowData) =>
        cellValue ? (
          <IconContainerBox color="#B44FCE">
            <span className="label">CONDITIONAL</span>
            <ConditionalIcon className="dark" />
            <ConditionalIconHover className="light" />
          </IconContainerBox>
        ) : (
          "-"
        ),
    },
    {
      key: "boi",
      name: "Structured Findings",
      displayCell: (cellValue, rowData) =>
        cellValue ? (
          <IconContainerBox color="#FF9900">
            <span className="label">STRUCTURED FINDINGS</span>
            <FindingsBoiIcon className="dark" />
            <FindingsBoiIconHover className="light" />
          </IconContainerBox>
        ) : (
          "-"
        ),
    },
    {
      key: "examType",
      name: "Test Type",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "recommendationType",
      name: "Recommendation Type",
      displayCell: (cellValue) => {
        let displayText;
        if (cellValue === "imaging_followup") {
          displayText = "Imaging";
        } else if (cellValue === "non_imaging_followup") {
          displayText = "Non-imaging";
        } else {
          displayText = cellValue;
        }
        return <DoubleDataCell mainInfo={displayText} />;
      },
    },
    {
      key: "contrast",
      name: "Contrast",

      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={cellValue ?? "-"} />
      ),
    },
    {
      key: "initialExamDate",
      name: "Exam Date",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "examCode",
      name: "Exam Code",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "status",
      name: "Status",
      displayCell: (status, row) => (
        <>
          <StatusBadge
            onClick={(e) => handleClickStatus(e, row.id)}
            status={status}
          />
        </>
      ),
    },
    {
      key: "range_start",
      name: "Min Due Date",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "range_end",
      name: "Max Due Date",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "anatomy",
      name: "Reco Anatomy",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue)} />
      ),
    },
    {
      key: "modality",
      name: "Reco Modality",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue)} />
      ),
    },
    {
      key: "communication_stage",
      name: "Communication Stage",
      displayCell: (cellValue, rowData) => {
        return (
          <div
            onClick={(e) =>
              handleClick(e, rowData.id, null, rowData?.communication_stage)
            }
            style={{
              border: rowData?.communication_stage
                ? colors[rowData?.communication_stage?.id % 10]
                : "2px solid #9FA2B4",
              borderRadius: "20px",
              padding: "5px, 12px, 5px, 12px",
              height: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              color: rowData?.communication_stage ? "white" : "#9FA2B4",
              fontWeight: 600,
              fontSize: "11px",
              gap: "10px",
              whiteSpace: "nowrap",
              padding: "10px",
              background: rowData?.communication_stage
                ? colors[rowData?.communication_stage?.id % 10]
                : "initial",
            }}
          >
            {rowData?.communication_stage ? (
              <>
                <span> {rowData?.communication_stage?.name} </span>
                <DropdownDown />
              </>
            ) : (
              <>
                <span>add </span>
                <Arrow />
              </>
            )}
          </div>
        );
      },
    },
    {
      key: "guidelineType",
      name: "Guideline Type",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue)} />
      ),
    },
    {
      key: "timeframe_status_extracted",
      name: "Timeframe",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue)} />
      ),
    },
  ];
  const validatedRecosColumnData = [
    {
      key: "patientName",
      name: "Patient",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "patientMRN",
      name: "Patient MRN",
      displayCell: (value, cellValue) => (
        <StyledLink
          to={`/auth/radAdmin/followUpList/patients/patientDetails/${cellValue.patientId}`}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
            window.analytics.track(
              "Recommendation-Task-List-Patient-MRN-Click",
              {
                patientId: cellValue.patientId,
              }
            );
          }}
        >
          {value}
        </StyledLink>
      ),
    },
    {
      key: "accessionNumber",
      name: "Accession Number",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "conditional",
      name: "Conditional",
      displayCell: (cellValue, rowData) =>
        cellValue ? (
          <IconContainerBox color="#B44FCE">
            <span className="label">CONDITIONAL</span>
            <ConditionalIcon className="dark" />
            <ConditionalIconHover className="light" />
          </IconContainerBox>
        ) : (
          "-"
        ),
    },
    {
      key: "boi",
      name: "Structured Findings",
      displayCell: (cellValue, rowData) =>
        cellValue ? (
          <IconContainerBox color="#FF9900">
            <span className="label">STRUCTURED FINDINGS</span>
            <FindingsBoiIcon className="dark" />
            <FindingsBoiIconHover className="light" />
          </IconContainerBox>
        ) : (
          "-"
        ),
    },
    {
      key: "examType",
      name: "Test Type",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "recommendationType",
      name: "Recommendation Type",
      displayCell: (cellValue) => {
        let displayText;
        if (cellValue === "imaging_followup") {
          displayText = "Imaging";
        } else if (cellValue === "non_imaging_followup") {
          displayText = "Non-imaging";
        } else {
          displayText = cellValue;
        }
        return <DoubleDataCell mainInfo={displayText ?? "-"} />;
      },
    },
    {
      key: "contrast",
      name: "Contrast",

      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={cellValue ?? "-"} />
      ),
    },
    {
      key: "initialExamDate",
      name: "Exam Date",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={cellValue ?? "-"} />
      ),
    },
    {
      key: "examCode",
      name: "Exam Code",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={cellValue ?? "-"} />
      ),
    },
    {
      key: "status",
      name: "Status",
      displayCell: (status, row) => (
        <>
          <StatusBadge
            onClick={(e) => handleClickStatus(e, row.id)}
            status={status}
          />
        </>
      ),
    },
    {
      key: "range_start",
      name: "Min Due Date",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={cellValue ?? "-"} />
      ),
    },
    {
      key: "range_end",
      name: "Max Due Date",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={cellValue ?? "-"} />
      ),
    },
    {
      key: "anatomy",
      name: "Reco Anatomy",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue ?? "-")} />
      ),
    },
    {
      key: "modality",
      name: "Reco Modality",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue ?? "-")} />
      ),
    },
    {
      key: "communication_stage",
      name: "Communication Stage",
      displayCell: (cellValue, rowData) => {
        return (
          <div
            onClick={(e) =>
              handleClick(e, rowData.id, null, rowData?.communication_stage)
            }
            style={{
              border: rowData?.communication_stage
                ? colors[rowData?.communication_stage?.id % 10]
                : "2px solid #9FA2B4",
              borderRadius: "20px",
              padding: "5px, 12px, 5px, 12px",
              height: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              color: rowData?.communication_stage ? "white" : "#9FA2B4",
              fontWeight: 600,
              fontSize: "11px",
              gap: "10px",
              whiteSpace: "nowrap",
              padding: "10px",
              background: rowData?.communication_stage
                ? colors[rowData?.communication_stage?.id % 10]
                : "initial",
            }}
          >
            {rowData?.communication_stage ? (
              <>
                <span> {rowData?.communication_stage?.name} </span>
                <DropdownDown />
              </>
            ) : (
              <>
                <span>add </span>
                <Arrow />
              </>
            )}
          </div>
        );
      },
    },
    {
      key: "guidelineType",
      name: "Guideline Type",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue ?? "-")} />
      ),
    },
    {
      key: "timeframe_status_extracted",
      name: "Timeframe",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={toUpperCase(cellValue ?? "-")} />
      ),
    },
  ];

  const pendingTasksColumnData = [
    {
      key: "type",
      name: "Task Type",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "due_at",
      name: "Due Date",
      displayCell: (cellValue) => (
        <DoubleDataCell
          mainInfo={moment(cellValue).format("MMMM D, YYYY [at] h:mm a")}
        />
      ),
    },
    {
      key: "patientName",
      name: "Patient",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "patientMRN",
      name: "MRN",
      displayCell: (value, cellValue) => (
        <StyledLink
          to={`/auth/radAdmin/followUpList/patients/patientDetails/${cellValue.patient.id}`}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
            window.analytics.track(
              "Recommendation-Task-List-Patient-MRN-Click",
              {
                patientId: cellValue.patient.id,
              }
            );
          }}
        >
          {value}
        </StyledLink>
      ),
    },
    {
      key: "assignedTo",
      name: "Assigned To",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "priority",
      name: "Priority",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={capitalize(cellValue)} />
      ),
    },
    {
      key: "communication_stage",
      name: "Communication Stage",
      displayCell: (cellValue, rowData) => {
        return (
          <div
            onClick={(e) =>
              handleClick(
                e,
                rowData.recommendation,
                rowData.id,
                rowData?.communication_stage
              )
            }
            style={{
              border: rowData?.communication_stage
                ? colors[rowData?.communication_stage?.id % 10]
                : "2px solid #9FA2B4",
              borderRadius: "20px",
              padding: "5px, 12px, 5px, 12px",
              height: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              color: rowData?.communication_stage ? "white" : "#9FA2B4",
              fontWeight: 600,
              fontSize: "11px",
              gap: "10px",
              whiteSpace: "nowrap",
              padding: "10px",
              background: rowData?.communication_stage
                ? colors[rowData?.communication_stage?.id % 10]
                : "initial",
            }}
          >
            {rowData?.communication_stage ? (
              <>
                <span> {rowData?.communication_stage?.name} </span>
                <DropdownDown />
              </>
            ) : (
              <>
                <span>add </span>
                <Arrow />
              </>
            )}
          </div>
        );
      },
    },
  ];

  const completedTasksColumnData = [
    {
      key: "type",
      name: "Task Type",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "due_at",
      name: "Due Date",
      displayCell: (cellValue) => (
        <DoubleDataCell
          mainInfo={moment(cellValue).format("MMMM D, YYYY [at] h:mm a")}
        />
      ),
    },
    {
      key: "patientName",
      name: "Patient",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "patientMRN",
      name: "MRN",
      displayCell: (value, cellValue) => (
        <StyledLink
          to={`/auth/radAdmin/followUpList/patients/patientDetails/${cellValue.patient.id}`}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
            window.analytics.track(
              "Recommendation-Task-List-Patient-MRN-Click",
              {
                patientId: cellValue.patient.id,
              }
            );
          }}
        >
          {value}
        </StyledLink>
      ),
    },
    {
      key: "assignedTo",
      name: "Assigned To",
      displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
    },
    {
      key: "priority",
      name: "Priority",
      displayCell: (cellValue) => (
        <DoubleDataCell mainInfo={capitalize(cellValue)} />
      ),
    },
    {
      key: "communication_stage",
      name: "Communication Stage",
      displayCell: (cellValue, rowData) => {
        return (
          <div
            onClick={(e) =>
              handleClick(
                e,
                rowData.recommendation,
                rowData.id,
                rowData?.communication_stage
              )
            }
            style={{
              border: rowData?.communication_stage
                ? colors[rowData?.communication_stage?.id % 10]
                : "2px solid #9FA2B4",
              borderRadius: "20px",
              padding: "5px, 12px, 5px, 12px",
              height: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              color: rowData?.communication_stage ? "white" : "#9FA2B4",
              fontWeight: 600,
              fontSize: "11px",
              gap: "10px",
              whiteSpace: "nowrap",
              padding: "10px",
              background: rowData?.communication_stage
                ? colors[rowData?.communication_stage?.id % 10]
                : "initial",
            }}
          >
            {rowData?.communication_stage ? (
              <>
                <span> {rowData?.communication_stage?.name} </span>
                <DropdownDown />
              </>
            ) : (
              <>
                <span>add </span>
                <Arrow />
              </>
            )}
          </div>
        );
      },
    },
  ];
  const {
    columnCheckbox: columnCheckboxValidateRecos,
    columnFilterDropdown: columnFilterDropdownValidateRecos,
    columns: columnsValidateRecos,
    handleColumnChange: handleColumnChangeValidateRecos,
    setColumnFilterDropdown: setColumnFilterDropdownValidateRecos,
  } = useTable(validateRecosColumnData);

  const {
    columnCheckbox: columnCheckboxValidatedRecos,
    columnFilterDropdown: columnFilterDropdownValidatedRecos,
    columns: columnsValidatedRecos,
    handleColumnChange: handleColumnChangeValidatedRecos,
    setColumnFilterDropdown: setColumnFilterDropdownValidatedRecos,
  } = useTable(validatedRecosColumnData);

  const {
    columnCheckbox: columnCheckboxPendingTasks,
    columnFilterDropdown: columnFilterDropdownPendingTasks,
    columns: columnsPendingTasks,
    handleColumnChange: handleColumnChangePendingTasks,
    setColumnFilterDropdown: setColumnFilterDropdownPendingTasks,
  } = useTable(pendingTasksColumnData);

  const {
    columnCheckbox: columnCheckboxCompletedTasks,
    columnFilterDropdown: columnFilterDropdownCompletedTasks,
    columns: columnsCompletedTasks,
    handleColumnChange: handleColumnChangeCompletedTasks,
    setColumnFilterDropdown: setColumnFilterDropdownCompletedTasks,
  } = useTable(completedTasksColumnData);

  let storedColsValidateRecos =
    JSON.parse(localStorage.getItem("col-order-validate-reco")) || [];
  const [colsOrderValidateRecos, setColsOrderValidateRecos] = useState(
    storedColsValidateRecos.length > 0
      ? storedColsValidateRecos
      : columnsValidateRecos.map((itm) => itm.key)
  );
  const columnsDataValidateRecos = colsOrderValidateRecos
    .map((itm) => columnsValidateRecos.find((it) => it.key === itm))
    .filter(Boolean);

  let storedColsValidatedRecos =
    JSON.parse(localStorage.getItem("col-order-validated-reco")) || [];
  const [colsOrderValidatedRecos, setColsOrderValidatedRecos] = useState(
    storedColsValidatedRecos.length > 0
      ? storedColsValidatedRecos
      : columnsValidatedRecos.map((itm) => itm.key)
  );
  const columnsDataValidatedRecos = colsOrderValidatedRecos
    .map((itm) => columnsValidatedRecos.find((it) => it.key === itm))
    .filter(Boolean);

  let storedColsPendingTasks =
    JSON.parse(localStorage.getItem("col-order-pending-tasks")) || [];
  const [colsOrderPendingTasks, setColsOrderPendingTasks] = useState(
    storedColsPendingTasks.length > 0
      ? storedColsPendingTasks
      : columnsPendingTasks.map((itm) => itm.key)
  );
  const columnsDataPendingTasks = colsOrderPendingTasks
    .map((itm) => columnsPendingTasks.find((it) => it.key === itm))
    .filter(Boolean);

  let storedColsCompletedTasks =
    JSON.parse(localStorage.getItem("col-order-completed-tasks")) || [];
  const [colsOrderCompletedTasks, setColsOrderCompletedTasks] = useState(
    storedColsCompletedTasks.length > 0
      ? storedColsCompletedTasks
      : columnsCompletedTasks.map((itm) => itm.key)
  );
  const columnsDataCompletedTasks = colsOrderCompletedTasks
    .map((itm) => columnsCompletedTasks.find((it) => it.key === itm))
    .filter(Boolean);

  useEffect(() => {
    const updateDimensions = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!params.get("type")) {
      updateURLParams({ type: activeTab });
    }
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getRecommendationsCohortWithId(id));
      return () => {
        dispatch(clearRecommendationsCohortWithId());
      };
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      const query = new URLSearchParams(history.location.search);
      let search = query.get("search") ?? "";

      let communicationStageFilter;
      let taskFilter;
      if (activeTab === "to_be_validated") {
        communicationStageFilter =
          query.getAll("communication-step")?.map((itm) => ({
            value: itm,
            label: itm,
            category: "communication-step",
          })) || "";

        setSelectedFilters({
          "communication-step": communicationStageFilter,
        });
      }

      if (
        activeTab === "completed_tasks" ||
        activeTab === "not_completed_tasks"
      ) {
        communicationStageFilter =
          query.getAll("communication-step")?.map((itm) => ({
            value: itm,
            label: itm,
            category: "communication-step",
          })) || "";

        taskFilter =
          query.getAll("task")?.map((itm) => ({
            value: itm,
            label: itm,
            category: "task",
          })) || "";

        setSelectedFilters({
          "communication-step": communicationStageFilter,
          task: taskFilter,
        });
      }

      if (search) {
        formik.setFieldValue("searchValue", search);
        setShowPlaceholder(false);
      }
      dispatch(
        getRecommendationsCountWithCohortId(
          id,
          search,
          communicationStageFilter?.length > 0 || taskFilter?.length > 0
            ? {
                "communication-step": communicationStageFilter,
                task: taskFilter,
              }
            : {}
        )
      ).then((res) => {
        if (res) {
          setCountsList(res);
        }
      });
      if (
        activeTab === "not_completed_tasks" ||
        activeTab === "completed_tasks"
      ) {
        dispatch(
          getTasksWithCohortId(
            id,
            page,
            activeTab === "not_completed_tasks" ? false : true,
            null,
            communicationStageFilter?.length > 0 || taskFilter?.length > 0
              ? {
                  "communication-step": communicationStageFilter,
                  task: taskFilter,
                }
              : {}
          )
        );
      } else if (activeTab === "validated" || activeTab === "to_be_validated") {
        dispatch(
          getRecommendationsWithCohortId(
            id,
            page,
            activeTab,
            search,
            communicationStageFilter?.length > 0
              ? {
                  "communication-step": communicationStageFilter,
                }
              : {},
            "null"
          )
        );
      } else {
        dispatch(
          getRecommendationsWithCohortId(
            id,
            page,
            "validated",
            search,
            communicationStageFilter?.length > 0
              ? {
                  "communication-step": communicationStageFilter,
                }
              : {},
            +activeTabId
          )
        );
      }
    }
  }, []);

  useEffect(() => {
    if(communicationStepList?.length > 0){
      setSelectedFilters((prev) => ({
        ...prev,
        ["communication-step"]: prev?.["communication-step"]?.map((itm) => ({
          ...itm,
          label: [
            {
              value: "null",
              name: "--Empty--",
              category: "communication-step",
            },
            ...communicationStepList,
          ]?.find((item) =>
            item.value === "null" ? item.value == itm.value : item.id == itm.value
          )?.name,
        })),
      }));
    }
  }, [communicationStepList]);

  const formik = useFormik({
    initialValues: {
      searchValue: "",
    },
    onSubmit: (values) => {
      dispatch(
        getRecommendationsCountWithCohortId(
          id,
          values.searchValue,
          selectedFilters
        )
      ).then((res) => {
        if (res) {
          setCountsList(res);
        }
      });
      if (activeTab === "validated" || activeTab === "to_be_validated") {
        dispatch(
          getRecommendationsWithCohortId(
            id,
            1,
            activeTab,
            values.searchValue,
            selectedFilters
          )
        );
      } else {
        dispatch(
          getRecommendationsWithCohortId(
            id,
            1,
            "validated",
            values.searchValue,
            selectedFilters,
            activeTabId
          )
        );
      }

      setPage(1);
      updateURLParams({ search: values.searchValue });

      return;
    },
  });

  useEffect(() => {
    if (communicationStepList === null) {
      dispatch(getCommunicationStepList());
    }
  }, []);

  useEffect(() => {
    const table = tableRefVal.current;

    const handleScroll = () => {
      setScrollPosition(table.scrollLeft);
    };

    if (table) {
      table.addEventListener("scroll", handleScroll);
      setScrollPosition(table.scrollLeft);
    }

    return () => {
      if (table) {
        table.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (tableRefVal?.current) {
      setIsAtStart(scrollPosition === 0);
      setIsAtEnd(
        scrollPosition === 0
          ? false
          : Math.abs(scrollPosition + tableRefVal.current.clientWidth - tableRefVal.current.scrollWidth) <= 2
      );
    }
  }, [scrollPosition, tableRefVal.current]);

  useEffect(() => {
    if (
      nextChevronLoading?.loading &&
      nextChevronLoading?.value &&
      !cohortsRecommendations?.isLoading
    ) {
      setOpenTaskData(cohortsRecommendations?.data[nextChevronLoading?.value]);
      setNextChevronLoading({ loading: false, value: null });
    }
  }, [
    nextChevronLoading?.value,
    nextChevronLoading?.loading,
    cohortsRecommendations,
  ]);

  useEffect(() => {
    if (
      (cohortWithId?.status === "in_progress" ||
        cohortWithId?.status === "created") &&
      !loading
    ) {
      setLoading(true);
      setStatus("in_progress");
      startPolling();
    }
  }, [cohortWithId?.status]);

  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  useEffect(() => {
    if (communicationStepList) {
      calculateTabsFit();
    }

    window.addEventListener("resize", calculateTabsFit);

    return () => {
      window.removeEventListener("resize", calculateTabsFit);
    };
  }, [communicationStepList]);

  useEffect(() => {
    const dynamicTabs = communicationStepList?.slice(0, tabsFitCount);
    const remainingTabs = communicationStepList?.slice(tabsFitCount);

    setVisibleTabs(dynamicTabs);
    setOverflowTabs(remainingTabs);
  }, [communicationStepList, tabsFitCount]);

  const dialogHandler = (data) => {
    setRemoveDialogOpen(!removeDialogOpen);
    setSelectedId(data.id);
    window.analytics.track("Recommendation-Delete-Button-Click");
  };

  const dialogHandlerTask = (data) => {
    setRemoveTaskDialogOpen(!removeTaskDialogOpen);
    setSelectedId(data.id);
    window.analytics.track("Task-Delete-Button-Click");
  };

  const onTabChange = (activeTab, activeTabId, page) => {
    if (id) {
      const query = new URLSearchParams(history.location.search);
      let search = query.get("search") ?? "";

      let communicationStageFilter;
      let taskFilter;
      if (activeTab === "to_be_validated") {
        communicationStageFilter =
          query.getAll("communication-step")?.map((itm) => ({
            value: itm,
            label: itm,
            category: "communication-step",
          })) || "";

        setSelectedFilters({
          "communication-step": communicationStageFilter,
        });
      }

      if (
        activeTab === "completed_tasks" ||
        activeTab === "not_completed_tasks"
      ) {
        communicationStageFilter =
          query.getAll("communication-step")?.map((itm) => ({
            value: itm,
            label: itm,
            category: "communication-step",
          })) || "";

        taskFilter =
          query.getAll("task")?.map((itm) => ({
            value: itm,
            label: itm,
            category: "task",
          })) || "";

        setSelectedFilters({
          "communication-step": communicationStageFilter,
          task: taskFilter,
        });
      }

      if (search) {
        formik.setFieldValue("searchValue", search);
        setShowPlaceholder(false);
      }
      if (
        activeTab === "not_completed_tasks" ||
        activeTab === "completed_tasks"
      ) {
        dispatch(
          getTasksWithCohortId(
            id,
            page,
            activeTab === "not_completed_tasks" ? false : true,
            null,
            communicationStageFilter?.length > 0 || taskFilter?.length > 0
              ? {
                  "communication-step": communicationStageFilter,
                  task: taskFilter,
                }
              : {}
          )
        );
      } else if (activeTab === "validated" || activeTab === "to_be_validated") {
        dispatch(
          getRecommendationsWithCohortId(
            id,
            page,
            activeTab,
            search,
            communicationStageFilter?.length > 0
              ? {
                  "communication-step": communicationStageFilter,
                }
              : {},
            "null"
          )
        );
      } else {
        dispatch(
          getRecommendationsWithCohortId(
            id,
            page,
            "validated",
            search,
            communicationStageFilter?.length > 0
              ? {
                  "communication-step": communicationStageFilter,
                }
              : {},
            +activeTabId
          )
        );
      }
    }
  };

  const onLoadMore = () => {
    if (id) {
      const query = new URLSearchParams(history.location.search);

      let search = query.get("search") ?? "";
      if (activeTab === "validated" || activeTab === "to_be_validated") {
        dispatch(
          getRecommendationsWithCohortId(
            id,
            page + 1,
            activeTab,
            search,
            selectedFilters
          )
        );
      } else {
        dispatch(
          getRecommendationsWithCohortId(
            id,
            page + 1,
            "validated",
            search,
            selectedFilters,
            activeTabId
          )
        );
      }

      setPage(page + 1);
    }
  };

  const onLoadMoreTasks = () => {
    if (id) {
      dispatch(
        getTasksWithCohortId(
          id,
          page + 1,
          activeTab === "not_completed_tasks" ? false : true,
          null,
          selectedFilters
        )
      );
      setPage(page + 1);
    }
  };

  const deleteRecommendationHandler = () => {
    setIsLoadingDeleteRecommendation(true);
    const query = new URLSearchParams(history.location.search);

    let search = query.get("search") ?? "";

    dispatch(
      deleteRecommendationInCohort(
        selectedId,
        page,
        id,
        search,
        activeTab,
        activeTab === "to_be_validated"
          ? countsList?.to_be_validated_reco ===
              cohortsRecommendations?.data?.length
          : activeTab === "validated"
          ? countsList?.validated_reco?.["empty"] ===
            cohortsRecommendations?.data?.length
          : countsList?.validated_reco?.[activeTab] ===
            cohortsRecommendations?.data?.length
      )
    ).then((res) => {
      setIsLoadingDeleteRecommendation(false);
      if (res) {
        setRemoveDialogOpen(false);

        dispatch(
          getRecommendationsCountWithCohortId(id, search, selectedFilters)
        ).then((res) => {
          if (res) {
            setCountsList(res);
          }
        });
      }
    });
  };

  const deleteTaskHandler = () => {
    setIsLoadingDeleteTask(true);
    dispatch(
      deleteTask(
        selectedId,
        page,
        id,
        activeTab === "not_completed_tasks"
          ? countsList?.not_completed_task_count === tasksData?.data?.length
          : countsList?.completed_task_count === tasksData?.data?.length
      )
    ).then((res) => {
      setIsLoadingDeleteTask(false);
      if (res) {
        setRemoveDialogOpen(false);

        dispatch(
          getRecommendationsCountWithCohortId(id, search, selectedFilters)
        ).then((res) => {
          if (res) {
            setCountsList(res);
          }
        });
      }
    });
  };

  const validateRecommendationHandler = (recId) => {
    setIsLoadingValidateRecommendation(true);
    const query = new URLSearchParams(history.location.search);

    let search = query.get("search") ?? "";

    dispatch(
      validateRecommendationInCohort(
        recId,
        page,
        id,
        search,
        activeTab,
        activeTab === "to_be_validated"
          ? countsList?.to_be_validated_reco ===
              cohortsRecommendations?.data?.length
          : activeTab === "validated"
          ? countsList?.validated_reco?.["empty"] ===
            cohortsRecommendations?.data?.length
          : countsList?.validated_reco?.[activeTab] ===
            cohortsRecommendations?.data?.length
      )
    ).then((res) => {
      setIsLoadingValidateRecommendation(false);
      if (res) {
        dispatch(
          getRecommendationsCountWithCohortId(id, search, selectedFilters)
        ).then((res) => {
          if (res) {
            setCountsList(res);
          }
        });
      }
    });
  };

  const handleAddTask = (data) => {
    setOpenCreateTask(true);
    setOpenTaskData(data);
    setRequiredIndex(
      cohortsRecommendations?.data.findIndex((reco) => reco.id === data.id)
    );
  };

  const handleEditTask = (data) => {
    setOpenEditTask(true);
    setOpenEditTaskData(data);
  };

  const handleViewRecoTask = (data) => {
    setOpenViewRecoTask(true);
    setOpenTaskData(data);
  };

  const updateURLParams = (paramsToUpdate) => {
    const params = new URLSearchParams(location.search);

    Object.keys(paramsToUpdate).forEach((key) => {
      if (paramsToUpdate[key]) {
        params.set(key, paramsToUpdate[key]);
      } else {
        params.delete(key);
      }
    });

    history.replace({ search: params.toString() });
  };

  const onHidePlaceholder = () => {
    setShowPlaceholder(false);
  };

  const onShowPlaceholder = () => {
    if (!inputRef.current.value) {
      setShowPlaceholder(true);
    }
  };

  const checkboxHandler = (data, type) => {
    dispatch(
      markAsComplete(
        data.id,
        type,
        page,
        id,
        activeTab === "not_completed_tasks"
          ? countsList?.not_completed_task_count === tasksData?.data?.length
          : countsList?.completed_task_count === tasksData?.data?.length
      )
    ).then((res) => {
      if (res) {
        dispatch(
          getRecommendationsCountWithCohortId(id, null, selectedFilters)
        ).then((res) => {
          if (res) {
            setCountsList(res);
          }
        });
      }
    });
    if (selectedIds.includes(data.id)) {
      const filteredItems = selectedIds.filter((item) => {
        return item !== data.id;
      });
      setSelectedIds([...filteredItems]);
    }
    if (!selectedIds.includes(data.id)) {
      setSelectedIds((prev) => [...prev, data.id]);
    }
  };

  const checkboxHandlerAll = (data) => {
    dispatch(markAsCompleteAll(id, [...data.map((itm) => itm.id)])).then(
      (res) => {
        if (res) {
          dispatch(
            getRecommendationsCountWithCohortId(id, null, selectedFilters)
          ).then((res) => {
            if (res) {
              setCountsList(res);
            }
          });
          if (
            activeTab === "not_completed_tasks" ||
            activeTab === "completed_tasks"
          ) {
            dispatch(
              getTasksWithCohortId(
                id,
                1,
                activeTab === "not_completed_tasks" ? false : true,
                null,
                selectedFilters
              )
            );
            setPage(1);
          }
        }
      }
    );
    if (allSelected) {
      setSelectedIds([]);
    } else {
      setSelectedIds([...data.map((itm) => itm.id)]);
    }
    setAllSelected((prev) => !prev);
  };

  const onResetForm = () => {
    formik.setFieldValue("searchValue", "");
    dispatch(
      getRecommendationsCountWithCohortId(id, null, selectedFilters)
    ).then((res) => {
      if (res) {
        setCountsList(res);
      }
    });
    if (activeTab === "validated" || activeTab === "to_be_validated") {
      dispatch(
        getRecommendationsWithCohortId(id, 1, activeTab, null, selectedFilters)
      );
    } else {
      dispatch(
        getRecommendationsWithCohortId(
          id,
          1,
          "validated",
          null,
          selectedFilters,
          activeTabId
        )
      );
    }
    let params = new URLSearchParams(history.location.search);
    params.delete("search");

    history.replace({
      search: params.toString(),
    });

    setShowPlaceholder(true);
    setPage(1);
  };

  const onClose = () => {
    setOpened({ visibility: false, data: null });
  };

  const handleClick = (event, id, taskId, communicationStage) => {
    setAnchorEl(event.currentTarget);
    setSelectedRecRowId(id);
    setSelectedRecTaskRowId(taskId);
    setExistingCommunicationStage(communicationStage);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRecRowId("");
    setSelectedRecTaskRowId("");
    setExistingCommunicationStage("");
  };

  const handleClickSnooze = (event, row) => {
    setSnoozeAnchorEl(event.currentTarget);
    setSelectedTaskRow(row);
  };

  const handleCloseSnooze = () => {
    setSnoozeAnchorEl(null);
    setSelectedTaskRow();
    setSelectedOption("7 days");
    setCustomDate(null);
    setCustomOptionVisible(false);
  };

  const handleScrollLeftReset = () => {
    const table = tableRefVal.current;
    if (table) {
      table.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    }
  };


  const handleScrollLeft = () => {
    const table = tableRefVal.current;
    if (table) {
      table.scrollTo({
        left: table.scrollLeft - 800,
        behavior: "smooth",
      });
    }
  };

  const handleScrollRight = () => {
    const table = tableRefVal.current;
    if (table) {
      table.scrollTo({
        left: table.scrollLeft + 800,
        behavior: "smooth",
      });
    }
  };

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    if (value !== "Custom") {
    } else {
      setCustomOptionVisible(!customOptionVisible);
    }
  };

  const handleDateChange = (date) => {
    setCustomDate(date);
    setSelectedOption(date ? date.toLocaleString() : "Custom");
    setCustomOptionVisible(true);
  };

  const chevronHandler = (direction, requiredIndex) => {
    if (direction === "forward") {
      if (requiredIndex < cohortsRecommendations?.data.length) {
        setOpenTaskData(cohortsRecommendations?.data[requiredIndex]);
      } else if (
        cohortsRecommendations?.data.length <=
        (activeTab === "to_be_validated"
          ? countsList?.to_be_validated_reco
          : activeTab === "validated"
          ? countsList?.validated_reco?.["empty"]
          : countsList?.validated_reco?.[activeTab])
      ) {
        onLoadMore();
        setNextChevronLoading({ loading: true, value: requiredIndex });
      }
    } else {
      setOpenTaskData(cohortsRecommendations?.data[requiredIndex]);
    }
  };

  const startPolling = () => {
    intervalRef.current = setInterval(checkStatus, 10000);

    // Stop polling after 2 minutes
    timeoutRef.current = setTimeout(() => {
      stopPolling();
      setStatus("timeout");
      setLoading(false);
      dispatch(showAlert("danger", "Error", `Error processing data!`));
    }, 120000);
  };

  const stopPolling = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  };

  const checkStatus = async () => {
    try {
      const response = await dispatch(getRecommendationsCohortWithId(id));
      if (response && response.payload?.status === "completed") {
        stopPolling();
        setStatus("completed");
        setLoading(false);
        setPage(1);
        const query = new URLSearchParams(history.location.search);
        let search = query.get("search") ?? "";
        if (search) {
          formik.setFieldValue("searchValue", search);
          setShowPlaceholder(false);
        }
        dispatch(
          getRecommendationsCountWithCohortId(id, search, selectedFilters)
        ).then((res) => {
          if (res) {
            setCountsList(res);
          }
        });
        dispatch(
          getRecommendationsWithCohortId(
            id,
            1,
            activeTab,
            search,
            selectedFilters
          )
        );
      } else if (response && response.payload?.status === "errored") {
        stopPolling();
        setStatus("errored");
        setLoading(false);
      }
    } catch (error) {
      stopPolling();
      setStatus("errored");
      setLoading(false);
    }
  };

  const handleCommuncationList = () => {
    return communicationStepList
      .filter((itm) => itm.id !== existingCommunicationStage?.id)
      .map((itm, idx) => (
        <span
          key={itm.id}
          style={{
            borderRadius: "30px",
            textAlign: "center",
            padding: "5px 34px",
            cursor: "pointer",
            fontSize: "11px",
            fontWeight: 600,
            color: "#FFFFFF",
            backgroundColor: colors[itm.id % 10],
          }}
          onClick={() => {
            if ((permission && updatePermission) || !permission) {
              if (
                activeTab !== "completed_tasks" &&
                activeTab !== "not_completed_tasks"
              ) {
                dispatch(
                  updateRecommendationCommunicationStage(
                    selectedRecRowId,
                    {
                      communication_stage: itm.id,
                    },
                    itm.name,
                    activeTab
                  )
                ).then((res) => {
                  if (res) {
                    if (activeTab === "to_be_validated") {
                    } else if (activeTab === "validated") {
                      setCountsList((prev) => ({
                        ...prev,
                        validated_reco: {
                          ...prev.validated_reco,
                          empty: prev.validated_reco.empty - 1,
                          [itm.name]: (prev.validated_reco[itm.name] ?? 0) + 1,
                        },
                      }));
                    } else {
                      const targetObj = communicationStepList.find(
                        (itm) => itm.id === +activeTabId
                      )?.name;

                      setCountsList((prev) => ({
                        ...prev,
                        validated_reco: {
                          ...prev.validated_reco,
                          [targetObj]: prev.validated_reco[targetObj] - 1,
                          [itm.name]: (prev.validated_reco[itm.name] ?? 0) + 1,
                        },
                      }));
                    }
                  }
                });
              } else {
                dispatch(
                  updateRecommendationTasksCommunicationStage(
                    selectedRecRowId,
                    {
                      communication_stage: itm.id,
                    },
                    itm.name,
                    selectedRecTaskRowId
                  )
                );
              }

              handleClose();
            } else {
              dispatch(
                showAlert(
                  "danger",
                  "Error",
                  `You don't have permission to update recommendation`
                )
              );
            }
          }}
        >
          {itm.name}
        </span>
      ));
  };

  const calculateTabsFit = () => {
    if (containerRef.current) {
      setVisibleTabs([]);
      setOverflowTabs([]);
      setTabsFitCount(0);
      const containerWidth = containerRef.current.offsetWidth - 137;
      let totalWidth = 0;
      let fitCount = 0;
      const baseTabWidth = 126;

      for (let i = 0; i < 4; i++) {
        totalWidth += tabRefs.current[i].offsetWidth;
      }

      for (let i = 0; i < communicationStepList?.length; i++) {
        if (
          totalWidth +
            (baseTabWidth + communicationStepList[i]?.name?.length * 8) <=
          containerWidth
        ) {
          totalWidth +=
            baseTabWidth + communicationStepList[i]?.name?.length * 8;
          fitCount += 1;
        } else {
          break;
        }
      }

      setTabsFitCount(fitCount);
    }
  };

  const handleTabClick = (item) => {
    if (
      activeTab !== item.name &&
      !cohortsRecommendations?.isLoading &&
      !tasksData?.isLoading
    ) {
      setActiveTab(item.name);
      setActiveTabId(item.id);
      setPage(1);
      if (
        item.name === "completed_tasks" ||
        item.name === "not_completed_tasks"
      ) {
        updateURLParams({
          type: item.name,
          search: "",
          "communication-step": "",
          task: "",
          tabId: item.id ? item.id : "",
        });
        formik.setFieldValue("searchValue", "");
        setShowPlaceholder(true);
      } else {
        updateURLParams({
          type: item.name,
          "communication-step": "",
          task: "",
          tabId: item.id ? item.id : "",
        });
      }

      setSelectedIds([]);
      setAllSelected(false);
      setSelectedFilters({});
      handleScrollLeftReset();
      setScrollPosition(0);
      onTabChange(item.name, item.id, 1);
    }
  };

  return (
    <Box p={30}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginBottom: "20px",
        }}
      >
        {activeTab === "to_be_validated" && (
          <FilterButton onClick={() => setShowRecoFilters(true)}>
            <Image src={filterOutline} alt={"settings icon"} />
          </FilterButton>
        )}
        {activeTab !== "completed_tasks" &&
          activeTab !== "not_completed_tasks" && (
            <>
              <AddContactStepBtn
                onClick={() => {
                  if ((permission && addPermission) || !permission) {
                    if (communicationStepList?.length >= 10) {
                      dispatch(
                        showAlert(
                          "danger",
                          "Error",
                          `Can't add more than 10 contact step!`
                        )
                      );
                    } else {
                      setOpened({ visibility: true, data: null });
                    }
                  } else {
                    dispatch(
                      showAlert(
                        "danger",
                        "Error",
                        `You don't have permission to add contact step`
                      )
                    );
                  }
                }}
              >
                Add Contact Step
              </AddContactStepBtn>
            </>
          )}
        {activeTab !== "not_completed_tasks" &&
          activeTab !== "completed_tasks" && (
            <div>
              <form onSubmit={formik.handleSubmit}>
                <InputContainer>
                  <SearchIcon>
                    <Search />
                  </SearchIcon>
                  {showPlaceholder && (
                    <>
                      <CustomPlaceholder onClick={onHidePlaceholder}>
                        {"Search by Accession Number"}
                      </CustomPlaceholder>
                    </>
                  )}
                  <Input
                    type="text"
                    id={"searchValue"}
                    name={"searchValue"}
                    onFocus={onHidePlaceholder}
                    onBlur={onShowPlaceholder}
                    onChange={formik.handleChange}
                    value={formik.values.searchValue}
                    ref={inputRef}
                  />
                  {!showPlaceholder && formik.values.searchValue && (
                    <ClearButton type={"reset"} onClick={onResetForm}>
                      <img src={cancelIcon} />
                    </ClearButton>
                  )}
                </InputContainer>
              </form>
            </div>
          )}

        {(activeTab === "not_completed_tasks" ||
          activeTab === "completed_tasks") && (
          <>
            <FilterButton onClick={() => setShowRecoFilters(true)}>
              <Image src={filterOutline} alt={"settings icon"} />
            </FilterButton>
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          marginBottom: "20px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          {" "}
          <span
            onClick={() =>
              history.push("/auth/radAdmin/followUp/recommendations/cohorts")
            }
            style={{ cursor: "pointer" }}
          >
            <BackNavigateIcon />
          </span>
          <HeadingLabel>Recommendation Validation</HeadingLabel>
          {loading && <LoadingLabel>Processing...</LoadingLabel>}
        </div>
        <Row
          style={{
            justifyContent: "flex-end",
            marginBottom: "20px",
            gap: "6px",
            flexWrap: "wrap",
          }}
        >
          {Object.values(selectedFilters).map((item, index) => {
            if (!item || !Array.isArray(item)) return null;
            return item.map((i, idx) => (
              <SelectedItemContainer key={index + idx}>
                <SelectedItem>
                  {" "}
                  {i?.category
                    ?.replace(/_/g, " ")
                    ?.replace(/-/g, " ")
                    ?.replace(/\b\w/g, function (char) {
                      return char.toUpperCase();
                    })}{" "}
                  :{" "}
                  {i?.label?.replace(/\b\w/g, function (char) {
                    return char.toUpperCase();
                  })}
                </SelectedItem>
                <CloseSign onClick={() => closeSelected(i.category, i)}>
                  <Image src={close} alt={"close sign"} />
                </CloseSign>
              </SelectedItemContainer>
            ));
          })}
        </Row>
      </div>

      <HeadingWrapper>
        <Description>Cohort Description:</Description>
        <DescriptionValue>
          <DescriptionChipGroups parents={parents} operators={operators} />
        </DescriptionValue>
      </HeadingWrapper>
      <div ref={containerRef} style={{ display: "flex" }}>
        <TabsContainer>
          {/* Fixed Tabs */}
          <Tab
            ref={(el) => (tabRefs.current[0] = el)}
            onClick={() => handleTabClick({ name: "to_be_validated" })}
            isActive={activeTab === "to_be_validated"}
          >
            Validate ({countsList?.to_be_validated_reco ?? 0})
          </Tab>
          <Tab
            ref={(el) => (tabRefs.current[1] = el)}
            onClick={() => handleTabClick({ name: "validated" })}
            isActive={activeTab === "validated"}
          >
            Validated List ({countsList?.validated_reco?.empty ?? 0})
          </Tab>
          <Tab
            ref={(el) => (tabRefs.current[2] = el)}
            onClick={() => handleTabClick({ name: "not_completed_tasks" })}
            isActive={activeTab === "not_completed_tasks"}
          >
            Task List ({countsList?.not_completed_task_count ?? 0})
          </Tab>
          <Tab
            ref={(el) => (tabRefs.current[3] = el)}
            onClick={() => handleTabClick({ name: "completed_tasks" })}
            isActive={activeTab === "completed_tasks"}
          >
            Completed Task List ({countsList?.completed_task_count ?? 0})
          </Tab>

          {/* Dynamic Tabs */}
          {visibleTabs?.map((item, idx) => (
            <Tab
              key={item.id}
              bg={colors[item.id % 10]}
              onClick={() => handleTabClick(item)}
              isActive={activeTab === item.name}
            >
              <CustomTabIcon style={{ marginRight: "8px" }} /> {item.name} (
              {countsList?.validated_reco?.[item?.name] ?? 0})
              <span onClick={() => setOpened({ visibility: true, data: item })}>
                <EditIcon style={{ width: "20px", marginLeft: "8px" }} />
              </span>
            </Tab>
          ))}

          {/* "View More" Dropdown */}
          {overflowTabs?.length > 0 && (
            <>
              <ChangeButton onClick={() => setShowMoreTabs(!showMoreTabs)}>
                <ChangeButtonTitle>{"More Tabs"}</ChangeButtonTitle>
                <ArrowContainer>
                  {screenWidth < 768 ? <MenuIcon /> : <Arrow />}
                </ArrowContainer>
              </ChangeButton>

              {showMoreTabs && (
                <ClickAwayListener onClickAway={() => setShowMoreTabs(false)}>
                  <ActionList>
                    {overflowTabs?.map((item) => (
                      <ContactStepTab
                        key={item.id}
                        bg={colors[item.id % 10]}
                        onClick={() => handleTabClick(item)}
                        isActive={+activeTabId === item.id}
                      >
                        <CustomTabIcon style={{ marginRight: "8px" }} />{" "}
                        {item.name} (
                        {countsList?.validated_reco?.[item?.name] ?? 0})
                        <span
                          onClick={() =>
                            setOpened({ visibility: true, data: item })
                          }
                        >
                          <EditIcon
                            style={{ width: "20px", marginLeft: "8px" }}
                          />
                        </span>
                      </ContactStepTab>
                    ))}
                  </ActionList>
                </ClickAwayListener>
              )}
            </>
          )}
        </TabsContainer>
      </div>
      <Card
        style={{
          overflowX: "auto",
          overflowY: "hidden",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          marginTop: 0,
          left: 30,
          right: 30,
          position: "absolute",
          whiteSpace: "nowrap",
        }}
        ref={tableRefVal}
      >
        {activeTab != "not_completed_tasks" &&
          activeTab != "completed_tasks" && (
            <>
              <Table
                showCustomColor={true}
                showCustomLabel={true}
                customColorKey="source"
                customColorValue={{
                  user: `azure`,
                  nlp: "#FFFFFF",
                }}
                customLabelKey="source"
                customLabelValue={{
                  user: "user-created",
                  nlp: "nlp-created",
                }}
                customLabelColorValue={{
                  user: "#0E5492",
                  nlp: "#4F4F4F",
                }}
                customLabelBackgroundColorValue={{
                  user: "#E1F1FF",
                  nlp: "#F6F6F6",
                }}
                showHorizontalScrollChevrons={
                  openCreateTask ||
                  removeTaskDialogOpen ||
                  removeDialogOpen ||
                  openViewRecoTask ||
                  openEditTask ||
                  open ||
                  openSnoozePopovers ||
                  opened?.visibility ||
                  showRecoFilters ||
                  openStatus ||
                  showMoreTabs
                    ? false
                    : true
                }
                handleScrollLeft={handleScrollLeft}
                handleScrollRight={handleScrollRight}
                isAtStart={isAtStart}
                isAtEnd={isAtEnd}
                shouldGetRowActions={false}
                rows={cohortsRecommendations?.data}
                columns={
                  activeTab === "to_be_validated"
                    ? [
                        {
                          key: "",
                          name: "",
                          displayCell: (cellValue, rowData) => (
                            <div style={{ display: "flex", gap: "20px" }}>
                              <IconContainerBox color="#643268">
                                <EditRecoIcon
                                  className="dark"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (
                                      (permission && addPermission) ||
                                      !permission
                                    ) {
                                      handleAddTask(rowData);
                                    } else {
                                      dispatch(
                                        showAlert(
                                          "danger",
                                          "Error",
                                          `You don't have permission to add task`
                                        )
                                      );
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                                <EditRecoIconHover
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (
                                      (permission && addPermission) ||
                                      !permission
                                    ) {
                                      handleAddTask(rowData);
                                    } else {
                                      dispatch(
                                        showAlert(
                                          "danger",
                                          "Error",
                                          `You don't have permission to add task`
                                        )
                                      );
                                    }
                                  }}
                                  className="light"
                                  style={{ cursor: "pointer" }}
                                />
                              </IconContainerBox>
                              <IconContainerBox color="#643268">
                                <ValidateIcon
                                  className="dark"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (
                                      (permission && updatePermission) ||
                                      !permission
                                    ) {
                                      validateRecommendationHandler(rowData.id);
                                    } else {
                                      dispatch(
                                        showAlert(
                                          "danger",
                                          "Error",
                                          `You don't have permission to validate recommendation`
                                        )
                                      );
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                                <ValidateIconHover
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (
                                      (permission && updatePermission) ||
                                      !permission
                                    ) {
                                      validateRecommendationHandler(rowData.id);
                                    } else {
                                      dispatch(
                                        showAlert(
                                          "danger",
                                          "Error",
                                          `You don't have permission to validate recommendation`
                                        )
                                      );
                                    }
                                  }}
                                  className="light"
                                  style={{ cursor: "pointer" }}
                                />
                              </IconContainerBox>
                              <IconContainerBox color="#643268">
                                <RemoveRecoIcon
                                  className="dark"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (
                                      (permission && updatePermission) ||
                                      !permission
                                    ) {
                                      dialogHandler(rowData);
                                    } else {
                                      dispatch(
                                        showAlert(
                                          "danger",
                                          "Error",
                                          `You don't have permission to delete recommendation`
                                        )
                                      );
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                                <RemoveRecoIconHover
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (
                                      (permission && updatePermission) ||
                                      !permission
                                    ) {
                                      dialogHandler(rowData);
                                    } else {
                                      dispatch(
                                        showAlert(
                                          "danger",
                                          "Error",
                                          `You don't have permission to delete recommendation`
                                        )
                                      );
                                    }
                                  }}
                                  className="light"
                                  style={{ cursor: "pointer" }}
                                />
                              </IconContainerBox>
                            </div>
                          ),
                          enableCheckBox: false,
                          align: "left",
                        },
                      ].concat(columnsDataValidateRecos, {
                        key: "columnFilter",
                        displayName: () => (
                          <ColumnFilterDropdown
                            columnCheckbox={columnCheckboxValidateRecos}
                            columnData={validateRecosColumnData}
                            isOpen={columnFilterDropdownValidateRecos}
                            handleColumnChange={handleColumnChangeValidateRecos}
                            handleColumnFilterDropdown={
                              setColumnFilterDropdownValidateRecos
                            }
                            setColsOrder={setColsOrderValidateRecos}
                            colOrderKey="col-order-validate-reco"
                          />
                        ),
                      })
                    : [
                        {
                          key: "",
                          name: "",
                          displayCell: (cellValue, rowData) => (
                            <div style={{ display: "flex", gap: "20px" }}>
                              <IconContainerBox color="#643268">
                                <AddTaskIcon
                                  className="dark"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (
                                      (permission && addPermission) ||
                                      !permission
                                    ) {
                                      handleAddTask(rowData);
                                    } else {
                                      dispatch(
                                        showAlert(
                                          "danger",
                                          "Error",
                                          `You don't have permission to add task`
                                        )
                                      );
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                                <AddTaskIconHover
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (
                                      (permission && addPermission) ||
                                      !permission
                                    ) {
                                      handleAddTask(rowData);
                                    } else {
                                      dispatch(
                                        showAlert(
                                          "danger",
                                          "Error",
                                          `You don't have permission to add task`
                                        )
                                      );
                                    }
                                  }}
                                  className="light"
                                  style={{ cursor: "pointer" }}
                                />
                              </IconContainerBox>
                              <IconContainerBox color="#643268">
                                <TaskListIcon
                                  className="dark"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleViewRecoTask(rowData);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                                <TaskListIconHover
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleViewRecoTask(rowData);
                                  }}
                                  className="light"
                                  style={{ cursor: "pointer" }}
                                />
                              </IconContainerBox>
                              <IconContainerBox color="#643268">
                                <RemoveRecoIcon
                                  className="dark"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (
                                      (permission && updatePermission) ||
                                      !permission
                                    ) {
                                      dialogHandler(rowData);
                                    } else {
                                      dispatch(
                                        showAlert(
                                          "danger",
                                          "Error",
                                          `You don't have permission to delete recommendation`
                                        )
                                      );
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                                <RemoveRecoIconHover
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (
                                      (permission && updatePermission) ||
                                      !permission
                                    ) {
                                      dialogHandler(rowData);
                                    } else {
                                      dispatch(
                                        showAlert(
                                          "danger",
                                          "Error",
                                          `You don't have permission to delete recommendation`
                                        )
                                      );
                                    }
                                  }}
                                  className="light"
                                  style={{ cursor: "pointer" }}
                                />
                              </IconContainerBox>
                            </div>
                          ),
                          enableCheckBox: false,
                          align: "left",
                        },
                      ].concat(columnsDataValidatedRecos, {
                        key: "columnFilter",
                        displayName: () => (
                          <ColumnFilterDropdown
                            columnCheckbox={columnCheckboxValidatedRecos}
                            columnData={validatedRecosColumnData}
                            isOpen={columnFilterDropdownValidatedRecos}
                            handleColumnChange={
                              handleColumnChangeValidatedRecos
                            }
                            handleColumnFilterDropdown={
                              setColumnFilterDropdownValidatedRecos
                            }
                            setColsOrder={setColsOrderValidatedRecos}
                            colOrderKey="col-order-validated-reco"
                          />
                        ),
                      })
                }
                initialPageNumber={cohortsRecommendations?.pageNumber}
                totalItems={
                  activeTab === "to_be_validated"
                    ? countsList?.to_be_validated_reco
                    : activeTab === "validated"
                    ? countsList?.validated_reco?.["empty"]
                    : countsList?.validated_reco?.[activeTab]
                }
                isLoadingRows={
                  cohortsRecommendations?.isLoading &&
                  cohortsRecommendations?.pageNumber < 2
                }
                noDataRender={() => (
                  <EmptyState
                    title={"No Results"}
                    img={() => <EmptyStateImg />}
                  >
                    Sorry, there are no results
                  </EmptyState>
                )}
              />
              {cohortsRecommendations?.data &&
                cohortsRecommendations?.data?.length !== 0 && (
                  <LoadMoreButton
                    title="Load more reports"
                    handleClick={onLoadMore}
                    isDisabled={
                      activeTab === "to_be_validated"
                        ? countsList?.to_be_validated_reco ===
                          cohortsRecommendations?.data?.length
                        : activeTab === "validated"
                        ? countsList?.validated_reco["empty"] ===
                          cohortsRecommendations?.data?.length
                        : countsList?.validated_reco[activeTab] ===
                          cohortsRecommendations?.data?.length
                    }
                    isLoading={cohortsRecommendations?.isLoading}
                    style={{
                      backgroundColor: "#0c2945",
                      fontWeight: 600,
                    }}
                  />
                )}
            </>
          )}

        {(activeTab === "not_completed_tasks" ||
          activeTab === "completed_tasks") && (
          <>
            <Table
              fromRecoValidation={true}
              showOnlyLabel={activeTab === "completed_tasks" ? true : false}
              checkBoxAtStart={false}
              allSelected={allSelected}
              selectedIds={selectedIds}
              selectAllCb={(rows, e) => {
                e.stopPropagation();
                if ((permission && updatePermission) || !permission) {
                  checkboxHandlerAll(rows);
                } else {
                  dispatch(
                    showAlert(
                      "danger",
                      "Error",
                      `You don't have permission to update task`
                    )
                  );
                }
              }}
              shouldGetRowActions={false}
              rows={tasksData?.data}
              columns={
                activeTab === "not_completed_tasks"
                  ? [
                      {
                        key: "",
                        name: "",
                        displayCell: (cellValue, rowData) => (
                          <div style={{ display: "flex", gap: "20px" }}>
                            <IconContainerBox color="#643268">
                              <EditRecoIcon
                                className="dark"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    (permission && addPermission) ||
                                    !permission
                                  ) {
                                    handleEditTask(rowData);
                                  } else {
                                    dispatch(
                                      showAlert(
                                        "danger",
                                        "Error",
                                        `You don't have permission to edit task`
                                      )
                                    );
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <EditRecoIconHover
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    (permission && addPermission) ||
                                    !permission
                                  ) {
                                    handleEditTask(rowData);
                                  } else {
                                    dispatch(
                                      showAlert(
                                        "danger",
                                        "Error",
                                        `You don't have permission to edit task`
                                      )
                                    );
                                  }
                                }}
                                className="light"
                                style={{ cursor: "pointer" }}
                              />
                            </IconContainerBox>
                            <IconContainerBox color="#643268">
                              <RemoveRecoIcon
                                className="dark"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    (permission && deletePermission) ||
                                    !permission
                                  ) {
                                    dialogHandlerTask(rowData);
                                  } else {
                                    dispatch(
                                      showAlert(
                                        "danger",
                                        "Error",
                                        `You don't have permission to delete task`
                                      )
                                    );
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <RemoveRecoIconHover
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    (permission && deletePermission) ||
                                    !permission
                                  ) {
                                    dialogHandlerTask(rowData);
                                  } else {
                                    dispatch(
                                      showAlert(
                                        "danger",
                                        "Error",
                                        `You don't have permission to delete task`
                                      )
                                    );
                                  }
                                }}
                                className="light"
                                style={{ cursor: "pointer" }}
                              />
                            </IconContainerBox>
                            {moment().isAfter(rowData.due_at) ? (
                              <>
                                <IconContainerBox color="#643268">
                                  <OverdueIcon
                                    className="dark"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        (permission && updatePermission) ||
                                        !permission
                                      ) {
                                        handleClickSnooze(e, rowData);
                                      } else {
                                        dispatch(
                                          showAlert(
                                            "danger",
                                            "Error",
                                            `You don't have permission to update task`
                                          )
                                        );
                                      }
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <OverdueIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        (permission && updatePermission) ||
                                        !permission
                                      ) {
                                        handleClickSnooze(e, rowData);
                                      } else {
                                        dispatch(
                                          showAlert(
                                            "danger",
                                            "Error",
                                            `You don't have permission to update task`
                                          )
                                        );
                                      }
                                    }}
                                    className="light"
                                    style={{ cursor: "pointer" }}
                                  />
                                </IconContainerBox>
                              </>
                            ) : (
                              <>
                                <IconContainerBox color="#643268">
                                  <SnoozeIcon
                                    className="dark"
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      if (
                                        (permission && updatePermission) ||
                                        !permission
                                      ) {
                                        handleClickSnooze(e, rowData);
                                      } else {
                                        dispatch(
                                          showAlert(
                                            "danger",
                                            "Error",
                                            `You don't have permission to update task`
                                          )
                                        );
                                      }
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <SnoozeIconHover
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      if (
                                        (permission && updatePermission) ||
                                        !permission
                                      ) {
                                        handleClickSnooze(e, rowData);
                                      } else {
                                        dispatch(
                                          showAlert(
                                            "danger",
                                            "Error",
                                            `You don't have permission to update task`
                                          )
                                        );
                                      }
                                    }}
                                    className="light"
                                    style={{ cursor: "pointer" }}
                                  />
                                </IconContainerBox>
                              </>
                            )}
                          </div>
                        ),
                        enableCheckBox: false,
                        align: "left",
                      },
                    ].concat(
                      columnsDataPendingTasks,
                      {
                        key: "",
                        name: "",
                        displayCell: (cellValue, rowData) => (
                          <div style={{ display: "flex", gap: "20px" }}>
                            <DoubleDataCell
                              mainInfo={"checkbox"}
                              checkboxHandler={(e) => {
                                e.stopPropagation();
                                if (
                                  (permission && updatePermission) ||
                                  !permission
                                ) {
                                  checkboxHandler(rowData, "complete");
                                } else {
                                  dispatch(
                                    showAlert(
                                      "danger",
                                      "Error",
                                      `You don't have permission to update task`
                                    )
                                  );
                                }
                              }}
                              selectedIds={selectedIds}
                              checked={selectedIds?.includes(rowData?.id)}
                            />
                          </div>
                        ),
                        enableCheckBox: true,
                        align: "left",
                      },
                      {
                        key: "columnFilter",
                        displayName: () => (
                          <ColumnFilterDropdown
                            columnCheckbox={columnCheckboxPendingTasks}
                            columnData={pendingTasksColumnData}
                            isOpen={columnFilterDropdownPendingTasks}
                            handleColumnChange={handleColumnChangePendingTasks}
                            handleColumnFilterDropdown={
                              setColumnFilterDropdownPendingTasks
                            }
                            setColsOrder={setColsOrderPendingTasks}
                            colOrderKey="col-order-pending-tasks"
                          />
                        ),
                      }
                    )
                  : [].concat(
                      columnsDataCompletedTasks,
                      {
                        key: "",
                        name: "",
                        displayCell: (cellValue, rowData) => (
                          <div style={{ display: "flex", gap: "20px" }}>
                            <DoubleDataCell
                              mainInfo={"checkbox"}
                              checkboxHandler={(e) => {
                                e.stopPropagation();
                                if (
                                  (permission && updatePermission) ||
                                  !permission
                                ) {
                                  checkboxHandler(rowData, "incomplete");
                                } else {
                                  dispatch(
                                    showAlert(
                                      "danger",
                                      "Error",
                                      `You don't have permission to update task`
                                    )
                                  );
                                }
                              }}
                              selectedIds={selectedIds}
                              checked={true}
                            />
                          </div>
                        ),
                        enableCheckBox: true,
                        align: "left",
                      },
                      {
                        key: "columnFilter",
                        displayName: () => (
                          <ColumnFilterDropdown
                            columnCheckbox={columnCheckboxCompletedTasks}
                            columnData={completedTasksColumnData}
                            isOpen={columnFilterDropdownCompletedTasks}
                            handleColumnChange={
                              handleColumnChangeCompletedTasks
                            }
                            handleColumnFilterDropdown={
                              setColumnFilterDropdownCompletedTasks
                            }
                            setColsOrder={setColsOrderCompletedTasks}
                            colOrderKey="col-order-completed-tasks"
                          />
                        ),
                      }
                    )
              }
              initialPageNumber={tasksData?.pageNumber}
              totalItems={tasksData?.totalItems}
              isLoadingRows={tasksData?.isLoading && tasksData?.pageNumber < 2}
              noDataRender={() => (
                <EmptyState title={"No Results"} img={() => <EmptyStateImg />}>
                  Sorry, there are no results
                </EmptyState>
              )}
            />
            {tasksData?.data && tasksData?.data?.length !== 0 && (
              <LoadMoreButton
                title="Load more tasks"
                handleClick={onLoadMoreTasks}
                isDisabled={
                  activeTab === "not_completed_tasks"
                    ? countsList?.not_completed_task_count ===
                      tasksData?.data?.length
                    : countsList?.completed_task_count ===
                      tasksData?.data?.length
                }
                isLoading={tasksData?.isLoading}
                style={{
                  backgroundColor: "#0c2945",
                  fontWeight: 600,
                }}
              />
            )}
          </>
        )}
      </Card>

      <RemoveConfirmation
        content="Are you sure you want to remove this recommendation?"
        openDialog={removeDialogOpen}
        dialogHandler={dialogHandler}
        isLoading={isLoadingDeleteRecommendation}
        deleteHandler={deleteRecommendationHandler}
        heading={"Remove Recommendation"}
        removeHeader={"Yes, Remove Recommendation"}
      />

      <RemoveConfirmation
        content="Are you sure you want to remove this task?"
        openDialog={removeTaskDialogOpen}
        dialogHandler={dialogHandlerTask}
        isLoading={isLoadingDeleteTask}
        deleteHandler={deleteTaskHandler}
        heading={"Remove Task"}
        removeHeader={"Yes, Remove Task"}
      />
      {openCreateTask && (
        <CreateTask
          openDialog={openCreateTask}
          editModalHandle={() => {
            setOpenCreateTask(false);
            setOpenTaskData({});
          }}
          selectedData={openTaskData}
          setCountsList={setCountsList}
          cohortId={id}
          activeTab={activeTab}
          setPage={setPage}
          setIsLoadingValidateRecommendation={
            setIsLoadingValidateRecommendation
          }
          chevronHandler={chevronHandler}
          countsList={countsList}
          page={page}
          setRequiredIndex={setRequiredIndex}
          requiredIndex={requiredIndex}
        />
      )}
      {openEditTask && (
        <EditTask
          openDialog={openEditTask}
          editModalHandle={() => {
            setOpenEditTask(false);
            setOpenEditTaskData({});
          }}
          selectedData={openEditTaskData}
          setCountsList={setCountsList}
          cohortId={id}
        />
      )}
      {openViewRecoTask && (
        <ViewRecoTask
          openDialog={openViewRecoTask}
          id={id}
          editModalHandle={() => {
            setOpenViewRecoTask(false);
            setOpenTaskData({});
          }}
          selectedData={openTaskData}
          setCountsList={setCountsList}
        />
      )}

      {opened?.visibility && (
        <AddContactStep
          opened={opened?.visibility}
          onClose={onClose}
          communicationStepList={communicationStepList}
          data={opened?.data}
          setActiveTab={setActiveTab}
          setCountsList={setCountsList}
          activeTab={activeTab}
        />
      )}
      {open && (
        <Popover
          id={communicationPopoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: -10,
            horizontal: "center",
          }}
          PaperProps={{
            style: {
              boxShadow: "none",
              borderRadius: 4,
              border: "2px solid #DFE0EB",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "12px",
              gap: "12px",
              background: "#F7F8FF",
              overflow: communicationStepList.length > 0 ? "auto" : "hidden",
              height: communicationStepList.length > 0 ? "130px" : "50px",
            }}
          >
            {communicationStepList.length > 0 ? (
              handleCommuncationList()
            ) : (
              <span>No Contact Step Added</span>
            )}
          </div>
        </Popover>
      )}
      {openSnoozePopovers && (
        <Popover
          classes={{ paper: classes.arrow }}
          id={snoozePopoverId}
          open={openSnoozePopovers}
          anchorEl={snoozeAnchorEl}
          onClose={handleCloseSnooze}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: -20,
          }}
        >
          <div
            style={{
              padding: "6px 12px 11px 12px",
            }}
          >
            <p style={{ fontSize: "12px", fontWeight: 600 }}>Snooze until...</p>
            <RadioContainer>
              {!moment().isAfter(
                moment(selectedTaskRow.due_at).add(7, "days")
              ) && (
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="7 days"
                    checked={selectedOption === "7 days"}
                    onChange={handleOptionChange}
                    borderColor={
                      selectedOption === "7 days" ? "#00AEEF" : "#A764C6"
                    }
                    bg={selectedOption === "7 days" ? "#00AEEF" : "transparent"}
                  />
                  7 days
                </RadioLabel>
              )}
              {!moment().isAfter(
                moment(selectedTaskRow.due_at).add(30, "days")
              ) && (
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="30 days"
                    checked={selectedOption === "30 days"}
                    onChange={handleOptionChange}
                    borderColor={
                      selectedOption === "30 days" ? "#00AEEF" : "#A764C6"
                    }
                    bg={
                      selectedOption === "30 days" ? "#00AEEF" : "transparent"
                    }
                  />
                  30 days
                </RadioLabel>
              )}
              {!moment().isAfter(
                moment(selectedTaskRow.due_at).add(90, "days")
              ) && (
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="90 days"
                    checked={selectedOption === "90 days"}
                    onChange={handleOptionChange}
                    borderColor={
                      selectedOption === "90 days" ? "#00AEEF" : "#A764C6"
                    }
                    bg={
                      selectedOption === "90 days" ? "#00AEEF" : "transparent"
                    }
                  />
                  90 days
                </RadioLabel>
              )}

              {customOptionVisible && customDate && (
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value={customDate.toLocaleString()}
                    checked={selectedOption === customDate.toLocaleString()}
                    onChange={handleOptionChange}
                    borderColor={
                      selectedOption === customDate.toLocaleString()
                        ? "#00AEEF"
                        : "#A764C6"
                    }
                    bg={
                      selectedOption === customDate.toLocaleString()
                        ? "#00AEEF"
                        : "transparent"
                    }
                  />
                  {customDate.toLocaleString()}
                </RadioLabel>
              )}

              <RadioLabel>
                <RadioBtn
                  type="radio"
                  value="custom"
                  checked={selectedOption === "custom"}
                  onChange={handleOptionChange}
                  borderColor={
                    selectedOption === "custom" ? "#00AEEF" : "#A764C6"
                  }
                  bg={selectedOption === "custom" ? "#00AEEF" : "transparent"}
                />
                Custom Date & Time
              </RadioLabel>
            </RadioContainer>

            {selectedOption === "custom" && (
              <DatePicker
                selected={customDate}
                onChange={handleDateChange}
                showTimeSelect
                dateFormat="Pp"
                placeholderText="Select date and time"
                inline
                minDate={new Date()}
              />
            )}

            <ApplyButton
              disabled={!selectedOption || selectedOption === "custom"}
              onClick={() => {
                setLoadingSnoozing(true);
                dispatch(
                  handleSnooze(
                    selectedTaskRow.id,
                    selectedOption === "7 days"
                      ? moment(selectedTaskRow.due_at).add(7, "days")
                      : selectedOption === "30 days"
                      ? moment(selectedTaskRow.due_at).add(30, "days")
                      : selectedOption === "90 days"
                      ? moment(selectedTaskRow.due_at).add(90, "days")
                      : moment(customDate.toLocaleString())
                  )
                ).then((res) => setLoadingSnoozing(false));
                handleCloseSnooze();
              }}
            >
              {loadingSnoozing ? (
                <Spinner size="sm" animation="border" role="status" />
              ) : (
                "Apply"
              )}
            </ApplyButton>
          </div>
        </Popover>
      )}
      {openStatus && (
        <Popover
          id={popupId}
          open={openStatus}
          anchorEl={anchorElStatus}
          onClose={handleCloseStatus}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          PaperProps={{
            style: {
              borderRadius: "10px",
              border: "2px solid #DFE0EB",
              width: "244px",
              background: "#F7F8FF",
              boxShadow: "none",
              marginTop: "10px",
            },
          }}
        >
          <StatusesContainer ref={node}>
            <ActionStatusList
              selectedStatus={selectedRecommendationStatus}
              id={openedRow}
              handleSelectStatus={(data) => {
                if ((permission && updatePermission) || !permission) {
                  setSelectedRecommendationStatus(data.status);
                  dispatch(
                    changeActionStatusRecoCohort(data.id, data.status)
                  ).then((res) => {
                    handleCloseStatus();
                  });
                } else {
                  dispatch(
                    showAlert(
                      "danger",
                      "Error",
                      `You don't have permission to update recommendation status`
                    )
                  );
                }
              }}
              fromMessage
            />
          </StatusesContainer>
        </Popover>
      )}
      {showRecoFilters && (
        <RecoFilters
          showModal={showRecoFilters}
          setPage={setPage}
          setSelectedFilters={setSelectedFilters}
          selectedFilters={selectedFilters}
          onClose={() => setShowRecoFilters(false)}
          communicationStepList={communicationStepList}
          id={id}
          page={page}
          activeTab={activeTab}
          search={search}
          setCountsList={setCountsList}
        />
      )}
    </Box>
  );
}

export default ViewRecommendations;
