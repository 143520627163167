import React, { forwardRef } from "react";
import { useState } from "react";
import moment from "moment";
import { ClickAwayListener } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import Flex from "../_styles/Flex";
import { ReactComponent as CalendarIcon } from "../../assets/calendar-outline.svg";
import { ReactComponent as RemoveRecoIcon } from "../../assets/close-outline.svg";

const RadioBtn = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 50%;
  border-color: ${(props) => props.borderColor};
  background-color: ${(props) => props.bg};
  padding: 1% !important;
  background-clip: content-box;
  margin-right: 7px;
  cursor: pointer;
`;

const RadioLabel = styled.label`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 0px;
`;

const RadioContainer = styled.div`
  border: 1px solid #c3c3c3;
  width: 230px;
  display: flex;
  flex-direction: column;
  background: white;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 12px;
  border-radius: 4px;
`;

const InitialSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43.5px;
  padding: 9.5px 12px;
  width: 230px;
  background: white;
  border: 1px solid #dfe0eb;
  cursor: pointer;
  border-radius: 5px;
`;

const SelectContainer = styled.div`
  position: relative;
  width: 230px;
`;

const DatePickerContainer = styled(Flex)`
  min-width: 230px;
  margin-right: 6px;
  & > div > div > div {
    height: 43.5px;
    min-width: 230px;
  }
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 8px;
  font-size: 18px;
  width: max-content;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;

const DateContainer = styled(Flex)`
  margin-right: 15px;
  font-size: 12px;
`;

const DateClearButton = styled.div`
  cursor: pointer;
  display: flex;
`;

const SelectDropdownTitle = styled.div`
  margin-bottom: 4px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 9.2px;
  line-height: 17px;
  color: #000000;
  opacity: 0.6;
  text-transform: uppercase;
  position: absolute;
  top: -20px;
`;

const RecoOptions = [
  {
    value: "last7days",
    label: "Last 7 days",
    start: new Date(moment().subtract(7, "days")),
    end: new Date(moment()),
  },
  {
    value: "last30days",
    label: "Last 30 days",
    start: new Date(moment().subtract(30, "days")),
    end: new Date(moment()),
  },
  {
    value: "last3months",
    label: "Last 3 months",
    start: new Date(moment().subtract(3, "months")),
    end: new Date(moment()),
  },
  {
    value: "last6months",
    label: "Last 6 months",
    start: new Date(moment().subtract(6, "months")),
    end: new Date(moment()),
  },
  {
    value: "last12months",
    label: "Last 12 months",
    start: new Date(moment().subtract(12, "months")),
    end: new Date(moment()),
  },
  { value: "custom", label: "Custom date range", start: null, end: null },
];

const ExamDateSelector = ({
  selectedValues,
  setSelectedValues,
  setCurrPageNumber,
}) => {
  const onInitialExamDatesChange = (dates) => {
    const [startDate, endDate] = dates;
    setSelectedValues((prev) => ({
      ...prev,
      initialExamStartDate: startDate,
      initialExamEndDate: endDate,
      initialExamDatesFilter: {
        startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      },
      triggerUpdate: true,
    }));
    setCurrPageNumber(1);
  };

  const handleRadioChange = (value) => {
    let obj = RecoOptions.find((itm) => itm.value === value);
    setSelectedValues((prev) => ({
      ...prev,
      selectedSubOptionExamDate: value,
      initialExamStartDate: obj.start,
      initialExamEndDate: obj.end,
      initialExamDatesFilter: {
        startDate: obj.start ? moment(obj.start).format("YYYY-MM-DD") : "",
        endDate: obj.end ? moment(obj.end).format("YYYY-MM-DD") : "",
      },
      triggerUpdate: value === "custom" ? false : true,
    }));
    setCurrPageNumber(1);
  };

  const resetExamDateFilter = () => {
    setSelectedValues((prev) => ({
      ...prev,
      initialExamStartDate: "",
      initialExamEndDate: "",
      initialExamDatesFilter: {
        startDate: "",
        endDate: "",
      },
    }));
    setCurrPageNumber(1);
  };

  const DatePickerInput = forwardRef(({ onClick, startDate, endDate }, ref) => {
    const formatDate = (date) => {
      return date ? moment(date).format("MMM, DD, YYYY") : "No Date";
    };

    return (
      <DatePickerButton ref={ref}>
        <DateContainer>
          {!startDate && !endDate
            ? "Select Exam Date Range"
            : `${formatDate(startDate)} - ${formatDate(endDate)}`}
        </DateContainer>
        {!selectedValues?.initialExamStartDate &&
          !selectedValues?.initialExamEndDate && <CalendarIcon />}
        {(selectedValues?.initialExamStartDate ||
          selectedValues?.initialExamEndDate) && (
          <DateClearButton
            onClick={() => {
              resetExamDateFilter();
              setSelectedValues((prev) => ({
                ...prev,
                selectedSubOptionExamDate: null,
                examDateView: true,
                triggerUpdate: true,
              }));
            }}
          >
            <RemoveRecoIcon />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });

  return (
    <div style={{ position: "relative" }}>
      <SelectDropdownTitle>EXAM DATE RANGE</SelectDropdownTitle>
      <SelectContainer>
        {selectedValues?.examDateView &&
        !selectedValues?.initialExamStartDate &&
        !selectedValues?.initialExamEndDate ? (
          <InitialSelect
            onClick={() =>
              setSelectedValues((prev) => ({ ...prev, examDateView: false }))
            }
          >
            <span>Select...</span>
            <CalendarIcon />
          </InitialSelect>
        ) : selectedValues?.initialExamStartDate &&
          selectedValues?.initialExamEndDate ? (
          <DatePickerContainer>
            <DatePicker
              popperPlacement="auto"
              selected={selectedValues?.initialExamStartDate}
              startDate={selectedValues?.initialExamStartDate}
              endDate={selectedValues?.initialExamEndDate}
              customInput={
                <DatePickerInput
                  startDate={selectedValues?.initialExamStartDate}
                  endDate={selectedValues?.initialExamEndDate}
                />
              }
              shouldCloseOnSelect={false}
              selectsRange
              dateFormat="dd/MM/yyyy"
              popperModifiers={{
                offset: { enabled: true, offset: "5px, 10px" },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: "viewport",
                },
              }}
            />
          </DatePickerContainer>
        ) : (
          <ClickAwayListener
            onClickAway={() =>
              setSelectedValues((prev) => ({
                ...prev,
                selectedSubOptionExamDate: null,
                examDateView: true,
              }))
            }
          >
            <div style={{ position: "absolute", zIndex: 2 }}>
              <RadioContainer>
                {[
                  "custom",
                  "last7days",
                  "last30days",
                  "last6months",
                  "last12months",
                ].map((value) => (
                  <RadioLabel key={value}>
                    <RadioBtn
                      type="radio"
                      value={value}
                      checked={
                        selectedValues?.selectedSubOptionExamDate === value
                      }
                      onChange={() => handleRadioChange(value)}
                      borderColor={
                        selectedValues?.selectedSubOptionExamDate === value
                          ? "#00AEEF"
                          : "#D4D4D4"
                      }
                      bg={
                        selectedValues?.selectedSubOptionExamDate === value
                          ? "#00AEEF"
                          : "#FFF"
                      }
                    />
                    {RecoOptions.find((itm) => itm.value === value)?.label ||
                      value}
                  </RadioLabel>
                ))}
              </RadioContainer>
              {selectedValues?.selectedSubOptionExamDate === "custom" && (
                <DatePicker
                  selected={selectedValues?.initialExamStartDate}
                  onChange={onInitialExamDatesChange}
                  startDate={selectedValues?.initialExamStartDate}
                  endDate={selectedValues?.initialExamEndDate}
                  selectsRange
                  inline
                  shouldCloseOnSelect
                />
              )}
            </div>
          </ClickAwayListener>
        )}
      </SelectContainer>
    </div>
  );
};

export default ExamDateSelector;
