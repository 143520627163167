import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import EditNote from './EditNote';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { isNull } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { AvatarContainer, UserAvatar, UserEmptyAvatar } from '../../components/Table/Table';
import NewNote from './NewNote';
import { Spinner } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Flex from '../_styles/Flex';
import loadMoreIcon from '../../assets/load-more.svg';
import { ReactComponent as EditIcon} from '../../assets/EditIcon.svg';
import { showAlert } from '../../actions/radminUploads';


const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: '22px',
        fontWeight: 700,
        color:'#0C2945',
        fontFamily:'Roboto Condensed',
    },
    noteBoxHeading: {
        fontSize: '18px',
        fontWeight: 700,
        color:'#0C2945',
        fontFamily:'Roboto Condensed'
    },
    noteBoxHeadingSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    leftGrid: {
        height: 'auto',
        maxHeight: '529px',
        overflow: 'scroll',
    },
    rightGrid: {
        height: 'auto',
        maxHeight: '307px',
        overflow: 'scroll'
    },
    innerLeftGrid: {
        width: '100%'
    },
    date: {
        fontSize: '12px',
        fontWeight:400,
        color: '#252733',
    },
    dateSection: {
        display: 'flex',
        alignItems: 'center',
        flexDirection:'column'
    },
    headingFirstSection: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '15px'
    },
    noteBox: {
        border: '1px solid #D3D3D3',
        display: 'flex',
        borderRadius: '4px',
        marginBottom: 20,
        padding:'20px 0px',
        background:'#FFFFFF'

    },
    noteHistoryBox: {
        display: 'flex',
        borderRadius: '15px',
    },
    noteBoxRightSide: {
        width: '80%',
        cursor: 'pointer'
    },
    noteBoxLeftSide: {
        width: '15%',
        display: 'flex',
        justifyContent: 'center',
    },
    historyHeading: {
        marginBottom: '20px',
        marginLeft: '4%',
        fontSize: '18px',
        fontWeight: 'bold'
    },
    historyDate: {
        color: 'rgba(0,0,0,.5)',
        fontSize: ' 12px',
        marginBottom: 10
    },
    addCircle: {
        cursor: 'pointer',
        color: "#4dabf5"
    },
    noteIcon: {
        paddingRight: 5
    },
    noData: {
        color: ' #AAAEBD',
        textAlign: 'center',
        fontSize: 16,
        padding: '15px 0'
    },
    notesBtnContainer:{
    marginBottom:'20px',
    width:'100%',
    display:'flex',
    gap:'22px',
    alignItems:'center'
    },
    saveBtn: {
        background: '#4dabf5',
        color: 'white',
        width: '117px',
        height:'38px',
        textTransform:'capitalize',
        '&:hover': {
            background: '#4dabf5',
        }
    }, 
    orderBtn: {
        background: 'transparent',
        color: '#00AEEF',
        // width: '117px',
        height:'19px',
        fontSize:'14px',
        fontWeight:600,
        textTransform:'capitalize',
        '&:hover': {
            background: 'transparent',
        }
    }
}));


const NoteDescriptionSection = styled.div`
  display: -webkit-box;
  minHeight: ${props => props.isNoteClicked ? '180px' : '47px'};
  -webkit-line-clamp: ${props => props.isNoteClicked ? '' : '2'};;
  font-size: 13px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 21px;
  font-weight:100px
`
export const LoadMoreContainer = styled.div`
    background: #0c2945;
    color: white;
    font-size: 13px;
    padding: 5px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    opacity: ${props => props.disabled ? '0.4' : 'none' }
    cursor: pointer;
`
const SpanText = styled.span`
  font-size:13px
`
const StrongText = styled.strong`
   font-size:13px
`

const ArrowUpwardIconStyled = styled(ArrowUpwardIcon)`
cursor:pointer;
color: ${({active})=>active? '#00AEEF' : '#c3c3c3' };
`

const ArrowDownwardIconStyled = styled(ArrowDownwardIcon)`
cursor:pointer;
color: ${({active})=>active? '#00AEEF' : '#c3c3c3' };
`

const SpinnerContainer = styled(Flex)`
  justify-content: center;
  margin: 30px 0;
  align-items: center;
`;

const StyledGrid = styled(Grid)`

`


const UserDataBlock = ({ note, avatar, }) => {
    let [firstName, lastName] = note?.user?.name?.split(' ')
    let firstChar = note  && firstName?.[0] || ""
    let secondChar = note  && lastName?.[0] || ""
    return (
        <>
            <AvatarContainer style={{ marginRight: 0 }}>
                {avatar ? (
                    <UserAvatar />
                ) : (
                    <UserEmptyAvatar style={{background:'#006186', color:'#ffffff'}}>{firstChar + secondChar}</UserEmptyAvatar>
                )}
            </AvatarContainer>
        </>
    );
}

function EditFormBottom({ selectedData, LoadMoreNotes, toggleNotesOrder, notesOrder,setNotesPage}) {
    const classes = useStyles();
    const notes = useSelector(state => state.radAdmin.notes.data);
    const totalItems = useSelector(state => state.radAdmin.notes.totalItems);
    const isLoading = useSelector(state => state.radAdmin.notes.isLoading);
    const [isNoteClicked, setIsNoteClicked] = useState(false);
    const [openEditNote, setOpenEditNote] = useState(false);
    const [openNewNote, setOpenNewNote] = useState(false);
    const [selectedNote, setSelectedNote] = useState(null);
    
  const permission = useSelector((state) => state.auth.user.permission_level); 
  const rolePermissions = useSelector(
     (state) =>state.auth.user.permissions
   );
  const addPermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "add_follow-ups")?.is_authorized
  const dispatch = useDispatch();

    const clickHandler = (index) => {
        if (index === isNoteClicked) {
            setIsNoteClicked(false)
            return
        }
        setIsNoteClicked(index)
    }

    const editClickHandler = (data) => {
        setSelectedNote(data)
        setOpenEditNote(!openEditNote)
        window.analytics.track('Follow-Up-List-Edit-Note');

    }
    const addNoteClickHandler = () => {
        if((permission && addPermission) || !permission){
            setOpenNewNote(!openNewNote)
            window.analytics.track('Follow-Up-List-New-Note');
           }else{
             dispatch(
               showAlert('danger', 'Error', `You don't have permission to change reco note`)
             );
           }
     
    }

    return (
        <div style={{flexBasis:'50%'}}>
            <Grid container>
                <EditNote openDialog={openEditNote} editClickHandler={editClickHandler} selectedData={selectedData} selectedNote={selectedNote} />
                {openNewNote && <NewNote notesOrder={notesOrder} openDialog={openNewNote} addNoteClickHandler={addNoteClickHandler} selectedData={selectedData} setNotesPage={setNotesPage} />
                }
                <StyledGrid item xs={12} sm={12} lg={12}>
                    <Grid className={classes.innerLeftGrid}>
                        <Grid className={classes.headingFirstSection}>
                            <Typography className={classes.heading}>Notes</Typography>
                            
                        </Grid>
                        <StyledGrid style={{border:'1px solid #CACACA', borderRadius:'8px', backgroundColor:'#FAFAFA', padding:'20px',   height: 'auto',
        maxHeight: '529px',
        overflow: 'scroll'}}>
                        <div className={classes.notesBtnContainer}>
                        <Button className={classes.saveBtn} onClick={addNoteClickHandler}>New Note</Button>
                        <Button   disableRipple
 endIcon={  notesOrder ==='ascending'? <ArrowUpwardIconStyled style={{fontSize:'18px'}} active={true}/>:  <ArrowDownwardIconStyled  style={{fontSize:'18px'}} active={true}/> } className={classes.orderBtn} onClick={toggleNotesOrder} >  
                     {notesOrder ==='ascending'? 'Oldest to Newest' : 'Newest to Oldest'}</Button>
                        </div>
                        {(isNull(notes) || (notes && notes.length === 0))  && !isLoading? <Grid><p className={classes.noData}>No data</p> </Grid> : ""}
                        <Grid>
                            { 
                            isLoading  ?  <SpinnerContainer> <Spinner
                            animation="border"
                            role="status"
                            style={{ width: 30, height: 30 }}
                        />
                        </SpinnerContainer>
                        :  notes && notes.length > 0 && notes.map((note, index) => {
                            return (
                                <Grid key={index} className={classes.noteBox} >
                                    <Grid className={classes.noteBoxLeftSide}>
                                        <UserDataBlock note={note} />
                                    </Grid>
                                    <Grid className={classes.noteBoxRightSide} onClick={() => clickHandler(index)}>
                                        <Grid className={classes.noteBoxHeadingSection}>
                                            <Typography className={classes.noteBoxHeading}>{note.title}</Typography>
                                            <Grid className={classes.dateSection}>
                                                <span className={classes.date}>{moment(note.created).format('ll')}</span>
                                               
                                            </Grid>
                                            
                                        </Grid>
                                        <div style={{display:'flex', justifyContent:'space-between', gap:'19px'}}>
                                        <NoteDescriptionSection
                                            isNoteClicked={isNoteClicked === index}
                                        >

                                            {note.note_text}
                                        </NoteDescriptionSection>
                                        <span
                                            onClick={() => editClickHandler(note)}
                                            style={{ cursor: "pointer", marginRight:'13px' }}
                                        >
                                            <EditIcon />
                                        </span>
                                        </div>
                                        
                                    </Grid>

                                </Grid>
                            )
                        })
                            }
                            
                        </Grid>
                   {(!isLoading && notes && notes.length !== 0)  &&  
                   <button
                   disabled={totalItems === notes.length}
                   onClick={totalItems !== notes.length? () => LoadMoreNotes(): null}
                   style={{
                     border: "2px solid #00AEEF",
                     color: "#00AEEF",
                     background: "white",
                     borderRadius: "2px",
                     textAlign: "center",
                     display: "flex",
                     justifyContent: "center",
                     margin:'0 auto',
                     alignItems:'center',
                     cursor:totalItems === notes.length? 'not-allowed':'pointer'
                   }}
                   >
                   <span
                     style={{
                       display: "flex",
                       alignItems: "center",
                       gap: "8px",
                     }}
                   >
                    
                     {
                      isLoading? <Spinner animation="border" role="status" size="sm" />  : 
                      <>
                        Load More
                     <img src={loadMoreIcon} />
                       </>
                     }
                    
                   </span>
                   </button>
                   
                   
                        }
                            
                        </StyledGrid>
                       
                    </Grid>
                </StyledGrid>
            </Grid>

        </div>
    )
}

export default EditFormBottom
