import React, { useState, useRef, useEffect, useCallback } from "react";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { div } from "react-bootstrap";
import styled from "styled-components";
import Flex from "../../../components/_styles/Flex";
import equalizer from "../../../assets/equalizer.png";
import { useHistory, useLocation } from "react-router";
import {
  getCohortsData,
  getDropdownValuesForCohort,
  getLocations,
  getReferrersForDropdown,
  getReferrersForDropdownOptions,
  getReportsModality,
} from "../../../actions/radminUploads";
import { Dialog, DialogContent } from "@material-ui/core";
import { ReactComponent as CloseIcon } from "../../../assets/cancel-outline.svg";
import {
  CloseSign,
  SelectedItem,
  SelectedItemContainer,
} from "./FollowUpList";
import close from "../../../assets/cancel-outline-chip.svg";
import { resetFollowUpListFiltersQuery } from "./utils";

export const Card = styled(Flex)`
  min-width: max-content;
  flex-direction: column;
  align-items: flex-start;
  media (max-width: 657px) {
    margin-left: 78px;
  }
`;

const FormContainer = styled.div`
  // width: 230px;
`;

const SelectContainer = styled.div`
  width: ${(props) => props.width || "227px"};
`;

export const FilterContainer = styled(Flex)`
  width: 41px;
  height: 38px;
  justify-content: space-around;
  align-items: center;
  // margin: 0 6px;
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;

export const SettingsContainer = styled.div`
  position: relative;
  z-index: 1;
  top: 50px;
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  margin: ${(props) => (props.margin ? props.margin : "0 0 20px 0")};
  border-radius: 8px;
  margin-right: ${(props) => (props.marginRight === "true" ? "45%" : "")};
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 3;
`;
const Image = styled.img`
  display: block;
  width: 20px;
`;

export const FilterButton = styled(Flex)`
  width: 100%;
  justify-content: space-around;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #00aeef;
`;

const FilterMenuTitle = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #0c2945;
`;

export const SelectDropdownTitle = styled.div`
  margin-bottom: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const Button = styled.button`
  width: 163px;
  height: 38px;
  background-color: ${(props) => (props.isPrimary ? "#00aeef" : "transparent")};
  border-radius: 4px;
  border: ${(props) => (props.isPrimary ? "0" : "2px solid #CFCFCF")};
  font: bold 14px "Open Sans", sans-serif;
  text-align: center;
  color: ${(props) => (props.isPrimary ? "#FFFFFF" : "#000000")};
  display: block;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
  &:hover {
    background-color: ${(props) =>
      props.isPrimary ? "transparent" : "#CFCFCF"};
    border: ${(props) => (props.isPrimary ? "2px solid #00aeef" : "0")};
    color: ${(props) => (props.isPrimary ? "#00aeef" : "#ffffff")};
  }
`;

const Label = styled.label`
  font-size: 9.2px;
  color: #828282;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 0px;
  text-transform: uppercase;
  color: #000000;
`;
const Row = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  padding-left: 25px;
`;

const RadioBtn = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 50%;
  border-color: ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  padding: 3% !important;
  background-clip: content-box;
  cursor: pointer;
`;
const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const FilterCategoriesContainer = styled.div`
  margin: 0 auto 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const RowHeader = styled.p`
  background: #eff3f5;
  color: #0c2945;
  font-weight: 500;
  font-family: Roboto Condensed;
  font-size: 21px;
  padding: 6px 10px;
`;

const RowContainer = styled.div`
  margin-bottom: 30px;
`;

const DialogContentStyled = styled(DialogContent)`
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const BodyContainer = styled.div`
  height:400px;
  overflow-y:scroll; 
  padding:25px 30px;
  padding-right:17px;
`

export const DEFAULT_VALUES = {
  radiologist: "",
  referrers: "",
  initial_modality: "",
  modality: "",
  anatomy: "",
  timeframe: "",
  careType: "",
  age: "",
  message_sent: "",
  has_note: "",
  sort: "",
  location: "",
  report_modality: "",
  exam_code: "",
  cohort: "",
  guideline_type: "",
  task_type: "",
  patient_language: "",
  exam_type: "",
  recommendation_type: "",
  contrast:"",
  source:""

};

export default function debounce(func, timeout = 300) {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ margin: "0 5px", verticalAlign: "middle" }}
        />{" "}
        <label style={{ margin: "0" }}>{props.label}</label>
      </components.Option>
    </div>
  );
};

export const FollowUpListFilter = (props) => {
  const {
    filterBy,
    setFilter,
    filterTypes,
    setFilterBy,
    setFilterValuesForRequest,
    setPageNumber,
    setShowSaveFilter = () => {},
    setShowAddCohort = () => {},
    setAllSelected = () => {},
    setSelectedIds = () => {},
    filterOpen,
    setFilterOpen,
  } = props;
  const {
    filterValuesForRequest,
    marginRight,
    getFilterDetail,
    operatorValues,
    anatomyOperator,
    modalityOperator,
    reportModalityOperator,
    examCodeOperator,
  } = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const cohorts = useSelector(
    (state) => state.radAdmin.followUpList?.dropdownCohorts.data
  );
  const referrers = useSelector(
    (state) => state.radAdmin.followUpList?.dropdownReferrers.data
  );
  const radiologists = useSelector(
    (state) => state.radAdmin.followUpList?.dropdownRadiologists.data
  );
  const modalities = useSelector(
    (state) => state.radAdmin.followUpList.modalities.data
  );
  const anatomies = useSelector(
    (state) => state.radAdmin.followUpList.anatomies.data
  );
  const timeframeStatuses = useSelector(
    (state) => state.radAdmin.followUpList.timeframeStatuses.data
  );
  const recoConfigData = useSelector((state) => state.radAdmin.recoConfig.data);


  const node = useRef();
  const [selectedCategories, setSelectedCategories] = useState([
    "radiologist",
    "referrers",
    "modality",
    "anatomy",
    "timeframe",
    "age",
    "location",
    "message sent",
    "contains note",
    "sort",
    "report modality",
    "exam_code",
  ]);
  const [selectedValues, setSelectedValues] = useState(filterValuesForRequest);

  const [selectedOperators, setSelectedOperators] = useState({
    modalityOperator: modalityOperator,
    anatomyOperator: anatomyOperator,
    reportModalityOperator: reportModalityOperator,
    examCodeOperator: examCodeOperator,
  });
  const [referrerPage, setReferrerPage] = useState(1);
  const [query, setQuery] = useState("");
  const [referrerList, setReferrerList] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);

  const generateOptions = (interval) => {
    const maxAge = 100;
    const newOptions = [];
    let start = 1;
  
    const numGroups = Math.floor(maxAge / interval);
    let end = 0;
  
    for (let i = 0; i < numGroups; i++) {
      end = start + interval - 1;
  
      newOptions.push({
        value: `${start}-${end}`,
        label: `${start}-${end}`,
        category: "age",
      });
  
      start = end + 1;
    }
  
    if (interval === 5 || interval === 10 || interval === 20 || interval === 25 || interval === 50) {
      newOptions.push({
        value: `101-150`,
        label: `101+`,
        category: "age",
      });
    } else if (interval === 15 || interval === 30) {
      newOptions.push({
        value: `91-150`,
        label: `91+`,
        category: "age",
      });
    } else if (interval === 35) {
      newOptions.push({
        value: `71-150`,
        label: `71+`,
        category: "age",
      });
    } else if (interval === 40) {
      newOptions.push({
        value: `81-150`,
        label: `81+`,
        category: "age",
      });
    } else if (interval === 45) {
      newOptions.push({
        value: `91-150`,
        label: `91+`,
        category: "age",
      });
    }
    setAgeOptions(newOptions);
  };
  
  useEffect(() => {
    generateOptions(recoConfigData?.patientAgeInterval ?? 5);
  }, [recoConfigData]);
  

  
  const referrerOptionsList = referrerList?.length
    ? referrerList
        .map((item) => ({
          value: item.id,
          label: `${item?.first_name} ${item?.last_name}`,
          category: "referrers",
        }))
        .filter((item) => item.value)
    : [];

  const location = useLocation();
  const cohortsOptions =
    cohorts?.length > 0
      ? cohorts.map((itm) => ({
          category: "cohort",
          label: itm.cohort_title,
          value: itm.id,
        }))
      : [];

  const fixedValues = useSelector(
    (state) => state.radAdmin.cohorts.fixedValues?.data
  );


  const guidelineOptions = fixedValues?.length
    ? fixedValues
        .find((val) => val.attribute === "guideline_type")
        ?.values?.map((itm) => ({
          value: itm,
          label: itm,
          category: "guideline_type",
        }))
    : [];

  const radiologistOptions = radiologists?.length
    ? radiologists
        .map((item) => ({
          value: item.id,
          label: `${item?.first_name} ${item?.last_name}`,
          category: "radiologist",
        }))
        .filter((item) => item.value)
    : [];
  const referrerOptions = referrers?.length
    ? referrers
        .map((item) => ({
          value: item.id,
          label: `${item?.first_name} ${item?.last_name}`,
          category: "referrers",
        }))
        .filter((item) => item.value)
    : [];
  const modalityOptions = modalities?.length
    ?  [{ value: 'empty', label: '--Empty--', category: "modality" }].concat(modalities
        .map((item) => ({ value: item, label: item, category: "modality" }))
        .filter((item) => item.value))
    : [];

  const anatomyOptions = anatomies?.length
    ? [{ value: 'empty', label: '--Empty--', category: "anatomy" }].concat(anatomies
        .map((item) => ({ value: item, label: item, category: "anatomy" }))
        .filter((item) => item.value))
    : [];
  const timeframeStatusOptions = [
    {
      value: "no_timeframe_identified",
      label: "No Timeframe Identified",
      category: "timeframe",
    },
    {
      value: "incomplete_timeframe_missing_end",
      label: "Incomplete Missing End",
      category: "timeframe",
    },
    {
      value: "incomplete_timeframe_missing_start",
      label: "Incomplete Missing Start",
      category: "timeframe",
    },
    {
      value: "complete_timeframe_identified",
      label: "Complete Timeframe",
      category: "timeframe",
    },
  ];

  const testOptions = [
    { value: "screening", label: "Screening", category: "exam_type" },
    { value: "diagnostic", label: "Diagnostic", category: "exam_type" },
  ];

  const recommemdationOptions = [
    { value: "imaging_followup", label: "Imaging Followup", category: "recommendation_type" },
    {
      value: "non_imaging_followup",
      label: "Non Imaging Followup",
      category: "recommendation_type",
    },
  ];

  const contrastOptions = [
    { value: "with", label: "With", category: "contrast" },
    {
      value: "without",
      label: "Without",
      category: "contrast",
    },
    {
      value: "with-without",
      label: "With and Without",
      category: "contrast",
    },
  ];


  const noteOptions = [
    { value: "true", label: "true", category: "has_note" },
    { value: "false", label: "false", category: "has_note" },
  ];
 

  const taskOptions = [
    { value: "call", label: "Call", category: "task_type" },
    { value: "fax", label: "Fax", category: "task_type" },
    { value: "email", label: "Email", category: "task_type" },
    { value: "sms", label: "SMS", category: "task_type" },
    { value: "letter", label: "Letter", category: "task_type" },
    { value: "general", label: "General", category: "task_type" },
  ];

  const languageOptions = [
    { value: "en", label: "English", category: "patient_language" },
    { value: "spa", label: "Spanish", category: "patient_language" },
  ];

  const modalityOperatorOption = [
    { value: "none", label: "none", category: "modality" },
    { value: "isNotEqual", label: "is not equal to", category: "modality" },
  ];
  const anatomyOperatorOption = [
    { value: "none", label: "none", category: "anatomy" },
    { value: "isNotEqual", label: "is not equal to", category: "anatomy" },
  ];

  const sortOptions = [
    { value: "Reco Max Asc", label: "Reco Max Asc", category: "sort" },
    { value: "Reco Max Desc", label: "Reco Max Desc", category: "sort" },
    { value: "Reco Min Asc", label: "Reco Min Asc", category: "sort" },
    { value: "Reco Min Desc", label: "Reco Min Desc", category: "sort" },
  ].filter((item) => item.value);

  const messageSentOptions = [
    { value: "true", label: "true", category: "message_sent" },
    { value: "false", label: "false", category: "message_sent" },
  ];

  const sourceOptions = [
    { value: "nlp", label: "Nlp", category: "source" },
    { value: "user", label: "User", category: "source" },
  ];

  const [locationOptions, setLocationOptions] = useState();
  const [nextLocationPage, setNextLocationPage] = useState();
  const [loadingMoreLocations, setLoadingMoreLocations] = useState(false);
  const [modalityReportOptions, setModalityReportOptions] = useState([]);
  const history = useHistory();

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const mergedFilters = {
      radiologist:
        selectedValues["radiologist"] || filterValuesForRequest["radiologist"],
      referrers:
        selectedValues["referrers"] || filterValuesForRequest["referrers"],
      modality:
        selectedValues["modality"] || filterValuesForRequest["modality"],
      anatomy: selectedValues["anatomy"] || filterValuesForRequest["anatomy"],
      timeframe:
        selectedValues["timeframe"] || filterValuesForRequest["timeframe"],
      has_note:
        selectedValues["has_note"] || filterValuesForRequest["has_note"],
      age: selectedValues["age"] || filterValuesForRequest["age"],
      // sort: selectedValues['sort'] || filterValuesForRequest['sort'],
      message_sent:
        selectedValues["message_sent"] ||
        filterValuesForRequest["message_sent"],
      location:
        selectedValues["location"] || filterValuesForRequest["location"],
      report_modality:
        selectedValues["report_modality"] ||
        filterValuesForRequest["report_modality"],
      exam_code:
        selectedValues["exam_code"] || filterValuesForRequest["exam_code"],
      cohort: selectedValues["cohort"] || filterValuesForRequest["cohort"],
      guideline_type:
        selectedValues["guideline_type"] ||
        filterValuesForRequest["guideline_type"],
      task_type:
        selectedValues["task_type"] || filterValuesForRequest["task_type"],
      patient_language:
        selectedValues["patient_language"] ||
        filterValuesForRequest["patient_language"],
      exam_type:
        selectedValues["exam_type"] || filterValuesForRequest["exam_type"],
      recommendation_type:
        selectedValues["recommendation_type"] ||
        filterValuesForRequest["recommendation_type"],
      contrast:  selectedValues["contrast"] ||
      filterValuesForRequest["contrast"],
      source:
      selectedValues["source"] || filterValuesForRequest["source"],

    };
    if (mergedFilters.referrers.length > 0) {
      const promises = mergedFilters.referrers.map((referrer) =>
        dispatch(getReferrersForDropdown(`?id=${referrer.value}`))
      );
      await Promise.all(promises);
    }

    setFilterBy([]);
    setFilterOpen(false);
    setFilterValuesForRequest(mergedFilters);
    setPageNumber(1);
    setAllSelected(false);
    setSelectedIds([]);
    operatorValues(
      selectedOperators?.modalityOperator,
      selectedOperators?.anatomyOperator,
      selectedOperators?.reportModalityOperator,
      selectedOperators?.examCodeOperator
    );
    setShowSaveFilter(true);
    setShowAddCohort(true);
    const selectedCategories = Object.values(mergedFilters)
      .map((i) => i.category)
      .filter((i) => i);
    window.analytics.track(
      `Follow-Up-List-Filter-${selectedCategories.join("-").toUpperCase()}`
    );

    let params = new URLSearchParams(history.location.search);
    resetFollowUpListFiltersQuery(params)

    if (mergedFilters.modality?.length > 0) {
      mergedFilters.modality.forEach((itm) => {
        if (selectedOperators?.modalityOperator?.value === "isNotEqual") {
          params.append("modality!", itm.value);
        } else {
          params.append("modality", itm.value);
        }
      });
    }

    if (mergedFilters.anatomy?.length > 0) {
      mergedFilters.anatomy.forEach((itm) => {
        if (selectedOperators?.anatomyOperator?.value === "isNotEqual") {
          params.append("anatomy!", itm.value);
        } else {
          params.append("anatomy", itm.value);
        }
      });
    }

    if (mergedFilters.radiologist?.length > 0) {
      mergedFilters.radiologist.forEach((itm) => {
        params.append("radiologist", itm.value);
      });
    }

    if (mergedFilters.exam_type?.length > 0) {
      mergedFilters.exam_type.forEach((itm) => {
        params.append("exam_type", itm.value);
      });
    }

    if (mergedFilters.guideline_type?.length > 0) {
      mergedFilters.guideline_type.forEach((itm) => {
        params.append("guideline_type", itm.value);
      });
    }

    if (mergedFilters.timeframe?.length > 0) {
      mergedFilters.timeframe.forEach((itm) => {
        params.append("timeframe", itm.value);
      });
    }

    if (mergedFilters.has_note?.length > 0) {
      mergedFilters.has_note.forEach((itm) => {
        params.append("has_note", itm.value);
      });
    }

    if (mergedFilters.task_type?.length > 0) {
      mergedFilters.task_type.forEach((itm) => {
        params.append("task_type", itm.value);
      });
    }

    if (mergedFilters.recommendation_type?.length > 0) {
      mergedFilters.recommendation_type.forEach((itm) => {
        params.append("recommendation_type", itm.value);
      });
    }
    if (mergedFilters.contrast?.length > 0) {
      mergedFilters.contrast.forEach((itm) => {
        params.append("contrast", itm.value);
      });
    }

    if (mergedFilters.source?.length > 0) {
      mergedFilters.source.forEach((itm) => {
        params.append("source", itm.value);
      });
    }

    // -------------------

    if (mergedFilters.report_modality?.length > 0) {
      mergedFilters.report_modality.forEach((itm) => {
        if (selectedOperators?.reportModalityOperator?.value === "isNotEqual") {
          params.append("report_modality!", itm.value);
        } else {
          params.append("report_modality", itm.value);
        }
      });
    }

    if (mergedFilters.exam_code?.length > 0) {
      mergedFilters.exam_code.forEach((itm) => {
        if (selectedOperators?.examCodeOperator?.value === "isNotEqual") {
          params.append("exam_code!", itm.value);
        } else {
          params.append("exam_code", itm.value);
        }
      });
    }

    // -------------------

    if (mergedFilters.patient_language?.length > 0) {
      mergedFilters.patient_language.forEach((itm) => {
        params.append("patient_language", itm.value);
      });
    }
    if (mergedFilters.age?.length > 0) {
      mergedFilters.age.forEach((itm) => {
        params.append("age", itm.value);
      });
    }

    //  -----------------

    if (mergedFilters.cohort?.length > 0) {
      mergedFilters.cohort.forEach((itm) => {
        params.append("cohort", itm.value);
      });
    }

    if (mergedFilters.message_sent?.length > 0) {
      mergedFilters.message_sent.forEach((itm) => {
        params.append("message_sent", itm.value);
      });
    }

    //------------------

    if (mergedFilters.referrers?.length > 0) {
      mergedFilters.referrers.forEach((itm) => {
        params.append("referrers", itm.value);
      });
    }

    if (mergedFilters.location?.length > 0) {
      mergedFilters.location.forEach((itm) => {
        params.append("location", itm.value);
      });
    }


    history.replace({
      search: params.toString(),
    });
  };

  const closeSelected = (category, selectedItem) => {
    const filters = selectedValues[category].filter((item) => {
      return item.value !== selectedItem.value;
    });
    let newFilters = {
      ...selectedValues,
      [category]: filters.length > 0 ? filters : [],
    };
    setSelectedValues(newFilters);
  };

  const IndicatorsContainer = (props) => {
    return (
      <div style={{ backgroundColor: "#FFF" }}>
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };

  const RadioOption = (props) => {
    return (
      <>
        <components.Option {...props}>
          <span style={{ marginRight: 5, paddingTop: 5 }}>
            <input
              type="radio"
              checked={props.isSelected}
              onChange={() => null}
              style={{ marginTop: 5 }}
            />{" "}
          </span>
          <label>{props.label}</label>
        </components.Option>
      </>
    );
  };

  const onInputChange = debounce(async (inputValue) => {
    if (inputValue) {
      setLoading(true);
      setReferrerList([]);
      setQuery(inputValue);
      setReferrerPage(1);
      const res = await dispatch(
        getReferrersForDropdownOptions(`?last_name__icontains=${inputValue}`)
      );
      if (res) {
        setReferrerList(res?.results);
        if (res?.next) {
          setReferrerPage(2);
        }
      }
      setLoading(false);
    } else {
      setReferrerList([]);
      setReferrerPage(1);
    }
  }, 1000);

  const loadMoreOptions = async () => {
    if (referrerPage > 1 && referrerOptionsList?.length >= 10 && !loading) {
      setLoading(true);
      const res = await dispatch(
        getReferrersForDropdownOptions(
          `?last_name__icontains=${query}`,
          referrerPage
        )
      );
      if(res){
        setReferrerList((prev) => [...prev, ...res?.results]);
        if (res.next) {
          setReferrerPage((prev) => prev + 1);
        } else {
          setReferrerPage(1);
        }
      }
      setLoading(false);
    }
  };

  const loadMoreLocationOptions = async () => {
    if (nextLocationPage && !loadingMoreLocations) {
      setLoadingMoreLocations(true);
      dispatch(getLocations(nextLocationPage)).then((res) => {
        setLoadingMoreLocations(false);
        if(res){
          setLocationOptions((prev) => [
            ...prev,
            ...res.results.map((itm) => ({
              category: "location",
              label: itm.location_address.replace(/\^/g, ","),
              value: itm.location_address,
            })),
          ]);
          if (res.next) {
            setNextLocationPage((prev) => prev + 1);
          } else {
            setNextLocationPage();
          }
        }
      });
    }
  };

  useEffect(() => {
    if (filterOpen === true) {
      dispatch(getLocations()).then((res) => {
        if(res){
          if (res.next) {
            setNextLocationPage(2);
          }
          setLocationOptions(
            res.results.map((itm) => ({
              category: "location",
              label: itm.location_address.replace(/\^/g, ","),
              value: itm.location_address,
            }))
          );
        }
      });
    }
  }, [filterOpen]);

  useEffect(() => {
    dispatch(getReportsModality())
      .then((res) =>
        setModalityReportOptions(
          [{ value: 'empty', label: '--Empty--', category: "report_modality" }].concat(res?.map((itm) => ({
            label: itm.report__modality,
            value: itm.report__modality,
            category: "report_modality",
          })).filter(itm=>itm.value))
        )
      )
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (filterOpen === false) {
      setNextLocationPage();
      setLocationOptions([]);
    }
  }, [filterOpen]);

  useEffect(() => {
    dispatch(getDropdownValuesForCohort("Recommendation", "guideline_type"));
  }, []);

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        fontSize: "14px",
        color: "black",
        cursor: isDisabled ? "not-allowed" : "default",
        backgroundColor: isSelected ? "#D9ECFF" : "white",
        display: "flex",
      };
    },
    multiValue: (styles, { data }) => ({
      ...styles,
      borderRadius: "25px",
      backgroundColor: "#EEEEEE",
      color: "#A1A1A1",
      padding: "4px 10px",
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#A1A1A1",
      fontSize: "10px",
    }),
  };

  const [validationError, setValidationError] = useState("");

  const handleInputChange = (event) => {
    const inputValue = event?.target?.value?.split(",");
    setSelectedValues({
      ...selectedValues,
      exam_code: inputValue.map((itm) => ({
        value: itm,
        category: "exam_code",
        label: "Exam Code",
      })),
    });

    const codes = inputValue.map((code) => code.trim());

    let error = "";

    let hasInvalidLength = false;
    let hasInvalidSeparator = false;

    for (let i = 0; i < codes.length; i++) {
      const code = codes[i];
      if (code && !code.match(/^\w{5}$/)) {
        hasInvalidLength = true;
      }
    }

    if (codes.some((code) => code.includes(" "))) {
      hasInvalidSeparator = true;
    }

    if (hasInvalidLength && hasInvalidSeparator) {
      error =
        "<span>Exam codes must be five digits long.</span><br/><span>Commas must be used to separate multiple exam codes (e.g. 00000,00000).</span>";
    } else if (hasInvalidLength) {
      error = "<span>Exam codes must be five digits long.</span>";
    } else if (hasInvalidSeparator) {
      error =
        "<span>Commas must be used to separate multiple exam codes (e.g. 00000,00000).</span>";
    }

    setValidationError(error);
  };

  return (
    <>
      <FilterContainer showMenu={filterOpen}>
        <SettingsContainer ref={node} marginRight={marginRight}>
          {filterOpen === true && (
            <Dialog
              open={filterOpen}
              aria-labelledby="form-dialog-title"
              maxWidth="xl"
              // style={{ height: 800 }}
              style={{
                backdropFilter: "blur(5px)",
              }}
              onClose={() => setFilterOpen(false)}
            >
              <DialogContentStyled style={{ padding: "0px" }}>
                <Card>
                  <div
                    style={{
                      width: "100%",

                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding:'25.5px 30px',
                      borderBottom:'1.5px solid #EFF3F5'
                    }}
                  >
                    <FilterMenuTitle>Filters </FilterMenuTitle>
                    <CloseIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setFilterOpen(false)}
                    />
                  </div>

                  <div>
                    <FormContainer>
                      <form onSubmit={handleFormSubmit}>
                        <div>
                          <BodyContainer>

                            <RowContainer>
                              <RowHeader>Recommendation</RowHeader>
                              <Row>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Recommended Modality
                                  </SelectDropdownTitle>

                                  <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                      Option,
                                      IndicatorsContainer,
                                    }}
                                    allowSelectAll={true}
                                    options={[...modalityOptions]}
                                    value={selectedValues["modality"]}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        modality: value,
                                      })
                                    }
                                    styles={colourStyles}
                                  />
                                  {selectedValues?.modality?.value !==
                                    "modality_none" && (
                                    <div>
                                      <SelectContainer
                                        style={{ marginTop: "8px" }}
                                        key={`item_1`}
                                      >
                                        <RadioGroup>
                                          <Label>
                                            <RadioBtn
                                              type="radio"
                                              value={
                                                selectedOperators[
                                                  "modalityOperator"
                                                ]
                                              }
                                              checked={
                                                selectedOperators[
                                                  "modalityOperator"
                                                ].value === "none"
                                              }
                                              onChange={(value) =>
                                                setSelectedOperators({
                                                  ...selectedOperators,
                                                  modalityOperator: {
                                                    value: "none",
                                                    label: "none",
                                                    category: "modality",
                                                  },
                                                })
                                              }
                                              borderColor={
                                                selectedOperators[
                                                  "modalityOperator"
                                                ].value === "none"
                                                  ? "#00AEEF"
                                                  : "#D4D4D4"
                                              }
                                              backgroundColor={
                                                selectedOperators[
                                                  "modalityOperator"
                                                ].value === "none"
                                                  ? "#00AEEF"
                                                  : "#FFF"
                                              }
                                            />
                                            include
                                          </Label>
                                          <Label>
                                            <RadioBtn
                                              type="radio"
                                              value={
                                                selectedOperators[
                                                  "modalityOperator"
                                                ]
                                              }
                                              checked={
                                                selectedOperators[
                                                  "modalityOperator"
                                                ].value === "isNotEqual"
                                              }
                                              onChange={(value) =>
                                                setSelectedOperators({
                                                  ...selectedOperators,
                                                  modalityOperator: {
                                                    value: "isNotEqual",
                                                    label: "is not equal to",
                                                    category: "modality",
                                                  },
                                                })
                                              }
                                              borderColor={
                                                selectedOperators[
                                                  "modalityOperator"
                                                ].value === "isNotEqual"
                                                  ? "#00AEEF"
                                                  : "#D4D4D4"
                                              }
                                              backgroundColor={
                                                selectedOperators[
                                                  "modalityOperator"
                                                ].value === "isNotEqual"
                                                  ? "#00AEEF"
                                                  : "#FFF"
                                              }
                                            />
                                            exclude
                                          </Label>
                                        </RadioGroup>
                                      </SelectContainer>
                                    </div>
                                  )}
                                </SelectContainer>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Recommended Anatomy
                                  </SelectDropdownTitle>

                                  <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                      Option,
                                      IndicatorsContainer,
                                    }}
                                    allowSelectAll={true}
                                    options={[...anatomyOptions]}
                                    value={selectedValues["anatomy"]}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        anatomy: value,
                                      })
                                    }
                                    styles={colourStyles}
                                  />
                                  {selectedValues?.anatomy?.value !==
                                    "anatomy_none" && (
                                    <div>
                                      <SelectContainer
                                        style={{ marginTop: "8px" }}
                                        key={"item_2"}
                                      >
                                        <RadioGroup>
                                          <Label>
                                            <RadioBtn
                                              type="radio"
                                              value={
                                                selectedOperators[
                                                  "anatomyOperator"
                                                ]
                                              }
                                              checked={
                                                selectedOperators[
                                                  "anatomyOperator"
                                                ].value === "none"
                                              }
                                              onChange={(value) =>
                                                setSelectedOperators({
                                                  ...selectedOperators,
                                                  anatomyOperator: {
                                                    value: "none",
                                                    label: "none",
                                                    category: "anatomy",
                                                  },
                                                })
                                              }
                                              borderColor={
                                                selectedOperators[
                                                  "anatomyOperator"
                                                ].value === "none"
                                                  ? "#00AEEF"
                                                  : "#D4D4D4"
                                              }
                                              backgroundColor={
                                                selectedOperators[
                                                  "anatomyOperator"
                                                ].value === "none"
                                                  ? "#00AEEF"
                                                  : "#FFF"
                                              }
                                            />
                                            include
                                          </Label>
                                          <Label>
                                            <RadioBtn
                                              type="radio"
                                              value={
                                                selectedOperators[
                                                  "anatomyOperator"
                                                ]
                                              }
                                              checked={
                                                selectedOperators[
                                                  "anatomyOperator"
                                                ].value === "isNotEqual"
                                              }
                                              onChange={(value) =>
                                                setSelectedOperators({
                                                  ...selectedOperators,
                                                  anatomyOperator: {
                                                    value: "isNotEqual",
                                                    label: "is not equal to",
                                                    category: "anatomy",
                                                  },
                                                })
                                              }
                                              borderColor={
                                                selectedOperators[
                                                  "anatomyOperator"
                                                ].value === "isNotEqual"
                                                  ? "#00AEEF"
                                                  : "#D4D4D4"
                                              }
                                              backgroundColor={
                                                selectedOperators[
                                                  "anatomyOperator"
                                                ].value === "isNotEqual"
                                                  ? "#00AEEF"
                                                  : "#FFF"
                                              }
                                            />
                                            exclude
                                          </Label>
                                        </RadioGroup>
                                      </SelectContainer>
                                    </div>
                                  )}
                                </SelectContainer>

                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Radiologist
                                  </SelectDropdownTitle>
                                  <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={radiologistOptions}
                                    value={selectedValues["radiologist"]}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                      Option,
                                      IndicatorsContainer,
                                    }}
                                    allowSelectAll={true}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        radiologist: value,
                                      })
                                    }
                                    styles={colourStyles}
                                  />
                                </SelectContainer>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Test Type
                                  </SelectDropdownTitle>
                                  <Select
                                    components={{
                                      IndicatorsContainer,
                                      Option: RadioOption,
                                    }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={testOptions}
                                    value={selectedValues["exam_type"]}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        exam_type: [value],
                                      })
                                    }
                                    styles={{
                                      option: (styles) => ({
                                        ...styles,
                                        fontSize: "14px",
                                      }),
                                    }}

                                  />
                                </SelectContainer>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Contrast
                                  </SelectDropdownTitle>
                                  <Select
                                    components={{
                                      IndicatorsContainer,
                                      Option: RadioOption,
                                    }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={contrastOptions}
                                    value={
                                      selectedValues["contrast"]
                                    }
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        contrast: [value],
                                      })
                                    }
                                    styles={{
                                      option: (styles) => ({
                                        ...styles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </SelectContainer>
                              </Row>
                              <Row>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Guideline Type

                                  </SelectDropdownTitle>
                                  <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={guidelineOptions}
                                    value={selectedValues["guideline_type"]}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                      Option,
                                      IndicatorsContainer,
                                    }}
                                    allowSelectAll={true}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        guideline_type: value,
                                      })
                                    }
                                    styles={colourStyles}
                                  />
                                </SelectContainer>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Recommended Timeframe
                                  </SelectDropdownTitle>
                                  <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={timeframeStatusOptions}
                                    value={selectedValues["timeframe"]}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                      Option,
                                      IndicatorsContainer,
                                    }}
                                    allowSelectAll={true}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        timeframe: value,
                                      })
                                    }
                                    styles={colourStyles}
                                  />
                                </SelectContainer>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Contains Note
                                  </SelectDropdownTitle>
                                  <Select
                                    components={{
                                      IndicatorsContainer,
                                      Option: RadioOption,
                                    }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={noteOptions}
                                    value={selectedValues["has_note"]}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        has_note: [value],
                                      })
                                    }

                                    styles={{
                                      option: (styles) => ({
                                        ...styles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </SelectContainer>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Task Type
                                  </SelectDropdownTitle>
                                  <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={taskOptions}
                                    value={selectedValues["task_type"]}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                      Option,
                                      IndicatorsContainer,
                                    }}
                                    allowSelectAll={true}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        task_type: value,
                                      })
                                    }
                                    styles={colourStyles}
                                  />
                                </SelectContainer>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Recommendation Type
                                  </SelectDropdownTitle>
                                  <Select
                                    components={{
                                      IndicatorsContainer,
                                      Option: RadioOption,
                                    }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={recommemdationOptions}
                                    value={
                                      selectedValues["recommendation_type"]
                                    }
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        recommendation_type: [value],
                                      })
                                    }
                                    styles={{
                                      option: (styles) => ({
                                        ...styles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </SelectContainer>
                              </Row>
                              <Row>
                           
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Recommendation Source
                                  </SelectDropdownTitle>
                                  <Select
                                    components={{
                                      IndicatorsContainer,
                                      Option: RadioOption,
                                    }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={sourceOptions}
                                    value={selectedValues["source"]}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        source: [value],
                                      })
                                    }

                                    styles={{
                                      option: (styles) => ({
                                        ...styles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </SelectContainer>
                     
                              </Row>
                              

                            </RowContainer>
                            <RowContainer>
                              <RowHeader>Report</RowHeader>
                              <Row>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Report Modality
                                  </SelectDropdownTitle>

                                  <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={modalityReportOptions}
                                    value={selectedValues["report_modality"]}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                      Option,
                                      IndicatorsContainer,
                                    }}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        report_modality: value,
                                      })
                                    }
                                    styles={colourStyles}
                                  />
                                  {
                                    <div>
                                      <SelectContainer
                                        style={{ marginTop: "8px" }}
                                        key={`item_1`}
                                      >
                                        <RadioGroup>
                                          <Label>
                                            <RadioBtn
                                              type="radio"
                                              value={
                                                selectedOperators[
                                                  "reportModalityOperator"
                                                ]
                                              }
                                              checked={
                                                selectedOperators[
                                                  "reportModalityOperator"
                                                ].value === "none"
                                              }
                                              onChange={(value) =>
                                                setSelectedOperators({
                                                  ...selectedOperators,
                                                  reportModalityOperator: {
                                                    value: "none",
                                                    label: "none",
                                                    category: "modality",
                                                  },
                                                })
                                              }
                                              borderColor={
                                                selectedOperators[
                                                  "reportModalityOperator"
                                                ].value === "none"
                                                  ? "#00AEEF"
                                                  : "#D4D4D4"
                                              }
                                              backgroundColor={
                                                selectedOperators[
                                                  "reportModalityOperator"
                                                ].value === "none"
                                                  ? "#00AEEF"
                                                  : "#FFF"
                                              }
                                            />
                                            include
                                          </Label>
                                          <Label>
                                            <RadioBtn
                                              type="radio"
                                              value={
                                                selectedOperators[
                                                  "reportModalityOperator"
                                                ]
                                              }
                                              checked={
                                                selectedOperators[
                                                  "reportModalityOperator"
                                                ].value === "isNotEqual"
                                              }
                                              onChange={(value) =>
                                                setSelectedOperators({
                                                  ...selectedOperators,
                                                  reportModalityOperator: {
                                                    value: "isNotEqual",
                                                    label: "is not equal to",
                                                    category: "modality",
                                                  },
                                                })
                                              }
                                              borderColor={
                                                selectedOperators[
                                                  "reportModalityOperator"
                                                ].value === "isNotEqual"
                                                  ? "#00AEEF"
                                                  : "#D4D4D4"
                                              }
                                              backgroundColor={
                                                selectedOperators[
                                                  "reportModalityOperator"
                                                ].value === "isNotEqual"
                                                  ? "#00AEEF"
                                                  : "#FFF"
                                              }
                                            />
                                            exclude
                                          </Label>
                                        </RadioGroup>
                                      </SelectContainer>
                                    </div>
                                  }
                                </SelectContainer>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Report Exam Code
                                  </SelectDropdownTitle>

                                  <input
                                    style={{
                                      resize: "none",
                                      border: validationError
                                        ? "1px solid #B40000"
                                        : "1px solid #D2D3D7",
                                      borderRadius: "4px",
                                      padding: "10px",
                                      height: "43.5px",
                                      width: "227px",
                                    }}
                                    value={
                                      selectedValues["exam_code"]?.length > 0
                                        ? selectedValues["exam_code"]
                                            ?.map((itm) => itm.value)
                                            ?.join(",")
                                        : ""
                                    }
                                    onChange={handleInputChange}
                                    placeholder="Enter 5 digit code"
                                  />

                                  {selectedValues["exam_code"]?.length > 0 &&
                                    validationError && (
                                      <div
                                        style={{
                                          color: "#B40000",
                                          fontSize: "14px",
                                          fontWeight: 400,
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: validationError,
                                        }}
                                      ></div>
                                    )}

                                  <div>
                                    <SelectContainer
                                      style={{ marginTop: "8px" }}
                                      key={`item_1`}
                                    >
                                      <RadioGroup>
                                        <Label>
                                          <RadioBtn
                                            type="radio"
                                            value={
                                              selectedOperators[
                                                "examCodeOperator"
                                              ]
                                            }
                                            checked={
                                              selectedOperators[
                                                "examCodeOperator"
                                              ].value === "none"
                                            }
                                            onChange={(value) =>
                                              setSelectedOperators({
                                                ...selectedOperators,
                                                examCodeOperator: {
                                                  value: "none",
                                                  label: "none",
                                                  category: "modality",
                                                },
                                              })
                                            }
                                            borderColor={
                                              selectedOperators[
                                                "examCodeOperator"
                                              ].value === "none"
                                                ? "#00AEEF"
                                                : "#D4D4D4"
                                            }
                                            backgroundColor={
                                              selectedOperators[
                                                "examCodeOperator"
                                              ].value === "none"
                                                ? "#00AEEF"
                                                : "#FFF"
                                            }
                                          />
                                          include
                                        </Label>
                                        <Label>
                                          <RadioBtn
                                            type="radio"
                                            value={
                                              selectedOperators[
                                                "examCodeOperator"
                                              ]
                                            }
                                            checked={
                                              selectedOperators[
                                                "examCodeOperator"
                                              ].value === "isNotEqual"
                                            }
                                            onChange={(value) =>
                                              setSelectedOperators({
                                                ...selectedOperators,
                                                examCodeOperator: {
                                                  value: "isNotEqual",
                                                  label: "is not equal to",
                                                  category: "modality",
                                                },
                                              })
                                            }
                                            borderColor={
                                              selectedOperators[
                                                "examCodeOperator"
                                              ].value === "isNotEqual"
                                                ? "#00AEEF"
                                                : "#D4D4D4"
                                            }
                                            backgroundColor={
                                              selectedOperators[
                                                "examCodeOperator"
                                              ].value === "isNotEqual"
                                                ? "#00AEEF"
                                                : "#FFF"
                                            }
                                          />
                                          exclude
                                        </Label>
                                      </RadioGroup>
                                    </SelectContainer>
                                  </div>
                                </SelectContainer>
                              </Row>
                            </RowContainer>
                            <RowContainer>
                              <RowHeader>Demographics</RowHeader>
                              <Row>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Language
                                  </SelectDropdownTitle>
                                  <Select
                                    components={{
                                      IndicatorsContainer,
                                      Option: RadioOption,
                                    }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={languageOptions}
                                    value={selectedValues["patient_language"]}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        patient_language: [value],
                                      })
                                    }
                                    styles={{
                                      option: (styles) => ({
                                        ...styles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </SelectContainer>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Patient Age
                                  </SelectDropdownTitle>
                                  <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={ageOptions}
                                    value={selectedValues["age"]}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        age: value,
                                      })
                                    }
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                      Option,
                                      IndicatorsContainer,
                                    }}
                                    allowSelectAll={true}
                                    styles={colourStyles}
                                  />
                                </SelectContainer>
                              </Row>
                            </RowContainer>

                            <RowContainer>
                              <RowHeader>Messaging</RowHeader>

                              <Row>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Cohort
                                  </SelectDropdownTitle>
                                  <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={cohortsOptions}
                                    value={selectedValues["cohort"]}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                      Option,
                                      IndicatorsContainer,
                                    }}
                                    allowSelectAll={true}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        cohort: value,
                                      })
                                    }
                                    styles={colourStyles}
                                  />
                                </SelectContainer>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Message Sent
                                  </SelectDropdownTitle>
                                  <Select
                                    components={{
                                      IndicatorsContainer,
                                      Option: RadioOption,
                                    }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={messageSentOptions}
                                    value={selectedValues["message_sent"]}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        message_sent: [value],
                                      })
                                    }
                                    styles={{
                                      option: (styles) => ({
                                        ...styles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </SelectContainer>
                              </Row>
                            </RowContainer>

                            <RowContainer>
                              <RowHeader>General</RowHeader>
                              <Row>
                                <SelectContainer>
                                  <SelectDropdownTitle>
                                    Referring Physician
                                  </SelectDropdownTitle>
                                  <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={referrerOptionsList}
                                    value={selectedValues["referrers"]}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                      Option,
                                      IndicatorsContainer,
                                    }}
                                    allowSelectAll={true}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        referrers: value,
                                      })
                                    }
                                    styles={colourStyles}
                                    onInputChange={onInputChange}
                                    placeholder={
                                      loading ? "Searching" : "Search lastname"
                                    }
                                    isLoading={loading}
                                    onMenuScrollToBottom={loadMoreOptions}
                                    onFocus={() => setReferrerList([])}
                                  />
                                </SelectContainer>
                                <SelectContainer width="464px">
                                  <SelectDropdownTitle>
                                    Location of Practice
                                  </SelectDropdownTitle>
                                  <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={locationOptions}
                                    value={selectedValues["location"]}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                      Option,
                                      IndicatorsContainer,
                                    }}
                                    allowSelectAll={true}
                                    onChange={(value) =>
                                      setSelectedValues({
                                        ...selectedValues,
                                        location: value,
                                      })
                                    }
                                    styles={colourStyles}
                                    onMenuScrollToBottom={
                                      loadMoreLocationOptions
                                    }
                                    isLoading={loadingMoreLocations}
                                  />
                                </SelectContainer>
                              </Row>
                            </RowContainer>
                          </BodyContainer>
                     

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding:'25px 30px',
                              borderTop:'1.5px solid #EFF3F5'
                             
                            }}
                          >
                                 <div
                            style={{
                              display: "flex",
                              gap:'10px',
                              flexWrap: "wrap",
                              width:'850px'
                              
                            }}
                          >
                              {Object.values(selectedValues).map(
                                (item, index) => {
                                  if (!item || !Array.isArray(item))
                                    return null;
                                  return item.map((i, idx) => (
                                    <SelectedItemContainer key={index + idx}>
                                      <SelectedItem>
                                        {" "}
                                        {i.category
                                          .replace(/_/g, " ")
                                          .replace(/\b\w/g, function (char) {
                                            return char.toUpperCase();
                                          })}{" "}
                                        :{" "}
                                        {((i.category === "modality" &&
                                          selectedOperators.modalityOperator
                                            .value === "isNotEqual") ||
                                          (i.category === "anatomy" &&
                                            selectedOperators.anatomyOperator
                                              .value === "isNotEqual") ||
                                          (i.category === "report_modality" &&
                                            selectedOperators
                                              .reportModalityOperator.value ===
                                              "isNotEqual") ||
                                          (i.category === "exam_code" &&
                                            selectedOperators.examCodeOperator
                                              .value === "isNotEqual")) &&
                                          "Is Not"}{" "}
                                        {i.category === "cohort"
                                          ? cohorts?.find(
                                              (itm) => itm.id === +i.value
                                            )?.cohort_title ?? ""
                                          : i.category === "referrers" ||
                                            i.category === "radiologist"
                                          ? i.label
                                          : i.value.replace(/_/g, ' ').replace(
                                              /\b\w/g,
                                              function (char) {
                                                return char.toUpperCase();
                                              }
                                            )}
                                      </SelectedItem>
                                      <CloseSign
                                        onClick={() =>
                                          closeSelected(i.category, i)
                                        }
                                      >
                                        <Image src={close} alt={"close sign"} />
                                      </CloseSign>
                                    </SelectedItemContainer>
                                  ));
                                }
                              )}
                          </div>
                          <div style={{   display: "flex",
                              justifyContent: "flex-end",
                              gap: "18px"}}> 
                          <Button onClick={() => setFilterOpen(false)}>
                              Cancel
                            </Button>

                            <Button
                              isPrimary={true}
                              disabled={
                                selectedValues["exam_code"]?.length > 0 &&
                                validationError
                                  ? true
                                  : false
                              }
                            >
                              Apply Filters
                            </Button>

                          </div>
                            
                          </div>
                        </div>
                      </form>
                    </FormContainer>
                  </div>
                </Card>
              </DialogContentStyled>
            </Dialog>
          )}
        </SettingsContainer>
      </FilterContainer>
    </>
  );
};
